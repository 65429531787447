import {
  deleteBatchApiData,
  getApiData,
  listApiData,
  postBatchApiData,
} from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState, useEffect } from "react";
import {
  decodeSocialSpend,
  encodeSocialSpend,
} from "social-pro-common/decoders/socialSpend";
import { SocialSpend } from "social-pro-common/entities/socialSpend";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";

export const useSocialSpend = (
  projectId?: string,
  reportId?: string,
  contractorPackageId?: string,
) => {
  const [socialSpends, setSocialSpends] = useState<SocialSpendLineItem[]>([]);
  const [isSocialSpendLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleApiError = (e: any, message: string) => {
    catchSentryError(e);
    setError(message);
  };

  const getSocialSpend = useCallback(
    async (id: string): Promise<SocialSpendLineItem | undefined> => {
      try {
        setIsLoading(true);
        const socialSpendResult = await getApiData(
          "getSocialSpend",
          "socialSpend",
          id,
        );
        if (
          !socialSpendResult.data ||
          Object.keys(socialSpendResult.data).length === 0
        ) {
          return undefined;
        }
        return decodeSocialSpend(socialSpendResult.data as SocialSpend);
      } catch (e: any) {
        handleApiError(e, "Could not fetch socialSpend");
      } finally {
        setIsLoading(false);
      }
    },
    [getApiData, decodeSocialSpend, handleApiError],
  );

  const listSocialSpend = useCallback(
    async (
      socialSpendId: string,
      reportId: string,
      contractorPackageId?: string,
    ): Promise<void> => {
      try {
        setIsLoading(true);
        const socialSpendResult = await listApiData(
          "listSocialSpend",
          "socialSpend",
          socialSpendId,
          {
            contractorPackageId,
            reportId,
          },
        );
        setSocialSpends(
          socialSpendResult.data.map((p) =>
            decodeSocialSpend(p as SocialSpend),
          ),
        );
      } catch (e: any) {
        handleApiError(e, "Could not list socialSpends");
      } finally {
        setIsLoading(false);
      }
    },
    [listApiData, decodeSocialSpend, handleApiError],
  );

  const createSocialSpend = useCallback(
    async (socialSpend: SocialSpendLineItem): Promise<SocialSpendLineItem> => {
      try {
        setIsLoading(true);
        const encodedSocialSpend = [encodeSocialSpend(socialSpend)];
        await postBatchApiData(
          "createSocialSpend",
          "socialSpend",
          encodedSocialSpend,
        );
        setSocialSpends((prevSocialSpends) => [
          ...prevSocialSpends,
          socialSpend,
        ]);
        return socialSpend;
      } catch (e: any) {
        handleApiError(e, "Could not create socialSpend");
      } finally {
        setIsLoading(false);
      }
      return socialSpend;
    },
    [postBatchApiData, encodeSocialSpend, handleApiError],
  );

  const updateSocialSpend = useCallback(
    async (socialSpend: SocialSpendLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedSocialSpend = [encodeSocialSpend(socialSpend)];
        await postBatchApiData(
          "upsertSocialSpend",
          "socialSpend",
          encodedSocialSpend,
        );
        setSocialSpends((prevSocialSpends) =>
          prevSocialSpends.map((c) =>
            c.id === socialSpend.id ? socialSpend : c,
          ),
        );
      } catch (e: any) {
        handleApiError(e, "Could not update socialSpend");
      } finally {
        setIsLoading(false);
      }
    },
    [postBatchApiData, encodeSocialSpend, handleApiError],
  );

  const upsertSocialSpends = useCallback(
    async (socialSpendsToUpsert: SocialSpendLineItem[]): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedSocialSpends = socialSpendsToUpsert.map((e) =>
          encodeSocialSpend(e),
        );
        await postBatchApiData(
          "upsertSocialSpend",
          "socialSpend",
          encodedSocialSpends,
        );

        const updatedSocialSpends = socialSpends.reduce((acc, socialSpend) => {
          const updatedSocialSpend = socialSpendsToUpsert.find(
            (e) => e.id === socialSpend.id,
          );
          if (updatedSocialSpend) {
            return [...acc, updatedSocialSpend];
          }
          return [...acc, socialSpend];
        }, [] as SocialSpendLineItem[]);

        const createdSocialSpend = socialSpendsToUpsert.filter(
          (sp) => !updatedSocialSpends.find((s) => s.id === sp.id),
        );
        setSocialSpends([...updatedSocialSpends, ...createdSocialSpend]);
      } catch (e: any) {
        handleApiError(e, "Could not upsert socialSpends");
      } finally {
        setIsLoading(false);
      }
    },
    [postBatchApiData, encodeSocialSpend, handleApiError],
  );

  const deleteSocialSpends = useCallback(
    async (socialSpendsToDelete: SocialSpendLineItem[]): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedSocialSpend = socialSpendsToDelete.map((e) =>
          encodeSocialSpend(e),
        );
        await deleteBatchApiData(
          "deleteSocialSpend",
          "socialSpend",
          encodedSocialSpend,
        );

        const deletedRowIds = socialSpendsToDelete.map((e) => e.id);
        setSocialSpends(
          socialSpends.filter((e) => !deletedRowIds.includes(e.id)),
        );
      } catch (e: any) {
        handleApiError(e, "Could not delete socialSpend");
      } finally {
        setIsLoading(false);
      }
    },
    [deleteBatchApiData, encodeSocialSpend, handleApiError],
  );

  useEffect(() => {
    if (projectId && reportId && contractorPackageId) {
      listSocialSpend(projectId, reportId, contractorPackageId);
    } else {
      setIsLoading(false);
    }
  }, [projectId, contractorPackageId, reportId]);

  return {
    createSocialSpend,
    deleteSocialSpends,
    error,
    getSocialSpend,
    isSocialSpendLoading,
    listSocialSpend,
    socialSpends,
    updateSocialSpend,
    upsertSocialSpends,
  };
};
