import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  Zoom,
} from "@mui/material";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import {
  GreenMetricCategoryLineItem,
  createDefaultGreenMetricCategory,
} from "social-pro-common/interfaces/greenMetricCategory";

import { GreenMetricFormTableRow } from "./GreenMetricFormTableRow";
import { NoRows } from "../ListTable/NoRows";

interface GreenMetricFormTableProps {
  loading: boolean;
  greenMetric: GreenMetricLineItem;
  greenMetricCategories: GreenMetricCategoryLineItem[];
  setGreenMetricCategories: (
    greenMetricCategory: GreenMetricCategoryLineItem[],
  ) => void;
  clearErrorMessage: () => void;
}

export const GreenMetricFormTable = ({
  clearErrorMessage,
  greenMetric,
  greenMetricCategories,
  loading,
  setGreenMetricCategories,
}: GreenMetricFormTableProps) => {
  const onClick = () => {
    setGreenMetricCategories([
      ...greenMetricCategories,
      createDefaultGreenMetricCategory(greenMetric.id),
    ]);
    clearErrorMessage();
  };
  return (
    <>
      <Table>
        <TableHead>
          <TableCell>Target Category</TableCell>
          <TableCell>Sub-Category</TableCell>
          <TableCell>Metric Type</TableCell>
        </TableHead>
        <TableBody>
          {greenMetricCategories?.length === 0 && !loading ? (
            <NoRows title={"No targets"} colSpan={4} />
          ) : null}
          {greenMetricCategories.map((greenMetricCategory) => {
            const onChange = (
              greenMetricCategory: GreenMetricCategoryLineItem,
            ) => {
              setGreenMetricCategories(
                greenMetricCategories.map((g) => {
                  if (g.id === greenMetricCategory.id) {
                    return greenMetricCategory;
                  }
                  return g;
                }),
              );
            };

            const onDelete = (
              greenMetricCategory: GreenMetricCategoryLineItem,
            ) => {
              setGreenMetricCategories(
                greenMetricCategories.filter(
                  (g) => g.id !== greenMetricCategory.id,
                ),
              );
            };
            return (
              <GreenMetricFormTableRow
                key={greenMetricCategory.id}
                loading={loading}
                greenMetricCategory={greenMetricCategory}
                handleChange={onChange}
                handleDelete={onDelete}
              />
            );
          })}
        </TableBody>
      </Table>
      <FormControlLabel
        sx={{
          alignItems: "center",
          flexDirection: "column",
          gap: "10px",
          margin: "auto",
        }}
        control={
          <Tooltip TransitionComponent={Zoom} placement="top" title="Upload">
            <IconButton
              color="primary"
              size="large"
              disabled={loading}
              onClick={onClick}
            >
              <ControlPointIcon
                sx={{
                  fontSize: "2rem",
                }}
              />
            </IconButton>
          </Tooltip>
        }
        label={"Add Category"}
      />
    </>
  );
};
