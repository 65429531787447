import { getApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import { decodeOrganisationLabourHourSubDataModel } from "social-pro-common/decoders/organisationDashboard";
import { OrganisationLabourHourSubDataModel } from "social-pro-common/entities/organisationDashboard";
import { OrganisationLabourHourSubDataModelLineItem } from "social-pro-common/interfaces/organisationDashboard";

export const useOrganisationLabourHourSubData = () => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [labourHourSubData, setLabourHourSubData] =
    useState<OrganisationLabourHourSubDataModelLineItem>();

  const getLabourHourSubData = useCallback(async () => {
    try {
      setIsAnalysisLoading(true);
      const analysisResult = await getApiData(
        "getLabourHourSubData",
        "organisationDashboard",
        "",
      );
      const labourHourSubData = decodeOrganisationLabourHourSubDataModel(
        analysisResult.data as OrganisationLabourHourSubDataModel,
      );
      setLabourHourSubData(labourHourSubData);
    } catch (error) {
      catchSentryError(error);
      setError("Could not fetch chart data");
    } finally {
      setIsAnalysisLoading(false);
    }
  }, [setIsAnalysisLoading, setError, setLabourHourSubData, getApiData, decodeOrganisationLabourHourSubDataModel, catchSentryError]);

  useEffect(() => {
    getLabourHourSubData();
  }, [getLabourHourSubData]);

  return {
    error,
    isAnalysisLoading,
    labourHourSubData,
  };
};