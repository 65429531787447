import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import {
  decodeProject,
  decodeProjectDetail,
  encodeProject,
  encodeProjectDetails,
} from "social-pro-common/decoders/project";
import { Project, ProjectDetail } from "social-pro-common/entities/project";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import {
  ProjectDetailLineItem,
  ProjectLineItem,
  newProjectToProjectDetail,
} from "social-pro-common/interfaces/project";

export const useProject = (
  userProfile?: ProfileLineItem,
  projectId?: string,
  archived?: boolean,
) => {
  const [selectedProject, setSelectedProject] = useState<ProjectLineItem>();
  const [projects, setProjects] = useState<ProjectDetailLineItem[]>([]);
  const [isProjectLoading, setIsProjectLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const acceptPackage = useCallback(
    async (projectId: string, contractorPackageId: string) => {
      try {
        setIsProjectLoading(true);
        await postApiData("acceptPackage", "contractorPackage", {
          contractorPackageId,
          projectId,
        });
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not accept package");
      } finally {
        setIsProjectLoading(false);
      }
    },
    [getImageUrl],
  );

  const getProject = useCallback(
    async (id: string) => {
      try {
        setIsProjectLoading(true);
        const project = (await getApiData("getProject", "project", id))
          .data as Project;
        if (project) {
          const projectLineItem = await decodeProject(project, getImageUrl);
          setSelectedProject(projectLineItem);
        } else {
          setSelectedProject(undefined);
        }
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not fetch project");
      } finally {
        setIsProjectLoading(false);
      }
    },
    [getImageUrl],
  );

  const listProjects = useCallback(
    async (organisationId: string, archived?: boolean) => {
      try {
        setIsProjectLoading(true);

        const projectRes = await listApiData(
          "listProjectDetails",
          "projectDetail",
          organisationId,
          {
            archived: archived ? "true" : undefined,
          },
        );
        const projectDetailLineItems = await Promise.all(
          projectRes.data.map((p) =>
            decodeProjectDetail(p as ProjectDetail, getImageUrl),
          ),
        );
        setProjects(projectDetailLineItems);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not list project details");
      } finally {
        setIsProjectLoading(false);
      }
    },
    [getImageUrl],
  );

  const createProject = useCallback(
    async (project: ProjectLineItem) => {
      try {
        setIsProjectLoading(true);
        await postApiData("createProject", "project", encodeProject(project));
        setProjects([newProjectToProjectDetail(project), ...projects]);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not create project");
      } finally {
        setIsProjectLoading(false);
      }
    },
    [projects],
  );

  const updateProject = useCallback(
    async (project: ProjectLineItem) => {
      try {
        setIsProjectLoading(true);
        await postApiData("updateProject", "project", encodeProject(project));
        setSelectedProject(project);
        setProjects(
          projects.map((c) => (c.id === project.id ? { ...c, ...project } : c)),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not update project");
      } finally {
        setIsProjectLoading(false);
      }
    },
    [projects],
  );

  const updateProjectDetail = useCallback(
    async (project: ProjectDetailLineItem) => {
      try {
        setIsProjectLoading(true);
        await postApiData(
          "upsertProjectDetail",
          "projectDetail",
          encodeProjectDetails(project),
        );
        setProjects(
          projects
            .map((c) => (c.id === project.id ? project : c))
            .filter((p) => p.archived === archived),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not update project detail");
      } finally {
        setIsProjectLoading(false);
      }
    },
    [projects, archived],
  );

  const deleteProject = useCallback(
    async (project: ProjectLineItem) => {
      try {
        setIsProjectLoading(true);
        await postApiData("deleteProject", "project", encodeProject(project));
        setProjects(projects.filter((p) => p.id !== project.id));
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not delete project");
      } finally {
        setIsProjectLoading(false);
      }
    },
    [projects],
  );

  useEffect(() => {
    if (projectId) {
      getProject(projectId);
    }
    if (userProfile) {
      listProjects(userProfile.organisationId, archived);
    }
  }, [userProfile, projectId, archived]);

  return {
    acceptPackage,
    createProject,
    deleteProject,
    error,
    getProject,
    isProjectLoading,
    listProjects,
    projects,
    selectedProject,
    setSelectedProject,
    updateProject,
    updateProjectDetail,
  };
};
