import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

export const LabourHourTableRowSkeleton = () => {
  return (
    <>
      <TableRow>
        <TableCell align="left" sx={{ width: "50%" }}>
          <Stack direction="row" spacing={2}>
            <Skeleton animation="wave" variant="circular">
              <InfoIcon color={"info"} />
            </Skeleton>

            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Skeleton animation="wave">
                <Typography>Commitment Name</Typography>
              </Skeleton>
            </Box>
          </Stack>
        </TableCell>
        <TableCell
          align="center"
          sx={{ textAlign: "center !important", width: "30%" }}
          className="projectTextField"
        >
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell
          align="center"
          sx={{ textAlign: "center !important", width: "10%" }}
        >
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell
          align="right"
          sx={{ textAlign: "right !important", width: "10%" }}
        >
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Stack direction="row" spacing={1}>
              <Skeleton animation="wave" variant="circular">
                <IconButton
                  color="primary"
                  sx={{
                    "&:hover ": {
                      background: "#e3f2fd",
                    },
                    borderColor: "#0063c6",
                    color: "#0063c6",
                  }}
                  size="large"
                >
                  <EditIcon sx={{ fontSize: "1.1rem" }} />
                </IconButton>
              </Skeleton>
              <Skeleton animation="wave" variant="circular">
                <IconButton color="error" size="large">
                  <DeleteIcon sx={{ fontSize: "1.1rem" }} />
                </IconButton>
              </Skeleton>
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
