import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePolicySubmission } from "@hooks/crud/policySubmission/usePolicySubmission";
import { Grid } from "@mui/material";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { Policies } from "@stories/organisms/Policies/Policies";

export const ProjectPolicies = () => {
  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const {
    createPolicySubmission,
    isPolicyLoading,
    policySubmissions,
    updatePolicySubmission,
  } = usePolicySubmission(selectedProject?.id, contractorPackage?.id);

  const loading = isAuthProfileLoading || isPolicyLoading || isProjectLoading;
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Project Policies"
          subTitle={userOrganisation?.organisationName}
        />

        <Grid
          item
          xs={12}
          md={12}
          sx={{
            padding: "0px !important",
          }}
        >
          <Policies
            loading={loading}
            project={selectedProject}
            contractorPackage={contractorPackage}
            policySubmissions={policySubmissions}
            createPolicySubmission={createPolicySubmission}
            updatePolicySubmission={updatePolicySubmission}
          />
        </Grid>
      </Grid>
    </>
  );
};
