import { useProjectContext } from "@hooks/context/useProjectContext";
import { Table, TableBody, TableHead } from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { LabourHoursAnalyticsModelLineItem } from "social-pro-common/interfaces/analytics";
import {
  labourHourCommitmentTypeToString,
  SocialCommitmentTypeHour,
} from "social-pro-common/interfaces/packageLabourHourCommitment";

import { ContractorAnalyticsLabourHoursRow } from "./ContractorAnalyticsLabourHoursRow";
import { ContractorAnalyticsMainRowSkeleton } from "../ContractorAnalyticsMainRow/ContractorAnalyticsMainRow";
import { NoRows } from "../ListTable/NoRows";

interface ContractorAnalyticsLabourHoursProps {
  loading: boolean;
  labourHourAnalytics?: LabourHoursAnalyticsModelLineItem;
  onLabourHourTargetChange: (targetId: string) => void;
}
export const ContractorAnalyticsLabourHours = ({
  labourHourAnalytics,
  loading,
  onLabourHourTargetChange,
}: ContractorAnalyticsLabourHoursProps) => {
  const { selectedProject } = useProjectContext();

  const tableHeader = (
    <TableHead>
      <TableHeader
        loading={loading}
        title={"Type"}
        sx={{ width: "30%" }}
        align={"left"}
      />
      <TableHeader
        loading={loading}
        title={"Target (Hours)"}
        sx={{ width: "10%" }}
      />
      <TableHeader
        loading={loading}
        title={"To Date (Hours)"}
        sx={{ width: "10%" }}
      />
      <TableHeader
        loading={loading}
        title={"This Month (Hours)"}
        sx={{ width: "10%" }}
      />
      <TableHeader loading={loading} title={"Progress"} sx={{ width: "10%" }} />
      <TableHeader loading={loading} title={"Actual"} sx={{ width: "10%" }} />
      <TableHeader
        loading={loading}
        title={"Action"}
        sx={{ width: "10%" }}
        align="center"
      />
    </TableHead>
  );

  const sortedTargets =
    labourHourAnalytics?.targets.sort((a, b) => {
      const firstTargetName =
        a?.targetDescription ||
        labourHourCommitmentTypeToString(
          a.targetType as SocialCommitmentTypeHour,
        );
      const secondTargetName =
        b?.targetDescription ||
        labourHourCommitmentTypeToString(
          b.targetType as SocialCommitmentTypeHour,
        );
      if (firstTargetName < secondTargetName) return -1;
      if (firstTargetName > secondTargetName) return 1;
      return 0;
    }) || [];
  return (
    <>
      {loading ? (
        <Table stickyHeader aria-label="sticky table">
          {tableHeader}
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <ContractorAnalyticsMainRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table
          data-test-id="contractor-analytics-labour-hours-table"
          stickyHeader
          aria-label="sticky table"
        >
          {tableHeader}
          <TableBody>
            {!loading && selectedProject
              ? sortedTargets.map((p) => {
                  return (
                    <ContractorAnalyticsLabourHoursRow
                      key={p.id}
                      project={selectedProject}
                      analysis={p}
                      onLabourHourTargetChange={onLabourHourTargetChange}
                    />
                  );
                })
              : null}
            {sortedTargets.length === 0 && !loading ? (
              <NoRows title={"labour hour analytics"} colSpan={6} />
            ) : null}
          </TableBody>
        </Table>
      )}
    </>
  );
};
