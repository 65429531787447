import useConfig from "@hooks/utils/useConfig";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  Theme,
} from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { useMemo, ReactNode } from "react";
import { CustomShadowProps } from "types/default-theme";

import componentStyleOverrides from "./compStyleOverride";
import Palette from "./palette";
import customShadows from "./shadows";
import Typography from "./typography";

// types

interface Props {
  children: ReactNode;
}

export default function ThemeCustomization({ children }: Props) {
  const {
    borderRadius,
    fontFamily,
    navType,
    outlinedFilled,
    presetColor,
    rtlLayout,
  } = useConfig();

  const theme: Theme = useMemo<Theme>(
    () => Palette(navType, presetColor),
    [navType, presetColor],
  );

  const themeTypography: TypographyOptions = useMemo<TypographyOptions>(
    () => Typography(theme, borderRadius, fontFamily),
    [theme, borderRadius, fontFamily],
  );
  const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(
    () => customShadows(navType, theme),
    [navType, theme],
  );

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      customShadows: themeCustomShadows,
      direction: rtlLayout ? "rtl" : "ltr",
      mixins: {
        toolbar: {
          "@media (min-width: 600px)": {
            minHeight: "48px",
          },
          minHeight: "48px",
          padding: "16px",
        },
      },
      palette: theme.palette,
      typography: themeTypography,
    }),
    [rtlLayout, theme, themeCustomShadows, themeTypography],
  );

  const themes: Theme = createTheme(themeOptions);
  themes.components = useMemo(
    () => componentStyleOverrides(themes, borderRadius, outlinedFilled),
    [themes, borderRadius, outlinedFilled],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
