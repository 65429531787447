import { Box, Stack } from "@mui/material";
import RegisterForm from "@stories/organisms/RegisterForm/RegisterForm";
import HeroBg from "assets/images/home/1.png";
import Logo from "assets/images/home/SocialPro_Blue.png";

const Register = () => {
  return (
    <Box className="loginPage">
      <img src={HeroBg} alt="HeroBg" className="loginBackground" />
      <Stack spacing={2} className="loginCard">
        <a href="/" style={{ marginBottom: "20px", textAlign: "center" }}>
          <img src={Logo} alt="" width="200px" />
        </a>

        <RegisterForm />
      </Stack>
    </Box>
  );
};

export default Register;
