import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";
import TrainingContractModal from "@stories/organisms/TrainingContractModal/TrainingContractModal";
import ViewTrainingContractModal from "@stories/organisms/TrainingContractModal/ViewTrainingContractModal";
import React from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";

interface EmployeeSupportingDocsFormRowSelectTraineeContractProps {
  errorMessage?: string;
  selectedEmployee: EmployeeLineItem;
  setEmployee: (employee: EmployeeLineItem) => void;
}

export const EmployeeSupportingDocsFormRowSelectTraineeContract = ({
  selectedEmployee,
  setEmployee,
}: EmployeeSupportingDocsFormRowSelectTraineeContractProps) => {
  const theme = useTheme();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [openViewModal, setViewModal] = React.useState(false);

  const clearTraineeDetails = () => {
    setEmployee({
      ...selectedEmployee,
      educationalInstitute: "",
      qualification: "",
      traineeContractEndDate: new Date(),
      trainingContractNumber: "",
      uniqueStudentId: "",
    });
  };
  return (
    <>
      <TableRow>
        <TableCell align="left" colSpan={1}>
          <Typography>{"Trainee/Apprentice Contract Details"}</Typography>
          <Stack>
            <Typography variant="caption">
              Unique Student ID:{" "}
              {selectedEmployee.uniqueStudentId ? (
                selectedEmployee.uniqueStudentId
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
            <Typography variant="caption">
              Training contract number:{" "}
              {selectedEmployee.trainingContractNumber ? (
                selectedEmployee.trainingContractNumber
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
            <Typography variant="caption">
              Qualification:{" "}
              {selectedEmployee.qualification ? (
                selectedEmployee.qualification
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
            <Typography variant="caption">
              Educational Institution:{" "}
              {selectedEmployee.educationalInstitute ? (
                selectedEmployee.educationalInstitute
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
            <Typography variant="caption">
              Contract End Date:{" "}
              {selectedEmployee.traineeContractEndDate ? (
                selectedEmployee.traineeContractEndDate.toLocaleDateString(
                  "en-GB",
                )
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip TransitionComponent={Zoom} placement="top" title="Edit">
              <IconButton
                color="error"
                sx={{
                  "&:hover ": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.light,
                  },
                  background: theme.palette.primary.light,
                  borderColor: "black",
                  color: theme.palette.primary.dark,
                }}
                size="large"
                onClick={() => setModalIsOpen(true)}
                data-test-id="edit-trainee-contract"
              >
                <EditIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip TransitionComponent={Zoom} placement="top" title="View">
              <IconButton
                color="error"
                sx={{
                  "&:hover ": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.light,
                  },
                  background: theme.palette.primary.light,
                  borderColor: "black",
                  color: theme.palette.primary.dark,
                }}
                disabled={
                  !selectedEmployee.uniqueStudentId ||
                  !selectedEmployee.uniqueStudentId.length
                }
                size="large"
                onClick={() => {
                  setViewModal(true);
                }}
              >
                <RemoveRedEyeIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title={"Delete"}
            >
              <IconButton
                color="error"
                sx={{
                  "&:hover ": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.error.light,
                  },
                  background: theme.palette.primary.light,
                  borderColor: "black",
                  color: theme.palette.error.dark,
                }}
                disabled={
                  !selectedEmployee.uniqueStudentId ||
                  !selectedEmployee.uniqueStudentId.length
                }
                size="large"
                onClick={() => clearTraineeDetails()}
              >
                <DeleteIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <TrainingContractModal
        open={modalIsOpen}
        setOpen={setModalIsOpen}
        selectedEmployee={selectedEmployee}
        setEmployee={setEmployee}
      />
      <ViewTrainingContractModal
        open={openViewModal}
        setOpen={setViewModal}
        selectedEmployee={selectedEmployee}
      />
    </>
  );
};
