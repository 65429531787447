import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import {
  decodeReportSubmission,
  encodeReportSubmission,
} from "social-pro-common/decoders/reportSubmission";
import { ReportSubmission } from "social-pro-common/entities/reportSubmission";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";

export const useReportSustainabilitySubmission = (
  contractorPackageId?: string,
) => {
  const [reportSubmissions, setReportSubmissions] = useState<
    ReportSubmissionLineItem[]
  >([]);
  const [isReportLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listReportSubmissions = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      const reportResult = await listApiData(
        "listReportSubmissions",
        "reportSubmissionSustainability",
        id,
      );
      reportResult.data.reverse();
      setReportSubmissions(
        reportResult.data.map((p) =>
          decodeReportSubmission(p as ReportSubmission),
        ),
      );
    } catch (e) {
      catchSentryError(e);
      setError("Could not list reports");
    } finally {
      setIsLoading(false);
    }
  }, [listApiData, decodeReportSubmission, setReportSubmissions, setIsLoading, catchSentryError]);

  const createReportSubmission = useCallback(
    async (report: ReportSubmissionLineItem) => {
      try {
        setIsLoading(true);
        const encodedReport = encodeReportSubmission(report);
        const upsertedReport = await postApiData(
          "createReportSubmission",
          "reportSubmissionSustainability",
          encodedReport,
        );
        const decodedReport = decodeReportSubmission(
          upsertedReport.data as ReportSubmission,
        );
        setReportSubmissions((prevState) => [...prevState, decodedReport]);
        return decodedReport;
      } catch (e) {
        catchSentryError(e);
        setError("Could not create report");
      } finally {
        setIsLoading(false);
      }
      return report;
    },
    [postApiData, encodeReportSubmission, decodeReportSubmission, setReportSubmissions, setIsLoading, catchSentryError]
  );

  const updateReportSubmission = useCallback(
    async (report: ReportSubmissionLineItem) => {
      try {
        setIsLoading(true);
        const encodedReport = encodeReportSubmission(report);
        await postApiData(
          "updateReportSubmission",
          "reportSubmissionSustainability",
          encodedReport,
        );
        setReportSubmissions((prevState) =>
          prevState.map((p) => (p.id === report.id ? report : p)),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not update report");
      } finally {
        setIsLoading(false);
      }
    },
    [postApiData, encodeReportSubmission, setReportSubmissions, setIsLoading, catchSentryError]
  );

  const deleteReportSubmission = useCallback(
    async (report: ReportSubmissionLineItem) => {
      try {
        setIsLoading(true);
        const encodedReport = encodeReportSubmission(report);
        await postApiData(
          "deleteReportSubmission",
          "reportSubmissionSustainability",
          encodedReport,
        );
        setReportSubmissions((prevState) =>
          prevState.filter((p) => p.id !== report.id),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not delete report");
      } finally {
        setIsLoading(false);
      }
    },
    [postApiData, encodeReportSubmission, setReportSubmissions, setIsLoading, catchSentryError]
  );

  const sendReportReminder = useCallback(
    async (contractorPackageId: string, reportId: string) => {
      try {
        await getApiData(
          "sendReportReminder",
          "reportSubmissionSustainability",
          contractorPackageId,
          { reportId },
        );
        toast("Reminder resent!", { type: "success" } as ToastOptions);
      } catch (e) {
        catchSentryError(e);
        toast("Could not resend reminder!", { type: "error" } as ToastOptions);
        setError("Could not resend reminder");
      }
    },
    [getApiData, toast, catchSentryError]
  );

  useEffect(() => {
    if (contractorPackageId) {
      listReportSubmissions(contractorPackageId);
    } else {
      setIsLoading(false);
    }
  }, [contractorPackageId, listReportSubmissions]);

  return {
    createReportSubmission,
    deleteReportSubmission,
    error,
    isReportLoading,
    listReportSubmissions,
    reportSubmissions,
    sendReportReminder,
    updateReportSubmission,
  };
};