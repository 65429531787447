import { useOrganisationLocalContentSubData } from "@hooks/crud/organisationDashboard/useOrganisationLocalContentSubData";
import { Grid } from "@mui/material";

import { AnzStack } from "../../Dashboard/ProjectProgress/AnzStack";
import { TripleTargetStack } from "../../Dashboard/ProjectProgress/TripleTargetStack";

interface OrganisationLocalContentProgressSubDataProps {
  loading: boolean;
}

export const OrganisationLocalContentProgressSubData = ({
  loading,
}: OrganisationLocalContentProgressSubDataProps) => {
  const { isAnalysisLoading, localContentSubData } =
    useOrganisationLocalContentSubData();
  const isLoading = loading || isAnalysisLoading;
  return (
    <Grid container justifyContent={"space-between"}>
      <AnzStack
        loading={isLoading}
        targets={localContentSubData?.localContentTargets || []}
        dataTestId={"local-content-targets"}
        compact
      />
      <TripleTargetStack
        loading={isLoading}
        targets={localContentSubData?.projects || []}
        title={"Subcontractors"}
        firstColumnTitle={"Name"}
        secondColumnTitle={"Reported ($)"}
        thirdColumnTitle={"ANZ Value %"}
        secondaryUnits="%"
        leftMargin
      />
    </Grid>
  );
};
