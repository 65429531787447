import {
  Button,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

export const PackageAnalyticsPolicySubRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="left" sx={{ width: "60%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Skeleton animation="wave" sx={{ margin: "auto !important" }}>
          <Typography align="center">Update</Typography>
        </Skeleton>
      </TableCell>
      <TableCell sx={{ textAlign: "right", width: "20%" }}>
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={{ margin: "auto !important" }}
        >
          <Button
            color="primary"
            size="large"
            className="blackBtn"
            sx={{
              color: "white",
              fontSize: "14px",
              lineHeight: "16px",
              padding: "10px 15px",
            }}
          >
            Download
          </Button>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
