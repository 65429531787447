//Public routes
export const login = "/login";
export const register = "/register";
export const forgotPassword = "/forgot-password";

//Private routes
export const organisation = "/organisation";
export const profile = "/profile";

export const createProfile = "/create-profile";

export const createAccount = "/create-account";
export const listProjects = "/list-projects";

export const employees = "/employees";
export const profileGroups = "/teams";
export const integrations = "/integrations";
export const organisationDashboard = "/organisation-dashboard";

export const platformSupport = "/platform-support";

export const users = "/users";

export const socialProAdmin = "/social-pro-admin";

export const forecast = (projectId: string, contractorPackageId: string) =>
  absolute(["forecast", projectId, contractorPackageId]);

export const profileGroup = (groupId?: string, panel?: string) =>
  absolute(["group", groupId, panel]);

export const project = (projectId: string, contractorPackageId: string) =>
  absolute(["project", projectId, contractorPackageId]);
export const userPackage = (projectId: string, contractorPackage?: string) =>
  absolute(["my-package", projectId, contractorPackage]);

export const blogDetail = (blogTitle?: string) => absolute(["blog", blogTitle]);

export const contractorDashboard = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["dashboard", projectId, contractorPackageId]);

export const contractorDashboardV2 = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["dashboard-v2", projectId, contractorPackageId]);

export const contractorDashboardPackage = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["dashboard", projectId, contractorPackageId, "package"]);

export const contractors = (projectId: string, contractorPackageId: string) =>
  absolute(["sub-contractors", projectId, contractorPackageId]);

export const packages = (projectId: string, contractorPackageId: string) =>
  absolute(["packages", projectId, contractorPackageId]);

export const projectEmployees = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["project-employees", projectId, contractorPackageId]);

export const projectPolicies = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["project-policies", projectId, contractorPackageId]);

export const projectSustainability = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["project-sustainability", projectId, contractorPackageId]);

export const sustainability = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["sustainability", projectId, contractorPackageId]);

export const contractorPackage = (
  projectId: string,
  contractorPackageId: string,
  packageId: string,
) => absolute(["package-details", projectId, contractorPackageId, packageId]);

export const analyticsSpend = (
  projectId: string,
  contractorPackageId: string,
  reportId?: string,
) => absolute(["analytics-spend", projectId, contractorPackageId, reportId]);

export const analyticsViewSpend = (
  projectId: string,
  contractorPackageId: string,
  reportId: string,
  targetId: string,
) =>
  absolute([
    "view-analytics-spend",
    projectId,
    contractorPackageId,
    reportId,
    targetId,
  ]);

export const analyticsEnviroSustainability = (
  projectId: string,
  contractorPackageId: string,
  reportId: string,
) =>
  absolute([
    "analytics-sustainability",
    projectId,
    contractorPackageId,
    reportId,
  ]);

//TODO: Does this really need contractor package id and package id??
export const analyticsEnviroSustainabilityDetails = (
  projectId: string,
  contractorPackageId: string,
  packageId: string,
  reportId: string,
) =>
  absolute([
    "package-analytics-sustainability",
    projectId,
    contractorPackageId,
    packageId,
    reportId,
  ]);

export const analyticsLabourHours = (
  projectId: string,
  contractorPackageId: string,
  reportId?: string,
) =>
  absolute([
    "analytics-labour-hours",
    projectId,
    contractorPackageId,
    reportId,
  ]);

export const analyticsViewLabourHours = (
  projectId: string,
  contractorPackageId: string,
  reportId: string,
  targetId: string,
) =>
  absolute([
    "view-analytics-labour-hours",
    projectId,
    contractorPackageId,
    reportId,
    targetId,
  ]);

export const analyticsPolicies = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["analytics-policies", projectId, contractorPackageId]);

export const analyticsLocalContent = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["analytics-localContent", projectId, contractorPackageId]);

export const analyticsLocalContentDetails = (
  projectId: string,
  contractorPackageId: string,
  packageId: string,
) =>
  absolute([
    "analytics-localContent",
    projectId,
    contractorPackageId,
    packageId,
  ]);

export const analyticsPackageLocalContentDetails = (
  projectId: string,
  contractorPackageId: string,
  packageId: string,
) =>
  absolute([
    "package-analytics-local-content",
    projectId,
    contractorPackageId,
    packageId,
  ]);

export const contractorPackageReports = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["report-packages", projectId, contractorPackageId]);

export const contractorProjectReports = (
  projectId: string,
  contractorPackageId: string,
) => absolute(["report-project", projectId, contractorPackageId]);

export const wizard = (
  projectId: string,
  contractorPackageId: string,
  reportId: string,
) => absolute(["wizard", projectId, reportId, contractorPackageId]);

export const wizardOneTime = (projectId: string, contractorPackageId: string) =>
  absolute(["one-time-wizard", projectId, contractorPackageId]);

export const readOnlyReportSocial = (
  projectId: string,
  contractorPackageId: string,
  packageId: string,
  reportId: string,
) =>
  absolute([
    "view-social-report",
    projectId,
    contractorPackageId,
    packageId,
    reportId,
  ]);

export const readOnlyReportSustainability = (
  projectId: string,
  contractorPackageId: string,
  packageId: string,
  reportId: string,
) =>
  absolute([
    "view-sustainability-report",
    projectId,
    contractorPackageId,
    packageId,
    reportId,
  ]);

export const sustainabilityWizard = (
  projectId: string,
  contractorPackageId: string,
  reportId: string,
) =>
  absolute(["sustainability-wizard", projectId, contractorPackageId, reportId]);

export const projectSocialWizard = (
  projectId: string,
  contractorPackageId: string,
  reportId: string,
) =>
  absolute(["project-social-wizard", projectId, contractorPackageId, reportId]);

export const projectSustainabilityWizard = (
  projectId: string,
  contractorPackageId: string,
  reportId: string,
) =>
  absolute([
    "project-sustainability-wizard",
    projectId,
    contractorPackageId,
    reportId,
  ]);

export const directory = (
  projectId: string,
  contractorPackageId: string,
  tagetId?: string,
) => absolute(["directory", projectId, contractorPackageId, tagetId]);

export const absolute = (params: (string | number | undefined)[]): string =>
  `/${params.filter((a) => Boolean(a)).join("/")}`;

export const faqQuestion = (faqId: string) => absolute(["faq-question", faqId]);
