import { useAuthContext } from "@hooks/context/useAuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Zoom,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import {
  ProfileLineItem,
  profileTypeToString,
} from "social-pro-common/interfaces/profile";

import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";


interface UserTableRowProps {
  profile: ProfileLineItem;
  handleEditUser: (profile: ProfileLineItem) => void;
  handleDeleteUser: (profile: ProfileLineItem) => void;
}

export const UserTableRow = ({
  handleDeleteUser,
  handleEditUser,
  profile,
}: UserTableRowProps) => {
  const theme = useTheme();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { userProfile } = useAuthContext();
  return (
    <>
      <TableRow key={profile.id}>
        <TableCell align="left">{profile.name}</TableCell>
        <TableCell align="left">{profile.email}</TableCell>
        <TableCell align="left">
          {profileTypeToString(profile.isAdmin)}
        </TableCell>
        <TableCell align="center">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Tooltip TransitionComponent={Zoom} placement="top" title="Edit">
              <IconButton
                color="primary"
                disabled={userProfile?.id === profile.id}
                sx={{
                  "&:hover ": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.light,
                  },
                  borderColor: "black",
                  color: theme.palette.primary.dark,
                }}
                size="large"
                onClick={() => handleEditUser(profile)}
              >
                <EditIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip TransitionComponent={Zoom} placement="top" title="Delete">
              <IconButton
                disabled={userProfile?.id === profile.id}
                color="error"
                sx={{
                  "&:hover ": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.error.light,
                  },
                  borderColor: "black",
                  color: theme.palette.error.dark,
                }}
                size="large"
                onClick={() => setOpenConfirmDialog(true)}
              >
                <DeleteIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Are you sure you want to delete this user?"}
        open={openConfirmDialog}
        title={"Delete User"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleDeleteUser(profile)}
      />
    </>
  );
};
