import {
  Button,
  Chip,
  Grid,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Avatar from "@stories/ui-component/extended/Avatar";

export const ReportSubmissionTableSkeleton = () => {
  return (
    <TableRow key={`sub-contractor-row-${0}`}>
      <TableCell align="left">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ justifySelf: "center" }}
        >
          <Grid item>
            <Skeleton animation="wave" variant="circular">
              <Avatar alt="User 1" />
            </Skeleton>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography align="left" variant="subtitle1" component="div">
              <Skeleton animation="wave" />
            </Typography>
            <Typography align="left" variant="subtitle2" noWrap>
              <Skeleton animation="wave" sx={{ width: "25%" }} />
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" variant="rounded" />
      </TableCell>
      <TableCell align="center" className="center">
        <Skeleton animation="wave" sx={{ margin: "auto" }} variant="rounded">
          <Chip label="Overdue" size="small" />
        </Skeleton>
      </TableCell>
      <TableCell align="center" className="center">
        <Stack
          direction="row"
          spacing={2}
          alignContent={"end"}
          justifyContent={"end"}
        >
          <Skeleton animation="wave" sx={{ margin: "auto" }} variant="rounded">
            <Button />
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
