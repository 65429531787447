import { Card, Table, TableBody } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

import { SocialSpendTableRow } from "./SocialSpendTableRow";
import { SocialSpendTableRowSkeleton } from "./SocialSpendTableRowSkeleton";

interface SocialSpendTableProps {
  loading: boolean;
  project?: ProjectLineItem;
  socialSpends: SocialSpendLineItem[];
  filterName?: string;
  readOnly?: boolean;
  handleEditSocialSpend: (socialSpend: SocialSpendLineItem) => void;
  handleDeleteSocialSpend: (socialSpend: SocialSpendLineItem[]) => void;
}

export const SocialSpendTable = ({
  filterName,
  handleDeleteSocialSpend,
  handleEditSocialSpend,
  loading,
  project,
  readOnly,
  socialSpends,
}: SocialSpendTableProps) => {
  const TABLE_HEAD: TableHeaderCell[] = [
    { align: "left", id: "transaction-date", label: "Transaction Date" },
    { align: "left", id: "socail-factors", label: "Social Factors" },
    { align: "left", id: "supplier", label: "Supplier" },
    { align: "left", id: "abn", label: "ABN" },
    {
      align: "left",
      id: "nature-of-expenditure",
      label: "Nature of Expenditure",
    },
    {
      align: "center",
      id: "invoice-value",
      label: "Invoice Value (ex. GST)",
    },
    // {
    //   id: "certified",
    //   label: "Certified",
    //   align: "left",
    // },
    { id: "" },
  ];

  const filteredSocialSpends = filterName?.length
    ? socialSpends.filter((t) =>
        t.supplier.toLowerCase().includes(filterName.toLowerCase()),
      )
    : socialSpends;

  const isNotFound = !socialSpends.length && Boolean(filterName);

  const tableHeader = (
    <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
  );

  return (
    <Card>
      {loading ? (
        <Table>
          {tableHeader}
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <SocialSpendTableRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table data-test-id="spend-submission-table">
          {tableHeader}
          <TableBody>
            {filteredSocialSpends.map((row) => {
              return (
                <SocialSpendTableRow
                  key={row.id}
                  loading
                  project={project}
                  socialSpend={row}
                  readOnly={readOnly}
                  handleEditSocialSpend={handleEditSocialSpend}
                  handleDeleteSocialSpend={handleDeleteSocialSpend}
                />
              );
            })}

            {isNotFound && (
              <NoResultsFoundRow
                colSpan={TABLE_HEAD.length}
                filterName={"filterName"}
              />
            )}
            {socialSpends.length === 0 ? (
              <NoRows
                colSpan={TABLE_HEAD.length}
                title={"eligible spend records"}
              />
            ) : null}
          </TableBody>
        </Table>
      )}
    </Card>
  );
};
