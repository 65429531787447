import { Grid, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { SustainabilityTargetAnalyticLineItem } from "social-pro-common/interfaces/analytics";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";

import { ContractorAnalyticsEnviroSustainabilityRowSkeleton } from "./ContractorAnalyticsEnviroSustainabilityRowSkeleton";
import { ContractorAnalyticsEnviroSustainabilityTableRow } from "./ContractorAnalyticsEnviroSustainabilityTableRow";
import { NoRows } from "../ListTable/NoRows";

interface ContractorAnalyticsEnviroSustainabilityTableProps {
  loading: boolean;
  greenMetric?: GreenMetricLineItem;
  targets: SustainabilityTargetAnalyticLineItem[];
}
export const ContractorAnalyticsEnviroSustainabilityTable = ({
  greenMetric,
  loading,
  targets,
}: ContractorAnalyticsEnviroSustainabilityTableProps) => {
  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader loading={loading} title={"Category"} align="left" />
        <TableHeader loading={loading} title={"Type"} align="left" />
        <TableHeader loading={loading} title={"To date"} align="center" />
        <TableHeader loading={loading} title={"This month"} align="center" />
        <TableHeader loading={loading} title={"% increase"} align="center" />
        <TableHeader loading={loading} title={""} align="left" />
      </TableRow>
    </TableHead>
  );

  return (
    <Grid item xs={12} md={12}>
      {loading ? (
        <Table>
          {tableHeader}
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <ContractorAnalyticsEnviroSustainabilityRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : null}
      {!loading ? (
        <Table data-test-id="sustainability-table">
          {tableHeader}
          <TableBody>
            {targets.map((target, index) => {
              return (
                <ContractorAnalyticsEnviroSustainabilityTableRow
                  key={`green-metric-table-row-${index}`}
                  greenMetric={greenMetric}
                  target={target}
                />
              );
            })}
            {!targets.length ? (
              <NoRows title="Sustainability Submissions" colSpan={6} />
            ) : null}
          </TableBody>
        </Table>
      ) : null}
    </Grid>
  );
};
