import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface NoPackagesModalProps {
  handleClose: () => void;
}

export default function NoPackagesModal({ handleClose }: NoPackagesModalProps) {
  return (
    <div>
      <Dialog
        open
        sx={{ "& .MuiDialog-paper": { maxHeight: 435, width: "80%" } }}
        maxWidth="xs"
        TransitionComponent={Transition}
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle>{"Project Report Warning"}</DialogTitle>
        <DialogContent>
          <Typography>
            Please add Subcontractors to generate Project Reports.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            data-test-id="confirm-dialog-button-ok"
            variant={"contained"}
            onClick={handleClose}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
