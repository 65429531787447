import { getApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState, useEffect } from "react";
import { decodeLocalContentSubDataModel } from "social-pro-common/decoders/dashboard";
import { LocalContentSubDataModel } from "social-pro-common/entities/dashboard";
import { LocalContentSubDataModelLineItem } from "social-pro-common/interfaces/dashboard";

export const useLocalContentSubData = (
  projectId?: string,
  packageId?: string,
  reportId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [localContentSubData, setLocalContentSubData] =
    useState<LocalContentSubDataModelLineItem>();

  const getLocalContentSubDataModel = useCallback(
    async (
      projectId: string,
      packageId: string,
      reportId: string,
    ): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getLocalContentSubData",
          "dashboard-v2",
          projectId,
          { packageId, reportId },
        );
        const localContentSubData = decodeLocalContentSubDataModel(
          analysisResult.data as LocalContentSubDataModel,
        );
        setLocalContentSubData(localContentSubData);
      } catch (error) {
        catchSentryError(error);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [getApiData, decodeLocalContentSubDataModel, catchSentryError],
  );

  useEffect(() => {
    if (projectId && packageId && reportId) {
      getLocalContentSubDataModel(projectId, packageId, reportId);
    }
  }, [projectId, packageId, reportId, getLocalContentSubDataModel]);

  return {
    error,
    isAnalysisLoading,
    localContentSubData,
  };
};