import { CircularProgress, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FileGenerationStatus } from "social-pro-common/entities/fileGeneration";

import { CircularProgressWithLabel } from "../CircularProgressWithLabel/CircularProgressWithLabel";


export interface PdfDownlodDialogProps {
  downloadStep: FileGenerationStatus;
  total: number;
  title: string;
}

export const PdfDownlodDialog = ({
  downloadStep,
  title,
  total,
}: PdfDownlodDialogProps) => {
  const displayMessage = (() => {
    switch (downloadStep) {
      default:
      case FileGenerationStatus.Fetching:
        return "Requesting...";
      case FileGenerationStatus.Collecting:
        return "Preparing data...";
      case FileGenerationStatus.Generating:
        return "Generating documents...";
      case FileGenerationStatus.Zipping:
        return "Compressing...";
      case FileGenerationStatus.Generated:
        return "Generated...";
      case FileGenerationStatus.Downloading:
        return "Downloading...";
      case FileGenerationStatus.Error:
        return "Error...";
    }
  })();

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: 435, width: "80%" } }}
      maxWidth="xs"
      open={downloadStep !== FileGenerationStatus.Idle}
      className="bgBlueOverlay"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2}>
            <Box sx={{ margin: "auto !important" }}>
              {total === 0 ? (
                <CircularProgress />
              ) : (
                <CircularProgressWithLabel value={total} />
              )}
            </Box>
            <Typography>{displayMessage}</Typography>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
