import { useLocalContentSubData } from "@hooks/crud/dashboard/useLocalContentSubData";
import { Grid } from "@mui/material";

import { AnzStack } from "../ProjectProgress/AnzStack";
import { TargetStack } from "../ProjectProgress/TargetStack";

interface LocalContentProgressSubDataProps {
  loading: boolean;
  projectId?: string;
  packageId?: string;
  reportId?: string;
}

export const LocalContentProgressSubData = ({
  loading,
  packageId,
  projectId,
  reportId,
}: LocalContentProgressSubDataProps) => {
  const { isAnalysisLoading, localContentSubData } = useLocalContentSubData(
    projectId,
    packageId,
    reportId,
  );
  const isLoading = loading || isAnalysisLoading;
  return (
    <Grid container justifyContent={"space-between"}>
      <AnzStack
        loading={isLoading}
        targets={localContentSubData?.localContentTargets || []}
        dataTestId={"local-content-targets"}
      />
      <TargetStack
        loading={isLoading}
        targets={localContentSubData?.subcontractors || []}
        title={"Subcontractors"}
        dataTestId={"local-content-subcontractors"}
        firstColumnTitle={"Name"}
        secondColumnTitle={"%"}
        units="%"
        leftMargin
      />
    </Grid>
  );
};
