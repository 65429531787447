import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { TrainingContractForm } from "@stories/molecules/TrainingContractForm/TrainingContractForm";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";

interface TrainingContractModalProps {
  open: boolean;
  selectedEmployee: EmployeeLineItem;
  setEmployee: (employee: EmployeeLineItem) => void;
  setOpen: (value: boolean) => void;
}

export default function TrainingContractModal({
  open,
  selectedEmployee,
  setEmployee,
  setOpen,
}: TrainingContractModalProps) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className={"bgBlueOverlay"}
    >
      <DialogTitle sx={{ fontSize: "24px", padding: "20px 40px 10px" }}>
        {"Add Training Contract Details"}
      </DialogTitle>
      <DialogContent sx={{ padding: "20px 40px 40px" }}>
        <TrainingContractForm
          selectedEmployee={selectedEmployee}
          setEmployee={setEmployee}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}
