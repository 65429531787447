import { Typography, Box, Button, Stack } from "@mui/material";
import { months } from "moment";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { ProjectReportSubmissionLineItem } from "social-pro-common/interfaces/projectReportSubmission";
import {
  dateToStringWithoutOffset,
  stringToDate,
} from "social-pro-common/utils/date";
import { getZipTitle } from "social-pro-common/utils/string";

interface ReportCardProps {
  report: ProjectReportSubmissionLineItem;
  dueDate: number;
  isPrimaryPackage: boolean;
  handleDownloadReport?: (reportId: string, reportTitle: string) => void;
  handlViewReport: (reportMonthId: string) => void;
}

const reportStatusToFill = (reportStatus: ReportStatus) => {
  switch (reportStatus) {
    case ReportStatus.Accepted:
    case ReportStatus.Complete:
      return "#B3D2B1";
    case ReportStatus.InProgress:
    case ReportStatus.ToSubmit:
      return "#faf7bb";
    case ReportStatus.Upcoming:
      return "#CDD1E8";
    case ReportStatus.Overdue:
      return "#FEC0CA";
  }
};

const reportStatusToBorder = (reportStatus: ReportStatus) => {
  switch (reportStatus) {
    case ReportStatus.Accepted:
    case ReportStatus.Complete:
      return "#499943";
    case ReportStatus.InProgress:
    case ReportStatus.ToSubmit:
      return "#ffb74d";
    case ReportStatus.Upcoming:
      return "#748DFD";
    case ReportStatus.Overdue:
      return "#FF3B35";
  }
};

export const ReportCard = ({
  dueDate,
  handlViewReport,
  handleDownloadReport,
  isPrimaryPackage,
  report,
}: ReportCardProps) => {
  const reportDate = stringToDate(report.reportId);
  const reportTitle = getZipTitle(reportDate);

  const reportDueDate = new Date(
    reportDate.getFullYear(),
    reportDate.getMonth(),
    dueDate,
  );
  return (
    <Box
      className="report-card"
      sx={{
        alignItems: "center",
        background: "#fff",
        border: "1px solid #C6D3DE",
        borderRadius: "5px",
        display: "flex",
        gap: "10px",
        marginBottom: "10px",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flex: "2",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            backgroundColor: `${reportStatusToFill(report.reportStatus)}`,
            border: `2px solid ${reportStatusToBorder(report.reportStatus)}`,
            borderRadius: "50%",
            height: "15px",
            marginTop: "5px",
            width: "15px",
          }}
         />
        <Box>
          <Typography
            data-test-id="report-card-title"
            sx={{
              color: "black",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "1.4",
            }}
          >
            {`${months(reportDate.getMonth())} ${reportDate.getFullYear()}`}
          </Typography>
          <Typography
            data-test-id="report-due-date"
            sx={{
              color: "#b9b9b9",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "1.4",
            }}
          >
            Due {dateToStringWithoutOffset(reportDueDate, "DD/MM/yyyy")}
          </Typography>
        </Box>
      </Box>
      <Stack direction="row" spacing={2}>
        {isPrimaryPackage &&
        (report.reportStatus === ReportStatus.Complete ||
          report.reportStatus === ReportStatus.Accepted) ? (
          <Button
            data-test-id="edit-report-button"
            color="primary"
            size="small"
            onClick={() => handlViewReport(report.reportId)}
            className="blackBtn"
            sx={{
              color: "white",
              fontSize: "11px !important",
              fontWeight: "600 !important",
              lineHeight: "14px",
              minWidth: "auto !important",
              padding: "4px 10px!important",
            }}
          >
            Edit Report
          </Button>
        ) : undefined}
        {report.reportStatus !== ReportStatus.Upcoming ? (
          report.reportStatus === ReportStatus.Complete ||
          report.reportStatus === ReportStatus.Accepted ? (
            handleDownloadReport ? (
              <Button
                data-test-id="download-report-button"
                color="primary"
                size="small"
                onClick={() =>
                  handleDownloadReport(report.reportId, reportTitle)
                }
                className="blackBtn"
                sx={{
                  color: "white",
                  fontSize: "11px !important",
                  fontWeight: "600 !important",
                  lineHeight: "14px",
                  minWidth: "auto !important",
                  padding: "4px 10px!important",
                }}
              >
                Download
              </Button>
            ) : null
          ) : (
            <Button
              data-test-id="submit-report-button"
              color="primary"
              size="small"
              onClick={() => handlViewReport(report.reportId)}
              className="blackBtn"
              sx={{
                color: "white",
                fontSize: "11px !important",
                fontWeight: "600 !important",
                lineHeight: "14px",
                minWidth: "auto !important",
                padding: "4px 10px!important",
              }}
            >
              Submit Report
            </Button>
          )
        ) : undefined}
      </Stack>
    </Box>
  );
};
