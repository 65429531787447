import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, IconButton, Stack } from "@mui/material";
import OneTimePackageDetailsForm from "@stories/molecules/OneTimePackageDetailsForm/OneTimePackageDetailsForm";
import { useEffect, useState } from "react";
import {
  ContractorPackageLineItem,
  ContractorPackageSetupLineItem,
} from "social-pro-common/interfaces/contractorPackage";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import "./ContractorPackageModalStyles.css";

interface OneTimeContractorPackageModalProps {
  open: boolean;
  loading: boolean;
  project: ProjectLineItem;
  packageCount: number;
  selectedPackageId?: string;
  createContractorOneTimePackage: (
    organisation: OrganisationLineItem,
    contractorPackage: ContractorPackageLineItem,
    profile?: ProfileLineItem,
  ) => void;
  updateContractorOneTimePackage: (
    organisation: OrganisationLineItem,
    contractorPackage: ContractorPackageLineItem,
    profile?: ProfileLineItem,
  ) => void;
  getContractorOneTimePackage: (
    contractorPackageId: string,
  ) => Promise<ContractorPackageSetupLineItem | undefined>;
  handleClose: () => void;
}

export default function OneTimeContractorPackageModal({
  createContractorOneTimePackage,
  getContractorOneTimePackage,
  handleClose,
  loading,
  open,
  packageCount,
  project,
  selectedPackageId,
  updateContractorOneTimePackage,
}: OneTimeContractorPackageModalProps) {
  const [selectedOneTimePackage, setSelectedOneTimePackage] = useState<
    ContractorPackageSetupLineItem | undefined
  >();

  useEffect(() => {
    const fetchContractorPackage = async () => {
      if (selectedPackageId) {
        const contractorPackage =
          await getContractorOneTimePackage(selectedPackageId);
        if (contractorPackage) {
          setSelectedOneTimePackage(contractorPackage);
        }
      }
    };
    if (selectedPackageId) {
      fetchContractorPackage();
    }
  }, [selectedPackageId]);

  const onSubmit = async (
    organisation: OrganisationLineItem,
    contractorPackage: ContractorPackageLineItem,
    profile?: ProfileLineItem,
  ) => {
    if (!selectedPackageId) {
      await createContractorOneTimePackage(
        organisation,
        contractorPackage,
        profile,
      );
    } else {
      await updateContractorOneTimePackage(
        organisation,
        contractorPackage,
        profile,
      );
    }
    handleClose();
  };

  const isLoading = loading;

  const onClose = () => {
    handleClose();
  };
  return (
    <Dialog fullWidth open={open} maxWidth={"sm"} className="bgBlueOverlay">
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          "&:hover": {
            backgroundColor: "#143E7C",
          },
          background: "black",
          color: "white",
          padding: "5px",
          position: "absolute",
          right: 24,
          top: 30,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        className=" custom-scrollbar"
        sx={{ borderRadius: "12px", padding: "0" }}
      >
        <Stack spacing={2}>
          <div style={{ margin: "0" }} className="">
            <OneTimePackageDetailsForm
              loading={isLoading}
              packageCount={packageCount}
              project={project}
              selectedOneTimePackage={selectedOneTimePackage}
              handleSubmit={onSubmit}
            />
          </div>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
