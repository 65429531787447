import { listApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import { decodePackageLocalContent } from "social-pro-common/decoders/packageLocalContent";
import { PackageLocalContent } from "social-pro-common/entities/packageLocalContent";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";

export const usePackageLocalContentAcc = (
  projectId?: string,
  packageId?: string,
  reportId?: string,
) => {
  const [packageLocalContents, setPackageLocalContents] = useState<
    PackageLocalContentLineItem[]
  >([]);
  const [isPackageLocalContentLoading, setIsPackageLocalContentLoading] =
    useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listPackageLocalContent = useCallback(
    async (projectId: string, packageId: string, reportId: string) => {
      try {
        setIsPackageLocalContentLoading(true);
        const localContentResult = await listApiData(
          "listPackageLocalContentAcc",
          "packageLocalContent",
          projectId,
          {
            packageId,
            reportId,
          },
        );
        const localContents = localContentResult.data.map((p) =>
          decodePackageLocalContent(p as PackageLocalContent),
        );
        setPackageLocalContents(localContents);
      } catch (error) {
        catchSentryError(error);
        setError("Could not list localContents");
      } finally {
        setIsPackageLocalContentLoading(false);
      }
    },
    [setPackageLocalContents, setIsPackageLocalContentLoading, setError],
  );

  useEffect(() => {
    if (projectId && packageId && reportId) {
      listPackageLocalContent(projectId, packageId, reportId);
    } else {
      setIsPackageLocalContentLoading(false);
    }
  }, [projectId, packageId, reportId, listPackageLocalContent]);

  return {
    error,
    isPackageLocalContentLoading,
    packageLocalContents,
  };
};