import { getApiData, listApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { debounce } from "@mui/material";
import { catchSentryError } from "@utils/sentry";
import * as React from "react";
import { decodeSocialBusiness } from "social-pro-common/decoders/socialBusiness";
import { SocialBusiness } from "social-pro-common/entities/socialBusiness";
import { SocialBusinessLineItem } from "social-pro-common/interfaces/socialBusiness";

export interface SocialBusinessSearchParams {
  targetId?: string;
  serviceLocation?: string;
  queryString?: string;
}

export const useSocialBusiness = (
  searchParams?: SocialBusinessSearchParams,
) => {
  const [socialBusinesses, setSocialBusinesses] = React.useState<
    SocialBusinessLineItem[]
  >([]);
  const [isSocialBusinessLoading, setIsSocialBusinessLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listSocialBusinesses = React.useCallback(async (socialFactor: string) => {
    try {
      setIsSocialBusinessLoading(true);
      setSocialBusinesses([]);
      const organisationResult = await listApiData(
        "listSocialBusinesses",
        "socialBusiness",
        socialFactor,
      );

      const socialBusinessLineItems = await Promise.all(
        organisationResult.data.map((p) =>
          decodeSocialBusiness(p as SocialBusiness, getImageUrl),
        ),
      );
      setSocialBusinesses(socialBusinessLineItems);
    } catch (e) {
      catchSentryError(e);
      setError("Could not list organisations");
    } finally {
      setIsSocialBusinessLoading(false);
    }
  }, [listApiData, decodeSocialBusiness, getImageUrl, catchSentryError]);

  const searchSocialBusinesses = React.useCallback(async (
    searchParams: SocialBusinessSearchParams,
  ) => {
    try {
      setIsSocialBusinessLoading(true);
      setSocialBusinesses([]);
      const organisationResult = await listApiData(
        "searchSocialBusinesses",
        "socialBusiness",
        "",
        { ...searchParams },
      );

      const socialBusinessLineItems = await Promise.all(
        organisationResult.data.map((p) =>
          decodeSocialBusiness(p as SocialBusiness, getImageUrl),
        ),
      );
      setSocialBusinesses(socialBusinessLineItems);
    } catch (e) {
      catchSentryError(e);
      setError("Could not list organisations");
    } finally {
      setIsSocialBusinessLoading(false);
    }
  }, [listApiData, decodeSocialBusiness, getImageUrl, catchSentryError]);

  const searchSocialBusinessesByABN = React.useCallback(async (
    abn: string,
  ) => {
    try {
      setIsSocialBusinessLoading(true);
      setSocialBusinesses([]);
      const organisationResult = await getApiData(
        "searchSocialBusinessesByABN",
        "socialBusiness",
        abn,
      );
      return organisationResult.data
        ? decodeSocialBusiness(
            organisationResult.data as SocialBusiness,
            getImageUrl,
          )
        : null;
    } catch (e) {
      catchSentryError(e);
      setError("Could not list organisations");
      return null;
    } finally {
      setIsSocialBusinessLoading(false);
    }
  }, [getApiData, decodeSocialBusiness, getImageUrl, catchSentryError]);

  const fetch = React.useRef(
    debounce((searchParams) => {
      if (searchParams) {
        searchSocialBusinesses(searchParams);
      } else {
        listSocialBusinesses("");
      }
    }, 500),
  );

  React.useEffect(() => {
    if (searchParams) {
      fetch.current(searchParams);
    } else {
      setIsSocialBusinessLoading(false);
    }
  }, [searchParams]);

  return {
    error,
    isSocialBusinessLoading,
    searchSocialBusinessesByABN,
    socialBusinesses,
  };
};