import { Skeleton, TableCell, TableRow } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";

export const LidpTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="left">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="left">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="left">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="left">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="left">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ backgroundColor: "#f4f4f3" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ backgroundColor: "#f4f4f3" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">{<Skeleton animation="wave" />}</TableCell>
      <TableCell align="right" sx={{}}>
        <StyledButton color="error" loading>
          Remove
        </StyledButton>
      </TableCell>
    </TableRow>
  );
};
