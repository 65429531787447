import { listApiData } from "@hooks/utils/api";
import { useCallback, useState, useEffect } from "react";
import { decodeLabourHour } from "social-pro-common/decoders/labourHour";
import { LabourHour } from "social-pro-common/entities/labourHour";
import { LabourHourLineItem } from "social-pro-common/interfaces/labourHour";

export const useLabourHourForEmployee = (
  employeeId?: string,
  projectId?: string,
) => {
  const [labourHours, setLabourHours] = useState<LabourHourLineItem[]>([]);
  const [isLabourHourLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listLabourHoursForEmployee = useCallback(
    async (employeeId: string, projectId?: string): Promise<void> => {
      try {
        setIsLoading(true);
        const labourHourResult = await listApiData(
          "listLabourHoursForEmployee",
          "labourHour",
          employeeId,
          { projectId },
        );
        const labourHours = labourHourResult.data.map((p) =>
          decodeLabourHour(p as LabourHour),
        );

        setLabourHours(labourHours);
      } catch (error) {
        console.error("Could not list labourHours for employee", error);
        setError("Could not list labourHours for employee");
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setLabourHours, setError],
  );

  useEffect(() => {
    if (employeeId) {
      listLabourHoursForEmployee(employeeId, projectId);
    } else {
      setIsLoading(false);
    }
  }, [employeeId, projectId, listLabourHoursForEmployee]);

  return {
    error,
    isLabourHourLoading,
    labourHours,
    listLabourHoursForEmployee,
  };
};