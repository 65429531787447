import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import {
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useState } from "react";
import { SustainabilityProgressModelLineItem } from "social-pro-common/interfaces/dashboard";
import {
  formatDecimalPlaces,
  nFormatter,
} from "social-pro-common/utils/number";

import { OrganisationsustainabilityProgressSubData } from "./OrganisationSustainabilityProgressSubData";
import { ChipProgress } from "../../Dashboard/ProjectProgress/ChipProgress";
import { DashboardProgressbar } from "../../Dashboard/ProjectProgress/DashboardProgressBar";
import { TitleStack } from "../../Dashboard/ProjectProgress/TitleStack";

interface OrganisationSustainabilityProgressProps {
  loading: boolean;
  sustainabilityProgress?: SustainabilityProgressModelLineItem;
}

export const OrganisationSustainabilityProgress = ({
  loading,
  sustainabilityProgress,
}: OrganisationSustainabilityProgressProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Grid
      item
      md={12}
      sx={{
        marginTop: "16px",
      }}
    >
      <Accordion
        square
        sx={{
          border: "1px solid #E3E8EA",
          borderRadius: "25px",
          padding: "5px",
        }}
        disableGutters
        expanded={open}
        onChange={(event: React.SyntheticEvent, expanded: boolean) => {
          setOpen(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandCircleDownIcon sx={{ color: "#C4D5E7" }} />}
          aria-controls="sustainability-content"
          id="sustainability-panel"
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item md={3}>
              <Stack spacing={2}>
                <TitleStack
                  loading={loading}
                  header="Sustainability"
                  subTitle="Overall Performance"
                />
                <ChipProgress
                  loading={loading}
                  value={`+0%`}
                  title="previous month"
                  primaryFontColour="#2C9F45"
                  secondaryFontColour="#4F8349"
                  chipColour="#D1F4D9"
                  dataTestId="sustainability-pc-change"
                />
              </Stack>
            </Grid>
            <Grid item md={9}>
              <Stack
                spacing={3}
                sx={{ paddingLeft: "5vw", paddingRight: "1vw", width: "100%" }}
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={`${nFormatter(
                        sustainabilityProgress?.fuelsTotal || 0,
                        2,
                      )} Litres`}
                      subTitle="Fuels (Litres)"
                      dataTestId="sustainability-total-value"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={`${nFormatter(
                        sustainabilityProgress?.waterTotal || 0,
                        2,
                      )} Litres`}
                      subTitle="Water Usage"
                      dataTestId="sustainability-achieved-value"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TitleStack
                      loading={loading}
                      header={`${formatDecimalPlaces(
                        sustainabilityProgress?.wasteTotal || 0,
                        2,
                      )} Tonnes`}
                      subTitle="Waste (Landfil, Reused, Soil)"
                      dataTestId="sustainability-pc-complete"
                    />
                  </Grid>
                </Grid>
                <DashboardProgressbar
                  loading={loading}
                  progress={0}
                  startDate={"0%"}
                  endDate={"100%"}
                  progressTestId="sustainability-progress-bar"
                  prefixTestId="sustainability-start"
                  suffixTestId="sustainability-end"
                />
              </Stack>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ borderRadius: "50px" }}>
          {open ? (
            <OrganisationsustainabilityProgressSubData loading={loading} />
          ) : null}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
