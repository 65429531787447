import { useSupportingDocumentForEmployee } from "@hooks/crud/supportingDocuments/useSupportingDocumentForEmployee";
import { Dialog, DialogContent, DialogTitle, Table } from "@mui/material";
import * as React from "react";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";

import SupportingDocumentDialogRow from "./SupportingDocumentDialogRow";
import SupportingDocumentDialogRowSkeleton from "./SupportingDocumentDialogRowSkeleton";
import { NoRows } from "../ListTable/NoRows";


interface SupportingDocumentDialogProps {
  open: boolean;
  labourHour: LabourHourForTargetLineItem;
  handleClose: () => void;
}

export default function SupportingDocumentDialog({
  handleClose,
  labourHour,
  open,
}: SupportingDocumentDialogProps) {
  const { isSupportingDocumentLoading, supportingDocuments } =
    useSupportingDocumentForEmployee(labourHour.employeeId);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className="bgBlueOverlay"
    >
      <DialogTitle>{`Supporting Documents for ${labourHour.employeeName}`}</DialogTitle>
      <DialogContent>
        <Table>
          {!isSupportingDocumentLoading &&
            supportingDocuments.map((sd) => (
              <SupportingDocumentDialogRow
                key={sd.id}
                supportingDocument={sd}
              />
            ))}
          {!isSupportingDocumentLoading && supportingDocuments?.length === 0 ? (
            <NoRows colSpan={4} title={"supporting documents for employee"} />
          ) : null}
          {isSupportingDocumentLoading
            ? Array.from({ length: 4 }, (_, index) => (
                <SupportingDocumentDialogRowSkeleton key={index} />
              ))
            : null}
        </Table>
      </DialogContent>
    </Dialog>
  );
}
