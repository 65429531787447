import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { NavItemType } from "types";

import { getProjectsNav } from "./projects";

export const getNavMenuItems = (
  userProfile?: ProfileLineItem,
): { items: NavItemType[] } => {
  const items = [getProjectsNav(userProfile)];

  return { items };
};
