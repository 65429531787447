"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toTitleCase = exports.formatProfileName = exports.formattedUserName = exports.cleanString = exports.getZipTitle = exports.getFileName = exports.getDocumentName = exports.validateEmail = exports.convertObjectToPostgresSafeObject = exports.reversePostgresSafe = exports.makePostgresSafe = exports.cleanTabsFromString = void 0;
var cleanTabsFromString = function (input, removeSpaces) {
    if (removeSpaces) {
        return input.replace(/[\s\t]+/g, "");
    }
    else {
        return input.replace(/[\t]+/g, "");
    }
};
exports.cleanTabsFromString = cleanTabsFromString;
var makePostgresSafe = function (x) {
    return Object.entries(x).reduce(function (acc, _a) {
        var key = _a[0], value = _a[1];
        if (typeof value === "string") {
            acc[key] = value.replace(/'/g, "''").trim();
        }
        return __assign({}, acc);
    }, x);
};
exports.makePostgresSafe = makePostgresSafe;
var reversePostgresSafe = function (x) {
    return Object.entries(x).reduce(function (acc, _a) {
        var key = _a[0], value = _a[1];
        if (typeof value === "string") {
            acc[key] = value.replace(/''/g, "'").trim();
        }
        return __assign({}, acc);
    }, x);
};
exports.reversePostgresSafe = reversePostgresSafe;
var isObject = function (d) {
    return typeof d === "object" && d !== null;
};
var convertObjectToPostgresSafeObject = function (requestData) {
    if (requestData.data && Array.isArray(requestData.data)) {
        return requestData.data.map(function (d) {
            return isObject(d) ? (0, exports.makePostgresSafe)(d) : d;
        });
    }
    else {
        return (0, exports.makePostgresSafe)(requestData);
    }
};
exports.convertObjectToPostgresSafeObject = convertObjectToPostgresSafeObject;
var date_1 = require("./date");
var validateEmail = function (email) {
    return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};
exports.validateEmail = validateEmail;
var getDocumentName = function (prefix, originalName) {
    return "".concat(prefix, ".").concat(originalName.split(".").pop());
};
exports.getDocumentName = getDocumentName;
var getFileName = function (originalName) {
    return "".concat(originalName.split("/").pop());
};
exports.getFileName = getFileName;
var getZipTitle = function (prm) {
    return "SocialPro_Project_Report-".concat((0, date_1.formatChartDate)(prm).replace(" ", "_"));
};
exports.getZipTitle = getZipTitle;
var cleanString = function (input) {
    return input.replace(/ /g, "").trim().toUpperCase();
};
exports.cleanString = cleanString;
var formattedUserName = function (userId) {
    var formattedUserId = userId.replace("BMD-SAML-IDP_", "");
    formattedUserId = formattedUserId.replace(/@/g, "");
    formattedUserId = formattedUserId.replace(/\./g, "");
    formattedUserId = formattedUserId.substring(0, 36);
    return formattedUserId.toLowerCase();
};
exports.formattedUserName = formattedUserName;
var formatProfileName = function (userAttributes) {
    var givenName = userAttributes.given_name || null;
    var surname = userAttributes.family_name || null;
    if (!givenName && !surname)
        return "";
    return [givenName, surname].join(" ").trim();
};
exports.formatProfileName = formatProfileName;
var toTitleCase = function (str) {
    // Check if the string is already in uppercase
    if (str === str.toUpperCase()) {
        // Convert the entire string to lowercase and apply title case transformation
        return str.toLowerCase().replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }
    else {
        // Apply title case transformation directly
        return str.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }
};
exports.toTitleCase = toTitleCase;
