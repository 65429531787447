import { useProjectContext } from "@hooks/context/useProjectContext";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { readXlsxFileToSocialSpend } from "@utils/xlsx/spend";
import { useRef, useState } from "react";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";

export enum ImportStep {
  Init = "Init",
  Parse = "Parse",
  Upload = "Upload",
  Validating = "Validating",
}

interface SpendImportProps {
  open: boolean;
  step: ImportStep;
  userProfile?: ProfileLineItem;
  reportId: string;
  userOrganisation?: OrganisationLineItem;
  setIsOpen: (open: boolean) => void;
  setStep: (step: ImportStep) => void;
  upsertSocialSpend: (employees: SocialSpendLineItem[]) => Promise<void>;
}

export default function SpendImport({
  open,
  reportId,
  setIsOpen,
  setStep,
  step,
  upsertSocialSpend,
  userOrganisation,
}: SpendImportProps) {
  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const [uploadMessage, setUploadMessage] = useState("Uploading...");

  const fileInput = useRef<HTMLInputElement | null>(null);

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      if (userOrganisation && contractorPackage && selectedProject) {
        setIsOpen(true);
        setStep(ImportStep.Parse);
        const file = e.currentTarget.files[0];
        try {
          const xlsxSocialSpendResponse = await readXlsxFileToSocialSpend(
            userOrganisation,
            selectedProject,
            contractorPackage.id,
            reportId,
            file,
          );

          if (xlsxSocialSpendResponse.errorMessage) {
            setIsOpen(false);
            alert(xlsxSocialSpendResponse.errorMessage);
          } else {
            setStep(ImportStep.Validating);
            const totalSocialSpend = xlsxSocialSpendResponse.socialSpend.length;
            const socialSpendData = [];

            let rowCount = 1;
            for (const socialSpend of xlsxSocialSpendResponse.socialSpend) {
              setUploadMessage(
                `Validating ${rowCount}/${totalSocialSpend} social spend...`,
              );

              //TODO: Fix this to handle it more gracefully
              if (socialSpend.supplierPostCode === "3001") {
                throw new Error(
                  `Could not validate supplier postcode for row ${rowCount}: ${socialSpend.supplier}`,
                );
              }
              if (socialSpend.victorianPostCode === "3001") {
                throw new Error(
                  `Could not validate victorian postcode for row ${rowCount}: ${socialSpend.supplier}`,
                );
              }
              rowCount += 1;
            }

            while (xlsxSocialSpendResponse.socialSpend.length > 0)
              socialSpendData.push(
                xlsxSocialSpendResponse.socialSpend.splice(0, 50),
              );

            setStep(ImportStep.Upload);

            let count = 0;
            for (const socialSpend of socialSpendData) {
              count += socialSpend.length;
              setUploadMessage(
                `Uploading ${count}/${totalSocialSpend} spend items...`,
              );
              await upsertSocialSpend(socialSpend);
            }
          }

          setIsOpen(false);
          setStep(ImportStep.Init);
        } catch (err: any) {
          setIsOpen(false);
          alert(err);
        }
      }
    }

    setIsOpen(false);
    setStep(ImportStep.Init);
  };

  const loading = isProjectLoading;
  const importSocialSpendStep = (() => {
    switch (step) {
      default:
      case ImportStep.Init:
        return (
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              gap: "30px",
              justifyContent: "center",
              margin: "0",
              padding: "50px 30px 50px",
            }}
          >
            <IconButton
              className="closeBtn"
              aria-label="close"
              onClick={() => {
                setIsOpen(false);
              }}
              disabled={loading}
              sx={{
                "&:hover": {
                  background: "#143E7C",
                },
                background: "#000",
                color: "#fff",
                position: "absolute",
                right: 14,
                top: 11,
              }}
            >
              <CloseIcon />
            </IconButton>
            <StyledButton
              loading={false}
              variant="contained"
              className="blackBtn grey-outline-btn"
              startIcon={<Iconify icon="mdi:microsoft-excel" />}
              disabled={loading}
            >
              <Link
                href={
                  process.env.PUBLIC_URL + "/SocialProTemplateSocialSpend.xlsx"
                }
                sx={{
                  color: "#000 !important",
                  textDecoration: "none !important",
                }}
              >
                Download Template
              </Link>
            </StyledButton>
            <StyledButton
              loading={false}
              onClick={onFileInput}
              variant="contained"
              className="blackBtn"
              startIcon={<CloudUploadIcon />}
              disabled={loading}
            >
              <input
                hidden
                ref={fileInput}
                accept="*/xlsx"
                type="file"
                onChange={onUpload}
              />
              {"Upload"}
            </StyledButton>
          </Grid>
        );

      case ImportStep.Parse:
        return (
          <>
            <Box
              sx={{
                margin: "auto !important",
                padding: "10%",
              }}
            >
              <CircularProgress />
            </Box>
            <Typography align="center">Parsing...</Typography>
          </>
        );
      case ImportStep.Validating:
      case ImportStep.Upload:
        return (
          <>
            <Box
              sx={{
                margin: "auto !important",
                padding: "10%",
              }}
            >
              <CircularProgress />
            </Box>
            <Typography align="center">{uploadMessage}</Typography>
          </>
        );
    }
  })();

  return (
    <Dialog maxWidth="md" open={open} className="bgBlueOverlay">
      <DialogTitle>Import Social Spend</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2}>{importSocialSpendStep}</Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
