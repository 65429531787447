import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourExport } from "@hooks/crud/downloadFiles/useLabourHourExport";
import { useLabourHoursForTarget } from "@hooks/crud/labourHours/useLabourHoursForTarget";
import DownloadIcon from "@mui/icons-material/Download";
import { Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SortInfo } from "@stories/atoms/TableHeader/TableHeader";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { LabourHoursTable } from "@stories/organisms/PackageTools/ToolsLabourHoursTable";
import * as RouteHelper from "@utils/routes";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";

export const AnalyticsHoursTarget = () => {
  const navigate = useNavigate();
  const [sortInfo, setSortInfo] = useState<SortInfo>({
    index: 0,
    order: "asc",
    orderBy: 0,
    page: 0,
  });

  const handleRequestSort = (newInfo: SortInfo) => {
    setSortInfo({
      ...sortInfo,
      ...newInfo,
    });
  };

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };
  const { contractorPackageId, projectId, reportId, targetId } = useParams();
  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { downloadProgress, downloadStep, getLabourHourExcel } =
    useLabourHourExport();

  const {
    hasMore,
    isLabourHourLoading,
    isLoadingMore,
    labourHourCount,
    labourHours,
  } = useLabourHoursForTarget(
    selectedProject?.id,
    targetId,
    contractorPackage?.id,
    sortInfo,
  );

  const loading = isProjectLoading || isLabourHourLoading;
  const selectedTarget = selectedProject?.commitmentsHours.find(
    (c) => c.id === targetId,
  );

  const handlOnBack = () => {
    if (projectId && contractorPackageId && reportId) {
      navigate(
        RouteHelper.analyticsLabourHours(
          projectId,
          contractorPackageId,
          reportId,
        ),
      );
    }
  };

  const filteredLabourHours = searchValue
    ? labourHours.filter((p) =>
        p.organisationName.toLowerCase().includes(searchValue.toLowerCase()),
      )
    : labourHours;
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title={`Labour Hours - ${
            selectedTarget
              ? selectedTarget.targetDescription ||
                labourHourCommitmentTypeToString(selectedTarget.targetName)
              : ""
          }`}
          subTitle={
            isPrimaryPackage ? "Project Analytics" : "Package Analytics"
          }
          handlOnBack={handlOnBack}
        />
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Grid
            item
            md={12}
            sx={{
              padding: "0 !important",
              textAlign: "right",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignContent={"end"}
              justifyContent={"end"}
            >
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />
              <StyledButton
                className="blackBtn grey-outline-btn topBarBtn"
                loading={loading}
                disabled={loading || !labourHours || !labourHours.length}
                variant="contained"
                onClick={() => {
                  if (
                    projectId &&
                    contractorPackageId &&
                    reportId &&
                    targetId
                  ) {
                    getLabourHourExcel(
                      projectId,
                      targetId,
                      projectId === contractorPackageId
                        ? undefined
                        : contractorPackageId,
                    );
                  }
                }}
                startIcon={<DownloadIcon />}
              >
                Export Records
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          className="analytic-table glb-table-sty"
          sx={{ padding: "0px 40px !important" }}
        >
          <LabourHoursTable
            loading={loading}
            labourHours={filteredLabourHours}
            labourHourCount={labourHourCount}
            handleRequestSort={handleRequestSort}
            isLoadingMore={isLoadingMore}
            sortInfo={sortInfo}
            hasMore={hasMore}
          />
        </Grid>
        <PdfDownlodDialog
          downloadStep={downloadStep}
          total={downloadProgress}
          title={"Local Content Export"}
        />
      </Grid>
    </>
  );
};
