import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { useFormik } from "formik";
import * as yup from "yup";

export interface HammertechCredentials {
  username: string;
  password: string;
  tennant: string;
  enabled: boolean;
}

interface HammertechModalProps {
  loading: boolean;
  open: boolean;
  credentials: HammertechCredentials;
  handleClose: () => void;
  onSubmit: (credentials: HammertechCredentials) => void;
}
export const HammertechModal = ({
  credentials,
  handleClose,
  loading,
  onSubmit,
  open,
}: HammertechModalProps) => {
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Email is not a valid email address")
      .required("Email is required"),
    enabled: yup.boolean(),
    password: yup.string().required("Password is required"),
    tennant: yup.string().required("Tenant is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: credentials.username,
      enabled: credentials.enabled,
      password: credentials.password,
      tennant: credentials.tennant,
    },
    onSubmit: async (values) => {
      await onSubmit({
        enabled: values.enabled,
        password: values.password,
        tennant: values.tennant,
        username: values.email,
      });
    },
    validationSchema: validationSchema,
  });

  const disabled = formik.isSubmitting || loading;
  const locked = formik.values.enabled;
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle>{"Hammertech Account Details"}</DialogTitle>{" "}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            background: "#000 !important",
            borderRadius: "50%",
            color: "#fff",
            padding: "5px",
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <StyledTextField
                  loading={loading}
                  disabled={disabled || locked}
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email ? formik.errors.email : ""}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextField
                  loading={loading}
                  disabled={disabled || locked}
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched.password ? formik.errors.password : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextField
                  loading={loading}
                  disabled={disabled || locked}
                  id="tennant"
                  name="tennant"
                  label="Tennant"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tennant}
                  error={
                    formik.touched.tennant && Boolean(formik.errors.tennant)
                  }
                  helperText={
                    formik.touched.tennant ? formik.errors.tennant : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={formik.values.enabled}
                        disabled={loading || formik.isSubmitting}
                        value={formik.values.enabled}
                        onChange={(_, checked) => {
                          if (
                            !formik.values.email?.length ||
                            !formik.values.password?.length ||
                            !formik.values.tennant?.length
                          ) {
                            alert(
                              "Please fill in all fields before enabling the API",
                            );
                          } else {
                            formik.setFieldValue("enabled", checked);
                          }
                        }}
                      />
                    }
                    label="API Enabled"
                  />
                </FormGroup>
              </Grid>

              <Grid
                item
                md={12}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Stack direction="row" spacing={1}>
                  <StyledButton
                    loading={loading}
                    disabled={disabled || !formik.values.enabled}
                    color="primary"
                    variant="outlined"
                    type="submit"
                  >
                    Test
                  </StyledButton>
                  <StyledButton
                    loading={loading}
                    disabled={disabled}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </StyledButton>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
