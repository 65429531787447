import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import {
  IconButton,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";


export const EmployeeSupportingDocsFormRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="left">
        <Skeleton animation="wave">
          <Typography>Social Factor Name</Typography>
        </Skeleton>
        <Skeleton animation="wave">
          <Typography variant="caption">* some supporting document</Typography>
        </Skeleton>
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
          <IconButton size="large">
            <UploadTwoToneIcon sx={{ fontSize: "1.1rem" }} />
          </IconButton>
        </Skeleton>
      </TableCell>
      <TableCell align="center" sx={{ pr: 3 }}>
        <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
          <IconButton size="large">
            <UploadTwoToneIcon sx={{ fontSize: "1.1rem" }} />
          </IconButton>
        </Skeleton>
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
          <IconButton size="large">
            <UploadTwoToneIcon sx={{ fontSize: "1.1rem" }} />
          </IconButton>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
