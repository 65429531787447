import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useProjectEmployee } from "@hooks/crud/projectEmployee/useProjectEmployee";
import { Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { ProjectEmployeeTable } from "@stories/molecules/ProjectEmployeeTable/ProjectEmployeeTable";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import EmployeeListModal from "@stories/organisms/EmployeeListModal/EmployeeListModal";
import * as RouteHelper from "@utils/routes";
import { stringToSearchQuery } from "@utils/utils";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ContractorEmployeeProjectLineItem } from "social-pro-common/interfaces/contractorEmployeeProject";

export const ProjectEmployees = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const { isAuthProfileLoading, userProfile } = useProfileContext();

  const [openListModal, setOpenListModal] = useState(false);

  const { isProjectLoading, selectedProject } = useProjectContext();
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeLineItem>();
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };
  const {
    employeeCount,
    employees,
    isEmployeeLoading,
    updateEmployeeAssignment,
  } = useProjectEmployee(
    userProfile?.organisationId,
    projectId,
    stringToSearchQuery(searchValue),
    page,
  );

  const handleEmployeeAssignment = async (
    employee: EmployeeLineItem,
    toggle: boolean,
  ) => {
    if (selectedProject) {
      const employeeAssignment = {
        assigned: toggle,
        contractorEmployeeId: employee.id,
        id: `${employee.id}-${selectedProject.id}`,
        projectId: selectedProject.id,
      } as ContractorEmployeeProjectLineItem;
      updateEmployeeAssignment([employeeAssignment]);
    }
  };

  const handleCloseListModal = () => {
    setOpenListModal(false);
  };

  const handleOpenListModal = (employee: EmployeeLineItem) => {
    setSelectedEmployee(employee);
    setOpenListModal(true);
  };

  const loading = isProjectLoading || isEmployeeLoading || isAuthProfileLoading;
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Project Employees"
          subTitle={`Project Assignment (${Math.max(employeeCount, 0)})`}
        />
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Grid item md={12}>
            <Stack
              direction="row"
              spacing={2}
              alignContent={"end"}
              justifyContent={"end"}
            >
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />

              <StyledButton
                data-test-id="manage-employees-button"
                className="blackBtn"
                loading={loading}
                variant="contained"
                onClick={() => {
                  navigate(RouteHelper.employees);
                }}
              >
                Manage Employees
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>

        <ProjectEmployeeTable
          project={selectedProject}
          employees={employees}
          loading={loading}
          employeeCount={employeeCount}
          setPage={setPage}
          handleEmployeeAssignment={handleEmployeeAssignment}
          handleOpenListModal={handleOpenListModal}
        />
        {openListModal && selectedEmployee ? (
          <EmployeeListModal
            open={openListModal}
            existingEmployee={selectedEmployee}
            handleClose={handleCloseListModal}
          />
        ) : null}
      </Grid>
    </>
  );
};
