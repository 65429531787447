import { Typography, Box, Stack, Skeleton } from "@mui/material";
import { ProjectReportSubmissionLineItem } from "social-pro-common/interfaces/projectReportSubmission";

import { ReportCard } from "../ReportCard/ReportCard";
import { ReportCardSkeleton } from "../ReportCardSkeleton/ReportCardSkeleton";

interface ReportColumnProps {
  dataTestId: string;
  loading: boolean;
  title: string;
  subTitle: string;
  dueDate: number;
  isPrimaryPackage: boolean;
  reports: ProjectReportSubmissionLineItem[];
  handleDownloadReport?: (reportId: string, reportTitle: string) => void;
  handlViewReport: (reportMonthId: string) => void;
}

export const ReportColumn = ({
  dataTestId,
  dueDate,
  handlViewReport,
  handleDownloadReport,
  isPrimaryPackage,
  loading,
  reports,
  subTitle,
  title,
}: ReportColumnProps) => {
  return (
    <Box
      data-test-id={dataTestId}
      sx={{
        background: "#E8F0F4",
        border: "1px solid #E8F0F4",
        borderRadius: "5px",
        margin: "0 10px 20px",
        padding: "10px",
        width: "calc(33.33% - 20px)",
      }}
    >
      <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <Box sx={{ marginTop: "1%", paddingLeft: "5px" }}>
          <Stack direction="row" spacing={1}>
            {loading ? (
              <Skeleton animation="wave" variant="text">
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "1.4",
                  }}
                >
                  {title}{" "}
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                sx={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "1.4",
                }}
              >
                {title} {`(${reports.length})`}
              </Typography>
            )}
          </Stack>
          {loading ? (
            <Skeleton animation="wave" variant="text">
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "1.4",
                  marginTop: "6px",
                }}
              >
                {subTitle}
              </Typography>
            </Skeleton>
          ) : (
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "1.4",
                marginTop: "6px",
              }}
            >
              {subTitle}
            </Typography>
          )}
        </Box>
      </Box>
      {!loading &&
        reports.map((report) => (
          <ReportCard
            key={report.id}
            report={report}
            dueDate={dueDate}
            isPrimaryPackage={isPrimaryPackage}
            handlViewReport={handlViewReport}
            handleDownloadReport={handleDownloadReport}
          />
        ))}
      {loading
        ? Array.from({ length: 6 }, (_, index) => (
            <ReportCardSkeleton key={index} />
          ))
        : undefined}
    </Box>
  );
};
