import { getApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState, useEffect } from "react";
import { decodeContractorEmployeeListModel } from "social-pro-common/decoders/contractorEmployee";
import { ContractorEmployeeListModel } from "social-pro-common/entities/contractorEmployee";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";

export const useProjectAllAssignedEmployee = (
  organisationId?: string,
  projectId?: string,
  query?: string,
  page = 0,
) => {
  const [employeeCount, setEmployeeCount] = useState<number>(0);
  const [employees, setEmployees] = useState<EmployeeLineItem[]>([]);
  const [isEmployeeLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listAllAssignedEmployees = useCallback(
    async (projectId: string) => {
      try {
        setIsLoading(true);
        const employeeResult = await getApiData(
          "listAllAssignedEmployees",
          "projectEmployee",
          projectId,
          {
            organisationId,
            page: page.toString(),
            query,
          },
        );
        const decodedEmployees = decodeContractorEmployeeListModel(
          employeeResult.data as ContractorEmployeeListModel,
        );
        setEmployeeCount(decodedEmployees.totalEmployees);
        setEmployees(decodedEmployees.employees);
      } catch (e) {
        catchSentryError(e);
        setError("Could not list employees");
      } finally {
        setIsLoading(false);
      }
    },
    [organisationId, page, query],
  );

  useEffect(() => {
    if (projectId) {
      listAllAssignedEmployees(projectId);
    }
  }, [listAllAssignedEmployees, organisationId, projectId]);

  return {
    employeeCount,
    employees,
    error,
    isEmployeeLoading,
  };
};
