import usePdf from "@hooks/crud/downloadFiles/usePdf";
import { useReportSustainabilitySubmission } from "@hooks/crud/reportSubmissionSustainability/useReportSubmissionSustainability";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import * as RouteHelper from "@utils/routes";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";
import { stringToDate } from "social-pro-common/utils/date";
import { getZipTitle } from "social-pro-common/utils/string";

import { ReportTableRow } from "./ReportTableRow";
import { ReportTableSubRowSkeleton } from "./ReportTableRowSkeleton";
import { NoRows } from "../ListTable/NoRows";
import { PdfDownlodDialog } from "../PdfDownlodDialog/PdfDownlodDialog";

interface ReportTableSustainabilityProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  subPackage?: ContractorPackageLineItem;
  isPrimaryPackage: boolean;
}

export const ReportTableSustainability = ({
  contractorPackage,
  isLoading,
  isPrimaryPackage,
  project,
  subPackage,
}: ReportTableSustainabilityProps) => {
  const navigate = useNavigate();

  const {
    deleteReportSubmission,
    isReportLoading,
    reportSubmissions,
    sendReportReminder,
    updateReportSubmission,
  } = useReportSustainabilitySubmission(subPackage?.id);
  const { downloadProgress, downloadStep, getPdfInfo } = usePdf();

  const loading = isLoading || isReportLoading;

  const orderedSubmissions = reportSubmissions.slice().reverse();

  const reportRows =
    project && contractorPackage && subPackage
      ? orderedSubmissions
          .filter((r) => {
            return (
              r.reportStatus === ReportStatus.Overdue ||
              r.reportStatus === ReportStatus.Accepted ||
              r.reportStatus === ReportStatus.Complete
            );
          })
          .map((reportSubmission) => {
            const reportDate = stringToDate(reportSubmission.reportId);
            const reportTitle = getZipTitle(reportDate);
            return (
              <ReportTableRow
                key={`report-row-${reportSubmission.reportId}`}
                project={project}
                subPackage={subPackage}
                reportSubmission={reportSubmission}
                reportId={reportSubmission.reportId}
                handleDownloadReport={(
                  contractorPackageId: string,
                  reportId: string,
                ) => {
                  getPdfInfo(
                    project.id,
                    reportId,
                    reportTitle,
                    contractorPackageId,
                    true,
                  );
                }}
                handleRevertReport={async (
                  reportSubmission: ReportSubmissionLineItem,
                  rejectMessage: string,
                ): Promise<void> => {
                  await deleteReportSubmission({
                    ...reportSubmission,
                    rejectMessage,
                  });
                }}
                handlViewReport={function (reportMonthId: string): void {
                  navigate({
                    pathname: RouteHelper.readOnlyReportSustainability(
                      project.id,
                      contractorPackage.id,
                      subPackage.id,
                      reportMonthId,
                    ),
                    search: createSearchParams({
                      redirect: window.location.pathname,
                    }).toString(),
                  });
                }}
                sendReportReminder={sendReportReminder}
                handleAcceptReport={async (
                  reportSubmission: ReportSubmissionLineItem,
                ): Promise<void> => {
                  await updateReportSubmission({
                    ...reportSubmission,
                    accepted: true,
                    reportStatus: ReportStatus.Accepted,
                  });
                }}
                isPrimaryPackage={isPrimaryPackage}
              />
            );
          })
          .filter((r) => !!r)
      : [];

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader
          loading={loading}
          title={"Report Month"}
          align={"left"}
          sx={{ width: "40%" }}
        />
        <TableHeader
          loading={loading}
          title={"Due Date"}
          sx={{ width: "20%" }}
          align={"left"}
        />
        <TableHeader
          loading={loading}
          title={"Status"}
          sx={{ width: "20%" }}
          align={"center"}
        />
        <TableCell sx={{ width: "20%" }} />
      </TableRow>
    </TableHead>
  );

  return (
    <>
      {loading ? (
        <TableContainer>
          <Table size="small" aria-label="purchases">
            {tableHeader}
            <TableBody>
              {Array.from({ length: 10 }, (_, index) => (
                <ReportTableSubRowSkeleton key={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <Table
            data-test-id="reports-table"
            size="small"
            aria-label="purchases"
          >
            {tableHeader}
            <TableBody>
              {!loading && project && subPackage ? reportRows : null}
              {!loading && reportRows.length == 0 ? (
                <NoRows colSpan={4} title={"Submitted Package Reports"} />
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Export Report"}
      />
    </>
  );
};
