import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { ReactNode } from "react";

interface AccordianPanelProps {
  label: string;
  children: ReactNode;
}

export const AccordianPanel = ({ children, label }: AccordianPanelProps) => {
  return (
    <Accordion sx={{ backgroundColor: "transparent" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="labour-hour-panel"
      >
        <Typography
          variant="h4"
          component="h4"
          sx={{
            color: "black",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "288px",
          overflowY: "auto",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
