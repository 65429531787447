import { Grid, Skeleton, Stack, Typography } from "@mui/material";

import { FAQCards } from "./FAQCards";

interface FAQSectionProps {
  loading: boolean;
}
export default function FAQSection({ loading }: FAQSectionProps) {
  return (
    <>
      <Grid item md={12}>
        {loading ? (
          <Stack direction="column">
            <Skeleton animation="wave">
              <Typography
                variant="h2"
                component="div"
                fontWeight="bold"
                fontSize="28px"
              >
                FAQs
              </Typography>
            </Skeleton>
            <Skeleton animation="wave">
              <Typography>Answers to common questions and queries</Typography>
            </Skeleton>
          </Stack>
        ) : (
          <Stack direction="column">
            <Typography
              variant="h2"
              component="div"
              fontWeight="bold"
              fontSize="28px"
            >
              FAQs
            </Typography>
            <Typography sx={{ mt: 1.5 }} variant="h4" fontWeight="normal">
              Answers to common SocialPro Questions
            </Typography>
          </Stack>
        )}
      </Grid>
      <FAQCards />
    </>
  );
}
