import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";

export const EmployeeTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>

      <TableCell align="center">
        <Box display="flex" gap="10px">
          <Stack direction="row" spacing={1}>
            <Skeleton animation="wave" variant="rounded">
              <Button
                color="primary"
                sx={{
                  "&:hover ": {
                    background: "#e3f2fd",
                  },
                  borderColor: "#0063c6",
                  color: "#0063c6",
                }}
                size="large"
               />
            </Skeleton>
            <Skeleton animation="wave" variant="circular">
              <IconButton
                color="primary"
                sx={{
                  "&:hover ": {
                    background: "#e3f2fd",
                  },
                  borderColor: "#0063c6",
                  color: "#0063c6",
                }}
                size="large"
              >
                <EditIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Skeleton>
            <Skeleton animation="wave" variant="circular">
              <IconButton color="error" size="large">
                <DeleteIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Skeleton>
          </Stack>
        </Box>
      </TableCell>
    </TableRow>
  );
};
