import { useAuthContext } from "@hooks/context/useAuthContext";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { ToastOptions, toast } from "react-toastify";

const FederatedLoginForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { federatedSignIn } = useAuthContext();

  const handleLogin = async () => {
    setIsSubmitting(true);
    try {
      await federatedSignIn();
      setIsSubmitting(false);
    } catch (err: any) {
      setIsSubmitting(false);
      toast("Something went wrong! Please contact support.", {
        type: "error",
      } as ToastOptions);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ fontFamily: "Roboto", mt: 4, textAlign: "center" }}>
      <Button
        id="submit_button"
        disabled={isSubmitting}
        variant={"contained"}
        fullWidth
        type="submit"
        size="large"
        sx={{
          background: "#054F81",
          margin: "0 auto",
          minWidth: "200px",
          transition: "0.3s all",
          width: "auto",
        }}
        onClick={handleLogin}
      >
        BMD Sign In
      </Button>
    </Box>
  );
};

export default FederatedLoginForm;
