import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import { Skeleton, TableCell, TableRow } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";

export const EmployeeSupportingDocumentSubTableSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="center" sx={{ width: "50%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "33%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <StyledButton
          loading
          variant="contained"
          size="small"
          color={"success"}
          disabled
          sx={{ margin: "auto", width: "33%" }}
          startIcon={<DownloadTwoToneIcon />}
        >
          Download
        </StyledButton>
      </TableCell>
    </TableRow>
  );
};
