import { useOrganisationSustainabilitySubData } from "@hooks/crud/organisationDashboard/useOrganisationSustainabilitySubData";
import { Grid } from "@mui/material";

import { SustainabilityTargetStack } from "../../Dashboard/ProjectProgress/SustainabilityTargetStack";

interface OrganisationsustainabilityProgressSubDataProps {
  loading: boolean;
}

export const OrganisationsustainabilityProgressSubData = ({
  loading,
}: OrganisationsustainabilityProgressSubDataProps) => {
  const { isAnalysisLoading, sustainabilitySubData } =
    useOrganisationSustainabilitySubData();
  const isLoading = loading || isAnalysisLoading;
  return (
    <Grid container justifyContent={"space-between"}>
      <SustainabilityTargetStack
        loading={isLoading}
        targets={sustainabilitySubData?.fuels || []}
        title={"Fuels, Lubricants and Solvents"}
        dataTestId={"sustainability-fuels"}
        firstColumnTitle={"Category"}
        secondColumnTitle="Qty"
        thirdColumnTitle="%"
        units="%"
        rightMargin
      />
      <SustainabilityTargetStack
        loading={isLoading}
        targets={sustainabilitySubData?.water || []}
        title={"Water Usage"}
        dataTestId={"sustainability-water"}
        firstColumnTitle={"Category"}
        secondColumnTitle="Qty"
        thirdColumnTitle="%"
        units="%"
        leftMargin
        rightMargin
      />
      <SustainabilityTargetStack
        loading={isLoading}
        targets={sustainabilitySubData?.waste || []}
        title={"Waster (Landfil, Reused, Soil)"}
        dataTestId={"sustainability-waste"}
        firstColumnTitle={"Category"}
        secondColumnTitle="Qty"
        thirdColumnTitle="%"
        units="%"
        leftMargin
      />
    </Grid>
  );
};
