"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeContractorPackage = exports.decodeContractorPackage = exports.encodeOneTimeContractorPackage = exports.decodeContractorPackageSetupLineItem = void 0;
var string_1 = require("../../utils/string");
var financial_1 = require("../financial");
var greenMetricSubmission_1 = require("../greenMetricSubmission");
var organisation_1 = require("../organisation");
var packageLabourHourCommitment_1 = require("../packageLabourHourCommitment");
var packageLocalContent_1 = require("../packageLocalContent");
var packageSocialSpendCommitment_1 = require("../packageSocialSpendCommitment");
var policySubmission_1 = require("../policySubmission");
var profile_1 = require("../profile");
var decodeContractorPackageSetupLineItem = function (contractorPackage, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var org, cp;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, organisation_1.decodeOrganisation)(contractorPackage.organisation, getImageUrl)];
            case 1:
                org = _a.sent();
                return [4 /*yield*/, (0, exports.decodeContractorPackage)(contractorPackage.contractor_package, getImageUrl)];
            case 2:
                cp = _a.sent();
                return [2 /*return*/, {
                        contractorPackage: cp,
                        organisation: org,
                    }];
        }
    });
}); };
exports.decodeContractorPackageSetupLineItem = decodeContractorPackageSetupLineItem;
var encodeOneTimeContractorPackage = function (organisation, contractorPackage, profile) {
    var org = (0, organisation_1.encodeOrganisation)(organisation);
    var cp = (0, exports.encodeContractorPackage)(contractorPackage);
    var prof = profile ? (0, profile_1.encodeProfile)(profile) : undefined;
    return {
        contractor_package: cp,
        organisation: org,
        profile: prof,
    };
};
exports.encodeOneTimeContractorPackage = encodeOneTimeContractorPackage;
var decodeContractorPackage = function (contractorPackage, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var presignedUrl, _a;
    var _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                if (!(contractorPackage.organisation_logo &&
                    contractorPackage.organisation_logo.length > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, getImageUrl(contractorPackage.organisation_logo)];
            case 1:
                _a = _d.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = "";
                _d.label = 3;
            case 3:
                presignedUrl = _a;
                return [2 /*return*/, {
                        commitmentsHours: contractorPackage.commitments_hours.map(function (s) {
                            return (0, packageLabourHourCommitment_1.decodePackageLabourHourCommitment)(s);
                        }),
                        commitmentsSpend: contractorPackage.commitments_spend.map(function (s) {
                            return (0, packageSocialSpendCommitment_1.decodePackageSocialSpendCommitment)(s);
                        }),
                        contractorAbn: contractorPackage.abn,
                        contractorId: contractorPackage.contractor_id,
                        contractorLogo: presignedUrl,
                        contractorName: contractorPackage.organisation_name,
                        created: new Date(contractorPackage.created),
                        estimatedProgress: contractorPackage.estimated_progress,
                        financial: (0, financial_1.decodeFinancial)(contractorPackage.financial),
                        greenMetricSubmissions: contractorPackage.green_metric_submissions.map(function (g) { return (0, greenMetricSubmission_1.decodeGreenMetricSubmission)(g); }),
                        id: contractorPackage.contractor_package_id,
                        isAccepted: contractorPackage.is_accepted,
                        localContent: ((_b = contractorPackage === null || contractorPackage === void 0 ? void 0 : contractorPackage.local_content) === null || _b === void 0 ? void 0 : _b.map(function (lc) {
                            return (0, packageLocalContent_1.decodePackageLocalContent)(lc);
                        })) || [],
                        nextReportId: contractorPackage.next_report_id,
                        packageDescription: contractorPackage.package_description,
                        packageEndDate: contractorPackage.package_end_date,
                        packageNumber: contractorPackage.package_number,
                        packageStartDate: contractorPackage.package_start_date,
                        packageType: contractorPackage.package_type,
                        policies: ((_c = contractorPackage.policies) === null || _c === void 0 ? void 0 : _c.map(function (p) { return (0, policySubmission_1.decodePolicySubmission)(p); })) || [],
                        projectId: contractorPackage.project_id,
                        representativeEmail: contractorPackage.representative_email,
                        sendInvite: contractorPackage.send_invite,
                        uri: contractorPackage.uri,
                        useLabourHour: contractorPackage.use_labour_hour,
                        useLocalContent: contractorPackage.use_local_content,
                        usePolicies: contractorPackage.use_policy,
                        useSocialSpend: contractorPackage.use_social_spend,
                        useSustainability: contractorPackage.use_sustainability,
                    }];
        }
    });
}); };
exports.decodeContractorPackage = decodeContractorPackage;
var encodeContractorPackage = function (contractorPackage) {
    var _a, _b;
    var sageContractorPackage = (0, string_1.makePostgresSafe)(contractorPackage);
    return {
        abn: sageContractorPackage.contractorAbn,
        commitments_hours: sageContractorPackage.commitmentsHours.map(function (s) {
            return (0, packageLabourHourCommitment_1.encodePackageLabourHourCommitment)(s);
        }),
        commitments_spend: sageContractorPackage.commitmentsSpend.map(function (s) {
            return (0, packageSocialSpendCommitment_1.encodePackageSocialSpendCommitment)(s);
        }),
        contractor_id: sageContractorPackage.contractorId,
        contractor_package_id: sageContractorPackage.id,
        created: sageContractorPackage.created,
        estimated_progress: sageContractorPackage.estimatedProgress,
        financial: (0, financial_1.encodeFinancial)(sageContractorPackage.financial),
        financial_id: sageContractorPackage.financial.id,
        green_metric_submissions: sageContractorPackage.greenMetricSubmissions.map(function (g) { return (0, greenMetricSubmission_1.encodeGreenMetricSubmission)(g); }),
        is_accepted: sageContractorPackage.isAccepted,
        local_content: ((_a = sageContractorPackage.localContent) === null || _a === void 0 ? void 0 : _a.map(function (lc) {
            return (0, packageLocalContent_1.encodePackageLocalContent)(lc);
        })) || [],
        next_report_id: sageContractorPackage.nextReportId,
        organisation_logo: sageContractorPackage.contractorLogo,
        organisation_name: sageContractorPackage.contractorName,
        package_description: sageContractorPackage.packageDescription,
        package_end_date: sageContractorPackage.packageEndDate,
        package_number: sageContractorPackage.packageNumber,
        package_start_date: sageContractorPackage.packageStartDate,
        package_type: sageContractorPackage.packageType,
        policies: ((_b = sageContractorPackage.policies) === null || _b === void 0 ? void 0 : _b.map(function (p) { return (0, policySubmission_1.encodePolicySubmission)(p); })) ||
            [],
        project_id: sageContractorPackage.projectId,
        representative_email: sageContractorPackage.representativeEmail,
        send_invite: sageContractorPackage.sendInvite,
        uri: contractorPackage.uri,
        use_labour_hour: sageContractorPackage.useLabourHour,
        use_local_content: sageContractorPackage.useLocalContent,
        use_policy: sageContractorPackage.usePolicies,
        use_social_spend: sageContractorPackage.useSocialSpend,
        use_sustainability: sageContractorPackage.useSustainability,
    };
};
exports.encodeContractorPackage = encodeContractorPackage;
