import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { CoolDownButton } from "@stories/atoms/CooldownButton/CooldownButton";
import { ReportStatusChip } from "@stories/atoms/ReportStatusChip/SuccessChip";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import Avatar from "@stories/ui-component/extended/Avatar";
import { userHasManagerAccess } from "@utils/projectAccess";
import * as RouteHelper from "@utils/routes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { stringToDate } from "social-pro-common/utils/date";

interface ContractorOneTimePackageTableRowProps {
  project: ProjectLineItem;
  userProfile?: ProfileLineItem;
  contractorPackage: ContractorPackageLineItem;
  subPackage: ContractorPackageLineItem;
  handleEditPackage: (contractorPackageId: string) => void;
  handleDeletePackage: (contractorPackage: ContractorPackageLineItem) => void;
  resendInvite: (contractorPackageId: string) => void;
}

export const ContractorOneTimePackageTableRow = ({
  contractorPackage,
  handleDeletePackage,
  handleEditPackage,
  project,
  resendInvite,
  subPackage,
  userProfile,
}: ContractorOneTimePackageTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const navigate = useNavigate();

  const viewPackageDetails = () => {
    navigate(
      RouteHelper.contractorPackage(
        project.id,
        contractorPackage.id,
        subPackage.id,
      ),
    );
  };

  let packageStatus =
    subPackage.estimatedProgress === 100
      ? ReportStatus.Complete
      : ReportStatus.ToSubmit;

  if (packageStatus === ReportStatus.ToSubmit) {
    const today = new Date();
    if (
      today.getTime() - stringToDate(subPackage.packageEndDate).getTime() >
      0
    ) {
      packageStatus = ReportStatus.Overdue;
    }
  }

  return (
    <>
      <TableRow hover style={{ cursor: "pointer", padding: "10px 0 0" }}>
        <TableCell size="small" align="left" onClick={viewPackageDetails}>
          {`${subPackage.packageNumber}`}
        </TableCell>
        <TableCell
          align="center"
          onClick={viewPackageDetails}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          <div style={{ alignItems: "center", display: "flex", gap: "25px" }}>
            <Avatar
              size="badge"
              alt={subPackage.contractorName}
              src={subPackage.contractorLogo}
              sx={{ backgroundColor: "#ffffff" }}
            />
            <Typography
              variant="caption"
              color="black"
              sx={{ fontSize: "0.875rem", fontWeight: "bold" }}
            >
              {subPackage.contractorName}
            </Typography>
          </div>
        </TableCell>
        <TableCell
          align="left"
          onClick={viewPackageDetails}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          {subPackage.packageDescription}
        </TableCell>
        <TableCell
          align="left"
          onClick={viewPackageDetails}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "initial",
          }}
        >
          {stringToDate(subPackage.packageEndDate).toLocaleDateString("en-GB")}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            display: "table-cell",
            justifyContent: "center",
          }}
        >
          <Typography align="center">
            <ReportStatusChip reportStatus={packageStatus} />
          </Typography>
        </TableCell>

        <TableCell align="right" className="right">
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            {subPackage.isAccepted ? (
              <Button
                color="primary"
                size="large"
                onClick={viewPackageDetails}
                className="blackBtn grey-outline-btn"
                sx={{ color: "white", fontSize: "12px" }}
              >
                Details
              </Button>
            ) : (
              <CoolDownButton
                cooldownDuration={5000}
                onResend={() => {
                  resendInvite(subPackage.id);
                }}
                title={"Resend"}
              />
            )}
            {userHasManagerAccess(project) ? (
              <>
                <Tooltip
                  placement="top"
                  title="Edit"
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    color="primary"
                    size="large"
                    disabled={!userProfile?.isAdmin}
                    onClick={() => handleEditPackage(subPackage.id)}
                  >
                    <EditIcon
                      sx={{
                        fontSize: "1.1rem",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  placement="top"
                  title="Delete"
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    color="error"
                    size="large"
                    disabled={!userProfile?.isAdmin}
                    onClick={() => setOpenConfirmDialog(true)}
                  >
                    <DeleteIcon
                      sx={{
                        fontSize: "1.1rem",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Are you sure you want to delete this package?"}
        open={openConfirmDialog}
        title={"Delete Package"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleDeletePackage(subPackage)}
      />
    </>
  );
};
