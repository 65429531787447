import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { LabourHourTargetForm } from "@stories/molecules/LabourHourTargetForm/LabourHourTargetForm";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  ProjectLabourHourCommitmentLineItem,
  getBaseMultiplierForHours,
} from "social-pro-common/interfaces/projectLabourHourCommitment";

interface LabourHourTargetModalProps {
  open: boolean;
  loading: boolean;
  project: ProjectLineItem;
  labourHourCommitment: ProjectLabourHourCommitmentLineItem;
  createSocialCommitment: (target: ProjectLabourHourCommitmentLineItem) => void;
  handleClose: () => void;
}

export default function LabourHourTargetModal({
  createSocialCommitment,
  handleClose,
  labourHourCommitment,
  loading,
  open,
  project,
}: LabourHourTargetModalProps) {
  const baseMultiplier = getBaseMultiplierForHours(
    project.financial,
    labourHourCommitment,
  );
  const onSubmit = async (
    socialRequirement: ProjectLabourHourCommitmentLineItem,
  ) => {
    if (socialRequirement) {
      await createSocialCommitment(socialRequirement);
      handleClose();
    }
  };

  const title = "Create New Hours Target";

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle sx={{ padding: "13px 24px !important" }}>{title}</DialogTitle>
        <DialogContent className=" custom-scrollbar">
          <LabourHourTargetForm
            project={project}
            socialCommitment={labourHourCommitment}
            baseMultiplier={baseMultiplier}
            handleSubmit={onSubmit}
            loading={loading}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
