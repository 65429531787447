import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SortInfo, TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import SupportingDocumentDialog from "@stories/molecules/SupportingDocumentDialog/SupportingDocumentDialog";
import { useCallback, useRef, useState } from "react";
import { DEFAULT_LABOUR_HOUR_LIMIT } from "social-pro-common/entities/labourHour";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";
import { stringToDate, formatReportDate } from "social-pro-common/utils/date";

interface LabourHoursTableProps {
  loading: boolean;
  labourHours: LabourHourForTargetLineItem[];
  labourHourCount: number;
  isLoadingMore: boolean;
  sortInfo: SortInfo;
  handleRequestSort: (sortInfo: SortInfo) => void;
  hasMore: boolean;
}

export const LabourHourTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell align="center" className="center" sx={{ margin: "auto" }}>
        <Skeleton animation="wave" variant="rounded">
          <StyledButton className="blackBtn" loading>
            Documents
          </StyledButton>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};

export const LabourHoursTable = ({
  handleRequestSort,
  isLoadingMore,
  labourHourCount,
  labourHours,
  loading,
  sortInfo,
}: LabourHoursTableProps) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const [selectedLabourHour, setSelectedLabourHour] =
    useState<LabourHourForTargetLineItem>();

  const handleClose = () => {
    setSelectedLabourHour(undefined);
  };

  // Callback for observing the trigger element
  const lastElementRef = useCallback(
    (node: any) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleRequestSort({ ...sortInfo, page: sortInfo.page + 1 }); // Increment page on intersection
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, sortInfo],
  );

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader
          loading={loading}
          title={"Report Date"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 0,
            page: 0,
          }}
          handleRequestSort={handleRequestSort}
          // sx={{ width: "5%" }}
        />
        <TableHeader
          loading={loading}
          title={"Name or Code"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 1,
            page: 0,
          }}
          handleRequestSort={handleRequestSort}
          // sx={{ width: "10%" }}
        />
        <TableHeader
          loading={loading}
          title={"Subcontractor Name"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 2,
            page: 0,
          }}
          handleRequestSort={handleRequestSort}
          // sx={{ width: "10%" }}
        />
        <TableHeader
          loading={loading}
          title={"Previous Hours Worked"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 3,
            page: 0,
          }}
          handleRequestSort={handleRequestSort}
          // sx={{ width: "15%" }}
        />
        <TableHeader
          loading={loading}
          title={"Current Hours Worked"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 4,
            page: 0,
          }}
          handleRequestSort={handleRequestSort}
          // sx={{ width: "15%" }}
        />
        <TableHeader
          loading={loading}
          title={"Current Hours Studied"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 5,
            page: 0,
          }}
          handleRequestSort={handleRequestSort}
          // sx={{ width: "15%" }}
        />
        <TableHeader
          loading={loading}
          title={"Total Hours Worked"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 6,
            page: 0,
          }}
          handleRequestSort={handleRequestSort}
          // sx={{ width: "15%" }}
        />
        <TableHeader
          loading={loading}
          title={"Total Hours Studied"}
          align="left"
          sortInfo={{
            ...sortInfo,
            index: 7,
            page: 0,
          }}
          handleRequestSort={handleRequestSort}
          // sx={{ width: "15%" }}
        />
        <TableHeader
          loading={loading}
          title={""}
          //sx={{ width: "5%" }}
        />
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          margin: "0 4px",
          padding: "20px 0 !important",
        }}
      >
        <TableContainer
          sx={{
            overflow: "auto",
            position: "sticky",
          }}
        >
          {loading ? (
            <Table data-test-id="labour-hours-table">
              {tableHeader}
              <TableBody>
                {Array.from({ length: 10 }, (_, index) => (
                  <LabourHourTableRowSkeleton key={index} />
                ))}
              </TableBody>
            </Table>
          ) : (
            <Table data-test-id="labour-hours-table">
              {tableHeader}
              <TableBody>
                {labourHours.map((lh, index) => {
                  return (
                    <TableRow key={`${index}-labour-hours-table-row`}>
                      <TableCell>{`${formatReportDate(stringToDate(lh.reportId))}`}</TableCell>
                      <TableCell>{`${lh.employeeName}`}</TableCell>
                      <TableCell>{`${lh.organisationName}`}</TableCell>
                      <TableCell>{`${lh.previousHoursWorked} Hours`}</TableCell>
                      <TableCell>{`${lh.currentHoursWorked} Hours`}</TableCell>
                      <TableCell>{`${lh.currentHoursStudied} Hours`}</TableCell>
                      <TableCell>{`${lh.totalHoursWorked} Hours`}</TableCell>
                      <TableCell>{`${lh.totalHoursStudied} Hours`}</TableCell>
                      <TableCell>
                        <StyledButton
                          loading={false}
                          className="blackBtn"
                          onClick={() => {
                            setSelectedLabourHour(lh);
                          }}
                        >
                          Documents
                        </StyledButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {labourHours.length === 0 ? (
                  <NoRows title={"Labour Hours"} colSpan={9} />
                ) : null}

                {isLoadingMore ? (
                  <TableRow
                    sx={{
                      background: "#fff !important", // Make the background transparent
                      textAlign: "center", // Center the content
                    }}
                  >
                    <TableCell colSpan={9}>
                      <Box
                        display="flex"
                        justifyContent="center" // Center horizontally
                        alignItems="center" // Center vertically
                        height="100%" // Ensure the cell takes full height
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          )}
        </TableContainer>

        {labourHourCount > DEFAULT_LABOUR_HOUR_LIMIT &&
        labourHours.length < labourHourCount ? (
          <Box
            ref={lastElementRef}
            style={{ backgroundColor: "transparent", height: "20px" }}
          />
        ) : null}

        {selectedLabourHour ? (
          <SupportingDocumentDialog
            open
            handleClose={handleClose}
            labourHour={selectedLabourHour}
          />
        ) : null}
      </Grid>
    </>
  );
};
