// material-ui
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Avatar from "@stories/ui-component/extended/Avatar";

export const ContractorOneTimePackageTableRowSkeleton = () => {
  return (
    <TableRow key={`sub-contractor-row-${0}`}>
      <TableCell align="center">
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Skeleton animation={"wave"} variant={"circular"}>
              <Avatar />
            </Skeleton>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Skeleton animation={"wave"}>
              <Typography align="left" variant="subtitle1" component="div">
                Contractor Name
              </Typography>
            </Skeleton>
            <Skeleton animation={"wave"}>
              <Typography align="left" variant="subtitle2" noWrap>
                Project Date
              </Typography>
            </Skeleton>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell align="center">
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          <Skeleton animation="wave" variant="rounded">
            <Button
              color="primary"
              sx={{
                "&:hover ": {
                  background: "#e3f2fd",
                },
                borderColor: "#0063c6",
                color: "#0063c6",
              }}
              size="large"
             />
          </Skeleton>
          <Skeleton animation="wave" variant="circular">
            <IconButton
              color="primary"
              sx={{
                "&:hover ": {
                  background: "#e3f2fd",
                },
                borderColor: "#0063c6",
                color: "#0063c6",
              }}
              size="large"
            >
              <EditIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>
          <Skeleton animation="wave" variant="circular">
            <IconButton color="error" size="large">
              <DeleteIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
