import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import {
  Box,
  Button,
  Grid,
  Pagination,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import * as RouteHelper from "@utils/routes";
import { useNavigate } from "react-router-dom";
import { DEFAULT_PAGINATION_LIMIT } from "social-pro-common/entities/contractorEmployee";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

import { ProjectEmployeeTableRow } from "./ProjectEmployeeTableRow";
import { ProjectEmployeeTableRowSkeleton } from "./ProjectEmployeeTableRowSkeleton";
import { NoRows } from "../ListTable/NoRows";


interface ProjectEmployeeTableProps {
  loading: boolean;
  project?: ProjectLineItem;
  employees: EmployeeLineItem[];
  employeeCount: number;
  setPage: (page: number) => void;
  handleOpenListModal: (employee: EmployeeLineItem) => void;
  handleEmployeeAssignment: (
    employee: EmployeeLineItem,
    toggle: boolean,
  ) => Promise<void>;
}

export const ProjectEmployeeTable = ({
  employeeCount,
  employees,
  handleEmployeeAssignment,
  handleOpenListModal,
  loading,
  project,
  setPage,
}: ProjectEmployeeTableProps) => {
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };
  const TABLE_HEAD: TableHeaderCell[] = [
    { align: "left", id: "employee-name", label: "Name or Code" },
    { align: "left", id: "occupation", label: "Occupation" },
    { align: "left", id: "occupationType", label: "Occupation Type" },
    { align: "left", id: "socialFactors", label: "Social Factors" },
    { align: "center", id: "toggle", label: "Assign To Project" },
    { align: "center", id: "viewEmployeeModal", label: "Action" },
  ];

  return (
    <>
      {loading ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: "0 40px",
            padding: "5px 0 !important",
          }}
        >
          <Table>
            <ListTableHead loading headLabel={TABLE_HEAD} />
            <TableBody>
              {Array.from({ length: 10 }, (_, index) => (
                <ProjectEmployeeTableRowSkeleton key={index} />
              ))}
            </TableBody>
          </Table>
        </Grid>
      ) : null}

      {!loading && employeeCount >= 0 ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: "0 40px",
            padding: "5px 0 !important",
          }}
        >
          <Table data-test-id="project-employee-table">
            <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
            <TableBody>
              {!loading && employees.length > 0
                ? employees.map((row) => {
                  return (
                    <ProjectEmployeeTableRow
                      key={row.id}
                      employee={row}
                      project={project}
                      handleOpenListModal={handleOpenListModal}
                      handleEmployeeAssignment={handleEmployeeAssignment}
                    />
                  );
                })
                : null}
              {!loading && employees.length == 0 ? (
                <NoRows title={"Employees"} colSpan={7} />
              ) : null}
            </TableBody>
          </Table>
        </Grid>
      ) : null}
      {!loading && employeeCount === -1 ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            alignItems: "center",
            background: "#E9F0F5",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            margin: "0 40px",
            minHeight: "400px",
            padding: "5px 0 !important",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              justifyContent: "center",
            }}
          >
            <GroupsOutlinedIcon
              sx={{ fontSize: "100px", height: "100px", width: "100px" }}
            />
            <Typography
              variant="h4"
              style={{ color: "#87959F", fontSize: "18px", fontWeight: "400" }}
            >
              Add an employee
            </Typography>
            <Button
              className="blackBtn"
              variant="contained"
              color="primary"
              onClick={() => navigate(RouteHelper.employees)}
            >
              Manage Employees
            </Button>
          </Box>
        </Grid>
      ) : null}
      {employeeCount > DEFAULT_PAGINATION_LIMIT ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "0 40px 40px 40px",
          }}
        >
          <Pagination
            count={Math.ceil(employeeCount / DEFAULT_PAGINATION_LIMIT)}
            sx={{ margin: "auto" }}
            onChange={handleChange}
            boundaryCount={2}
          />
        </Grid>
      ) : null}
    </>
  );
};
