// material-ui

// project imports
import { useProfileContext } from "@hooks/context/useProfileContext";
import { getImageUrl } from "@hooks/utils/useUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RestoreIcon from "@mui/icons-material/Restore";
import {
  Avatar,
  AvatarGroup,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Box,
  Zoom,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { userHasAdminAccess } from "@utils/projectAccess";
import { useEffect, useState } from "react";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { packageTypeToString } from "social-pro-common/interfaces/contractorPackage";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectDetailLineItem } from "social-pro-common/interfaces/project";
import { getSeverityColour } from "social-pro-common/utils/color";

// style constant

interface ProjectCardProps {
  project: ProjectDetailLineItem;
  index: number;
  userProfile: ProfileLineItem;
  handleAcceptPackage: (projectId: string, contractorPackageId: string) => void;
  handleViewProject: (project: ProjectDetailLineItem) => void;
  handleEditProject: (project: ProjectDetailLineItem) => void;
  handleArchiveProject: (project: ProjectDetailLineItem) => void;
}

export const ProjectCard = ({
  handleAcceptPackage,
  handleArchiveProject,
  handleEditProject,
  handleViewProject,
  index,
  project,
  userProfile,
}: ProjectCardProps) => {
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [collaboratorImages, setCollaboratorImages] = useState<string[]>([]);

  const { userOrganisation } = useProfileContext();
  useEffect(() => {
    const getImage = async () => {
      const images = await Promise.all(
        project.collaborators.map((c) => getImageUrl(c)),
      );
      setCollaboratorImages(images);
    };
    if (project) {
      getImage();
    }
  }, [project]);

  const viewProject = () => {
    if (project.isAccepted) {
      handleViewProject(project);
    } else {
      setOpenAcceptDialog(true);
    }
  };

  return (
    <>
      <TableRow
        hover
        key={index}
        style={{ cursor: "pointer", padding: "10px 0 0" }}
      >
        <TableCell onClick={viewProject}>
          <span
            className={project.archived ? "archivedIcon" : "checkIcon"}
            style={{ boxShadow: "none", height: "12px", width: "12px" }}
          />
        </TableCell>
        <TableCell onClick={viewProject}>
          <Stack direction="column" spacing={0}>
            <Typography variant="body1" fontWeight={600}>
              {project.projectName || ""}
            </Typography>
            <Typography variant="caption">
              {project.packageType !== PackageType.PrimaryPackage
                ? packageTypeToString(project.packageType)
                : null}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell onClick={viewProject}>
          {project.organisationName || ""}
        </TableCell>
        <TableCell>
          {`${project.projectAddress.addressLine1} ${project.projectAddress.postCode}`}
        </TableCell>

        {collaboratorImages.length > 0 ? (
          <TableCell
            className="left logos-group"
            onClick={viewProject}
            sx={{ alignContent: "left", textAlign: "left !important" }}
          >
            <Box sx={{}}>
              <AvatarGroup
                max={5}
                sx={{
                  "& .MuiAvatar-root": {
                    backgroundColor: "#ffffff",
                    fontSize: "10px",
                    height: 23,
                    width: 23,
                  },
                }}
              >
                {collaboratorImages.map((img, index) => {
                  return (
                    <Avatar
                      key={index}
                      alt={`${index}`}
                      src={img}
                      sx={{ backgroundColor: "#ffffff" }}
                    />
                  );
                })}
              </AvatarGroup>
            </Box>
          </TableCell>
        ) : (
          <TableCell onClick={viewProject}>
            <Typography align="left">-</Typography>
          </TableCell>
        )}
        <TableCell align="center" className="center" onClick={viewProject}>
          <CircularProgressWithLabel
            sx={{ margin: "auto" }}
            value={project.estimatedProgress}
            color={getSeverityColour(project.estimatedProgress)}
          />
        </TableCell>

        <TableCell align="right" className="right">
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Button
              color="primary"
              size="large"
              onClick={viewProject}
              className="blackBtn"
              sx={{ color: "white", fontSize: "12px" }}
            >
              view
            </Button>

            {userHasAdminAccess(project) ? (
              <Box display="flex" alignItems="center">
                <Tooltip
                  placement="top"
                  title="Edit"
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    color="primary"
                    size="large"
                    disabled={
                      project.organisationId !== userOrganisation?.id ||
                      !userProfile.isAdmin
                    }
                    onClick={() => handleEditProject(project)}
                  >
                    <EditIcon
                      sx={{
                        fontSize: "1.1rem",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                {userHasAdminAccess(project) ? (
                  !project.archived ? (
                    <Tooltip
                      placement="top"
                      title="Archive"
                      TransitionComponent={Zoom}
                    >
                      <IconButton
                        color="error"
                        size="large"
                        disabled={
                          project.organisationId !== userOrganisation?.id ||
                          !userProfile.isAdmin
                        }
                        onClick={() => setOpenConfirmDialog(true)}
                      >
                        <DeleteIcon
                          sx={{
                            fontSize: "1.1rem",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement="top"
                      title="Restore"
                      TransitionComponent={Zoom}
                    >
                      <IconButton
                        color="error"
                        size="large"
                        disabled={
                          project.organisationId !== userOrganisation?.id ||
                          !userProfile.isAdmin
                        }
                        onClick={() => setOpenConfirmDialog(true)}
                      >
                        <RestoreIcon
                          sx={{
                            fontSize: "1.1rem",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )
                ) : null}
              </Box>
            ) : null}
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={
          !project.archived
            ? "Are you sure you want to archive this project?"
            : "Are you sure you want to restore this project?"
        }
        open={openConfirmDialog}
        title={!project.archived ? "Archive Project" : "Restore Project"}
        buttonText={!project.archived ? "Archive" : "Restore"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleArchiveProject(project)}
      />
      <ConfirmationDialog
        message={"Do you accept this package of works?"}
        open={openAcceptDialog}
        title={"Accept Package"}
        buttonText={"Accept"}
        intent={"error"}
        onCancel={() => setOpenAcceptDialog(false)}
        onConfirm={async () => {
          await handleAcceptPackage(project.id, project.contractorPackageId);
          await handleViewProject(project);
          setOpenAcceptDialog(false);
        }}
      />
    </>
  );
};
