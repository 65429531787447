import {
  Box,
  InputAdornment,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { ChangeEvent } from "react";
import { FormikField } from "social-pro-common/interfaces/project";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

interface PackageSocialSpendTableRowProps {
  loading: boolean;
  title: string;
  field: FormikField;
  onChangeTarget: (target: number) => void;
  onChangeTargetRealised: (targetRealised: number) => void;
}

export const PackageSocialSpendTableRow = ({
  field,
  loading,
  onChangeTarget,
  onChangeTargetRealised,
  title,
}: PackageSocialSpendTableRowProps) => {
  const onChangeTargetValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeTarget(Number(e.target.value));
  };

  const onChangeTargetValueRealised = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeTargetRealised(Number(e.target.value));
  };

  return (
    <TableRow key={title}>
      <TableCell align="left" sx={{ minWidth: "10vw" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Tooltip title={title} TransitionComponent={Zoom}>
            <Typography noWrap>{title}</Typography>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell align="center">
        <StyledTextField
          className="search tableInput"
          loading={loading}
          disabled={loading}
          id={`${field.id}-target`}
          name={`${field.id}-target`}
          fullWidth
          sx={{ width: "75%" }}
          onChange={onChangeTargetValue}
          value={formatDecimalPlaces(field.targetValue)}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputComponent: NumericFormatCustom as any,
          }}
        />
      </TableCell>
      <TableCell align="center">
        <StyledTextField
          className="search tableInput"
          loading={loading}
          disabled={loading}
          id={`${field.id}-target-realised`}
          name={`${field.id}-target-realised`}
          fullWidth
          sx={{ width: "75%" }}
          onChange={onChangeTargetValueRealised}
          value={formatDecimalPlaces(field.targetValueRealised)}
          InputProps={{
            inputComponent: NumericFormatCustom as any,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </TableCell>
    </TableRow>
  );
};
