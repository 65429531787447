import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { useProject } from "@hooks/crud/project/useProject";
import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  createDefaultProfile,
  ProfileLineItem,
} from "social-pro-common/interfaces/profile";
import {
  ProjectDetailLineItem,
  ProjectLineItem,
} from "social-pro-common/interfaces/project";

import { useProfileContext } from "./useProfileContext";


type ProjectContextType = {
  allProjects: ProjectDetailLineItem[];
  contractorPackage?: ContractorPackageLineItem;
  createProject: (project: ProjectLineItem) => Promise<void>;
  isOwner: boolean;
  isPrimaryPackage: boolean;
  isProjectLoading: boolean;
  selectedProject?: ProjectLineItem;
  setSelectedProject: (project: ProjectLineItem) => void;
  updateProject: (project: ProjectLineItem) => Promise<void>;
  userProfile: ProfileLineItem;
};

export const ProjectContext = React.createContext<ProjectContextType>({
  allProjects: [],
  createProject: () => Promise.resolve(),
  isOwner: false,
  isPrimaryPackage: false,
  isProjectLoading: false,
  setSelectedProject: () => {
    console.error("setSelectedProject function not implemented");
  },
  updateProject: () => Promise.resolve(),
  userProfile: createDefaultProfile(),
});

type ProjectProviderProps = {
  children: React.ReactNode;
};

const ProjectProvider: React.FC<ProjectProviderProps> = (props) => {
  const { contractorPackageId, projectId } = useParams();
  const [isPrimaryPackage, setIsPrimaryPackage] = useState<boolean>(false);
  const [contractorPackage, setContractorPackage] =
    useState<ContractorPackageLineItem>();

  if (!projectId) {
    throw new Error("Project id is required");
  }

  const { userProfile } = useProfileContext();

  if (!userProfile) {
    throw new Error("User profile is required");
  }

  const {
    createProject,
    getProject,
    isProjectLoading,
    projects,
    selectedProject,
    setSelectedProject,
    updateProject,
  } = useProject();

  const { getContractorPackage, isContractorPackageLoading } =
    useContractorPackage();

  const fetchProjectData = useCallback(
    async (projectId: string, contractorPackageId: string) => {
      const [_, contractorPackageRes] = await Promise.all([
        getProject(projectId),
        getContractorPackage(contractorPackageId),
      ]);
      const selectedPackage = contractorPackageRes;
      setIsPrimaryPackage(
        selectedPackage
          ? selectedPackage.packageType === PackageType.PrimaryPackage
          : false,
      );
      setContractorPackage(selectedPackage);
    },
    [getProject, getContractorPackage],
  );

  useEffect(() => {
    if (projectId && contractorPackageId) {
      fetchProjectData(projectId, contractorPackageId);
    }
  }, [projectId, fetchProjectData]);

  return (
    <ProjectContext.Provider
      value={{
        allProjects: projects,
        contractorPackage: contractorPackage,
        createProject,
        isOwner: isPrimaryPackage,
        isPrimaryPackage,
        isProjectLoading: isProjectLoading || isContractorPackageLoading,
        selectedProject: selectedProject || undefined,
        setSelectedProject,
        updateProject,
        userProfile,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

const useProjectContext = () => React.useContext(ProjectContext);

export { ProjectProvider, useProjectContext };
