import {
  Skeleton,
  SxProps,
  TableCell,
  TableSortLabel,
  Theme,
  Typography,
} from "@mui/material";

// material-ui

interface TableHeaderProps {
  loading: boolean;
  title: string;
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  sx?: SxProps<Theme>;
  sortInfo?: SortInfo;
  handleRequestSort?: (property: SortInfo) => void;
}

export interface SortInfo {
  order: Order;
  page: number;
  index: number;
  orderBy: number;
}

export type Order = "asc" | "desc";

export const TableHeader = ({
  align = "center",
  handleRequestSort,
  loading,
  sortInfo,
  sx,
  title,
}: TableHeaderProps) => {
  const content = (
    <Typography
      align={align}
      sx={{
        color: "#333333",
        fontSize: "0.875rem",
        fontWeight: "500",
      }}
    >
      {title}
    </Typography>
  );
  return (
    <TableCell align={align} sx={{ ...sx }}>
      {loading ? (
        <Skeleton
          animation="wave"
          sx={{
            margin: align == "center" ? "auto" : undefined,
            marginLeft: align == "right" ? "auto" : undefined,
          }}
        >
          <Typography
            sx={{
              color: "#333333",
              fontSize: "0.875rem",
              fontWeight: "500",
            }}
          >
            {title.length > 0 ? title : ""}
          </Typography>
        </Skeleton>
      ) : sortInfo ? (
        <TableSortLabel
          active={sortInfo.index === sortInfo.orderBy}
          direction={
            sortInfo.index === sortInfo.orderBy ? sortInfo.order : "asc"
          }
          onClick={
            handleRequestSort
              ? () => {
                  handleRequestSort({
                    ...sortInfo,
                    order: sortInfo.order === "asc" ? "desc" : "asc",
                    orderBy: sortInfo.index,
                  });
                }
              : undefined
          }
        >
          {content}
        </TableSortLabel>
      ) : (
        content
      )}
    </TableCell>
  );
};
