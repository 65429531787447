import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { DEFAULT_PAGINATION_LIMIT } from "social-pro-common/entities/contractorEmployee";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

import { EmployeeTableRow } from "./EmployeeTableRow";
import { EmployeeTableRowSkeleton } from "./EmployeeTableRowSkeleton";
import { NoRows } from "../ListTable/NoRows";


interface EmployeeTableProps {
  loading: boolean;
  employees: EmployeeLineItem[];
  employeeCount: number;
  setPage: (page: number) => void;
  handleOpenListModal: (employee: EmployeeLineItem) => void;
  handleEditEmployee: (employee: EmployeeLineItem) => void;
  handleArchiveEmployee: (employee: EmployeeLineItem) => void;
}

export const EmployeeTable = ({
  employeeCount,
  employees,
  handleArchiveEmployee,
  handleEditEmployee,
  handleOpenListModal,
  loading,
  setPage,
}: EmployeeTableProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const TABLE_HEAD: TableHeaderCell[] = [
    {
      align: "left",
      id: "employee-name",
      label: "Name or Code",
      width: "15%",
    },
    {
      align: "left",
      id: "primary-employer",
      label: "Primary Employer",
      width: "25%",
    },
    { align: "left", id: "occupation", label: "Occupation", width: "15%" },
    {
      align: "left",
      id: "occupationType",
      label: "Occupation Type",
      width: "30%",
    },
    { align: "left", id: "gender", label: "Gender", width: "10%" },
    { align: "center", id: "action", label: "Action", width: "10%" },
  ];

  return (
    <Grid
      item
      xs={12}
      md={12}
      sx={{
        margin: "0 40px",
        padding: "5px 0 !important",
      }}
    >
      {loading ? (
        <Table>
          <ListTableHead loading headLabel={TABLE_HEAD} />
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <EmployeeTableRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : null}
      {!loading && employeeCount >= 0 ? (
        <Table data-test-id="employee-table">
          {/* <ListTableHeadNew loading={loading} headLabel={TABLE_HEAD} /> */}
          <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
          <TableBody>
            {employees.length > 0
              ? employees?.map((row) => {
                  return (
                    <EmployeeTableRow
                      key={row.id}
                      employee={row}
                      handleOpenListModal={handleOpenListModal}
                      handleEditEmployee={handleEditEmployee}
                      handleArchiveEmployee={handleArchiveEmployee}
                    />
                  );
                })
              : null}
            {employees.length == 0 ? (
              <NoRows title={"Employees"} colSpan={7} />
            ) : null}
          </TableBody>
        </Table>
      ) : null}

      {!loading && employeeCount === -1 ? (
        <Grid
          data-test-id="empty-employee-table"
          item
          xs={12}
          md={12}
          sx={{
            alignItems: "center",
            background: "#E9F0F5",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            minHeight: "400px",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              justifyContent: "center",
            }}
          >
            <GroupsOutlinedIcon
              sx={{ fontSize: "100px", height: "100px", width: "100px" }}
            />
            <Typography
              variant="h4"
              style={{ color: "#87959F", fontSize: "18px", fontWeight: "400" }}
            >
              Add an employee
            </Typography>
          </Box>
        </Grid>
      ) : null}
      {employeeCount > DEFAULT_PAGINATION_LIMIT ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "0 40px 40px 40px",
          }}
        >
          <Pagination
            count={Math.ceil(employeeCount / DEFAULT_PAGINATION_LIMIT)}
            sx={{ margin: "auto" }}
            onChange={handleChange}
            boundaryCount={2}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
