import { InputAdornment, TableCell, TableRow, Typography } from "@mui/material";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import * as React from "react";
import { ForecastUnitType } from "social-pro-common/entities/forecastData";
import { ForecastDataLineItem } from "social-pro-common/interfaces/forecastData";
import { reportIdToMonth, reportIdToYear } from "social-pro-common/utils/date";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

interface ForecastInputTableRowProps {
  loading: boolean;
  units: ForecastUnitType;
  forecastData: ForecastDataLineItem;
  handleSave: (forecast: ForecastDataLineItem) => void;
}

export default function ForecastInputTableRow({
  forecastData,
  handleSave,
  loading,
  units,
}: ForecastInputTableRowProps) {
  return (
    <TableRow key={forecastData.id}>
      <TableCell align="left" scope="row" sx={{ width: "30%" }}>
        <Typography align="left">
          {`${reportIdToMonth(forecastData.reportId)} ${reportIdToYear(
            forecastData.reportId,
          )}`}
        </Typography>
      </TableCell>
      <TableCell align="right" className="transpField right">
        <StyledTextField
          sx={{ height: "35px" }}
          fullWidth
          loading={loading}
          className="search tableInput"
          disabled={loading}
          id={`${forecastData.id}-row`}
          name="forecastDataValue"
          margin="dense"
          onChange={(e) => {
            handleSave({
              ...forecastData,
              value: parseFloat(e.target.value),
            });
          }}
          value={
            forecastData.value ? formatDecimalPlaces(forecastData.value) : ""
          }
          InputProps={{
            endAdornment:
              units === ForecastUnitType.Hour ? (
                <InputAdornment
                  position="end"
                  sx={{
                    color: loading ? "rgba(0, 0, 0, 0.26)" : undefined,
                  }}
                >
                  hours
                </InputAdornment>
              ) : undefined,
            inputComponent: NumericFormatCustom as any,
            startAdornment:
              units === ForecastUnitType.Spend ? (
                <InputAdornment
                  position="start"
                  sx={{
                    color: loading ? "rgba(0, 0, 0, 0.26)" : undefined,
                  }}
                >
                  $
                </InputAdornment>
              ) : undefined,
          }}
        />
      </TableCell>
    </TableRow>
  );
}
