// material-ui
import { List, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NavItemType } from "types";

import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

interface NavGroupProps {
  loading: boolean;
  item: NavItemType;
  isDrawOpen: boolean;
  selectedItem: string[];
  setSelectedItem: (selectedItem: string[]) => void;
  setIsDrawOpen: (value: boolean) => void;
}

const NavGroup = ({
  isDrawOpen,
  item,
  loading,
  selectedItem,
  setIsDrawOpen,
  setSelectedItem,
}: NavGroupProps) => {
  const theme = useTheme();

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case "collapse":
        return (
          <NavCollapse
            loading={loading}
            key={menu.id}
            isDrawOpen={isDrawOpen}
            setIsDrawOpen={setIsDrawOpen}
            menu={menu}
            level={1}
            parentId={item.id || ""}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        );
      case "item":
        return (
          <NavItem
            loading={loading}
            key={menu.id}
            item={menu}
            isDrawOpen={isDrawOpen}
            setIsDrawOpen={setIsDrawOpen}
            level={1}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        );
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        disablePadding={!isDrawOpen}
        subheader={
          item.title &&
          isDrawOpen && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.menuCaption }}
              display="block"
              gutterBottom
            >
              {!loading ? item.title : <Skeleton variant="text" />}
              {item.caption && (
                <Typography
                  variant="caption"
                  sx={{ ...theme.typography.subMenuCaption, color: "#FFFFFF" }}
                  display="block"
                  gutterBottom
                  color="#FFFFFF"
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      {/* {isDrawOpen && <Divider sx={{ mt: 0.25, mb: 1.25 }} />} */}
    </>
  );
};

export default NavGroup;
