import { useProjectContext } from "@hooks/context/useProjectContext";
import { Table, TableBody } from "@mui/material";
import { SocialSpendAnalyticsModelLineItem } from "social-pro-common/interfaces/analytics";
import {
  SocialCommitmentTypeSpend,
  socialSpendCommitmentTypeToString,
} from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

import { ContractorAnalyticsSocialSpendRow } from "./ContractorAnalyticsSocialSpendRow";
import { ContractorAnalyticsMainRowSkeleton } from "../ContractorAnalyticsMainRow/ContractorAnalyticsMainRow";
import ListTableHead from "../ListTable/ListTableHead";
import { NoRows } from "../ListTable/NoRows";

interface ContractorAnalyticsSocialSpendProps {
  loading: boolean;
  socialSpendAnalysis?: SocialSpendAnalyticsModelLineItem;
  onSocialSpendTargetChange: (targetId: string) => void;
}
export const ContractorAnalyticsSocialSpend = ({
  loading,
  onSocialSpendTargetChange,
  socialSpendAnalysis,
}: ContractorAnalyticsSocialSpendProps) => {
  const { selectedProject } = useProjectContext();

  const TABLE_HEAD: TableHeaderCell[] = [
    {
      align: "left",
      id: "spend-type",
      label: "Type",
      width: "40%",
    },
    {
      align: "center",
      id: "target",
      label: "Target",
      width: "10%",
    },
    { align: "center", id: "to-date", label: "To Date", width: "10%" },
    {
      align: "center",
      id: "reported",
      label: "This Month",
      width: "10%",
    },
    { align: "center", id: "progress", label: "Progress", width: "10%" },
    { align: "center", id: "action", label: "Action", width: "10%" },
  ];

  const sortedTargets =
    socialSpendAnalysis?.targets.sort((a, b) => {
      const firstTargetName =
        a?.targetDescription ||
        socialSpendCommitmentTypeToString(
          a.targetType as SocialCommitmentTypeSpend,
        );
      const secondTargetName =
        b?.targetDescription ||
        socialSpendCommitmentTypeToString(
          b.targetType as SocialCommitmentTypeSpend,
        );
      if (firstTargetName < secondTargetName) return -1;
      if (firstTargetName > secondTargetName) return 1;
      return 0;
    }) || [];
  return (
    <>
      {loading ? (
        <Table>
          <ListTableHead loading headLabel={TABLE_HEAD} />
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <ContractorAnalyticsMainRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : null}
      {!loading ? (
        <Table
          data-test-id="contractor-analytics-spend-table"
          style={{ tableLayout: "fixed" }}
        >
          <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
          <TableBody>
            {!loading && selectedProject
              ? sortedTargets.map((p) => {
                  return (
                    <ContractorAnalyticsSocialSpendRow
                      key={p.id}
                      project={selectedProject}
                      analysis={p}
                      onSocialSpendTargetChange={onSocialSpendTargetChange}
                    />
                  );
                })
              : null}
            {sortedTargets.length === 0 && !loading ? (
              <NoRows title={"spend analytics"} colSpan={6} />
            ) : null}
          </TableBody>
        </Table>
      ) : null}
    </>
  );
};
