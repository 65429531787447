"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatForecastDataTypeToString = exports.createDefaultForecastData = exports.createDefaultForecastDataLineItem = exports.createDefaultForecastModel = exports.createDefaultForecastModelLineItem = void 0;
var uuid_1 = require("uuid");
var forecastData_1 = require("../../entities/forecastData");
var createDefaultForecastModelLineItem = function (projectId, contractorPackageId, reportIds, modelName, colour, projectLabourHourCommitmentId, projectSocialSpendCommitmentId) {
    if (projectLabourHourCommitmentId && projectSocialSpendCommitmentId) {
        throw new Error("Cannot have both projectLabourHourCommitmentId and projectSocialSpendCommitmentId");
    }
    if (!projectLabourHourCommitmentId && !projectSocialSpendCommitmentId) {
        throw new Error("Must have either projectLabourHourCommitmentId or projectSocialSpendCommitmentId");
    }
    var forecastId = (0, uuid_1.v4)();
    return {
        active: true,
        colour: colour,
        contractorPackageId: contractorPackageId,
        created: new Date(),
        data: reportIds.map(function (rm) {
            return (0, exports.createDefaultForecastDataLineItem)(forecastId, rm);
        }),
        id: forecastId,
        modelName: modelName,
        projectId: projectId,
        projectLabourHourCommitmentId: projectLabourHourCommitmentId,
        projectSocialSpendCommitmentId: projectSocialSpendCommitmentId,
        units: projectLabourHourCommitmentId
            ? forecastData_1.ForecastUnitType.Hour
            : forecastData_1.ForecastUnitType.Spend,
    };
};
exports.createDefaultForecastModelLineItem = createDefaultForecastModelLineItem;
var createDefaultForecastModel = function (projectId, contractorPackageId, reportIds, modelName, colour, projectLabourHourCommitmentId, projectSocialSpendCommitmentId) {
    if (projectLabourHourCommitmentId && projectSocialSpendCommitmentId) {
        throw new Error("Cannot have both projectLabourHourCommitmentId and projectSocialSpendCommitmentId");
    }
    if (!projectLabourHourCommitmentId && !projectSocialSpendCommitmentId) {
        throw new Error("Must have either projectLabourHourCommitmentId or projectSocialSpendCommitmentId");
    }
    var forecastId = (0, uuid_1.v4)();
    return {
        active: true,
        colour: colour,
        contractor_package_id: contractorPackageId,
        created: new Date(),
        data: reportIds.map(function (rm) { return (0, exports.createDefaultForecastData)(forecastId, rm); }),
        forecast_model_id: forecastId,
        model_name: modelName,
        project_id: projectId,
        project_labour_hour_commitment_id: projectLabourHourCommitmentId,
        project_social_spend_commitment_id: projectSocialSpendCommitmentId,
        units: projectLabourHourCommitmentId
            ? forecastData_1.ForecastUnitType.Hour
            : forecastData_1.ForecastUnitType.Spend,
    };
};
exports.createDefaultForecastModel = createDefaultForecastModel;
var createDefaultForecastDataLineItem = function (forecastModelId, reportId) {
    return {
        created: new Date(),
        forecastModelId: forecastModelId,
        id: (0, uuid_1.v4)(),
        reportId: reportId,
        value: 0,
    };
};
exports.createDefaultForecastDataLineItem = createDefaultForecastDataLineItem;
var createDefaultForecastData = function (forecastModelId, reportId) {
    return {
        created: new Date(),
        forecast_data_id: (0, uuid_1.v4)(),
        forecast_model_id: forecastModelId,
        report_id: reportId,
        value: 0,
    };
};
exports.createDefaultForecastData = createDefaultForecastData;
var formatForecastDataTypeToString = function (type) {
    switch (type) {
        case forecastData_1.ForecastUnitType.Hour:
            return "Hours";
        case forecastData_1.ForecastUnitType.Spend:
            return "Spend";
        default:
            return "";
    }
};
exports.formatForecastDataTypeToString = formatForecastDataTypeToString;
