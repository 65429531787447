import { InputAdornment, TableCell, TableRow, Typography } from "@mui/material";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import React from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";

interface EmployeeDisadvantagedCostEntryFormRowProps {
  loading: boolean;
  employee: EmployeeLineItem;
  setEmployee: (employee: EmployeeLineItem) => void;
}

export const EmployeeDisadvantagedCostEntryFormRow = ({
  employee,
  loading,
  setEmployee,
}: EmployeeDisadvantagedCostEntryFormRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell align="left" colSpan={1}>
          <Typography>{"Disadvantaged Cost Entry"}</Typography>
        </TableCell>
        <TableCell align="center" colSpan={3}>
          <StyledTextField
            loading={loading}
            id="targetValue"
            name="targetValue"
            value={employee.disadvantagedCostEntry}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const numberValue = e.target.value as any as number;
              setEmployee({
                ...employee,
                disadvantagedCostEntry: numberValue,
              });
            }}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            label=""
          />
        </TableCell>
      </TableRow>
    </>
  );
};
