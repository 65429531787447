import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import {
  CircularProgress,
  TableCell,
  TableRow,
  Button,
  Typography,
} from "@mui/material";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { useRef } from "react";
import { PolicySubmissionLineItem } from "social-pro-common/interfaces/policySubmission";
import {
  ProjectPolicyLineItem,
  policyTypeToString,
} from "social-pro-common/interfaces/projectPolicy";
import { getDocumentName } from "social-pro-common/utils/string";

interface PolicyRowProps {
  projectPolicy: ProjectPolicyLineItem;
  policySubmission?: PolicySubmissionLineItem;

  isUploading: boolean;
  isDownloading: boolean;
  selectedTargetId?: string;
  uploadProgress: number;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDownload: (uri: string, policyName: string) => void;
  setSelectedTargetId: (id: string) => void;
}

export const PolicyRow = ({
  isDownloading,
  isUploading,
  onDownload,
  onUpload,
  policySubmission,
  projectPolicy,
  selectedTargetId,
  setSelectedTargetId,
  uploadProgress,
}: PolicyRowProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const onFileInput = (id: string) => {
    if (fileInput.current) {
      fileInput.current.click();
      setSelectedTargetId(id);
    }
  };
  return (
    <>
      <TableRow>
        <TableCell>
          <Typography align="left">
            {projectPolicy.policyDescription ||
              policyTypeToString(projectPolicy.policyType)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography align="center">
            {policySubmission?.created.toLocaleDateString("en-GB") || (
              <DangerousOutlinedIcon
                color="error"
                sx={{ margin: "auto", padding: "0" }}
              />
            )}
          </Typography>
        </TableCell>
        <TableCell align="center" className="right">
          {policySubmission ? (
            isDownloading &&
            projectPolicy.id === selectedTargetId &&
            policySubmission?.uri ? (
              <CircularProgress />
            ) : policySubmission?.uri ? (
              <Button
                color="primary"
                size="large"
                disabled={isUploading || isDownloading}
                onClick={() =>
                  policySubmission.uri
                    ? onDownload(
                        policySubmission.uri,
                        getDocumentName(
                          policyTypeToString(projectPolicy.policyType),
                          policySubmission.uri,
                        ),
                      )
                    : undefined
                }
                className="blackBtn grey-outline-btn"
                sx={{
                  color: "white",
                  fontSize: "14px",
                  lineHeight: "16px",
                  padding: "10px 15px",
                }}
              >
                Download
              </Button>
            ) : null
          ) : null}
        </TableCell>
        <TableCell align="center" className="center">
          {isUploading && projectPolicy.id === selectedTargetId ? (
            <CircularProgressWithLabel value={uploadProgress} />
          ) : (
            <label htmlFor="containedUploadButtonFile">
              <input
                ref={fileInput}
                hidden
                type="file"
                style={{
                  cursor: "pointer",
                  opacity: 0,
                  padding: 0.5,
                  position: "fixed",
                  zIndex: 1,
                }}
                onChange={onUpload}
              />
              <Button
                color="primary"
                size="large"
                data-test-id="policy-details-button"
                disabled={isUploading || isDownloading}
                onClick={() => {
                  onFileInput(projectPolicy.id);
                }}
                className={"blackBtn"}
                sx={{
                  color: "white",
                  fontSize: "14px",
                  lineHeight: "16px",
                  padding: "10px 15px",
                }}
              >
                {policySubmission ? "Reupload" : "Upload"}
              </Button>
            </label>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
