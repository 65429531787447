import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MainCard from "@stories/ui-component/cards/MainCard";
import Transitions from "@stories/ui-component/extended/Transitions";
import * as RouteHelper from "@utils/routes";
import {
  Folder,
  Settings,
  LogOut,
  HelpCircle,
  UserCogIcon,
  Users,
  SlidersVertical,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

// material-ui

// third-party
// project imports

// assets
// import { createDemoData } from "@hooks/demo/useDemo";
// import { DemoGeneratorDialog } from "@stories/molecules/DemoGeneratorDialog/DemoGeneratorDialog";
// import { IS_PROD_ENV } from "init";

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

const ProfileSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { isSocialProAdmin, signOut, userProfile } = useAuthContext();
  const { isAuthProfileLoading, userOrganisation } = useProfileContext();
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);

  const routeMatch = useRouteMatch([
    RouteHelper.listProjects,
    RouteHelper.organisation,
    RouteHelper.profile,
    RouteHelper.users,
    RouteHelper.platformSupport,
    RouteHelper.socialProAdmin,
  ]);

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (err) {
      console.error(err);
    }
  };
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    route = "",
  ) => {
    setSelectedIndex(index);
    handleClose(event);

    if (route) {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent,
  ) => {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const currentPathId = routeMatch?.pattern?.path.split("/")[1];

    if (currentPathId === "list-projects") {
      setSelectedIndex(0);
    } else if (currentPathId === "organisation") {
      setSelectedIndex(1);
    } else if (currentPathId === "profile") {
      setSelectedIndex(2);
    } else if (currentPathId === "users") {
      setSelectedIndex(3);
    } else if (currentPathId === "platform-support") {
      setSelectedIndex(4);
    } else if (currentPathId === "social-pro-admin") {
      setSelectedIndex(5);
    } else {
      setSelectedIndex(-1);
    }
  }, [location]);

  // const [isGeneratingDemoData, setIsGeneratingDemoData] = useState(false);
  // const [demoDataMessage, setDemoDataMessage] = useState("");

  // const createDemo = async () => {
  //   if (userOrganisation) {
  //     try {
  //       setIsGeneratingDemoData(true);
  //       await createDemoData(userOrganisation.id, setDemoDataMessage);
  //       setIsGeneratingDemoData(false);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // };

  return (
    <>
      <Chip
        className="profileChip"
        sx={{
          "& .MuiChip-label": {
            lineHeight: 0,
          },
          '&[aria-controls="menu-list-grow"], &:hover': {
            "& svg": {
              stroke: theme.palette.primary.light,
            },
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
          },
          alignItems: "center",
          backgroundColor: "#1971D1",
          borderRadius: "12px",
          height: "48px",
          transition: "all .2s ease-in-out",
        }}
        icon={
          !isAuthProfileLoading && userOrganisation ? (
            <Avatar
              src={userOrganisation.organisationLogo}
              alt="user-images"
              sx={{
                ...theme.typography.mediumAvatar,
                backgroundColor: "#ffffff",
                cursor: "pointer",
                margin: "8px 0 8px 8px !important",
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            />
          ) : (
            <Skeleton animation={"wave"} variant="circular">
              <Avatar />
            </Skeleton>
          )
        }
        label={
          <Typography
            data-test-id="representative-name"
            variant="h4"
            sx={{ color: "#fff", fontSize: "14px", fontWeight: "500" }}
          >
            {userProfile?.name || userOrganisation?.organisationName || ""}
          </Typography>
        }
        variant="filled"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
        aria-label="user-account"
      />

      <Popper
        className="popup"
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <PerfectScrollbar>
                      <Box sx={{ p: 1.5, pt: 1 }}>
                        <List
                          component="nav"
                          sx={{
                            "& .MuiListItemButton-root": {
                              mt: 0.5,
                            },
                            backgroundColor: theme.palette.background.paper,
                            maxWidth: 350,
                            minWidth: 300,

                            [theme.breakpoints.down("md")]: {
                              minWidth: "100%",
                            },
                            width: "100%",
                          }}
                        >
                          <ListItemButton
                            sx={{ borderRadius: "20px", padding: "6px 12px" }}
                            selected={selectedIndex === 0}
                            onClick={(
                              event: React.MouseEvent<HTMLDivElement>,
                            ) =>
                              handleListItemClick(
                                event,
                                0,
                                RouteHelper.listProjects,
                              )
                            }
                          >
                            <ListItemIcon>
                              <Folder />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  data-test-id="list-projects-button"
                                  variant="body2"
                                >
                                  Projects
                                </Typography>
                              }
                            />
                          </ListItemButton>
                          {/* {!IS_PROD_ENV ? (
                            <ListItemButton
                              sx={{ borderRadius: "20px", padding: "6px 12px" }}
                              selected={selectedIndex === 1}
                              onClick={() => createDemo()}
                            >
                              <ListItemIcon>
                                <Sparkles />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    Create Demo Data
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          ) : null} */}
                          {/* {!IS_PROD_ENV ? (
                            <ListItemButton
                              sx={{ borderRadius: "20px", padding: "6px 12px" }}
                              selected={selectedIndex === 2}
                              onClick={() => createDemo2()}
                            >
                              <ListItemIcon>
                                <Sparkles />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    Create Demo Data 2 (Do not press this!)
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          ) : null} */}

                          {userProfile?.isAdmin ? (
                            <ListItemButton
                              sx={{ borderRadius: "20px", padding: "6px 12px" }}
                              selected={selectedIndex === 1}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>,
                              ) =>
                                handleListItemClick(
                                  event,
                                  1,
                                  RouteHelper.organisation,
                                )
                              }
                            >
                              <ListItemIcon>
                                <Settings />
                              </ListItemIcon>
                              <ListItemText
                                data-test-id="account-settings-button"
                                primary={
                                  <Typography variant="body2">
                                    Account Settings
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          ) : null}

                          <ListItemButton
                            sx={{ borderRadius: "20px", padding: "6px 12px" }}
                            selected={selectedIndex === 2}
                            onClick={(
                              event: React.MouseEvent<HTMLDivElement>,
                            ) =>
                              handleListItemClick(event, 2, RouteHelper.profile)
                            }
                          >
                            <ListItemIcon>
                              <UserCogIcon />
                            </ListItemIcon>
                            <ListItemText
                              data-test-id="profile-settings-button"
                              primary={
                                <Typography variant="body2">Profile</Typography>
                              }
                            />
                          </ListItemButton>

                          {userProfile?.isAdmin ? (
                            <ListItemButton
                              sx={{ borderRadius: "20px", padding: "6px 12px" }}
                              selected={selectedIndex === 3}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>,
                              ) =>
                                handleListItemClick(event, 3, RouteHelper.users)
                              }
                            >
                              <ListItemIcon>
                                <Users />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Grid
                                    container
                                    spacing={1}
                                    justifyContent="space-between"
                                  >
                                    <Grid item>
                                      <Typography
                                        variant="body2"
                                        data-test-id="user-settings"
                                      >
                                        Users & Permissions
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                }
                              />
                            </ListItemButton>
                          ) : null}
                          <ListItemButton
                            sx={{ borderRadius: "20px", padding: "6px 12px" }}
                            selected={selectedIndex === 4}
                            onClick={(
                              event: React.MouseEvent<HTMLDivElement>,
                            ) =>
                              handleListItemClick(
                                event,
                                4,
                                RouteHelper.platformSupport,
                              )
                            }
                          >
                            <HelpCircle />
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  data-test-id="help-center-button"
                                >
                                  Help Center
                                </Typography>
                              }
                            />
                          </ListItemButton>
                          {isSocialProAdmin ? (
                            <ListItemButton
                              sx={{ borderRadius: "20px", padding: "6px 12px" }}
                              selected={selectedIndex === 5}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>,
                              ) =>
                                handleListItemClick(
                                  event,
                                  5,
                                  RouteHelper.socialProAdmin,
                                )
                              }
                            >
                              <SlidersVertical />
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="body2"
                                    data-test-id="help-center-button"
                                  >
                                    Social Pro Admin
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          ) : null}
                          <ListItemButton
                            sx={{ borderRadius: "20px", padding: "6px 12px" }}
                            selected={selectedIndex === 6}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <LogOut />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  data-test-id="logout-button"
                                >
                                  Logout
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
      {/* <DemoGeneratorDialog
        isGeneratingDemoData={isGeneratingDemoData}
        demoDataMessage={demoDataMessage}
      /> */}
    </>
  );
};

export default ProfileSection;
