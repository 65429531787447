import { downloadFile } from "@hooks/utils/useUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import {
  Chip,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Zoom,
} from "@mui/material";
import { wordsToThreeLetterAcronym } from "@utils/string";
import { useState } from "react";
import { toast } from "react-toastify";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  SocialSpendLineItem,
  socialSpendServiceCategoryToString,
} from "social-pro-common/interfaces/socialSpend";
import { formatChartDate } from "social-pro-common/utils/date";
import { getDocumentName } from "social-pro-common/utils/string";

import { CircularProgressWithLabel } from "../CircularProgressWithLabel/CircularProgressWithLabel";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";


interface SocialSpendTableRowProps {
  loading: boolean;
  project?: ProjectLineItem;
  socialSpend: SocialSpendLineItem;
  readOnly?: boolean;
  handleEditSocialSpend: (socialSpend: SocialSpendLineItem) => void;
  handleDeleteSocialSpend: (socialSpend: SocialSpendLineItem[]) => void;
}

export const SocialSpendTableRow = ({
  handleDeleteSocialSpend,
  handleEditSocialSpend,
  project,
  readOnly,
  socialSpend,
}: SocialSpendTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const onDownload = async (uri: string, policyName: string) => {
    try {
      setDownloadProgress(0);
      setIsDownloading(true);
      await downloadFile(uri, policyName, (progress: any) => {
        const total = (progress.loaded / progress.total) * 100;

        setDownloadProgress(total);
        if (total >= 100) {
          setIsDownloading(false);
        }
      });

      toast.success("Downloaded successfully");
    } catch (e: any) {
      toast.error("Failed to download file");
      setIsDownloading(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell align="left">
          {formatChartDate(socialSpend.transactionDate)}
        </TableCell>
        <TableCell align="left">
          {socialSpend.socialFactors.map((f) => {
            const matchingCommitment = project?.commitmentsSpend.find(
              (c) => c.id === f,
            );
            if (!matchingCommitment) return null;

            const chipLabel =
              matchingCommitment?.targetDescription ||
              socialSpendCommitmentTypeToString(matchingCommitment.targetName);
            return (
              <Tooltip
                TransitionComponent={Zoom}
                key={`tool-tip-employee-social-factor-${matchingCommitment.id}`}
                title={chipLabel}
              >
                <Chip
                  size="small"
                  label={wordsToThreeLetterAcronym(chipLabel)}
                  sx={{
                    backgroundColor: matchingCommitment.colour,
                    color: "white",
                  }}
                />
              </Tooltip>
            );
          })}
        </TableCell>
        <TableCell align="left">{socialSpend.supplier}</TableCell>
        <TableCell align="left">{socialSpend.abn}</TableCell>
        <TableCell align="left">
          {socialSpendServiceCategoryToString(socialSpend.natureOfExpenditure)}
        </TableCell>
        <TableCell align="center">
          ${socialSpend.invoiceValue.toLocaleString()}
        </TableCell>
        {/* <TableCell align="left">
          {socialSpend.registered ? "Yes" : "No"}
        </TableCell> */}
        <TableCell>
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            {isDownloading ? (
              <CircularProgressWithLabel value={downloadProgress} />
            ) : (
              <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                title="Download"
              >
                <IconButton
                  color="primary"
                  size="large"
                  disabled={!socialSpend.uri || socialSpend.uri.length === 0}
                  onClick={() =>
                    onDownload(
                      socialSpend.uri,
                      getDocumentName(
                        `${
                          socialSpend.supplier
                        }-${socialSpend.transactionDate.toLocaleDateString(
                          "en-GB",
                        )}`,
                        socialSpend.uri,
                      ),
                    )
                  }
                >
                  <DownloadTwoToneIcon
                    sx={{
                      fontSize: "1.1rem",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {!readOnly ? (
              <>
                <Tooltip
                  placement="top"
                  title="Edit"
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    color="primary"
                    size="large"
                    onClick={() => handleEditSocialSpend(socialSpend)}
                  >
                    <EditIcon
                      sx={{
                        fontSize: "1.1rem",
                      }}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Delete"
                >
                  <IconButton
                    color="error"
                    size="large"
                    onClick={() => setOpenConfirmDialog(true)}
                  >
                    <DeleteIcon
                      sx={{
                        fontSize: "1.1rem",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </Stack>
        </TableCell>
      </TableRow>
      {!readOnly ? (
        <ConfirmationDialog
          message={"Are you sure you want to delete this spend item?"}
          open={openConfirmDialog}
          title={"Delete Spend"}
          intent={"error"}
          buttonText={"Delete"}
          onCancel={() => setOpenConfirmDialog(false)}
          onConfirm={() => handleDeleteSocialSpend([socialSpend])}
        />
      ) : null}
    </>
  );
};
