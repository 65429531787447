import { getApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState, useEffect } from "react";
import { decodeLabourHourSubDataModel } from "social-pro-common/decoders/dashboard";
import { LabourHourSubDataModel } from "social-pro-common/entities/dashboard";
import { LabourHourSubDataModelLineItem } from "social-pro-common/interfaces/dashboard";

export const useLabourHourSubData = (
  projectId?: string,
  packageId?: string,
  reportId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [labourHourSubData, setLabourHourSubData] = useState<LabourHourSubDataModelLineItem>();

  const getLabourHourSubData = useCallback(
    async (projectId: string, packageId: string, reportId: string) => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getLabourHourSubData",
          "dashboard-v2",
          projectId,
          { packageId, reportId },
        );
        const labourHourSubData = await decodeLabourHourSubDataModel(
          analysisResult.data as LabourHourSubDataModel,
          getImageUrl,
        );
        setLabourHourSubData(labourHourSubData);
      } catch (e) {
        catchSentryError(e);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [getImageUrl, decodeLabourHourSubDataModel],
  );

  useEffect(() => {
    if (projectId && packageId && reportId) {
      getLabourHourSubData(projectId, packageId, reportId);
    }
  }, [projectId, packageId, reportId, getLabourHourSubData]);

  return {
    error,
    isAnalysisLoading,
    labourHourSubData,
  };
};