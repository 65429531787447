import DeleteIcon from "@mui/icons-material/Delete";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import {
  Chip,
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";

export const SocialSpendTableRowSkeleton = () => {
  const theme = useTheme();
  return (
    <TableRow role="checkbox">
      <TableCell align="center" scope="row">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Stack direction="row" spacing={1}>
          <Skeleton animation="wave" variant="rounded" sx={{ marign: "auto" }}>
            <Chip
              size="small"
              label={"LTUM"}
              sx={{
                color: "white",
              }}
            />
          </Skeleton>
          <Skeleton animation="wave" variant="rounded" sx={{ marign: "auto" }}>
            <Chip
              size="small"
              label={"LTUM"}
              sx={{
                color: "white",
              }}
            />
          </Skeleton>
        </Stack>
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
            <IconButton
              color="primary"
              sx={{
                "&:hover ": {
                  background: theme.palette.primary.dark,
                  color: theme.palette.error.light,
                },
                background: theme.palette.primary.light,
                borderColor: "black",
                color: theme.palette.error.dark,
              }}
              size="large"
            >
              <DownloadTwoToneIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>

          <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
            <IconButton
              color="primary"
              sx={{
                "&:hover ": {
                  background: theme.palette.primary.dark,
                  color: theme.palette.primary.light,
                },
                background: theme.palette.primary.light,
                borderColor: "black",
                color: theme.palette.primary.dark,
              }}
              size="large"
            >
              <EditIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>

          <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
            <IconButton
              color="error"
              sx={{
                "&:hover ": {
                  background: theme.palette.primary.dark,
                  color: theme.palette.error.light,
                },
                background: theme.palette.primary.light,
                borderColor: "black",
                color: theme.palette.error.dark,
              }}
              size="large"
            >
              <DeleteIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
