import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { useFormik } from "formik";
import * as yup from "yup";

interface DamstraImportModalProps {
  loading: boolean;
  toggle: boolean;
  open: boolean;
  handleClose: () => void;
  onSubmit: (enabled: boolean) => void;
}
export const DamstraImportModal = ({
  handleClose,
  loading,
  onSubmit,
  open,
  toggle,
}: DamstraImportModalProps) => {
  const validationSchema = yup.object({
    toggle: yup.boolean().required("Email is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      toggle: toggle,
    },
    onSubmit: async (values) => {
      await onSubmit(values.toggle);
    },
    validationSchema: validationSchema,
  });

  const disabled = formik.isSubmitting || loading;
  console.log("formik", formik);
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle>{"Damstra Excel Import"}</DialogTitle>{" "}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            background: "#000 !important",
            borderRadius: "50%",
            color: "#fff",
            padding: "5px",
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={formik.values.toggle}
                        disabled={loading || formik.isSubmitting}
                        value={formik.values.toggle}
                        onChange={(_, checked) => {
                          formik.setFieldValue("toggle", checked);
                        }}
                      />
                    }
                    label="Imports Enabled"
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Stack direction="row" spacing={1}>
                  <StyledButton
                    loading={loading}
                    disabled={disabled}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </StyledButton>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
