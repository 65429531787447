import { useProjectContext } from "@hooks/context/useProjectContext";
import { useMainChartData } from "@hooks/crud/dashboard/useMainChartData";
import { useForecastModel } from "@hooks/crud/forecastData/useForecastData";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { ForecastChart } from "@stories/molecules/ForecastChart/ForecastChart";
import {
  ChartRangeType,
  DashboardChartRangeTabs,
} from "@stories/pages/Contractor/DashboardChart/DashboardChartRangeTabs";
import { DashboardChartTabs } from "@stories/pages/Contractor/DashboardChart/DashboardChartTabs";
import { useState } from "react";
import { ChartTargetType } from "social-pro-common/entities/analysis";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

interface PackageDetailsForecastProps {
  isLoading: boolean;
  contractorPackage?: ContractorPackageLineItem;
}
export const PackageDetailsForecast = ({
  contractorPackage,
  isLoading,
}: PackageDetailsForecastProps) => {
  const { isProjectLoading, selectedProject } = useProjectContext();

  const { forecastModels, isForecastLoading } = useForecastModel(
    selectedProject,
    contractorPackage,
  );

  const { chartData, isChartDataLoading } = useMainChartData(
    selectedProject?.id,
    contractorPackage?.id,
  );
  const [selectedTarget, setSelectedTarget] = useState<ChartTargetType>(
    ChartTargetType.Hour,
  );
  const [selectedRange, setSelectedRange] = useState<ChartRangeType>(
    ChartRangeType.TwelveMonth,
  );

  const handleChangeTarget = (
    event: React.SyntheticEvent,
    newValue: ChartTargetType,
  ) => {
    if (newValue) {
      setSelectedTarget(newValue);
    }
  };

  const handleChangeRange = (
    event: React.SyntheticEvent,
    newValue: ChartRangeType,
  ) => {
    if (newValue) {
      setSelectedRange(newValue);
    }
  };

  const allBarChartData = {
    greenMetrics: {},
    labourHours: {},
    socialSpend: {},
  };

  const selectedChartData = (() => {
    if (chartData) {
      switch (selectedTarget) {
        default:
        case ChartTargetType.Hour:
          return chartData.labourHours;
        case ChartTargetType.Spend:
          return chartData.socialSpend;
        case ChartTargetType.GreenMetric:
          return chartData.greenMetrics;
      }
    }
  })();

  const chartUnits = (() => {
    if (allBarChartData) {
      switch (selectedTarget) {
        default:
        case ChartTargetType.Hour:
          return "Hours";
        case ChartTargetType.Spend:
          return "Dollars";
        case ChartTargetType.GreenMetric:
          return "Submissions";
      }
    }
    return "";
  })();

  const reportMonths = selectedProject?.projectReportMonths;

  const loading =
    isLoading || isProjectLoading || isForecastLoading || isChartDataLoading;

  return (
    <>
      <Grid
        item
        md={12}
        sx={{ margin: "0 0 20px", padding: "0 !important", width: "100%" }}
      >
        {loading ? (
          <Skeleton animation="wave">
            <Typography variant="h2" sx={{ fontSize: "24px" }}>
              {"Forecast"}
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h2" sx={{ fontSize: "24px" }}>
            {"Forecast"}
          </Typography>
        )}
      </Grid>

      <Grid item md={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DashboardChartTabs
            isLoading={loading}
            value={selectedTarget}
            project={selectedProject}
            handleChange={handleChangeTarget}
          />
          <DashboardChartRangeTabs
            isLoading={loading}
            value={selectedRange}
            handleChange={handleChangeRange}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          padding: "0 !important",
          textAlign: "right",
        }}
      >
        <ForecastChart
          loading={loading}
          chartRangeType={selectedRange}
          chartUnits={chartUnits}
          reportMonths={reportMonths}
          forecastModals={forecastModels}
          chartData={selectedChartData}
        />
      </Grid>
    </>
  );
};
