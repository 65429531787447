import { Box, Table, TableBody } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import * as React from "react";
import {
  ForecastDataLineItem,
  ForecastModelLineItem,
} from "social-pro-common/interfaces/forecastData";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

import ForecastInputTableRow from "./ForecastInputTableRow";
import { ForecastTableRowSkeleton } from "../../ForecastTableRowSkeleton";


interface ForecastInputTableProps {
  loading: boolean;
  forecast: ForecastModelLineItem;
  handleChange: (forecast: ForecastDataLineItem) => void;
}

export default function ForecastInputTable({
  forecast,
  handleChange,
  loading,
}: ForecastInputTableProps) {
  const TABLE_HEAD: TableHeaderCell[] = [
    {
      align: "left",
      id: "month",
      label: "Month",
      width: "25%",
    },
    { align: "right", id: "value", label: "Value" },
  ];

  return (
    <>
      {loading ? (
        <Table>
          <ListTableHead loading headLabel={TABLE_HEAD} />
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <ForecastTableRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <>
          <Table stickyHeader>
            <ListTableHead loading={false} headLabel={TABLE_HEAD} />
          </Table>
          <Box
            sx={{
              height: "200px",
              maxHeight: "250px",
              minHeight: "150px",
              overflowY: "auto",
              paddingX: "10px ",
            }}
          >
            <Table>
              <TableBody>
                {forecast.data.map((d) => (
                  <ForecastInputTableRow
                    key={d.id}
                    loading={loading}
                    forecastData={d}
                    units={forecast.units}
                    handleSave={handleChange}
                  />
                ))}
                {forecast.data.length == 0 ? (
                  <NoRows title={"Months"} colSpan={7} />
                ) : null}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
    </>
  );
}
