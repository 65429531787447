import { useLabourHourSubData } from "@hooks/crud/dashboard/useLabourHourSubData";
import { Grid } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

import { AchievedStack } from "../ProjectProgress/AchievedStack";
import { ForecastStack } from "../ProjectProgress/ForecastStack";
import { RecommendedSupplierStack } from "../ProjectProgress/RecommendedSupplierStack";
import { TargetStack } from "../ProjectProgress/TargetStack";

interface LabourHourProgressSubDataProps {
  loading: boolean;
  isPrimaryPackage: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  reportId?: string;
}

export const LabourHourProgressSubData = ({
  contractorPackage,
  loading,
  project,
  reportId,
}: LabourHourProgressSubDataProps) => {
  const { isAnalysisLoading, labourHourSubData } = useLabourHourSubData(
    project?.id,
    contractorPackage?.id,
    reportId,
  );

  const isLoading = loading || isAnalysisLoading;
  return (
    <Grid container justifyContent={"space-between"}>
      <AchievedStack
        loading={isLoading}
        targets={labourHourSubData?.targets || []}
        title={"Targets"}
        firstColumnTitle={"Category"}
        secondColumnTitle={"Hours"}
        thirdColumnTitle={"Target"}
        rightMargin
        url={RouteHelper.analyticsLabourHours(
          project?.id || "",
          contractorPackage?.id || "",
          reportId,
        )}
        dataTestId={"labour-hour-targets"}
      />
      {labourHourSubData?.recommendedSuppliers ? (
        <RecommendedSupplierStack
          loading={isLoading}
          suppliers={labourHourSubData?.recommendedSuppliers || []}
          title={"Smart Directory"}
          firstColumnTitle={"Suggested Suppliers"}
          url={RouteHelper.directory(
            project?.id || "",
            contractorPackage?.id || "",
          )}
          dataTestId={"labour-hour-recommendations"}
        />
      ) : (
        <TargetStack
          loading={isLoading}
          targets={labourHourSubData?.subcontractors || []}
          title={"Subcontractors"}
          firstColumnTitle={"Name"}
          secondColumnTitle={"%"}
          leftMargin
          rightMargin
          url={RouteHelper.packages(
            project?.id || "",
            contractorPackage?.id || "",
          )}
          units="%"
          dataTestId={"labour-hour-subcontractors"}
        />
      )}
      <ForecastStack
        loading={isLoading}
        forecast={labourHourSubData?.forecast}
        url={RouteHelper.forecast(
          project?.id || "",
          contractorPackage?.id || "",
        )}
        dataTestId={"labour-hour-forecast"}
      />
    </Grid>
  );
};
