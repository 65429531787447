import { CognitoUser } from "@aws-amplify/auth";
import { useProfile } from "@hooks/crud/profile/useProfile";
import { PageLoader } from "@stories/atoms/Loaders/PageLoader/PageLoader";
import { catchSentryError } from "@utils/sentry";
import { sleep } from "@utils/utils";
import { Auth } from "aws-amplify";
import { API_END_POINT_AUTH, IS_PROD_ENV } from "init";
import * as React from "react";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { UserLineItem } from "social-pro-common/interfaces/user";
import { formattedUserName } from "social-pro-common/utils/string";

export enum AuthenticateChallenge {
  NONE = "NONE",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  AUTH_ERROR = "AUTH_ERROR",
  PROFILE_ERROR = "PROFILE_ERROR",
}

type AuthUser = CognitoUser & {
  challengeName?: AuthenticateChallenge;
  preferredMFA?: AuthenticateChallenge;
};

type AC = {
  loggedIn: boolean;
  isFirstLoad: boolean;
  user?: UserLineItem;
  isAuthLoading: boolean;
  userProfile?: ProfileLineItem;
  isContractor: boolean;
  isSocialProAdmin: boolean;
  setUserProfile: (profile: ProfileLineItem) => void;
  isAuthenticated: () => Promise<boolean>;
  federatedSignIn: () => Promise<void>;
  signIn: (
    username: string,
    password: string,
  ) => Promise<AuthenticateChallenge>;
  signUp: (
    username: string,
    password: string,
    isContractor: boolean,
  ) => Promise<void>;
  forgotPassword: (username: string) => Promise<void>;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    newPassword: string,
  ) => Promise<string>;
  resetPassword: (
    username: string,
    oldPassword: string,
    newPassword: string,
  ) => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  signOut: () => Promise<void>;
};

export const AuthContext = React.createContext<AC>({
  changePassword: () => Promise.resolve(),
  federatedSignIn: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  forgotPasswordSubmit: () => Promise.resolve(""),
  isAuthLoading: false,
  isAuthenticated: () => Promise.resolve(false),
  isContractor: false,
  isFirstLoad: false,
  isSocialProAdmin: false,
  loggedIn: false,
  resetPassword: () => Promise.resolve(),
  setUserProfile: () => {
    return;
  },
  signIn: () => Promise.resolve(AuthenticateChallenge.NONE),
  signOut: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
});

type AuthProviderProps = {
  children: React.ReactNode;
};

export const requestMagicLink = async (email: string) => {
  const res = await fetch(API_END_POINT_AUTH, {
    body: JSON.stringify({ email }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  return res.json();
};

const AuthProvider = (props: AuthProviderProps) => {
  const [user, setUser] = React.useState<UserLineItem>();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isAuthLoading, setIsAuthLoading] = React.useState(true);
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const { getProfile } = useProfile();
  const [userProfile, setUserProfile] = React.useState<ProfileLineItem>();

  const getEmail = (cognitoUser: AuthUser) => {
    const payload = cognitoUser.getSignInUserSession()?.getIdToken().payload;
    if (!payload) {
      throw new Error("User not configured correctly.");
    }
    return payload.email;
  };

  const getUserId = (cognitoUser: AuthUser) => {
    const payload = cognitoUser.getSignInUserSession()?.getIdToken().payload;
    if (!payload) {
      throw new Error("User not configured correctly.");
    }
    const userId = payload["cognito:username"] as string;
    if (!userId) {
      throw new Error("User userId id not available");
    }
    return formattedUserName(userId);
  };

  const getGroups = (cognitoUser: AuthUser) => {
    const payload = cognitoUser.getSignInUserSession()?.getIdToken().payload;
    if (!payload) {
      throw new Error("User not configured correctly.");
    }
    const groups = payload["cognito:groups"];
    const isContractor = groups?.includes("Contractor");
    const isSocialProAdmin = groups?.includes("SocialProAdmin");
    return { isContractor, isSocialProAdmin };
  };

  const isAuthenticated = React.useCallback(async () => {
    const maxRetries = 3,
      delay = 1000;
    let attempt = 0;
    try {
      while (attempt < maxRetries) {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const userId = getUserId(user);
          const profile = await getProfile(userId);
          if (!profile.success) {
            return false;
          }
          setUser({ email: getEmail(user), id: userId, ...getGroups(user) });
          if (profile.profile) {
            setUserProfile(profile.profile);
            return true;
          }
          return true;
        } catch (error) {
          console.log(`Attempt ${attempt + 1} failed:`, error);
          attempt++;

          if (attempt < maxRetries) {
            console.log(`Retrying in ${delay} ms...`);
            await sleep(delay); // Wait before retrying
          } else {
            console.error("Max retries reached. Authentication failed.");
            return false; // All attempts failed
          }
        }
      }
    } catch (e) {
      catchSentryError(e);
    } finally {
      setIsAuthLoading(false);
    }
    return false;
  }, [getProfile]);

  const federatedSignIn = React.useCallback(async (): Promise<void> => {
    try {
      await Auth.federatedSignIn({ provider: "BMD-SAML-IDP" } as any);
      const user = await Auth.currentAuthenticatedUser();
      const userId = getUserId(user);
      const profile = await getProfile(userId);
      if (profile.profile && profile.success) {
        setUserProfile(profile.profile);
      }
      if (profile.success) {
        setUser({ email: getEmail(user), id: userId, ...getGroups(user) });
        setLoggedIn(true);
      }
    } catch (e) {
      catchSentryError(e);
    }
  }, [getProfile]);

  const signIn = React.useCallback(
    async (
      username: string,
      password: string,
    ): Promise<AuthenticateChallenge> => {
      try {
        const res = await Auth.signIn(username, password);
        const challengeName = res.challengeName || AuthenticateChallenge.NONE;
        if (challengeName === AuthenticateChallenge.NONE) {
          const user = await Auth.currentAuthenticatedUser();

          const userId = getUserId(user);
          try {
            const profile = await getProfile(userId);
            if (!profile.success) {
              return AuthenticateChallenge.PROFILE_ERROR;
            }
            if (profile.profile) {
              setUserProfile(profile.profile);
            }
            if (profile.success) {
              setUser({
                email: getEmail(user),
                id: userId,
                ...getGroups(user),
              });
              setLoggedIn(true);
            }
          } catch (e) {
            return AuthenticateChallenge.PROFILE_ERROR;
          }
        }
        return challengeName;
      } catch (e) {
        catchSentryError(e);
        return AuthenticateChallenge.AUTH_ERROR;
      }
    },
    [getProfile],
  );

  const signUp = React.useCallback(
    async (
      email: string,
      password: string,
      isContractor: boolean,
    ): Promise<void> => {
      if (IS_PROD_ENV) {
        throw new Error("Not implemented");
      }
      try {
        const res = await Auth.signUp({
          attributes: {
            "custom:authChallenge": isContractor ? "Contractor" : undefined,
            email,
          },
          password,
          username: email,
        });

        // No email confirmation required, the user is automatically confirmed
        if (res.userConfirmed) {
          console.log("User confirmed during sign-up");
        } else {
          console.log(
            "User needs to confirm registration (bypass email confirmation via Lambda)",
          );
        }
      } catch (error) {
        console.error("Error signing up:", error);
      }
    },
    [],
  );

  const changePassword = React.useCallback(
    async (oldPassword: string, newPassword: string): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
      } catch (e) {
        catchSentryError(e);
        throw e;
      }
    },
    [],
  );

  const resetPassword = React.useCallback(
    async (
      username: string,
      oldPassword: string,
      newPassword: string,
    ): Promise<void> => {
      try {
        const user = await Auth.signIn(username, oldPassword);
        await Auth.completeNewPassword(user, newPassword);
        const res = await Auth.currentAuthenticatedUser();
        const userId = getUserId(res);
        const profile = await getProfile(userId);
        if (profile.profile && profile.success) {
          setUserProfile(profile.profile);
        }
        if (profile.success) {
          setUser({ email: getEmail(user), id: userId, ...getGroups(user) });
          setLoggedIn(true);
        }
      } catch (e) {
        catchSentryError(e);
      }
    },
    [getProfile],
  );

  const forgotPassword = React.useCallback(
    async (username: string): Promise<void> => {
      try {
        await Auth.forgotPassword(username);
      } catch (e) {
        catchSentryError(e);
      }
    },
    [],
  );

  const forgotPasswordSubmit = React.useCallback(
    async (
      username: string,
      code: string,
      newPassword: string,
    ): Promise<string> => {
      try {
        const res = await Auth.forgotPasswordSubmit(
          username,
          code,
          newPassword,
        );
        return res;
      } catch (e) {
        catchSentryError(e);
        return "";
      }
    },
    [],
  );

  const signOut = React.useCallback(async () => {
    try {
      setIsAuthLoading(true);
      await Auth.signOut();
      setLoggedIn(false);
      setUser(undefined);
      setUserProfile(undefined);
    } catch (e) {
      catchSentryError(e);
    } finally {
      setIsAuthLoading(false);
    }
  }, []);

  React.useEffect(() => {
    isAuthenticated()
      .then((res) => {
        setLoggedIn(res);
        setIsAuthLoading(false);
      })
      .finally(() => {
        setIsFirstLoad(false);
      });
  }, []);

  if (isFirstLoad) {
    return <PageLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        changePassword,
        federatedSignIn,
        forgotPassword,
        forgotPasswordSubmit,
        isAuthLoading,
        isAuthenticated,
        isContractor: user?.isContractor || false,
        isFirstLoad,
        isSocialProAdmin: user?.isSocialProAdmin || false,
        loggedIn,
        resetPassword,
        setUserProfile,
        signIn,
        signOut,
        signUp,
        user,
        userProfile,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => React.useContext(AuthContext);

export { AuthProvider, useAuthContext };
