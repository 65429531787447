import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid } from "@mui/material";
import { SubTitleWithImage } from "@stories/atoms/SubTitle/SubTitle";
import { CreateProfileForm } from "@stories/molecules/CreateProfileForm/CreateProfileForm";
import { catchSentryError } from "@utils/sentry";
import { ToastOptions, toast } from "react-toastify";
import {
  ProfileLineItem,
  ProfileStatus,
} from "social-pro-common/interfaces/profile";

export const CreateProfile = () => {
  const { user } = useAuthContext();
  if (!user) {
    throw Error("No user in state.");
  }
  const {
    isAuthProfileLoading,
    updateUserProfile,
    userOrganisation,
    userProfile,
  } = useProfileContext();

  const handleSubmit = async (profile: ProfileLineItem) => {
    try {
      if (userProfile && userOrganisation) {
        await updateUserProfile({ ...profile, status: ProfileStatus.Active });
      }
    } catch (error) {
      catchSentryError(error);
      toast("Could not save account, please contact support.", {
        type: "error",
      } as ToastOptions);
    }
  };

  const title =
    userProfile?.status !== ProfileStatus.Invited
      ? `${userProfile?.email} Profile`
      : "Create Profile";

  return (
    <>
      <Grid container spacing={2} sx={{ padding: "12px" }}>
        <Grid item xs={12} md={8}>
          <SubTitleWithImage
            loading={isAuthProfileLoading}
            title={title}
            url={userOrganisation?.organisationLogo || ""}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ padding: "12px" }}>
        <Grid item xs={12} md={8}>
          <CreateProfileForm
            loading={isAuthProfileLoading}
            userProfile={userProfile}
            finalizeProfile={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};
