import { postApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState } from "react";
import {
  decodeIntegration,
  encodeIntegration,
} from "social-pro-common/decoders/integration";
import { IntegrationInfo } from "social-pro-common/entities/integration";
import { IntegrationInfoLineItem } from "social-pro-common/interfaces/integration";

export const useIntegration = () => {
  const [isIntegrationLoading, setIsIntegrationLoading] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleApiError = useCallback((e: any, errorMessage: string) => {
    catchSentryError(e);
    setError(errorMessage);
  }, []);

  const createIntegration = useCallback(
    async (
      integration: IntegrationInfoLineItem,
    ): Promise<IntegrationInfoLineItem> => {
      try {
        setIsIntegrationLoading(true);
        const encodedIntegration = encodeIntegration(integration);
        const upsertedIntegration = await postApiData(
          "createIntegration",
          "integration",
          encodedIntegration,
        );
        const decodedIntegration = decodeIntegration(
          upsertedIntegration.data as IntegrationInfo,
        );
        return decodedIntegration;
      } catch (e: any) {
        handleApiError(e, "Could not create profile");
      } finally {
        setIsIntegrationLoading(false);
      }
      return integration;
    },
    [handleApiError],
  );

  const updateIntegration = useCallback(
    async (integration: IntegrationInfoLineItem): Promise<void> => {
      try {
        setIsIntegrationLoading(true);
        const encodedIntegration = encodeIntegration(integration);
        await postApiData(
          "createIntegration",
          "integration",
          encodedIntegration,
        );
      } catch (e: any) {
        handleApiError(e, "Could not update integration");
      } finally {
        setIsIntegrationLoading(false);
      }
    },
    [handleApiError],
  );

  return {
    createIntegration,
    error,
    isIntegrationLoading,
    updateIntegration,
  };
};
