import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourAnalytics } from "@hooks/crud/analytics/useLabourHourAnalytics";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { MonthSelect } from "@stories/atoms/MonthSelect/MonthSelect";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ContractorAnalyticsLabourHours } from "@stories/molecules/ContractorAnalyticsLabourHours/ContractorAnalyticsLabourHours";
import { writeXlsxLabourHourTargets } from "@utils/xlsx/labourHours";
import { useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { dateToString, stringToDate } from "social-pro-common/utils/date";

interface PackageDetailsHoursProps {
  isLoading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  onLabourHourTargetChange: (targetId: string) => void;
}

export const PackageDetailsHours = ({
  contractorPackage,
  isLoading,
  onLabourHourTargetChange,
}: PackageDetailsHoursProps) => {
  const [reportId, setReportDate] = useState<string>(dateToString(new Date()));

  const { isPrimaryPackage, isProjectLoading, selectedProject } =
    useProjectContext();
  const { isAnalysisLoading, labourHoursAnalytics } = useLabourHourAnalytics(
    reportId,
    selectedProject?.id,
    contractorPackage?.id,
  );

  const loading = isLoading || isProjectLoading || isAnalysisLoading;

  return (
    <>
      <Grid
        container
        className="analytic-table"
        sx={{
          alignItems: "center", // Vertically align items
          display: "flex",
          margin: "0 0 15px",
          padding: "0 !important",
          width: "100%",
        }}
      >
        <Grid
          item
          md={6}
          sx={{
            alignItems: "center", // Ensure alignment for this item
            display: "flex",
          }}
        >
          {loading ? (
            <Skeleton animation="wave">
              <Typography variant="h2">Labour Hours</Typography>
            </Skeleton>
          ) : (
            <Typography variant="h2">Labour Hours</Typography>
          )}
        </Grid>

        <Grid
          item
          md={6}
          sx={{
            alignItems: "center", // Ensure alignment for this item
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Box className="newStyleField monthSelect">
              <MonthSelect
                data-test-id="month-select"
                loading={loading}
                project={selectedProject}
                contractorPackage={contractorPackage}
                isPrimaryPackage={isPrimaryPackage}
                setDate={(date: Date) => {
                  setReportDate(dateToString(date));
                }}
                date={stringToDate(reportId)}
              />
            </Box>
            <StyledButton
              data-test-id="export-targets-button"
              className="blackBtn grey-outline-btn"
              loading={loading}
              disabled={loading || !labourHoursAnalytics?.targets.length}
              variant="contained"
              onClick={() => {
                if (labourHoursAnalytics) {
                  writeXlsxLabourHourTargets(labourHoursAnalytics.targets);
                }
              }}
              startIcon={<DownloadIcon />}
            >
              Export Targets
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} className="analytic-table">
        <ContractorAnalyticsLabourHours
          loading={loading}
          labourHourAnalytics={labourHoursAnalytics}
          onLabourHourTargetChange={onLabourHourTargetChange}
        />
      </Grid>
    </>
  );
};
