import { getApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import { decodeSustainabilitysAnalyticsModel } from "social-pro-common/decoders/analytics";
import { SustainabilityAnalyticsModel } from "social-pro-common/entities/analytics";
import { SustainabilityAnalyticsModelLineItem } from "social-pro-common/interfaces/analytics";

export const useSustainabilityAnalytics = (
  reportId: string,
  projectId?: string,
  contractorPackageId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [sustainabilityAnalytics, setSustainabilityAnalytics] =
    useState<SustainabilityAnalyticsModelLineItem>();
  const [error, setError] = useState<string | null>(null);

  const getSustainabilityAnalyticsModel = useCallback(
    async (
      reportId: string,
      projectId: string,
      contractorPackageId?: string,
    ): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getSustainabilityAnalytics",
          "analytics",
          projectId,
          { contractorPackageId, reportId },
        );

        const res = decodeSustainabilitysAnalyticsModel(
          analysisResult.data as SustainabilityAnalyticsModel,
        );
        setSustainabilityAnalytics(res);
      } catch (e) {
        catchSentryError(e);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [getApiData, decodeSustainabilitysAnalyticsModel, catchSentryError],
  );

  useEffect(() => {
    if (projectId && contractorPackageId) {
      getSustainabilityAnalyticsModel(reportId, projectId, contractorPackageId);
    }
  }, [
    reportId,
    projectId,
    contractorPackageId,
    getSustainabilityAnalyticsModel,
  ]);

  return {
    error,
    isAnalysisLoading,
    sustainabilityAnalytics,
  };
};