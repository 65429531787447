import { useProjectContext } from "@hooks/context/useProjectContext";
import {
  SocialBusinessSearchParams,
  useSocialBusiness,
} from "@hooks/crud/socialBusiness/useSocialBusiness";
import {
  FormControl,
  Grid,
  MenuItem,
  Stack,
  Table,
  TableCell,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { DirectoryCard } from "@stories/atoms/DirectoryCard/DirectoryCard";
import { DirectoryCardSkeleton } from "@stories/atoms/DirectoryCard/DirectoryCardSkeleton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";

const serviceLocations = [
  "All",
  "ACT",
  "NSW",
  "NT",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
];

export const Companies = () => {
  const { targetId } = useParams();

  const [searchParams, setSearchParams] = useState<SocialBusinessSearchParams>({
    targetId,
  });

  const { isProjectLoading, selectedProject } = useProjectContext();

  const { isSocialBusinessLoading, socialBusinesses } =
    useSocialBusiness(searchParams);

  const [inputValue, setInputValue] = useState("");
  const handleSearchChange = () => {
    setSearchParams({
      ...searchParams,
      queryString: inputValue,
    });
  };

  const loading = isProjectLoading || isSocialBusinessLoading;

  const selectableCommitmentHours = selectedProject?.commitmentsHours || [];
  const selectableCommitmentSpend = selectedProject?.commitmentsSpend || [];
  const allTargets = [
    {
      id: "All",
      label: "All",
    },
    ...selectableCommitmentHours.map((c) => ({
      id: c.id,
      label:
        c.targetDescription || labourHourCommitmentTypeToString(c.targetName),
    })),
    ...selectableCommitmentSpend.map((c) => ({
      id: c.id,
      label:
        c.targetDescription || socialSpendCommitmentTypeToString(c.targetName),
    })),
  ];
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader loading={loading} title="Smart Directory" subTitle={"  "} />

        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              padding: "0 !important",
              textAlign: "left",
            }}
          >
            <Stack direction="row" spacing={2}>
              <StyledTextField
                data-test-id="location-input"
                loading={loading}
                select
                margin="dense"
                className="search"
                label={"Service Locations"}
                name="serivceLocation"
                defaultValue={"All"}
                disabled={loading}
                skeletonHeight="40px"
                value={searchParams.serviceLocation}
                onChange={(s) => {
                  setSearchParams({
                    ...searchParams,
                    serviceLocation: s.target.value as string,
                  });
                }}
              >
                {serviceLocations.map((c) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                data-test-id="category-input"
                loading={loading}
                select
                margin="dense"
                className="search"
                label={"Commitment Categories"}
                name="targetType"
                disabled={loading}
                skeletonHeight="40px"
                value={searchParams.targetId || "All"}
                defaultValue={searchParams.targetId || "All"}
                onChange={(s) => {
                  const targetId = s.target.value as string;
                  setSearchParams({
                    ...searchParams,
                    targetId: targetId === "All" ? undefined : targetId,
                  });
                }}
              >
                {allTargets.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.label}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Stack>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              padding: "0 !important",
              textAlign: "right",
            }}
          >
            <FormControl sx={{ minWidth: "25%" }}>
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />
            </FormControl>
          </Grid>
        </Grid>

        {!loading && (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={2}
            sx={{ marginTop: "10px", padding: "0px 40px 40px !important" }}
          >
            {socialBusinesses.map((c, index) => (
              <Grid
                item
                key={c.socialBusinessName + index}
                md={4}
                sx={{ display: "flex" }}
                justifyContent={"stretch"}
              >
                <DirectoryCard
                  key={c.socialBusinessName + index}
                  socialBusiness={c}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {!loading && socialBusinesses.length === 0 ? (
          <Table>
            <TableRow key={"no-data-row-1"}>
              <TableCell colSpan={4} align="center">
                <Typography variant={"h4"} component="div">
                  No companies found
                </Typography>
              </TableCell>
            </TableRow>
          </Table>
        ) : null}

        {loading ? (
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "10px", padding: "0 40px 40px !important" }}
          >
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex" }}
              justifyContent={"stretch"}
            >
              <DirectoryCardSkeleton />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};
