import { listApiData, postApiData, postBatchApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import {
  decodeProfileProjectAccess,
  encodeProfileProjectAccess,
} from "social-pro-common/decoders/profileProjectAccess";
import { ProfileProjectAccess } from "social-pro-common/entities/profileProjectAccess";
import { ProfileProjectAccessLineItem } from "social-pro-common/interfaces/profileProjectAccess";

export const useProfileProjectAccess = (organisationId?: string) => {
  const [profileProjectAccessLevels, setProfileProjectAccessLevels] = useState<
    ProfileProjectAccessLineItem[]
  >([]);
  const [isProfileProjectAccessLoading, setProfileProjectAccessLoading] =
    useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listProfileProjectAccess = useCallback(async (orgId: string) => {
    try {
      setProfileProjectAccessLoading(true);
      const profileResult = await listApiData(
        "listProfileProjectAccess",
        "profileProjectAccess",
        orgId,
      );
      setProfileProjectAccessLevels(
        profileResult.data.map((p) =>
          decodeProfileProjectAccess(p as unknown as ProfileProjectAccess),
        ),
      );
    } catch (error) {
      catchSentryError(error);
      setError("Could not list profile groups");
    } finally {
      setProfileProjectAccessLoading(false);
    }
  }, [listApiData, decodeProfileProjectAccess, catchSentryError]);

  const createProfileProjectAccess = useCallback(
    async (profileProjectAccessLevel: ProfileProjectAccessLineItem) => {
      try {
        setProfileProjectAccessLoading(true);
        const encodedProfileProjectAccess = encodeProfileProjectAccess(
          profileProjectAccessLevel,
        );
        await postApiData(
          "createProfileProjectAccess",
          "profileProjectAccess",
          encodedProfileProjectAccess,
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not create profile project access");
      } finally {
        setProfileProjectAccessLoading(false);
      }
    },
    [postApiData, encodeProfileProjectAccess, catchSentryError]
  );

  const updateProfileProjectAccess = useCallback(
    async (profileProjectAccessLevel: ProfileProjectAccessLineItem) => {
      try {
        setProfileProjectAccessLoading(true);
        const encodedProfileProjectAccess = encodeProfileProjectAccess(
          profileProjectAccessLevel,
        );
        await postApiData(
          "updateProfileProjectAccess",
          "profileProjectAccess",
          encodedProfileProjectAccess,
        );

        setProfileProjectAccessLevels((prevLevels) =>
          prevLevels.map((p) =>
            profileProjectAccessLevel.id === p.id
              ? profileProjectAccessLevel
              : p,
          ),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not update profile project access");
      } finally {
        setProfileProjectAccessLoading(false);
      }
    },
    [postApiData, encodeProfileProjectAccess, catchSentryError]
  );

  const upsertProfileProjectAccess = useCallback(
    async (projectAccessToUpsert: ProfileProjectAccessLineItem[]) => {
      try {
        setProfileProjectAccessLoading(true);
        const encodedProjectAccess = projectAccessToUpsert.map((e) =>
          encodeProfileProjectAccess(e),
        );
        await postBatchApiData(
          "upsertProfileProjectAccess",
          "profileProjectAccess",
          encodedProjectAccess,
        );

        setProfileProjectAccessLevels((prevLevels) =>
          prevLevels.map(
            (p) => projectAccessToUpsert.find((e) => e.id === p.id) || p,
          ),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not upsert profile project access");
      } finally {
        setProfileProjectAccessLoading(false);
      }
    },
    [postBatchApiData, encodeProfileProjectAccess, catchSentryError]
  );

  useEffect(() => {
    if (organisationId) {
      listProfileProjectAccess(organisationId);
    }
  }, [organisationId, listProfileProjectAccess]);

  return {
    createProfileProjectAccess,
    error,
    isProfileProjectAccessLoading,
    listProfileProjectAccess,
    profileProjectAccessLevels,
    updateProfileProjectAccess,
    upsertProfileProjectAccess,
  };
};