import {
  FormControlLabel,
  FormGroup,
  Switch,
  Grid,
  Skeleton,
  DialogTitle,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { useFormik } from "formik";
import {
  ProfileLineItem,
  ProfileStatus,
} from "social-pro-common/interfaces/profile";
import * as yup from "yup";

interface ProfileFormProps {
  loading: boolean;
  profile?: ProfileLineItem;
  handleSubmit: (profile: ProfileLineItem) => void;
}

export const ProfileForm = ({
  handleSubmit,
  loading,
  profile,
}: ProfileFormProps) => {
  const validationSchema = yup.object({
    emailNotifications: yup.boolean().required("Email notifications required"),
    name: yup.string().required("Nname is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailNotifications: profile?.emailNotifications || true,
      name: profile?.name || "",
    },
    onSubmit: (values) => {
      if (profile) {
        handleSubmit({
          ...profile,
          emailNotifications: values.emailNotifications,
          name: values.name,
          status: ProfileStatus.Active,
        });
      }
    },
    validationSchema: validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogTitle sx={{ paddingBottom: "4px", paddingLeft: "40px", paddingTop: "0px" }}>{"Profile Details"}</DialogTitle>
      <Grid
        container
        spacing={2}
        sx={{ margin: "0", maxWidth: "900px", padding: "0px 24px" }}
      >
        <Grid item xs={6} sm={6}>
          <StyledTextField
            loading={loading}
            id="name"
            name="name"
            label="Name"
            fullWidth
            margin="dense"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name ? formik.errors.name : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormGroup>
            <FormControlLabel
              control={
                !loading ? (
                  <Switch
                    defaultChecked
                    value={formik.values.emailNotifications}
                    onChange={(event, checked) => {
                      formik.setFieldValue("emailNotifications", checked);
                    }}
                  />
                ) : (
                  <Skeleton animation="wave">
                    <Switch
                      defaultChecked
                      value={formik.values.emailNotifications}
                    />
                  </Skeleton>
                )
              }
              label="Email Notifications"
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <StyledButton loading={loading} variant="contained" type="submit">
            Save
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};
