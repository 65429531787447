import { downloadFile } from "@hooks/utils/useUpload";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { useState } from "react";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { PolicySubmissionLineItem } from "social-pro-common/interfaces/policySubmission";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

import { PackageAnalyticsPolicyRow } from "./PackageAnalyticsPolicyRow";
import { PackageAnalyticsPolicySubRowSkeleton } from "./PackageAnalyticsPolicyRowSkeleton";
import { NoRows } from "../ListTable/NoRows";


interface PackageAnalyticsPolicySubRowProps {
  loading: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  policySubmissions: PolicySubmissionLineItem[];
}
export const PackageAnalyticsPolicyTable = ({
  contractorPackage,
  loading,
  policySubmissions,
  project,
}: PackageAnalyticsPolicySubRowProps) => {
  const [selectedTargetId, setSelectedTargetId] = useState<string>();

  const [isDownloading, setIsDownloading] = useState(false);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader
          loading={loading}
          title={"Target"}
          sx={{ width: "60%" }}
          align={"left"}
        />
        <TableHeader
          loading={loading}
          title={"Uploaded Date"}
          sx={{ width: "20%" }}
          align="center"
        />
        <TableHeader
          loading={loading}
          title={"Download"}
          sx={{ width: "20%" }}
          align="center"
        />
      </TableRow>
    </TableHead>
  );

  return loading ? (
    <Table stickyHeader aria-label="sticky table">
      {tableHeader}
      <TableBody>
        {Array.from({ length: 10 }, (_, index) => (
          <PackageAnalyticsPolicySubRowSkeleton key={index} />
        ))}
      </TableBody>
    </Table>
  ) : (
    <Table
      data-test-id="policy-commitments-table"
      stickyHeader
      aria-label="sticky table"
    >
      {tableHeader}
      <TableBody>
        {project
          ? project.policies.map((policy) => {
              if (
                policy.isProjectLevelPolicy &&
                contractorPackage &&
                contractorPackage.packageType !== PackageType.PrimaryPackage
              ) {
                return null;
              }
              const policySubmission = policySubmissions.find(
                (p) => p.projectPolicyId === policy.id,
              );

              return (
                <PackageAnalyticsPolicyRow
                  key={policy.id}
                  projectPolicy={policy}
                  policySubmission={policySubmission}
                  isDownloading={isDownloading}
                  setSelectedTargetId={setSelectedTargetId}
                  selectedTargetId={selectedTargetId}
                  onDownload={onDownload}
                />
              );
            })
          : null}
        {project && project.policies?.length === 0 ? (
          <NoRows title={"Policy Submissions"} colSpan={6} />
        ) : null}
      </TableBody>
    </Table>
  );
};
