import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid, Skeleton, Typography, Box } from "@mui/material";
import { DashboardSubcontractorActivityLineItem } from "social-pro-common/interfaces/analysis";

interface OrganisationSidebarProps {
  loading: boolean;
  subcontractorActivity?: DashboardSubcontractorActivityLineItem[];
  numberOfDaysForPackageSubmission: number;
}

export const OrganisationSidebar = ({ loading }: OrganisationSidebarProps) => {
  const { userOrganisation } = useProfileContext();

  return (
    <Grid
      md={3}
      item
      sx={{
        display: "flex",
        justifyContent: "space-between",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          background: "#E9F0F5",
          padding: "85px 16px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            height: "100px",
            justifyContent: "center",
            margin: "0 auto 20px",
            minWidth: "100px",
          }}
        >
          {loading ? (
            <Skeleton
              variant="rounded"
              animation="wave"
              width={100}
              height={100}
            >
              <img
                width={100}
                height={100}
                alt="companylogo"
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  verticalAlign: "top",
                }}
              />
            </Skeleton>
          ) : (
            <img
              src={userOrganisation?.organisationLogo}
              alt="companylogo"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                verticalAlign: "top",
              }}
            />
          )}
        </Box>
        {loading ? (
          <Skeleton sx={{ margin: "auto" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: "24px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Project Name
            </Typography>
          </Skeleton>
        ) : (
          <Typography
            data-test-id="contractor-project-name"
            sx={{
              color: "black",
              fontSize: "24px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            {userOrganisation?.organisationName}
          </Typography>
        )}
        {loading ? (
          <Skeleton sx={{ margin: "auto" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: "16px",
                fontWeight: "400",
                margin: "10px 0",
                opacity: "0.3",
                textAlign: "center",
              }}
            >
              Project Code
            </Typography>
          </Skeleton>
        ) : (
          <Typography
            data-test-id="contractor-project-code"
            sx={{
              color: "black",
              fontSize: "16px",
              fontWeight: "400",
              margin: "10px 0",
              opacity: "0.5",
              textAlign: "center",
            }}
          >
            {userOrganisation?.contactInfo?.addressLine1}
          </Typography>
        )}
        {loading ? (
          <Skeleton sx={{ margin: "auto" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: "16px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              City, State
            </Typography>
          </Skeleton>
        ) : (
          <Typography
            data-test-id="contractor-project-location"
            sx={{
              color: "black",
              fontSize: "16px",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            {userOrganisation?.contactInfo.city},{" "}
            {userOrganisation?.contactInfo.state}
          </Typography>
        )}

        {/* <div
          style={{
            alignItems: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            padding: "60px 0 0",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {loading ? (
              <Skeleton>
                <AccordianPanel label="Upcoming Reports">
                  <QuickAction
                    isLoading={loading}
                    title={"Submit Report"}
                    overdue={numberOfDaysForPackageSubmission < 0}
                    message={`Due in ${numberOfDaysForPackageSubmission} days`}
                    icon={<Package color={"#000"} />}
                    action={() => {
                      if (selectedProject && contractorPackage) {
                        navigate(
                          RouteHelper.contractorPackageReports(
                            selectedProject?.id,
                          ),
                        );
                      }
                    }}
                  />
                </AccordianPanel>
              </Skeleton>
            ) : (
              <AccordianPanel label="Upcoming Reports">
                <QuickAction
                  backgroundColor="#f2f7f9"
                  isLoading={loading}
                  title={"Submit Report"}
                  overdue={numberOfDaysForPackageSubmission < 0}
                  message={`Due in ${numberOfDaysForPackageSubmission} days`}
                  icon={<Package color={"#000"} />}
                  action={() => {
                    if (selectedProject && contractorPackage) {
                      navigate(
                        RouteHelper.contractorPackageReports(
                          selectedProject?.id,
                        ),
                      );
                    }
                  }}
                />
                {isPrimaryPackage ? (
                  <QuickAction
                    backgroundColor="#f2f7f9"
                    data-test-id="primary-package-report-btn"
                    isLoading={loading}
                    title={"Submit project report"}
                    overdue={numberOfDaysForPackageSubmission < 0}
                    message={`Due in ${numberOfDaysForPackageSubmission} days`}
                    icon={<FileBox color={"#000"} />}
                    action={() => {
                      if (selectedProject && contractorPackage) {
                        navigate(
                          RouteHelper.contractorProjectReports(
                            selectedProject?.id,
                          ),
                        );
                      }
                    }}
                  />
                ) : null}
              </AccordianPanel>
            )}
          </div>
          <SubcontractorActivity
            loading={loading}
            activity={subcontractorActivity || []}
          />
        </div> */}
      </Box>
    </Grid>
  );
};
