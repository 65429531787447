import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { SocialSpendForm } from "@stories/molecules/SocialSpendForm/SocialSpendForm";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  SocialSpendLineItem,
  createDefaultSocialSpend,
} from "social-pro-common/interfaces/socialSpend";

interface SocialSpendModalProps {
  open: boolean;
  reportId: string;
  loading: boolean;
  project: ProjectLineItem;
  contractorOrganisation: OrganisationLineItem;
  contractorPackageId: string;
  existingSocialSpend?: SocialSpendLineItem;
  createSocialSpend: (employee: SocialSpendLineItem) => void;
  updateSocialSpend: (employee: SocialSpendLineItem) => void;
  handleClose: () => void;
}

export default function SocialSpendModal({
  contractorOrganisation,
  contractorPackageId,
  createSocialSpend,
  existingSocialSpend,
  handleClose,
  loading,
  open,
  project,
  reportId,
  updateSocialSpend,
}: SocialSpendModalProps) {
  const onSubmit = async (socialSpend: SocialSpendLineItem) => {
    if (socialSpend) {
      if (existingSocialSpend) {
        await updateSocialSpend({
          ...socialSpend,
        });
      } else {
        await createSocialSpend({
          ...socialSpend,
        });
      }
    }
    handleClose();
  };

  const title = existingSocialSpend
    ? "Edit Social Procurement"
    : "Create Social Procurement";

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className="bgBlueOverlay"
      maxWidth={"sm"}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ borderRadius: "12px", padding: "0" }}>
        <SocialSpendForm
          project={project}
          userOrganisation={contractorOrganisation}
          socialSpend={
            existingSocialSpend ||
            createDefaultSocialSpend(
              project.id,
              contractorPackageId,
              reportId,
              contractorOrganisation,
            )
          }
          handleSubmit={onSubmit}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );
}
