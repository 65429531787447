import { downloadFile } from "@hooks/utils/useUpload";
import {
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SortInfo, TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { useCallback, useRef, useState } from "react";
import { DEFAULT_SOCIAL_SPEND_LIMIT } from "social-pro-common/entities/socialSpend";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";
import { formatReportDate } from "social-pro-common/utils/date";
import { getDocumentName } from "social-pro-common/utils/string";

interface ToolsSocialSpendTableProps {
  loading: boolean;
  socialSpends: SocialSpendLineItem[];
  socialSpendCount: number;
  sortInfo: SortInfo;
  isLoadingMore: boolean;
  handleRequestSort: (sortInfo: SortInfo) => void;
}

export const ToolsSpendTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell>
        <Skeleton animation={"wave"} />
      </TableCell>
      <TableCell align="center" className="center">
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
          <StyledButton className="blackBtn" loading>
            Download
          </StyledButton>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};

export const ToolsSocialSpendTable = ({
  handleRequestSort,
  isLoadingMore,
  loading,
  socialSpendCount,
  socialSpends,
  sortInfo,
}: ToolsSocialSpendTableProps) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const [isDownloading, setIsDownloading] = useState(false);
  const onDownload = async (uri: string, spendName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, spendName);
    setIsDownloading(false);
  };
  // Callback for observing the trigger element
  const lastElementRef = useCallback(
    (node: any) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleRequestSort({ ...sortInfo, page: sortInfo.page + 1 }); // Increment page on intersection
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, sortInfo],
  );

  const tableHeader = (
    <TableHead>
      <TableHeader
        loading={loading}
        title={"Transaction Date"}
        align="left"
        sortInfo={{
          ...sortInfo,
          index: 0,
          page: 0,
        }}
        handleRequestSort={handleRequestSort}
        sx={{ width: "15%" }}
      />
      <TableHeader
        loading={loading}
        title={"Subcontractor"}
        align="left"
        sortInfo={{
          ...sortInfo,
          index: 1,
          page: 0,
        }}
        handleRequestSort={handleRequestSort}
        sx={{ width: "25%" }}
      />
      <TableHeader
        loading={loading}
        title={"Supplier"}
        align="left"
        sortInfo={{
          ...sortInfo,
          index: 2,
          page: 0,
        }}
        handleRequestSort={handleRequestSort}
        sx={{ width: "25%" }}
      />
      <TableHeader
        loading={loading}
        title={"Amount"}
        align="left"
        sortInfo={{
          ...sortInfo,
          index: 3,
          page: 0,
        }}
        handleRequestSort={handleRequestSort}
        sx={{ width: "20%" }}
      />
      <TableHeader loading={loading} title={""} sx={{ width: "15%" }} />
    </TableHead>
  );

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          margin: "0 4px",
          padding: "20px 0 !important",
        }}
      >
        {loading ? (
          <Table>
            {tableHeader}
            <TableBody>
              {loading
                ? Array.from({ length: 10 }, (_, index) => (
                    <ToolsSpendTableRowSkeleton key={index} />
                  ))
                : null}
            </TableBody>
          </Table>
        ) : (
          <Table data-test-id="spend-table">
            {tableHeader}
            <TableBody>
              {socialSpends.map((sp, index) => (
                <TableRow key={`${index}-social-spend-table-row`}>
                  <TableCell>{`${formatReportDate(sp.transactionDate)}`}</TableCell>
                  <TableCell>{`${
                    sp.organisationName || sp.contractorPackageId
                  }`}</TableCell>
                  <TableCell>{`${sp.supplier}`}</TableCell>
                  <TableCell>{`$${sp.invoiceValue}`}</TableCell>
                  <TableCell align="right" className="right">
                    <StyledButton
                      loading={false}
                      className="blackBtn"
                      disabled={isDownloading || !sp.uri}
                      onClick={() =>
                        onDownload(
                          sp.uri,
                          getDocumentName(
                            `${
                              sp.supplier
                            }-${sp.transactionDate.toLocaleDateString(
                              "en-GB",
                            )}`,
                            sp.uri,
                          ),
                        )
                      }
                    >
                      Download
                    </StyledButton>
                  </TableCell>
                </TableRow>
              ))}
              {socialSpends.length === 0 ? (
                <NoRows title={"Spend"} colSpan={5} />
              ) : null}
            </TableBody>
          </Table>
        )}
      </Grid>
      {socialSpendCount > DEFAULT_SOCIAL_SPEND_LIMIT &&
      socialSpends.length < socialSpendCount ? (
        <Box
          ref={lastElementRef}
          style={{ backgroundColor: "transparent", height: "20px" }}
        />
      ) : null}
    </>
  );
};
