import { Grid, Typography, Box, Chip, Stack, Tooltip } from "@mui/material";
import {
  formatDecimalPlaces,
  nFormatter,
} from "social-pro-common/utils/number";
interface WidgetProps {
  loading: boolean;
  title: string;
  value: number;
  pcChange: number;
  colour: string;
  units: string;
  tooltip?: { [key: string]: number };
}

const hexToRgba = (hex: string, opacity: number) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const Widget = ({
  colour,
  pcChange,
  title,
  tooltip,
  units,
  value,
}: WidgetProps) => {
  const backgroundColor = hexToRgba(colour, 0.05);

  const content = (
    <Stack spacing={2} sx={{ padding: "20px" }}>
      <Stack spacing={1}>
        <Box
          sx={{
            display: "flex",
            height: "40px", // Fixed height for title
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography variant="h2" sx={{ fontWeight: "bold" }}>
          {units === "$" ? "$" : null}
          {nFormatter(value, 2)}
          {units !== "$" ? units : null}
        </Typography>
      </Stack>
      <Box>
        <Chip
          label={`${pcChange < 0 ? "-" : "+"}${formatDecimalPlaces(pcChange)}%`}
          size="small"
          sx={{
            alignSelf: "flex-start",
            backgroundColor: colour,
            color: "#fff",
            minWidth: "50%",
          }}
        />
      </Box>
    </Stack>
  );
  return (
    <Grid
      item
      md={2}
      sx={{
        backgroundColor: backgroundColor,
        border: `1px solid ${colour}`,
        borderRadius: "25px",
        objectFit: "cover",
      }}
      data-testid="widget"
    >
      {tooltip ? (
        <Tooltip
          title={
            <Box sx={{ display: "inline-block", padding: 1 }}>
              {Object.entries(tooltip).map(([name, count], index) => (
                <Typography key={index} variant="body2">
                  <strong>{name}:</strong> {count}
                </Typography>
              ))}
            </Box>
          }
          arrow
          componentsProps={{
            arrow: {
              sx: {
                color: "white",
              },
            },
            tooltip: {
              sx: {
                backgroundColor: "white",
                borderRadius: 1,
                boxShadow: 1,
                color: "black",
                maxWidth: "none", // Prevent max width constraint
                padding: 1,
              },
            },
          }}
        >
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </Grid>
  );
};
