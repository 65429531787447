import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { UserForm } from "@stories/molecules/UserForm/UserForm";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import {
  ProfileLineItem,
  createDefaultProfile,
} from "social-pro-common/interfaces/profile";

interface UserModalProps {
  open: boolean;
  loading: boolean;
  organisation: OrganisationLineItem;
  existingUser?: ProfileLineItem;
  profiles: ProfileLineItem[];
  createUser: (profile: ProfileLineItem) => void;
  updateUser: (profile: ProfileLineItem) => void;
  handleClose: () => void;
}

export default function UserModal({
  createUser,
  existingUser,
  handleClose,
  loading,
  open,
  organisation,
  profiles,
  updateUser,
}: UserModalProps) {
  const onSubmit = async (profile: ProfileLineItem) => {
    if (profile) {
      if (existingUser) {
        await updateUser(profile);
      } else {
        await createUser(profile);
      }
      handleClose();
    }
  };

  const title = "User Details";

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className={"bgBlueOverlay"}
    >
      <DialogTitle sx={{ fontSize: "24px", padding: "20px 40px 10px" }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ padding: "20px 40px 40px" }}>
        <UserForm
          user={existingUser || createDefaultProfile(organisation)}
          isEdit={Boolean(existingUser)}
          existingProfiles={profiles}
          handleSubmit={onSubmit}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );
}
