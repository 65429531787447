import { downloadFile } from "@hooks/utils/useUpload";
import {
  Button,
  CircularProgress,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { SustainabilityTargetAnalyticLineItem } from "social-pro-common/interfaces/analytics";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import { getDocumentName } from "social-pro-common/utils/string";

interface GreenMetricTableRowProps {
  greenMetric?: GreenMetricLineItem;
  target: SustainabilityTargetAnalyticLineItem;
}

export const ContractorAnalyticsEnviroSustainabilityTableRow = ({
  greenMetric,
  target,
}: GreenMetricTableRowProps) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  return (
    <TableRow key={target.id}>
      <TableCell scope="row">{target.category}</TableCell>
      <TableCell>{target.type}</TableCell>
      <TableCell align="center">
        <Typography align="center">{target.toDate}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">{target.thisMonth}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">{target.pcIncrease}%</Typography>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          {target.uri && greenMetric ? (
            isDownloading ? (
              <CircularProgress />
            ) : (
              <Button
                disabled={isDownloading}
                onClick={() =>
                  target?.uri
                    ? onDownload(
                        target?.uri,
                        getDocumentName(
                          greenMetric.reportTargetName,
                          target?.uri,
                        ),
                      )
                    : undefined
                }
                className="blackBtn"
                sx={{
                  color: "white",
                  fontSize: "14px",
                  lineHeight: "16px",
                  padding: "10px 15px",
                }}
              >
                Download
              </Button>
            )
          ) : null}
        </Stack>
      </TableCell>
    </TableRow>
  );
};
