import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, IconButton, Stack } from "@mui/material";
import PackageDetailsForm from "@stories/molecules/PackageDetailsForm/PackageDetailsForm";
import { PackageLidpRequirementForm } from "@stories/molecules/PackageLidpRequirementForm/PackageLidpRequirementForm";
import { PackageSocialRequirementFormHours } from "@stories/molecules/PackageSocialRequirementFormHours/PackageSocialRequirementFormHours";
import { PackageSocialRequirementFormSpend } from "@stories/molecules/PackageSocialRequirementFormSpend/PackageSocialRequirementFormSpend";
import "./ContractorPackageModalStyles.css";
import { PackageSustainabilityRequirementForm } from "@stories/molecules/PackageSustainabilityRequirementForm/PackageSustainabilityRequirementForm";
import { useEffect, useState } from "react";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import {
  ContractorPackageLineItem,
  ContractorPackageSetupLineItem,
  createDefaultContractorPackage,
} from "social-pro-common/interfaces/contractorPackage";
import {
  createDefaultOrganisation,
  OrganisationLineItem,
} from "social-pro-common/interfaces/organisation";
import { cloneProjectLabourHourCommitmentsForPackage } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { cloneProjectSpendCommitmentsForPackage } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { calculatePackageProgressLineItem } from "social-pro-common/utils/calc";

import { Steps } from "../../atoms/Steps/Steps";

interface ContractorPackageModalProps {
  open: boolean;
  loading: boolean;
  project: ProjectLineItem;
  packageCount: number;
  selectedPackageId?: string;
  createContractorPackage: (
    organisation: OrganisationLineItem,
    contractorPackage: ContractorPackageLineItem,
    profile?: ProfileLineItem,
  ) => void;
  updateContractorPackage: (
    contractorPackage: ContractorPackageLineItem,
  ) => void;
  handleClose: () => void;
}

export default function ContractorPackageModal({
  createContractorPackage,
  handleClose,
  loading,
  open,
  packageCount,
  project,
  selectedPackageId,
  updateContractorPackage,
}: ContractorPackageModalProps) {
  const [activeStep, setActiveStep] = useState(0);

  const { getContractorPackageSetup, isContractorPackageLoading } =
    useContractorPackage();

  const [selectedContractorPackage, setSelectedContractorPackage] =
    useState<ContractorPackageSetupLineItem>({
      contractorPackage: createDefaultContractorPackage(
        project,
        PackageType.BalustradesAndHandrail,
        packageCount,
      ),
      organisation: createDefaultOrganisation(""),
    });

  useEffect(() => {
    const fetchContractorPackage = async () => {
      if (selectedPackageId) {
        const contractorPackage =
          await getContractorPackageSetup(selectedPackageId);
        if (contractorPackage) {
          setSelectedContractorPackage(contractorPackage);
        }
      }
    };
    if (selectedPackageId) {
      fetchContractorPackage();
    }
  }, [selectedPackageId]);

  const onSubmit = async (
    contractorPackageToSave: ContractorPackageSetupLineItem,
  ) => {
    if (contractorPackageToSave) {
      contractorPackageToSave.contractorPackage.estimatedProgress =
        calculatePackageProgressLineItem(
          contractorPackageToSave.contractorPackage.commitmentsHours,
          contractorPackageToSave.contractorPackage.commitmentsSpend,
        );
      if (selectedPackageId) {
        await updateContractorPackage(
          contractorPackageToSave.contractorPackage,
        );
      } else {
        await createContractorPackage(
          contractorPackageToSave.organisation,
          contractorPackageToSave.contractorPackage,
          contractorPackageToSave.profile,
        );
      }
    }
    handleClose();
  };

  const steps = [{ label: "Subcontractor Details" }];

  const handleNext = (contractorPacakge: ContractorPackageSetupLineItem) => {
    if (activeStep === steps.length - 1) {
      onSubmit(contractorPacakge);
    } else {
      setSelectedContractorPackage(contractorPacakge);
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const isLoading = loading || isContractorPackageLoading;

  const availableComponents = [
    <PackageDetailsForm
      key={"subcontractor-details"}
      loading={isLoading}
      project={project}
      contractorPackageSetup={selectedContractorPackage}
      handleSubmit={(
        contractorPackage: ContractorPackageLineItem,
        contractorOrganisation: OrganisationLineItem,
        profile?: ProfileLineItem,
      ) => {
        const packageSetup = {
          contractorPackage,
          organisation: contractorOrganisation,
          profile,
        };
        setSelectedContractorPackage(packageSetup);
        handleNext(packageSetup);
      }}
    />,
  ];

  if (project.commitmentsHours.length) {
    steps.push({ label: "Labour Hours" });
  }
  if (project.commitmentsSpend.length) {
    steps.push({ label: "Spend" });
  }
  if (project.localContents.length) {
    steps.push({ label: "Local Content" });
  }
  if (project.greenMetrics.length) {
    steps.push({ label: "Sustainability" });
  }

  const finalStep = steps.length ? steps[steps.length - 1].label : "No steps";

  if (project.commitmentsHours.length) {
    const hoursCommitments =
      selectedContractorPackage?.contractorPackage.commitmentsHours ||
      cloneProjectLabourHourCommitmentsForPackage(
        selectedContractorPackage.contractorPackage.id,
        project.commitmentsHours,
      );
    availableComponents.push(
      <PackageSocialRequirementFormHours
        loading={isLoading}
        project={project}
        contractorPackageSetup={selectedContractorPackage}
        commitmentLineItems={hoursCommitments}
        isFinalStep={finalStep === "Labour Hours"}
        handleNext={handleNext}
        handleBack={handleBack}
      />,
    );
  }
  if (project.commitmentsSpend.length) {
    const spendCommitments =
      selectedContractorPackage?.contractorPackage.commitmentsSpend ||
      cloneProjectSpendCommitmentsForPackage(
        selectedContractorPackage.contractorPackage.id,
        project.commitmentsSpend,
      );
    availableComponents.push(
      <PackageSocialRequirementFormSpend
        loading={isLoading}
        project={project}
        contractorPackageSetup={selectedContractorPackage}
        commitmentLineItems={spendCommitments}
        isFinalStep={finalStep === "Social Spend"}
        handleNext={handleNext}
        handleBack={handleBack}
      />,
    );
  }
  if (project.localContents.length) {
    availableComponents.push(
      <PackageLidpRequirementForm
        loading={isLoading}
        project={project}
        contractorPackageSetup={selectedContractorPackage}
        isFinalStep={finalStep === "Local Content"}
        handleNext={handleNext}
        handleBack={handleBack}
      />,
    );
  }
  if (project.greenMetrics.length) {
    availableComponents.push(
      <PackageSustainabilityRequirementForm
        loading={isLoading}
        project={project}
        contractorPackageSetup={selectedContractorPackage}
        handleNext={handleNext}
        handleBack={handleBack}
      />,
    );
  }

  const selectedCreateContractorStep = availableComponents[activeStep];

  const onClose = () => {
    setActiveStep(0);
    handleClose();
  };
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={"lg"}
      className="bgBlueOverlay addProjectModal"
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          "&:hover": {
            backgroundColor: "#143E7C",
          },
          background: "black",
          color: "white",
          padding: "5px",
          position: "absolute",
          right: 24,
          top: 30,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Steps activeStep={activeStep} steps={steps} />
      <DialogContent
        className=" custom-scrollbar"
        sx={{ borderRadius: "12px", padding: "0" }}
      >
        <Stack spacing={2}>
          <div style={{ margin: "0" }} className="">
            {selectedCreateContractorStep}
          </div>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
