import "chartjs-adapter-date-fns";
import { Box, Skeleton } from "@mui/material";
import { ChartRangeType } from "@stories/pages/Contractor/DashboardChart/DashboardChartRangeTabs";
import { Chart as ChartJS, registerables } from "chart.js";
import { useRef } from "react";
import { Chart } from "react-chartjs-2";
import { ForecastUnitType } from "social-pro-common/entities/forecastData";
import { ChartDataLineItem } from "social-pro-common/interfaces/analysis";
import { ForecastModelLineItem } from "social-pro-common/interfaces/forecastData";
import { formatChartDate } from "social-pro-common/utils/date";

ChartJS.register(...registerables);

interface ForecastChartProps {
  loading: boolean;
  chartRangeType: ChartRangeType;
  chartData?: ChartDataLineItem;
  forecastModals: ForecastModelLineItem[];
  reportMonths?: Date[];
  chartUnits: string;
}

export const ForecastChart = ({
  chartData,
  chartRangeType,
  chartUnits,
  forecastModals,
  loading,
  reportMonths,
}: ForecastChartProps) => {
  const containerRef = useRef<HTMLElement>(null);

  const options = {
    aspectRatio: 3,
    borderRadius: 4,
    layout: {
      padding: {
        left: 0,
        right: 1,
        top: 0,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        align: "start" as const,
        display: false,
        font: {
          family: "Roboto",
          lineHeight: 1.2,
          size: 16,
          weight: "normal",
        },
        position: "bottom" as const,
      },
    },
    responsive: true,
    scales: {
      x: {
        display: true,
        stacked: true,
      },
      y: {
        display: true,
        stacked: false,
        title: {
          color: "#000",
          display: true,
          font: {
            family: "Roboto",
            lineHeight: 1.2,
            size: 16,
            weight: "normal",
          },
          text: chartUnits,
        },
      },
    },
  };

  const sliceRange =
    chartRangeType === ChartRangeType.TwelveMonth
      ? 12
      : reportMonths?.length || 0;

  const labels =
    reportMonths?.slice(0, sliceRange).map((rm) => formatChartDate(rm)) || [];
  const data = {
    datasets: [] as any[],
    labels,
  };
  const primaryColour = "#0081E2";

  data.datasets.push({
    backgroundColor: primaryColour,
    barPercentage: 0.2,
    borderColor: primaryColour,
    data: chartData?.achievedData.slice(0, sliceRange) || [],
    label: "Actual",
    width: 20,
  });

  for (const forecastModel of forecastModals) {
    if (forecastModel.active) {
      if (
        forecastModel.units === ForecastUnitType.Hour &&
        chartUnits != "Hours"
      ) {
        continue;
      } else if (
        forecastModel.units === ForecastUnitType.Spend &&
        chartUnits != "Dollars"
      ) {
        continue;
      }

      const forecastData = forecastModel.data
        .slice(0, sliceRange)
        .map((fd) => fd.value || 0);
      const forecastColour = forecastModel.colour;
      const forecastName = forecastModel.modelName;

      const forecastDataSet = {
        backgroundColor: forecastColour,
        borderColor: forecastColour,
        borderWidth: 2,
        data: forecastData,
        label: forecastName,
        lineTension: 0.2,
        type: "line" as const,
        width: 20,
        yAxisID: "y",
      };

      data.datasets.push(forecastDataSet);
    }
  }

  if (loading) {
    return (
      <Skeleton
        animation="wave"
        variant="rounded"
        width="100%"
        sx={{ margin: "auto", marginBottom: "30px" }}
      >
        <Box
          sx={{
            flexDirection: "row",
            flexGrow: 1,
            height: "500px",
            padding: "12px",
            width: "100%",
          }}
        />
      </Skeleton>
    );
  }

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          height: "500px",
          margin: "auto",
          padding: "0px 0px 10px 0px",
          position: "relative",
          width: "100% !important",
        }}
      >
        <Chart
          className="barChart"
          type="bar"
          data={data}
          options={options as any}
        />
      </Box>
    </>
  );
};
