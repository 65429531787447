import { useSocialSpendSubData } from "@hooks/crud/dashboard/useSocialSpendSubData";
import { Grid } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

import { AchievedStack } from "../ProjectProgress/AchievedStack";
import { ForecastStack } from "../ProjectProgress/ForecastStack";
import { RecommendedSupplierStack } from "../ProjectProgress/RecommendedSupplierStack";
import { TargetStack } from "../ProjectProgress/TargetStack";

interface SocialSpendProgressSubDataProps {
  loading: boolean;
  isPrimaryPackage: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  reportId?: string;
}

export const SocialSpendProgressSubData = ({
  contractorPackage,
  loading,
  project,
  reportId,
}: SocialSpendProgressSubDataProps) => {
  const { isAnalysisLoading, socialSpendSubData } = useSocialSpendSubData(
    project?.id,
    contractorPackage?.id,
    reportId,
  );

  const isLoading = loading || isAnalysisLoading;
  return (
    <Grid container justifyContent={"space-between"}>
      <AchievedStack
        loading={isLoading}
        targets={socialSpendSubData?.targets || []}
        title={"Targets"}
        firstColumnTitle={"Category"}
        secondColumnTitle={"Spend ($)"}
        thirdColumnTitle={"Target"}
        units="$"
        rightMargin
        dataTestId={"social-spend-targets"}
        url={RouteHelper.analyticsSpend(
          project?.id || "",
          contractorPackage?.id || "",
          reportId,
        )}
      />
      {socialSpendSubData?.recommendedSuppliers ? (
        <RecommendedSupplierStack
          loading={isLoading}
          suppliers={socialSpendSubData?.recommendedSuppliers || []}
          title={"Smart Directory"}
          firstColumnTitle={"Suggested Suppliers"}
          dataTestId={"social-spend-recommendations"}
        />
      ) : (
        <TargetStack
          loading={isLoading}
          targets={socialSpendSubData?.socialSpendData || []}
          title={"Suppliers"}
          firstColumnTitle={"Name"}
          secondColumnTitle={"Spend ($)"}
          units="$"
          leftMargin
          rightMargin
          dataTestId={"social-spend-subcontractors"}
        />
      )}

      <ForecastStack
        loading={isLoading}
        forecast={socialSpendSubData?.forecast}
        dataTestId={"social-spend-forecast"}
      />
    </Grid>
  );
};
