import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ViewTrainingContractForm } from "@stories/molecules/ViewTrainingContractDetail/ViewTrainingContractDetail";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";

interface ViewTrainingContractModalProps {
  open: boolean;
  selectedEmployee: EmployeeLineItem;
  setOpen: (value: boolean) => void;
}

export default function ViewTrainingContractModal({
  open,
  selectedEmployee,
  setOpen,
}: ViewTrainingContractModalProps) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className={"bgBlueOverlay"}
    >
      <DialogTitle sx={{ fontSize: "24px", padding: "20px 40px 10px" }}>
        {"Training Contract Details"}
      </DialogTitle>
      <DialogContent sx={{ padding: "20px 40px 40px" }}>
        <ViewTrainingContractForm
          selectedEmployee={selectedEmployee}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}
