import { Box, Button, Grid, Typography  } from "@mui/material";
import backgroundOverlay from "assets/images/home/background-overlay.png";
import Logo from "assets/images/home/white-logo.png";


const ErrorPage = () => {

  return (
    
    <Box 
    className="loginPage errorpage" 
    sx={{  
      background: `linear-gradient(90deg, #02548A 0%, #0386DD 100%)`, 
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      position: 'relative',
    }}
  >
    <Box 
      sx={{
        backgroundImage: `url(${backgroundOverlay})`,
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        height: '100%',
        opacity: 0.8,   // Adjust the opacity if needed
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',   // Adjust the width as needed
      }}
    />
      <Grid spacing={2} sx={{margin:'0 auto', maxWidth:'1144px', padding:'120px 50px', }} >
        <a href="/" style={{ marginBottom: "20px", textAlign: "center" }}>
          <img src={Logo} alt="" width="200px" />
        </a>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1" sx={{color:'white' ,fontFamily:'Roboto',fontSize:'200px', fontWeight:'600' ,lineHeight:'1.3', }} >
              404
            </Typography>
            <Typography variant="h2" sx={{color:'white',fontFamily:'Roboto' ,fontSize:'60px',fontWeight:'500', lineHeight:'1.2'  ,marginTop:'10px',}}>
                An error has occurred.   
            </Typography>
            <Typography variant="body1" sx={{color:'white !important',fontFamily:'Roboto' , fontSize:'30px' , fontWeight:'400',lineHeight:'40px', marginTop:'40px' ,maxWidth:'1033px',  }}>
            We are sorry for the inconvenience. Please check your internet connection, firewall, proxies or try again later.<br /><br />
            If the problem persists, please reach out to support through the chat icon.
            </Typography>
            <Button sx={{'&:hover':{
                background: "linear-gradient(88.06deg, #005690 0.21%, #2881BC 103.61%)", // New background on hover
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Additional hover shadow
              } , 
              background: "linear-gradient(88.06deg, #2881BC 0.21%, #005690 103.61%)" ,backgroundClip: 'padding-box', backgroundOrigin: 'border-box' , border:'none', borderRadius:'170px', 
              boxShadow: 'inset 0 0 0 2px rgba(255, 255, 255, 0.1)', color:'white', cursor:'pointer', fontFamily:'Roboto',
              fontSize:'23px', fontWeight:'500',
              lineHeight:'30px' , marginTop:'56px', minWidth:'300px' , outline:'none', 
              padding:'18px 30px' ,
              textTransform: 'none !important',
              transition:'linear 0.3s'
              
              }} className="blueBtn">
                
                Return to home</Button>
            
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ErrorPage;
