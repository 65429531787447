import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { useFormik } from "formik";
import { EmployeeSocialFactor } from "social-pro-common/entities/contractorEmployee";
import {
  DisabilityCategory,
  EmployeeLineItem,
  employeeDisabilityCategoryToString,
} from "social-pro-common/interfaces/contractorEmployee";
import {
  SupportingDocumentLineItem,
  createDefaultSupportingDocument,
} from "social-pro-common/interfaces/supportingDocument";
import * as yup from "yup";

interface DisabilityFormProps {
  employee: EmployeeLineItem;
  supportingDocument?: SupportingDocumentLineItem;
  setEmployee: (employee: EmployeeLineItem) => void;
  handleClose: () => void;
}

export const DisabilityForm = ({
  employee,
  handleClose,
  setEmployee,
  supportingDocument,
}: DisabilityFormProps) => {
  const validationSchema = yup.object({
    category: yup.string().required("Category is required"),
    programProvider: yup.string().required("Program provider is required"),
  });
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      category:
        (supportingDocument?.documentCategory as DisabilityCategory) ||
        DisabilityCategory.DisabilityEnterpriseServices,
      programProvider: supportingDocument?.documentDescription || "",
    },
    onSubmit: (values) => {
      const filteredDocuments = employee.supportingDocuments.filter(
        (d) =>
          d.supportingDocumentType !==
          supportingDocument?.supportingDocumentType,
      );
      setEmployee({
        ...employee,
        supportingDocuments: [
          ...filteredDocuments,
          {
            ...createDefaultSupportingDocument(
              employee.id,
              EmployeeSocialFactor.PersonWithDisability,
              "",
            ),
            documentCategory: values.category as DisabilityCategory,
            documentDescription: values.programProvider,
            supportingDocumentType: EmployeeSocialFactor.PersonWithDisability,
          },
        ],
      });
      handleClose();
    },
    validationSchema: validationSchema,
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <StyledTextField
              fullWidth
              loading={false}
              margin="dense"
              id="programProvider"
              name="programProvider"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.programProvider}
              error={
                formik.touched.programProvider &&
                Boolean(formik.errors.programProvider)
              }
              helperText={
                formik.touched.programProvider
                  ? formik.errors.programProvider
                  : ""
              }
              label={"Program Provider"}
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ paddingTop: "8px !important" }}>
            <Autocomplete
              id="category"
              options={Object.values(DisabilityCategory)}
              getOptionLabel={(option) =>
                employeeDisabilityCategoryToString(option) || ""
              }
              disabled={formik.isSubmitting}
              value={formik.values.category}
              onChange={(e: any, value: DisabilityCategory | null) => {
                formik.setFieldValue("category", value);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="category"
                  label="Disability Category"
                  fullWidth
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  helperText={
                    formik.touched.category ? formik.errors.category : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex !important",
              justifyContent: "space-between !important",
              paddingTop: "16px !important",
            }}
          >
            <StyledButton
              loading={formik.isSubmitting}
              variant="contained"
              color="error"
              disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              Cancel
            </StyledButton>
            <StyledButton
              loading={formik.isSubmitting}
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
