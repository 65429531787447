import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { ContractorAnalyticsLocalContent } from "@stories/molecules/ContractorAnalyticsLocalContent/ContractorAnalyticsLocalContent";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

export const AnalyticsPackageLocalContent = () => {
  const { packageId } = useParams();

  const [contractorPackage, setContractorPackage] =
    useState<ContractorPackageLineItem>();

  const { isPrimaryPackage, isProjectLoading } = useProjectContext();

  const { getContractorPackage, isContractorPackageLoading } =
    useContractorPackage();

  useEffect(() => {
    const fetchContractorPackage = async () => {
      if (packageId) {
        const contractorPackage = await getContractorPackage(packageId);
        if (contractorPackage) {
          setContractorPackage(contractorPackage);
        }
      }
    };
    fetchContractorPackage();
  }, [packageId]);
  const loading = isProjectLoading || isContractorPackageLoading;
  return (
    <>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          margin: "0",
          padding: "20px 40px 20px 40px",
          width: "100%",
        }}
      >
        <Grid item md={12} sx={{ padding: "0px !important" }}>
          {loading ? (
            <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
              <Stack>
                <Skeleton animation="wave">
                  <Typography
                    variant="h1"
                    component="div"
                    sx={{ fontSize: "28px" }}
                  >
                    {"Project Dashboard September 2000"}
                  </Typography>
                </Skeleton>
                <Skeleton animation="wave">
                  <Typography variant="subtitle1">
                    {"Project Analytics"}
                  </Typography>
                </Skeleton>
              </Stack>
            </Grid>
          ) : (
            <>
              <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
                <Stack>
                  <Typography
                    variant="h1"
                    component="div"
                    sx={{ fontSize: "28px" }}
                  >
                    {"Local Content"}
                  </Typography>
                  <Typography variant="subtitle1">
                    {isPrimaryPackage
                      ? "Project Analytics"
                      : "Package Analytics"}
                  </Typography>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table localContent-tb "
        sx={{
          margin: "0",
          padding: "0 !important",
          width: "100%",
        }}
      >
        <ContractorAnalyticsLocalContent
          loading={loading}
          isPrimaryPackage={isPrimaryPackage}
          contractorPackage={contractorPackage}
          localContents={contractorPackage?.localContent || []}
        />
      </Grid>
    </>
  );
};
