import { getApiData } from "@hooks/utils/api";
import { downloadFile } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";
import { sleep } from "@utils/utils";
import * as React from "react";
import { decodeFileGeneration } from "social-pro-common/decoders/fileGeneration";
import {
  FileGeneration,
  FileGenerationStatus,
} from "social-pro-common/entities/fileGeneration";
import { FileGenerationLineItem } from "social-pro-common/interfaces/fileGeneration";
import { v4 as uuid } from "uuid";

export const useSocialSpendExport = () => {
  const [downloadStep, setDownloadStep] = React.useState<FileGenerationStatus>(
    FileGenerationStatus.Idle,
  );
  const [downloadProgress, setDownloadProgress] = React.useState<number>(0);
  const [error, setError] = React.useState<string | null>(null);

  const getSocialSpendExcel = React.useCallback(
    async (
      projectId: string,
      targetId: string,
      contractorPackageId?: string,
    ) => {
      try {
        const fileGenerationId = uuid();
        setDownloadProgress(0);
        setDownloadStep(FileGenerationStatus.Fetching);

        let fileGenerationResult: FileGenerationLineItem | undefined =
          undefined;
        let counter = 0;
        while (counter < 300) {
          const profileResult = await getApiData(
            "getSocialSepndTargetGeneration",
            "fileGeneration",
            fileGenerationId,
            {
              contractorPackageId,
              projectId,
              targetId,
            },
          );
          fileGenerationResult = decodeFileGeneration(
            profileResult.data as FileGeneration,
          );

          setDownloadStep(fileGenerationResult.status);
          if (fileGenerationResult.status === FileGenerationStatus.Error) {
            setError("Could not fetch pdf report");
            break;
          } else if (
            fileGenerationResult.status === FileGenerationStatus.Generated
          ) {
            break;
          }
          await sleep(1000);
          counter++;
        }

        if (fileGenerationResult && fileGenerationResult.url) {
          await downloadFile(
            fileGenerationResult.url,
            "Social-Pro-Social-Spend-Report.xlsx",
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;

              setDownloadProgress(total);
              if (total >= 100) {
                setDownloadStep(FileGenerationStatus.Idle);
              }
            },
          );
        } else {
          setError("Could not fetch pdf report");
          setDownloadStep(FileGenerationStatus.Error);
        }
      } catch (error) {
        catchSentryError(error);
        setError("Could not fetch pdf report");
        setDownloadStep(FileGenerationStatus.Error);
      } finally {
        setDownloadStep(FileGenerationStatus.Idle);
      }
    },
    [setDownloadProgress, setDownloadStep, setError],
  );

  return {
    downloadProgress,
    downloadStep,
    error,
    getSocialSpendExcel,
  };
};
