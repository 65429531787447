import { Button, Stack, TableCell, TableRow, Typography } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { useNavigate } from "react-router-dom";
import { LabourHourTargetAnalyticsLineItem } from "social-pro-common/interfaces/analytics";
import {
  SocialCommitmentTypeHour,
  labourHourCommitmentTypeToString,
} from "social-pro-common/interfaces/packageLabourHourCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

interface ContractorAnalyticsLabourHoursRowProps {
  project?: ProjectLineItem;
  analysis: LabourHourTargetAnalyticsLineItem;
  onLabourHourTargetChange: (targetId: string) => void;
}
export const ContractorAnalyticsLabourHoursRow = ({
  analysis,
  onLabourHourTargetChange,
  project,
}: ContractorAnalyticsLabourHoursRowProps) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    onLabourHourTargetChange(analysis.id);
  };

  const handleSmartDirectory = () => {
    if (project?.id) {
      navigate(RouteHelper.directory(project?.id, analysis.id));
    }
  };

  return (
    <TableRow>
      <TableCell align="center">
        <Typography>
          {analysis.targetDescription ||
            labourHourCommitmentTypeToString(
              analysis.targetType as SocialCommitmentTypeHour,
            )}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align={"center"}>
          {formatDecimalPlaces(analysis.targetValue)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align={"center"}>
          {formatDecimalPlaces(analysis.totalReportedValue)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align={"center"}>
          {formatDecimalPlaces(analysis.monthReportedValue)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align={"center"}>
          {formatDecimalPlaces(analysis.progress)}%
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align={"center"}>
          {formatDecimalPlaces(analysis.actual)}%
        </Typography>
      </TableCell>
      <TableCell align="right" className="right">
        <Stack
          direction="row"
          spacing={2}
          alignContent={"end"}
          justifyContent={"end"}
        >
          <Button
            onClick={handleViewDetails}
            className="blackBtn grey-outline-btn"
            sx={{
              fontSize: "14px",
              lineHeight: "16px",
              padding: "10px 15px",
            }}
          >
            Details
          </Button>

          <Button
            onClick={handleSmartDirectory}
            className="blackBtn"
            sx={{
              color: "white",
              fontSize: "14px",
              lineHeight: "16px",
              padding: "10px 15px",
            }}
          >
            Directory
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
