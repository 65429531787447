import { getApiData, listApiData, postApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState, useEffect } from "react";
import {
  decodeProfile,
  encodeProfile,
} from "social-pro-common/decoders/profile";
import { Profile } from "social-pro-common/entities/profile";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";

export const useProfile = (organisationId?: string) => {
  const [profiles, setProfiles] = useState<ProfileLineItem[]>([]);
  const [isProfileLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleApiError = useCallback((e: any, errorMessage: string) => {
    catchSentryError(e);
    setError(errorMessage);
  }, []);

  const getProfile = useCallback(
    async (
      id: string,
    ): Promise<{ profile?: ProfileLineItem; success: boolean }> => {
      try {
        setIsLoading(true);
        const profileResult = await getApiData("getProfile", "profile", id);

        if (profileResult && profileResult.status !== 200) {
          throw new Error("Could not fetch profile");
        }
        if (
          !profileResult ||
          !profileResult.data ||
          Object.keys(profileResult.data).length === 0
        ) {
          return { success: true };
        }
        return {
          profile: decodeProfile(profileResult.data as Profile),
          success: true,
        };
      } catch (e: any) {
        handleApiError(e, "Could not fetch profile");
      } finally {
        setIsLoading(false);
      }
      return {
        success: false,
      };
    },
    [handleApiError],
  );

  const listProfiles = useCallback(
    async (orgId: string): Promise<void> => {
      try {
        setIsLoading(true);
        const profileResult = await listApiData(
          "listProfiles",
          "profile",
          orgId,
        );
        setProfiles(profileResult.data.map((p) => decodeProfile(p as Profile)));
      } catch (e: any) {
        handleApiError(e, "Could not list profiles");
      } finally {
        setIsLoading(false);
      }
    },
    [handleApiError],
  );

  const searchProfiles = useCallback(
    async (query: string): Promise<ProfileLineItem[]> => {
      try {
        const profileResult = await listApiData(
          "searchProfiles",
          "profile",
          query,
        );
        return profileResult.data.map((p) => decodeProfile(p as Profile));
      } catch (e: any) {
        handleApiError(e, "Could not search profiles");
        return [];
      }
    },
    [handleApiError],
  );

  const createProfile = useCallback(
    async (profile: ProfileLineItem): Promise<ProfileLineItem> => {
      try {
        setIsLoading(true);
        const encodedProfile = encodeProfile(profile);
        const upsertedProfile = await postApiData(
          "createProfile",
          "profile",
          encodedProfile,
        );
        const decodedProfile = decodeProfile(upsertedProfile.data as Profile);
        setProfiles((prevProfiles) => [...prevProfiles, decodedProfile]);
        return decodedProfile;
      } catch (e: any) {
        handleApiError(e, "Could not create profile");
      } finally {
        setIsLoading(false);
      }
      return profile;
    },
    [handleApiError],
  );

  const inviteProfile = useCallback(
    async (profile: ProfileLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedProfile = encodeProfile(profile);
        const response = await postApiData(
          "inviteProfile",
          "profile",
          encodedProfile,
        );
        const createdProfile = decodeProfile(response.data as never as Profile);
        setProfiles((prevProfiles) => [...prevProfiles, createdProfile]);
      } catch (e: any) {
        handleApiError(e, "Could not invite profile");
      } finally {
        setIsLoading(false);
      }
    },
    [handleApiError],
  );

  const updateProfile = useCallback(
    async (profile: ProfileLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedProfile = encodeProfile(profile);
        const response = await postApiData(
          "updateProfile",
          "profile",
          encodedProfile,
        );
        const updatedProfile = decodeProfile(response.data as never as Profile);

        setProfiles((prevProfiles) =>
          prevProfiles.map((p) =>
            p.id === updatedProfile.id ? updatedProfile : p,
          ),
        );
      } catch (e: any) {
        handleApiError(e, "Could not update profile");
      } finally {
        setIsLoading(false);
      }
    },
    [handleApiError],
  );

  const deleteProfile = useCallback(
    async (profile: ProfileLineItem): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedProfile = encodeProfile(profile);
        await postApiData("deleteProfile", "profile", encodedProfile);
        setProfiles((prevProfiles) =>
          prevProfiles.filter((p) => p.id !== profile.id),
        );
      } catch (e: any) {
        handleApiError(e, "Could not delete profile");
      } finally {
        setIsLoading(false);
      }
    },
    [handleApiError],
  );

  useEffect(() => {
    if (organisationId) {
      listProfiles(organisationId);
    }
  }, [organisationId, listProfiles]);

  return {
    createProfile,
    deleteProfile,
    error,
    getProfile,
    inviteProfile,
    isProfileLoading,
    listProfiles,
    profiles,
    searchProfiles,
    updateProfile,
  };
};
