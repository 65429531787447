import { browserStorage } from "@utils/browserStorage";
import { Auth } from "aws-amplify";

export const isProdEnv = () => {
  return window.location.href.includes("https://portal.socialproapp.com");
};

export const isLocalHost = () => {
  return window.location.href.includes("http://localhost:3000");
};

export const isLocalHostSAML = () => {
  return false;
};

export const isBMD = () => {
  return window.location.href.includes("https://bmd.socialproapp.com");
};

export const IS_PROD_ENV = isProdEnv() || isBMD();
export const IS_TEST_ENV =
  !IS_PROD_ENV && (window as any).PLAYWRIGHT_RUNNING === true;

export const API_END_POINT_AUTH = IS_PROD_ENV
  ? "https://1v1rcpqvx9.execute-api.ap-southeast-2.amazonaws.com/dev/signIn"
  : "https://88mycudolb.execute-api.ap-southeast-2.amazonaws.com/dev/signIn";

export const BUCKET_NAME = IS_PROD_ENV
  ? "storagestack-prod-databucketd8691f4e-158dh2vnoby26"
  : "storagestack-dev-databucketd8691f4e-kkco8jw9wvo9";

export const getAmplifyConfiguration = () => {
  if (isBMD()) {
    const userPoolId = "ap-southeast-2_ZoriuC3KK";
    const userPoolClientId = "4sho33go57m0rs1cvke3fi7i7b";
    const cognitoDomain =
      "social-pro-saml-signin.auth.ap-southeast-2.amazoncognito.com";
    return {
      API: {
        endpoints: [
          {
            custom_header: async () => {
              return {
                Authorization: `${(await Auth.currentSession())
                  .getAccessToken()
                  .getJwtToken()}`,
              };
            },
            endpoint:
              "https://8mfqbpfm8h.execute-api.ap-southeast-2.amazonaws.com/dev/",
            name: "api",
          },
          {
            custom_header: async () => {
              return {
                Authorization: `${(await Auth.currentSession())
                  .getAccessToken()
                  .getJwtToken()}`,
              };
            },
            endpoint:
              "https://3m4897j9xg.execute-api.ap-southeast-2.amazonaws.com/dev/",
            name: "geo",
          },
        ],
      },
      Auth: {
        identityPoolId: "ap-southeast-2:2cbee5ed-7b73-4ede-b905-bf78dfb6e434",
        mandatorySignIn: true,
        oauth: {
          domain: cognitoDomain,
          redirectSignIn: window.location.origin,
          redirectSignOut: window.location.origin,
          responseType: "code",
          scope: ["email", "openid", "profile"],
        },
        region: "ap-southeast-2",
        storage: browserStorage,
        userPoolId: userPoolId,
        userPoolWebClientId: userPoolClientId,
      },
      Storage: {
        AWSS3: {
          bucket: BUCKET_NAME,
          region: "ap-southeast-2",
        },
        customPrefix: {
          private: "",
          protected: "",
          public: "",
        },
      },
    };
  } else if (isLocalHostSAML()) {
    const userPoolId = "ap-southeast-2_VTYVLMnTm";
    const userPoolClientId = "ve07pu1psaad5icfub86e1554";
    const cognitoDomain =
      "dev-social-pro-saml-signin.auth.ap-southeast-2.amazoncognito.com";
    return {
      API: {
        endpoints: [
          {
            custom_header: async () => {
              return {
                Authorization: `${(await Auth.currentSession())
                  .getAccessToken()
                  .getJwtToken()}`,
              };
            },
            endpoint:
              "https://8mfqbpfm8h.execute-api.ap-southeast-2.amazonaws.com/dev/",
            name: "api",
          },
          {
            custom_header: async () => {
              return {
                Authorization: `${(await Auth.currentSession())
                  .getAccessToken()
                  .getJwtToken()}`,
              };
            },
            endpoint:
              "https://3m4897j9xg.execute-api.ap-southeast-2.amazonaws.com/dev/",
            name: "geo",
          },
        ],
      },
      Auth: {
        identityPoolId: "ap-southeast-2:66c655da-73bf-4921-a5ff-f451c0b85f11",
        mandatorySignIn: true,
        oauth: {
          domain: cognitoDomain,
          redirectSignIn: window.location.origin,
          redirectSignOut: window.location.origin,
          responseType: "code",
          scope: ["email", "openid", "profile"],
        },
        region: "ap-southeast-2",
        storage: browserStorage,
        userPoolId: userPoolId,
        userPoolWebClientId: userPoolClientId,
      },
      Storage: {
        AWSS3: {
          bucket: BUCKET_NAME,
          region: "ap-southeast-2",
        },
        customPrefix: {
          private: "",
          protected: "",
          public: "",
        },
      },
    };
  } else if (isProdEnv()) {
    const userPoolId = "ap-southeast-2_ax9BilHXQ";
    const userPoolClientId = "26l7l8n3747i1hffg34e1isctf";
    return {
      API: {
        endpoints: [
          {
            custom_header: async () => {
              return {
                Authorization: `${(await Auth.currentSession())
                  .getAccessToken()
                  .getJwtToken()}`,
              };
            },
            endpoint:
              "https://8mfqbpfm8h.execute-api.ap-southeast-2.amazonaws.com/dev/",
            name: "api",
          },
          {
            custom_header: async () => {
              return {
                Authorization: `${(await Auth.currentSession())
                  .getAccessToken()
                  .getJwtToken()}`,
              };
            },
            endpoint:
              "https://3m4897j9xg.execute-api.ap-southeast-2.amazonaws.com/dev/",
            name: "geo",
          },
        ],
      },
      Auth: {
        authenticationFlowType: "USER_SRP_AUTH",
        identityPoolId: "ap-southeast-2:5a6ce371-b239-4187-9aa9-00e89e1b3a50",
        mandatorySignIn: true,
        region: "ap-southeast-2",
        storage: browserStorage,
        userPoolId: userPoolId,
        userPoolWebClientId: userPoolClientId,
      },
      Storage: {
        AWSS3: {
          bucket: BUCKET_NAME,
          region: "ap-southeast-2",
        },
        customPrefix: {
          private: "",
          protected: "",
          public: "",
        },
      },
    };
  }
  const userPoolId = "ap-southeast-2_wFuraZnLs";
  const userPoolClientId = "358am0h5s4he0392f6g0aedd5";
  return {
    API: {
      endpoints: [
        {
          custom_header: async () => {
            return {
              Authorization: `${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            };
          },
          endpoint:
            "https://9hjkyzjozi.execute-api.ap-southeast-2.amazonaws.com/dev/",
          name: "api",
        },
        {
          custom_header: async () => {
            return {
              Authorization: `${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            };
          },
          endpoint:
            "https://bkzusr7l8i.execute-api.ap-southeast-2.amazonaws.com/dev/",
          name: "geo",
        },
      ],
    },
    Auth: {
      authenticationFlowType: "USER_SRP_AUTH",
      identityPoolId: "ap-southeast-2:c9b4e82b-47e9-42a8-87bf-a49bf4d2c9d7",
      mandatorySignIn: true,
      region: "ap-southeast-2",
      storage: browserStorage,
      userPoolId: userPoolId,
      userPoolWebClientId: userPoolClientId,
    },
    Storage: {
      AWSS3: {
        bucket: BUCKET_NAME,
        region: "ap-southeast-2",
      },
      customPrefix: {
        private: "",
        protected: "",
        public: "",
      },
    },
  };
};
