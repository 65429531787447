import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
} from "@mui/material";
import { ProfileProjectAccessLevel } from "social-pro-common/entities/profileProjectAccess";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import {
  ProfileProjectAccessLineItem,
  createDefaultProfileProjectAccessLineItem,
  profileProjectAccessLevelToString,
} from "social-pro-common/interfaces/profileProjectAccess";
import { ProjectDetailLineItem } from "social-pro-common/interfaces/project";

interface ProfileGroupTableRowProps {
  profile: ProfileLineItem;
  projects: ProjectDetailLineItem[];
  selectedAccessLevel?: ProfileProjectAccessLevel;
  profileProjectAccess: ProfileProjectAccessLineItem[];
  handleUpdateAccess: (
    profileProjectAccess: ProfileProjectAccessLineItem,
  ) => void;
}

export const ProfileGroupTableRow = ({
  handleUpdateAccess,
  profile,
  profileProjectAccess,
  projects,
  selectedAccessLevel,
}: ProfileGroupTableRowProps) => {
  const projectCells = projects.map((project, index) => {
    const ppa = profileProjectAccess.find((p) => p.projectId === project.id);

    const handleChange = (event: SelectChangeEvent) => {
      if (ppa) {
        handleUpdateAccess({
          ...ppa,
          accessLevel: event.target.value as ProfileProjectAccessLevel,
        });
      } else {
        handleUpdateAccess({
          ...createDefaultProfileProjectAccessLineItem(
            project.id,
            profile.organisationId,
            profile.id,
          ),
          accessLevel: event.target.value as ProfileProjectAccessLevel,
          created: new Date(),
        });
      }
    };

    return (
      <TableCell key={`${project.id}-${index}`}>
        {!selectedAccessLevel || ppa?.accessLevel === selectedAccessLevel ? (
          <FormControl fullWidth>
            <Select
              value={ppa?.accessLevel || ProfileProjectAccessLevel.NoAccess}
              onChange={handleChange}
              sx={{
                maxWidth: "10vw",
              }}
            >
              {Object.values(ProfileProjectAccessLevel).map((level) => {
                return (
                  <MenuItem value={level} key={level}>
                    {profileProjectAccessLevelToString(level)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : null}
      </TableCell>
    );
  });
  return (
    <>
      <TableRow key={profile.id}>
        <TableCell align="left" scope="row" width={"10%"} key={0}>
          {`${profile.name}`}
        </TableCell>
        {projectCells}
      </TableRow>
    </>
  );
};
