import { useSocialSpend } from "@hooks/crud/socialSpend/useSocialSpend";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { SocialSpendTable } from "@stories/molecules/SocialSpendTable/SocialSpendTable";
import SpendImport, {
  ImportStep,
} from "@stories/molecules/SpendImport/SpendImport";
import SocialSpendModal from "@stories/organisms/SocialSpendModal/SocialSpendModal";
import { ChangeEvent, FocusEvent, useState } from "react";
import { PackageCommentType } from "social-pro-common/entities/packageComment";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { packageCommentTypeToString } from "social-pro-common/interfaces/packageComment";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";

interface SocialSpendProps {
  isLoading: boolean;
  userProfile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  selectedProject?: ProjectLineItem;
  contractorPackageId?: string;
  reportId?: string;
  commentMap: Map<PackageCommentType, string>;
  isSubmitting: boolean;
  readOnly?: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  updateComment: (
    packageType: PackageCommentType,
    comment: string,
    save: boolean,
  ) => Promise<void>;
  handlOnBack?: () => void;
}

export const SocialSpends = ({
  commentMap,
  contractorPackageId,
  handlOnBack,
  isLoading,
  isSubmitting,
  readOnly,
  reportId,
  selectedProject,
  setIsSubmitting,
  updateComment,
  userOrganisation,
  userProfile,
}: SocialSpendProps) => {
  const [openImportModal, setOpenImportModal] = useState(false);
  const [step, setStep] = useState<ImportStep>(ImportStep.Init);

  const [open, setOpen] = useState(false);
  const {
    createSocialSpend,
    deleteSocialSpends,
    isSocialSpendLoading,
    socialSpends,
    updateSocialSpend,
    upsertSocialSpends,
  } = useSocialSpend(selectedProject?.id, reportId, contractorPackageId);

  const [selectedSocialSpend, setSelectedSocialSpend] =
    useState<SocialSpendLineItem>();

  const handleClose = () => {
    setOpen(false);
    setSelectedSocialSpend(undefined);
  };

  const handleOpen = () => {
    setOpen(true);
    setSelectedSocialSpend(undefined);
  };

  const handleDeleteSocialSpends = (socialSpends: SocialSpendLineItem[]) => {
    deleteSocialSpends(socialSpends);
  };

  const handleEditSocialSpend = (socialSpends: SocialSpendLineItem) => {
    setSelectedSocialSpend(socialSpends);
    setOpen(true);
  };

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>();
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : undefined);
  };

  const handleOpenImportModal = () => {
    setOpenImportModal(true);
  };
  const filteredSocialSpends =
    socialSpends?.filter((c) =>
      c.supplier.toLowerCase().includes(inputValue.toLowerCase()),
    ) || [];
  const commentValue = commentMap.get(PackageCommentType.Spend) || "";

  const loading = isLoading || isSocialSpendLoading;

  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          margin: "0",
          padding: "30px 40px 0",
          width: "100%",
        }}
        className="borderTop"
      >
        <Grid item xs={12} md={6}>
          {loading ? (
            <Stack direction="row" spacing={2}>
              {handlOnBack ? (
                <Skeleton variant="circular">
                  <IconButton color="primary" size="large">
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                </Skeleton>
              ) : null}
              <Stack>
                <Skeleton animation="wave">
                  <Typography variant="h2">
                    {"Project Reporting 2000"}
                  </Typography>
                </Skeleton>
                <Skeleton animation="wave">
                  <Typography variant="subtitle1">
                    {"September 2000"}
                  </Typography>
                </Skeleton>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              {handlOnBack ? (
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Back to package details"
                >
                  <IconButton
                    data-test-id="back-button-top"
                    size="large"
                    onClick={handlOnBack}
                    sx={{
                      color: "black",
                      height: "auto",
                      padding: "5px",
                      width: "auto",
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              <Stack>
                <Typography variant="h2">{`Spend Reporting`}</Typography>
                <Typography variant="subtitle1">
                  {`${selectedProject?.projectName}: ${
                    reportId ? formatReportDate(stringToDate(reportId)) : ""
                  }`}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignItems: "center",
            display: "flex",
            gap: "20px",
            justifyContent: "flex-end",
            textAlign: "right",
          }}
        >
          <SearchInput
            loading={loading}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleSearchChange={handleSearchChange}
          />
          {!readOnly ? (
            <>
              <StyledButton
                className="blackBtn"
                loading={loading}
                variant="contained"
                onClick={handleOpenImportModal}
                startIcon={<CloudUploadIcon />}
              >
                Import
              </StyledButton>
              <StyledButton
                data-test-id="add-spend-button"
                loading={loading}
                variant="contained"
                onClick={handleOpen}
                disabled={loading}
                className="blackBtn"
              >
                {"Add Spend"}
              </StyledButton>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Grid container sx={{ margin: "0", padding: "0px 40px 40px" }}>
        <Grid item xs={12} md={12} sx={{ paddingTop: "30px" }}>
          <SocialSpendTable
            loading={loading && !isSubmitting}
            project={selectedProject}
            socialSpends={filteredSocialSpends}
            handleDeleteSocialSpend={handleDeleteSocialSpends}
            filterName={searchValue}
            handleEditSocialSpend={handleEditSocialSpend}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledTextField
            data-test-id="spend-comments-input"
            variant="outlined"
            loading={loading && !isSubmitting}
            disabled={loading || isSubmitting || readOnly}
            id={`${packageCommentTypeToString(PackageCommentType.Spend)}`}
            name={`${packageCommentTypeToString(PackageCommentType.Spend)}`}
            label={`${packageCommentTypeToString(
              PackageCommentType.Spend,
            )} Comments`}
            fullWidth
            maxLength={250}
            rows={5}
            multiline
            margin="dense"
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await updateComment(
                PackageCommentType.Spend,
                e.target.value,
                false,
              );
            }}
            onBlur={async (e: FocusEvent<HTMLInputElement>) => {
              setIsSubmitting(true);
              await updateComment(
                PackageCommentType.Spend,
                e.target.value,
                true,
              );
              setIsSubmitting(false);
            }}
            value={commentValue}
          />
        </Grid>
      </Grid>
      {openImportModal && reportId ? (
        <SpendImport
          open={openImportModal}
          step={step}
          userProfile={userProfile}
          userOrganisation={userOrganisation}
          setIsOpen={setOpenImportModal}
          setStep={setStep}
          reportId={reportId}
          upsertSocialSpend={upsertSocialSpends}
        />
      ) : null}
      {open &&
      selectedProject &&
      contractorPackageId &&
      userOrganisation &&
      reportId ? (
        <SocialSpendModal
          open={open}
          reportId={reportId}
          loading={loading}
          project={selectedProject}
          contractorOrganisation={userOrganisation}
          contractorPackageId={contractorPackageId}
          existingSocialSpend={selectedSocialSpend}
          createSocialSpend={createSocialSpend}
          updateSocialSpend={updateSocialSpend}
          handleClose={handleClose}
        />
      ) : null}
    </>
  );
};
