"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseMultiplierForHours = exports.createDefaultLabourHourCommitment = exports.createDefaultLabourHourCommitmentsForProject = exports.defaultLabourHourCommmimentCriteria = exports.LOCAL_JOBS_FIRST_DISTANCE = void 0;
var uuid_1 = require("uuid");
var contractorEmployee_1 = require("../../entities/contractorEmployee");
var financial_1 = require("../../entities/financial");
var packageLabourHourCommitment_1 = require("../packageLabourHourCommitment");
exports.LOCAL_JOBS_FIRST_DISTANCE = 50;
exports.defaultLabourHourCommmimentCriteria = (_a = {},
    _a[packageLabourHourCommitment_1.SocialCommitmentTypeHour.AboriginalTorresStraitIslanderParticipation] = {
        colour: "#FFAB91",
        employmentLevel: [],
        gender: [],
        maxDistance: undefined,
        socialFactors: [contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander],
        targetValue: 4.1,
    },
    _a[packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepManagementHours] = {
        colour: "#15cf74",
        employmentLevel: [],
        gender: [contractorEmployee_1.Gender.Female],
        maxDistance: undefined,
        occupationType: [contractorEmployee_1.OccupationType.ManagementSupervisorSpecialistLabourRoles],
        socialFactors: [],
        targetValue: 35,
    },
    _a[packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepNonTradeHours] = {
        colour: "#15cf74",
        employmentLevel: [],
        gender: [contractorEmployee_1.Gender.Female],
        maxDistance: undefined,
        occupationType: [contractorEmployee_1.OccupationType.NonTradePosition],
        socialFactors: [],
        targetValue: 7,
    },
    _a[packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepTradeHours] = {
        colour: "#15cf74",
        employmentLevel: [],
        gender: [contractorEmployee_1.Gender.Female],
        maxDistance: undefined,
        occupationType: [contractorEmployee_1.OccupationType.TradeRole],
        socialFactors: [],
        targetValue: 3,
    },
    _a[packageLabourHourCommitment_1.SocialCommitmentTypeHour.FemaleApprenticeAndTrainee] = {
        colour: "#28A745",
        employmentLevel: [contractorEmployee_1.EmploymentLevel.Apprentice, contractorEmployee_1.EmploymentLevel.Trainee],
        gender: [contractorEmployee_1.Gender.Female],
        maxDistance: undefined,
        socialFactors: [],
        targetValue: 4,
    },
    _a[packageLabourHourCommitment_1.SocialCommitmentTypeHour.FemaleParticipation] = undefined,
    _a[packageLabourHourCommitment_1.SocialCommitmentTypeHour.LocalJobsFirst] = {
        colour: "#90CAF9",
        employmentLevel: [],
        gender: [],
        maxDistance: exports.LOCAL_JOBS_FIRST_DISTANCE,
        socialFactors: [],
        targetValue: 90,
    },
    _a[packageLabourHourCommitment_1.SocialCommitmentTypeHour.MPSGHoursRequirement] = {
        colour: "#3f99fa",
        employmentLevel: [
            contractorEmployee_1.EmploymentLevel.Apprentice,
            contractorEmployee_1.EmploymentLevel.Trainee,
            contractorEmployee_1.EmploymentLevel.Cadet,
        ],
        gender: [],
        maxDistance: undefined,
        socialFactors: [],
        targetValue: 10,
    },
    _a[packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce] = {
        colour: "#1565C0",
        employmentLevel: [],
        gender: [],
        maxDistance: undefined,
        socialFactors: [
            contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee,
            contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth,
            contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed,
            contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability,
            contractorEmployee_1.EmployeeSocialFactor.Veteran,
            contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence,
            contractorEmployee_1.EmployeeSocialFactor.ExOffender,
        ],
        targetValue: 2,
    },
    _a);
var createDefaultLabourHourCommitmentsForProject = function (projectId) {
    return Object.values(packageLabourHourCommitment_1.SocialCommitmentTypeHour).reduce(function (acc, sc) {
        if (sc !== packageLabourHourCommitment_1.SocialCommitmentTypeHour.DefaultHour) {
            var commitmentConfiguration = exports.defaultLabourHourCommmimentCriteria[sc];
            if (commitmentConfiguration) {
                return __spreadArray(__spreadArray([], acc, true), [
                    __assign(__assign({}, commitmentConfiguration), { achievedValue: 0, created: new Date(), id: (0, uuid_1.v4)(), projectId: projectId, targetName: sc, targetValueRealised: 0 }),
                ], false);
            }
        }
        return __spreadArray([], acc, true);
    }, []);
};
exports.createDefaultLabourHourCommitmentsForProject = createDefaultLabourHourCommitmentsForProject;
var createDefaultLabourHourCommitment = function (projectId) {
    return {
        achievedValue: 0,
        colour: "#000000",
        created: new Date(),
        employmentLevel: [],
        gender: [],
        id: (0, uuid_1.v4)(),
        maxDistance: undefined,
        projectId: projectId,
        projectSocialSpendCommitmentId: (0, uuid_1.v4)(),
        socialFactors: [],
        targetName: packageLabourHourCommitment_1.SocialCommitmentTypeHour.DefaultHour,
        targetValue: 0,
        targetValueRealised: 0,
    };
};
exports.createDefaultLabourHourCommitment = createDefaultLabourHourCommitment;
var getBaseMultiplierForHours = function (financial, c) {
    return financial.projectEstimateMethod === financial_1.EstimateType.DeemedHours
        ? financial.requiredHours
        : (function () {
            switch (c.targetName) {
                default:
                    return financial.estimatedTotalWorkHours;
                case packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepTradeHours:
                    return financial.estimatedBepHoursTrade;
                case packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepNonTradeHours:
                    return financial.estimatedBepHoursNonTrade;
                case packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepManagementHours:
                    return financial.estimatedBepHoursManagement;
            }
        })();
};
exports.getBaseMultiplierForHours = getBaseMultiplierForHours;
