import { useAuthContext } from "@hooks/context/useAuthContext";
import {
  Box,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import * as Yup from "yup";

const RegisterForm = () => {
  const { isAuthLoading, signUp } = useAuthContext();

  const [success, setSuccess] = useState(false);
  return (
    <Formik
      initialValues={{
        email: "",
        isContractor: false,
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          // .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        isContractor: Yup.bool(),
        password: Yup.string().required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const emailAddress = values.email.toLowerCase();
          await signUp(
            emailAddress,
            values.password.trim(),
            values.isContractor,
          );

          setSuccess(true);
          setSubmitting(false);
        } catch (err: any) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          toast("Something went wrong!", { type: "error" } as ToastOptions);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
                sx={{ margin: "auto" }}
              >
                <TextField
                  id="email"
                  type="email"
                  label="Email"
                  disabled={isSubmitting}
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="info@socialproapp.com"
                />
                {touched.email && errors.email && (
                  <FormHelperText error>{errors.email}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
                sx={{ margin: "auto" }}
              >
                <TextField
                  id="password"
                  type="password"
                  label="Password"
                  disabled={isSubmitting}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="*******"
                />
                {touched.password && errors.password && (
                  <FormHelperText error>{errors.password}</FormHelperText>
                )}
              </FormControl>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={isAuthLoading}
                          checked={values.isContractor}
                          onChange={(event, checked) => {
                            setFieldValue("isContractor", checked);
                          }}
                        />
                      }
                      label={"Contractor"}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            {errors.submit && (
              <Grid item xs={12}>
                <Box sx={{ justifyContent: "center", margin: "auto" }}>
                  <FormHelperText
                    error
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <Typography align="center">{errors.submit}</Typography>
                  </FormHelperText>
                </Box>
              </Grid>
            )}
            {success && (
              <Grid item xs={12}>
                <Box sx={{ justifyContent: "center", margin: "auto" }}>
                  <FormHelperText
                    error
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <Typography data-test-id="register-success" align="center">
                      {"REGISTERED"}
                    </Typography>
                  </FormHelperText>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ fontFamily: "Roboto", textAlign: "center" }}>
                <Button
                  id="submit_button"
                  disabled={isSubmitting || isAuthLoading}
                  variant={"contained"}
                  fullWidth
                  type="submit"
                  size="large"
                  sx={{
                    background: "#054F81",
                    margin: "0",
                    minWidth: "200px",
                    transition: "0.3s all",
                    width: "auto",
                  }}
                >
                  Register
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default RegisterForm;
