import { useReportSocialSubmission } from "@hooks/crud/reportSocialSubmission/useReportSocialSubmission";
import { Table, TableBody } from "@mui/material";
import { FileGenerationStatus } from "social-pro-common/entities/fileGeneration";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

import { ReportSubmssionTableRow } from "./ReportSubmissionTableRow";
import { ReportSubmissionTableSkeleton } from "./ReportSubmissionTableSkeleton";
import ListTableHead from "../ListTable/ListTableHead";
import { NoResultsFoundRow } from "../ListTable/NoResultsFoundRow";
import { NoRows } from "../ListTable/NoRows";
import { PdfDownlodDialog } from "../PdfDownlodDialog/PdfDownlodDialog";


interface ReportSubmissionTableProps {
  loading: boolean;
  project?: ProjectLineItem;
  contractorPackages: ContractorPackageLineItem[];
  reportSubmissions: ReportSubmissionLineItem[];
  reportId?: string;
  filterName?: string;
  downloadStep: FileGenerationStatus;
  downloadProgress: number;
  downloadPdf: (
    projectId: string,
    reportId: string,
    contractorPackageId?: string,
  ) => void;
  viewReadOnlyReport: (reportMonthId: string, rowId: string) => void;
  deleteReportSubmission: (report: ReportSubmissionLineItem) => Promise<void>;
  updateReportSubmission: (report: ReportSubmissionLineItem) => Promise<void>;
}

export const ReportSubmissionTable = ({
  contractorPackages,
  deleteReportSubmission,
  downloadPdf,
  downloadProgress,
  downloadStep,
  filterName,
  loading,
  project,
  reportId,
  reportSubmissions,
  updateReportSubmission,
  viewReadOnlyReport,
}: ReportSubmissionTableProps) => {
  const { sendReportReminder } = useReportSocialSubmission();

  const filteredContractors = contractorPackages.filter((c) =>
    filterName
      ? c.contractorName.toLowerCase().includes(filterName.toLowerCase())
      : true,
  );

  const isNotFound = !contractorPackages.length && Boolean(filterName);

  const TABLE_HEAD: TableHeaderCell[] = [
    {
      align: "left",
      id: "package-number",
      label: "Package Number",
      width: "20%",
    },
    {
      align: "left",
      id: "contractor-name",
      label: "Contractor Name",
      width: "20%",
    },
    {
      align: "left",
      id: "package-type",
      label: "Package Type",
      width: "10%",
    },
    {
      align: "center",
      id: "progress",
      label: "Progress",
      width: "15%",
    },
    {
      align: "center",
      id: "status",
      label: "Status",
      width: "15%",
    },
    {
      align: "center",
      id: "controls",
      label: "",
      width: "20%",
    },
  ];

  return (
    <>
      {loading ? (
        <Table>
          <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <ReportSubmissionTableSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : null}
      {!loading && project && reportId && filteredContractors ? (
        <Table data-test-id="report-submissions-table">
          <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
          <TableBody>
            {filteredContractors.map((row) => {
              return (
                <ReportSubmssionTableRow
                  key={row.id}
                  project={project}
                  contractorPackage={row}
                  reportSubmissions={reportSubmissions}
                  reportId={reportId}
                  handleDownloadReport={(contractorPackageId: string) => {
                    downloadPdf(project.id, contractorPackageId);
                  }}
                  handleRevertReport={async (
                    reportSubmission: ReportSubmissionLineItem,
                    rejectMessage: string,
                  ): Promise<void> => {
                    await deleteReportSubmission({
                      ...reportSubmission,
                      rejectMessage,
                    });
                  }}
                  handleAcceptReport={async (
                    reportSubmission: ReportSubmissionLineItem,
                  ): Promise<void> => {
                    await updateReportSubmission({
                      ...reportSubmission,
                      accepted: true,
                      reportStatus: ReportStatus.Accepted,
                    });
                  }}
                  handlViewReport={viewReadOnlyReport}
                  sendReportReminder={sendReportReminder}
                />
              );
            })}
            {contractorPackages.length === 0 && !loading ? (
              <NoRows colSpan={6} title={"Reports"} />
            ) : null}

            {isNotFound && filterName && (
              <NoResultsFoundRow colSpan={6} filterName={filterName} />
            )}
            {loading
              ? Array.from({ length: 10 }, (_, index) => (
                  <ReportSubmissionTableSkeleton key={index} />
                ))
              : null}
          </TableBody>
        </Table>
      ) : null}
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Export Report"}
      />
    </>
  );
};
