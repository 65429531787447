import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
  Button,
  Stack,
} from "@mui/material";

export enum SupportServicesTabOptions {
  Tutorials = "support-services-tab_tutorial",
  Faqs = "support-services-tab_faqs",
  Defination = "support-services-tab_defination",
}

interface SupportServicesTabsProps {
  isLoading: boolean;
  value: SupportServicesTabOptions;
  handleChange: (
    event: React.SyntheticEvent,
    newValue?: SupportServicesTabOptions,
  ) => void;
}

export const SupportServicesTabs = ({
  handleChange,
  isLoading,
  value,
}: SupportServicesTabsProps): JSX.Element => {
  return (
    <Box>
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>FAQs</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Tutorials</Button>
          </Skeleton>

          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Definitions</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value={SupportServicesTabOptions.Faqs} size="small">
            FAQs
          </ToggleButton>
          <ToggleButton
            value={SupportServicesTabOptions.Tutorials}
            size="small"
          >
            Tutorials
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
