import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";
import { Steps } from "@stories/atoms/Steps/Steps";

const steps = [
  { label: "Project" },
  { label: "Target Hours" },
  { label: "Target Spend" },
  { label: "Local Content" },
  { label: "Policies" },
  { label: "Sustainability" },
];

interface ProjectStepsProps {
  loading: boolean;
  activeStep: number;
}

export const ProjectSteps = ({ activeStep, loading }: ProjectStepsProps) => {
  if (loading) {
    return (
      <Skeleton animation="wave" sx={{ margin: "auto" }}>
        <Steps activeStep={activeStep} steps={steps} />
      </Skeleton>
    );
  }
  return <Box
    sx={{
      // position: "sticky",
      // top: "0px",
      zIndex: 9
    }}>
    <Steps activeStep={activeStep} steps={steps} />
  </Box>
};
