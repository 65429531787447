import { usePackageLocalContent } from "@hooks/crud/packageLocalContent/usePackageLocalContent";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableHead,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import LidpModal from "@stories/organisms/LidpModal/LidpModal";
import { ChangeEvent, FocusEvent, useState } from "react";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { PackageCommentType } from "social-pro-common/entities/packageComment";
import { packageCommentTypeToString } from "social-pro-common/interfaces/packageComment";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";

import { LidpTableRow } from "../LidpTableRow/LidpTableRow";
import { LidpTableRowSkeleton } from "../LidpTableRow/LidpTableRowSkeleton";
import { LidpTableSumRow } from "../LidpTableSumRow/LidpTableSumRow";
import { LidpTabs } from "../LidpTabs/LidpTabs";
import { NoRows } from "../ListTable/NoRows";


interface PackageLidpSubmissionFormProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  isPrimaryPackage: boolean;
  isSubmitting: boolean;
  contractorPackageId?: string;
  reportId?: string;
  commentMap: Map<PackageCommentType, string>;
  readOnly?: boolean;
  packageValue: number;
  packageType: PackageType;
  updateComment: (
    commentType: PackageCommentType,
    comment: string,
    save: boolean,
  ) => Promise<void>;
  handlOnBack?: () => void;
}

export const PackageLidpSubmissionForm = ({
  commentMap,
  contractorPackageId,
  handlOnBack,
  isLoading,
  isPrimaryPackage,
  isSubmitting,
  packageType,
  packageValue,
  project,
  readOnly,
  reportId,
  updateComment,
}: PackageLidpSubmissionFormProps) => {
  const [open, setOpen] = useState(false);
  const [selectedLocalContent, setSelectedLocalContent] = useState<
    PackageLocalContentLineItem | undefined
  >();
  const {
    createPackageLocalContent,
    deletePackageLocalContents,
    isPackageLocalContentLoading,
    packageLocalContents,
    updatePackageLocalContent,
  } = usePackageLocalContent(contractorPackageId, reportId);

  const handleCreatePackageLocalContent = async (
    localContent: PackageLocalContentLineItem[],
  ) => {
    //TODO: Fix this so it handles multiple lc
    for (const lc of localContent) {
      await createPackageLocalContent(lc);
    }
  };

  const handleUpdatePackageLocalContent = async (
    localContent: PackageLocalContentLineItem[],
  ) => {
    //TODO: Fix this so it handles multiple lc
    for (const lc of localContent) {
      await updatePackageLocalContent(lc);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const showExpected = value === 1;

  let itemsToDisplay = showExpected
    ? project?.localContents || []
    : packageLocalContents || [];

  itemsToDisplay = itemsToDisplay.sort((a, b) => {
    return a.itemDescription.localeCompare(b.itemDescription);
  });

  const commentValue = commentMap.get(PackageCommentType.LocalContent) || "";

  const loading = isLoading || isPackageLocalContentLoading;

  const tableHeader = (
    <TableHead>
      <TableHeader loading={loading} title={"Item Description"} align="left" />
      <TableHeader loading={loading} title={"Manufacturer"} align="left" />
      <TableHeader loading={loading} title={"Supplier"} align="left" />
      <TableHeader loading={loading} title={"Value ($)"} />
      <TableHeader loading={loading} title={"Steel Mass (T)"} />
      <TableHeader loading={loading} title={"Local Value Add"} />
      <TableHeader loading={loading} title={"Import Value Add"} />
      <TableHeader loading={loading} title={"Contract Content"} />
      <TableHeader loading={loading} title={"ANZ value-add activity (%)"} />
      <TableHeader loading={loading} title={"SME Count"} />
      <TableHeader loading={loading} title={"Supplier Count"} />
    </TableHead>
  );
  return (
    <>
      <Grid
        container
        sx={{ margin: "0", padding: "30px 40px 0" }}
        className="borderTop"
      >
        <Grid item md={8}>
          {loading ? (
            <Stack direction="row" spacing={2}>
              {handlOnBack ? (
                <Skeleton variant="circular">
                  <IconButton color="primary" size="large">
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                </Skeleton>
              ) : null}
              <Stack>
                <Skeleton animation="wave">
                  <Typography variant="h2">
                    {"Local Content Reporting 2000"}
                  </Typography>
                </Skeleton>
                <Skeleton animation="wave">
                  <Typography variant="subtitle1">
                    {"September 2000"}
                  </Typography>
                </Skeleton>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              {handlOnBack ? (
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Back to package details"
                >
                  <IconButton
                    data-test-id="back-button-top"
                    size="large"
                    onClick={handlOnBack}
                    sx={{
                      color: "black",
                      height: "auto",
                      padding: "5px",
                      width: "auto",
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              <Stack>
                <Typography variant="h2">{`Local Content Reporting`}</Typography>
                <Typography variant="subtitle1">
                  {`${project?.projectName}: ${
                    reportId ? formatReportDate(stringToDate(reportId)) : ""
                  }`}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            alignContent: "right",
            display: "flex",
            justifyContent: "right",
            margin: "auto",
          }}
          justifyContent={"right"}
        >
          {!showExpected && !readOnly ? (
            <StyledButton
              data-test-id="add-localContent-button"
              loading={loading}
              variant="contained"
              onClick={() => {
                if (project) {
                  setOpen(true);
                }
              }}
              className="blackBtn"
            >
              Add Local Content
            </StyledButton>
          ) : null}
        </Grid>
      </Grid>
      <Grid container sx={{ margin: "0", padding: "0px 40px 40px" }}>
        <Grid item xs={12} md={6} sx={{ paddingTop: "30px" }}>
          {isPrimaryPackage && contractorPackageId && packageType ? (
            <LidpTabs
              isLoading={loading}
              value={value}
              handleChange={handleChange}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          {loading ? (
            <Table>
              {tableHeader}
              <TableBody>
                {Array.from({ length: 10 }, (_, index) => (
                  <LidpTableRowSkeleton key={index} />
                ))}
              </TableBody>
            </Table>
          ) : (
            <Table data-test-id="localContent-commitment-table">
              {tableHeader}
              <TableBody>
                {itemsToDisplay.map((lc) => {
                  const handleEdit = () => {
                    setSelectedLocalContent(lc);
                    setOpen(true);
                  };
                  return (
                    <LidpTableRow
                      key={`local-content-table-row-${lc.id}`}
                      loading={loading}
                      reportId={reportId}
                      localContent={lc}
                      showExpected={showExpected}
                      readOnly={readOnly}
                      handleEdit={handleEdit}
                      onDelete={() => {
                        deletePackageLocalContents([lc]);
                      }}
                    />
                  );
                })}
                {itemsToDisplay.length > 0 ? (
                  <LidpTableSumRow
                    localContents={itemsToDisplay}
                    hasButtonColumn
                  />
                ) : (
                  <NoRows title={"Local Content"} colSpan={11} />
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"flex-start"}
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold" }}>
              * Please ensure the total value of local content items is equal to
              the value of monthly progress claims
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <StyledTextField
            data-test-id="localContent-comment-input"
            loading={loading}
            disabled={loading || isSubmitting || readOnly}
            id={`${packageCommentTypeToString(
              PackageCommentType.LocalContent,
            )}`}
            name={`${packageCommentTypeToString(
              PackageCommentType.LocalContent,
            )}`}
            label={`${packageCommentTypeToString(
              PackageCommentType.LocalContent,
            )} Comments`}
            fullWidth
            rows={5}
            maxLength={250}
            multiline
            margin={"dense"}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await updateComment(
                PackageCommentType.LocalContent,
                e.target.value,
                false,
              );
            }}
            onBlur={async (e: FocusEvent<HTMLInputElement>) => {
              await updateComment(
                PackageCommentType.LocalContent,
                e.target.value,
                true,
              );
            }}
            value={commentValue}
            className="textareaBlack"
          />
        </Grid>
      </Grid>

      {project && contractorPackageId && open ? (
        <LidpModal
          open={open}
          loading={loading}
          projectId={project.id}
          packageId={contractorPackageId}
          isCreateModal={false}
          reportId={reportId}
          isPrimaryPackage={isPrimaryPackage}
          packageValue={packageValue}
          existingLocalContent={selectedLocalContent}
          createLocalContent={handleCreatePackageLocalContent}
          updateLocalContent={handleUpdatePackageLocalContent}
          handleClose={handleClose}
        />
      ) : null}
    </>
  );
};
