import { listApiData, postApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState, useEffect } from "react";
import {
  decodeGreenMetricCategorySubmission,
  encodeGreenMetricCategorySubmission,
} from "social-pro-common/decoders/greenMetricCategorySubmission";
import { GreenMetricCategorySubmission } from "social-pro-common/entities/greenMetricCategorySubmission";
import { GreenMetricCategorySubmissionLineItem } from "social-pro-common/interfaces/greenMetricCategorySubmission";

export const useGreenMetricCategorySubmission = (
  contractorPackageId?: string,
  reportId?: string,
) => {
  const [greenMetricCategorySubmissions, setGreenMetricCategorySubmissions] =
    useState<GreenMetricCategorySubmissionLineItem[]>([]);
  const [isGreenMetricCategorySubmissionLoading, setIsLoading] =
    useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listGreenMetricCategorySubmissions = useCallback(
    async (packageId: string, reportId?: string): Promise<void> => {
      try {
        setIsLoading(true);
        const greenMetricResult = await listApiData(
          "listGreenMetricCategorySubmissions",
          "greenMetricCategorySubmission",
          packageId,
          {
            reportId,
          },
        );
        setGreenMetricCategorySubmissions(
          greenMetricResult.data.map((p) =>
            decodeGreenMetricCategorySubmission(
              p as GreenMetricCategorySubmission,
            ),
          ),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not list greenMetrics");
      } finally {
        setIsLoading(false);
      }
    },
    [listApiData, decodeGreenMetricCategorySubmission, setGreenMetricCategorySubmissions, setIsLoading, catchSentryError],
  );

  const createGreenMetricCategorySubmission = useCallback(
    async (
      greenMetric: GreenMetricCategorySubmissionLineItem,
    ): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedGreenMetric =
          encodeGreenMetricCategorySubmission(greenMetric);
        const upsertedGreenMetric = await postApiData(
          "createGreenMetricCategorySubmission",
          "greenMetricCategorySubmission",
          encodedGreenMetric,
        );
        const decodedGreenMetric = decodeGreenMetricCategorySubmission(
          upsertedGreenMetric.data as GreenMetricCategorySubmission,
        );
        setGreenMetricCategorySubmissions((prevSubmissions) => [
          ...prevSubmissions,
          decodedGreenMetric,
        ]);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not create greenMetric");
      } finally {
        setIsLoading(false);
      }
    },
    [postApiData, encodeGreenMetricCategorySubmission, decodeGreenMetricCategorySubmission, setGreenMetricCategorySubmissions, setIsLoading, catchSentryError],
  );

  const updateGreenMetricCategorySubmission = useCallback(
    async (
      greenMetric: GreenMetricCategorySubmissionLineItem,
    ): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedGreenMetric =
          encodeGreenMetricCategorySubmission(greenMetric);
        await postApiData(
          "updateGreenMetricCategorySubmission",
          "greenMetricCategorySubmission",
          encodedGreenMetric,
        );
        setGreenMetricCategorySubmissions((prevSubmissions) =>
          prevSubmissions.map((g) =>
            g.id === greenMetric.id ? greenMetric : g,
          ),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not update greenMetric");
      } finally {
        setIsLoading(false);
      }
    },
    [postApiData, encodeGreenMetricCategorySubmission, setGreenMetricCategorySubmissions, setIsLoading, catchSentryError],
  );

  const deleteGreenMetricCategorySubmission = useCallback(
    async (
      greenMetric: GreenMetricCategorySubmissionLineItem,
    ): Promise<void> => {
      try {
        setIsLoading(true);
        const encodedGreenMetric =
          encodeGreenMetricCategorySubmission(greenMetric);
        await postApiData(
          "deleteGreenMetricCategorySubmission",
          "greenMetricCategorySubmission",
          encodedGreenMetric,
        );
        setGreenMetricCategorySubmissions((prevSubmissions) =>
          prevSubmissions.filter((p) => p.id !== greenMetric.id),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not delete greenMetric");
      } finally {
        setIsLoading(false);
      }
    },
    [postApiData, encodeGreenMetricCategorySubmission, setGreenMetricCategorySubmissions, setIsLoading, catchSentryError],
  );

  useEffect(() => {
    if (contractorPackageId && reportId) {
      listGreenMetricCategorySubmissions(contractorPackageId, reportId);
    }
  }, [contractorPackageId, reportId, listGreenMetricCategorySubmissions]);

  return {
    createGreenMetricCategorySubmission,
    deleteGreenMetricCategorySubmission,
    error,
    greenMetricCategorySubmissions,
    isGreenMetricCategorySubmissionLoading,
    listGreenMetricCategorySubmissions,
    updateGreenMetricCategorySubmission,
  };
};