import { Stack, Typography } from "@mui/material";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { packageTypeToString } from "social-pro-common/interfaces/contractorPackage";
import { NavItemType } from "types";

export const getProjectsNav = (
  projectName: string,
  packageType: PackageType,
): NavItemType => {
  return {
    children: [
      {
        breadcrumbs: false,
        id: "list-projects",
        static: true,
        title: (
          <Stack direction="column" spacing={1}>
            <Typography variant="body1" fontWeight={600}>
              {projectName || ""}
            </Typography>
            <Typography variant="caption">
              {packageType !== PackageType.PrimaryPackage
                ? packageTypeToString(packageType)
                : null}
            </Typography>
          </Stack>
        ),
        type: "item",
      },
    ],
    id: "projects",
    title: "",
    type: "group",
  };
};

{
  /* <Box style={{color:'#fff',borderRadius:'99px',padding:'8px 20px 10px',position:'absolute',left:'50%', 
width:'calc(100% - 26px)' ,transform:'translate(-50%)', background:'rgb(7 30 60 / 14%)', fontSize:'14px', fontWeight:'500', marginTop:'12px'}}>
  Project Name</Box> */
}
