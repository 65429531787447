import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePackageLocalContentAcc } from "@hooks/crud/packageLocalContent/usePackageLocalContentAcc";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MonthSelect } from "@stories/atoms/MonthSelect/MonthSelect";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { writeXlsxLidp } from "@utils/xlsx/localContent";
import { useState } from "react";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import { dateToString, stringToDate } from "social-pro-common/utils/date";

import { ContractorAnalyticsLocalContentRow } from "./PackageAnalyticsLocalContentRow";
import { ContractorAnalyticsLocalContentRowSkeleton } from "./PackageAnalyticsLocalContentRowSkeleton";
import { LidpTableSumRow } from "../LidpTableSumRow/LidpTableSumRow";
import { LidpTableSumRowSkeleton } from "../LidpTableSumRow/LidpTableSumRowSkeleton";
import { LidpTabs } from "../LidpTabs/LidpTabs";
import { NoRows } from "../ListTable/NoRows";

interface PackageAnalyticsLocalContentProps {
  loading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  localContents: PackageLocalContentLineItem[];
}

export const PackageAnalyticsLocalContent = ({
  contractorPackage,
  loading,
  localContents,
}: PackageAnalyticsLocalContentProps) => {
  const [reportId, setReportDate] = useState<string>(dateToString(new Date()));

  const [value, setValue] = useState(0);

  const { isPrimaryPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { isPackageLocalContentLoading, packageLocalContents } =
    usePackageLocalContentAcc(
      contractorPackage?.projectId,
      contractorPackage?.id,
      reportId,
    );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const showActual = value === 1;

  const itemsToDisplay = showActual
    ? localContents
    : packageLocalContents || [];

  const isLoading = loading || isPackageLocalContentLoading || isProjectLoading;

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableHeader
          loading={isLoading}
          title={"Item Description"}
          align="left"
        />
        <TableHeader loading={isLoading} title={"Manufacturer"} align="left" />
        <TableHeader loading={isLoading} title={"Supplier"} align="left" />
        <TableHeader loading={isLoading} title={"Value ($)"} align="center" />
        <TableHeader
          loading={isLoading}
          title={"Steel Mass (T)"}
          align="center"
        />
        <TableHeader
          loading={isLoading}
          title={"Local Value Add"}
          align="center"
        />
        <TableHeader
          loading={isLoading}
          title={"Import Value Add"}
          align="center"
        />
        <TableHeader
          loading={isLoading}
          title={"Contract Content"}
          align="center"
        />
        <TableHeader
          loading={isLoading}
          title={"ANZ value-add activity (%)"}
          align="center"
        />
        <TableHeader loading={isLoading} title={"SME Count"} align="center" />
        <TableHeader
          loading={isLoading}
          title={"Supplier Count"}
          align="center"
        />
      </TableRow>
    </TableHead>
  );
  return (
    <>
      <Grid
        container
        className="analytic-table"
        sx={{
          alignItems: "center", // Vertically align items
          display: "flex",
          margin: "0 0 15px",
          padding: "0 !important",
          width: "100%",
        }}
      >
        <Grid
          item
          md={6}
          sx={{
            alignItems: "center", // Ensure alignment for this item
            display: "flex",
          }}
        >
          {loading ? (
            <Skeleton animation="wave">
              <Typography variant="h2">Local Content</Typography>
            </Skeleton>
          ) : (
            <Typography variant="h2">Local Content</Typography>
          )}
        </Grid>

        <Grid
          item
          md={6}
          sx={{
            alignItems: "center", // Ensure alignment for this item
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Box className="newStyleField monthSelect">
              <MonthSelect
                data-test-id="month-select"
                loading={loading}
                project={selectedProject}
                contractorPackage={contractorPackage}
                isPrimaryPackage={isPrimaryPackage}
                setDate={(date: Date) => {
                  setReportDate(dateToString(date));
                }}
                date={stringToDate(reportId)}
              />
            </Box>
            <StyledButton
              data-test-id="export-targets-button"
              className="blackBtn grey-outline-btn"
              loading={isLoading}
              disabled={loading || !itemsToDisplay || !itemsToDisplay.length}
              variant="contained"
              onClick={() => {
                if (itemsToDisplay) {
                  writeXlsxLidp(itemsToDisplay);
                }
              }}
              startIcon={<DownloadIcon />}
            >
              Export Targets
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table"
        sx={{ margin: "0", width: "100%" }}
      >
        <Box>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ marginTop: "0", padding: "0 !important" }}
            >
              {isPrimaryPackage &&
              contractorPackage &&
              contractorPackage.packageType == PackageType.PrimaryPackage ? (
                <LidpTabs
                  isLoading={isLoading}
                  value={value}
                  handleChange={handleChange}
                />
              ) : null}
            </Grid>
          </Grid>
          {isLoading ? (
            <Table>
              {tableHeader}
              <TableBody>
                {Array.from({ length: 10 }, (_, index) => (
                  <ContractorAnalyticsLocalContentRowSkeleton key={index} />
                ))}
                <LidpTableSumRowSkeleton />
              </TableBody>
            </Table>
          ) : (
            <Table data-test-id="localContent-commitment-table">
              {tableHeader}
              <TableBody>
                {!loading &&
                  itemsToDisplay.map((lc) => {
                    return (
                      <ContractorAnalyticsLocalContentRow
                        key={`local-content-table-row-${lc.id}`}
                        localContent={lc}
                      />
                    );
                  })}
                {itemsToDisplay.length > 0 ? (
                  <LidpTableSumRow
                    localContents={itemsToDisplay}
                    hasButtonColumn={false}
                  />
                ) : (
                  <NoRows title={"Local Content"} colSpan={11} />
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </Grid>
    </>
  );
};
