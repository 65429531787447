import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
  Button,
  Stack,
} from "@mui/material";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";

interface SustainabilityTypeTabsProps {
  isLoading: boolean;
  value?: GreenMetricLineItem;
  greenMetrics: GreenMetricLineItem[];
  setGreenMetric: (newValue: GreenMetricLineItem) => void;
}

export const SustainabilityTypeTabs = ({
  greenMetrics,
  isLoading,
  setGreenMetric,
  value,
}: SustainabilityTypeTabsProps): JSX.Element => {
  //TODO: https://github.com/mui/material-ui/issues/32894 Fix the console warnings
  const handleChange = (
    event: React.SyntheticEvent,
    newValue?: GreenMetricLineItem,
  ) => {
    if (newValue) {
      setGreenMetric(newValue);
    }
  };

  return (
    <Box marginTop="20px" marginBottom="20px">
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Button One</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Button Two</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Button Three</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Button Four</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          {greenMetrics.map((greenMetric) => (
            <ToggleButton value={greenMetric} size="small" key={greenMetric.id}>
              {greenMetric.reportTargetName}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
