import { downloadFile } from "@hooks/utils/useUpload";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Zoom,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  SupportingDocumentLineItem,
  supportingDocumentTypeToString,
} from "social-pro-common/interfaces/supportingDocument";
import { getDocumentName } from "social-pro-common/utils/string";

import { CircularProgressWithLabel } from "../CircularProgressWithLabel/CircularProgressWithLabel";

interface SupportingDocumentDialogRowProps {
  supportingDocument: SupportingDocumentLineItem;
}

export default function SupportingDocumentDialogRow({
  supportingDocument,
}: SupportingDocumentDialogRowProps) {
  const theme = useTheme();

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const onDownload = async (uri: string, policyName: string) => {
    try {
      setDownloadProgress(0);
      setIsDownloading(true);
      await downloadFile(uri, policyName, (progress: any) => {
        const total = (progress.loaded / progress.total) * 100;

        setDownloadProgress(total);
        if (total >= 100) {
          setIsDownloading(false);
        }
      });

      toast.success("Downloaded successfully");
    } catch (e: any) {
      toast.error("Failed to download file");
      setIsDownloading(false);
    }
  };

  return (
    <TableRow>
      <TableCell>
        {supportingDocumentTypeToString(
          supportingDocument.supportingDocumentType,
        )}
      </TableCell>
      <TableCell>
        {isDownloading ? (
          <CircularProgressWithLabel value={downloadProgress} />
        ) : (
          <Tooltip TransitionComponent={Zoom} placement="top" title="Download">
            <IconButton
              color="error"
              sx={{
                "&:hover ": {
                  background: theme.palette.primary.dark,
                  color: theme.palette.primary.light,
                },
                background: theme.palette.primary.light,
                borderColor: "black",
                color: theme.palette.primary.dark,
              }}
              size="large"
              onClick={() =>
                onDownload(
                  supportingDocument.uri,
                  getDocumentName(
                    supportingDocumentTypeToString(
                      supportingDocument.supportingDocumentType,
                    ),
                    supportingDocument.uri,
                  ),
                )
              }
            >
              <DownloadTwoToneIcon
                sx={{
                  fontSize: "1.1rem",
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{supportingDocument.documentDescription}</TableCell>
      <TableCell>{supportingDocument.documentCategory}</TableCell>
    </TableRow>
  );
}
