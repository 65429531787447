import * as ExcelJs from "exceljs";

const cleanValue = (value: string): string => {
  return value
    .replace(/\t/g, "") // Remove tabs
    .replace(/\n/g, " ") // Replace new lines with spaces
    .replace(/\s+/g, " ") // Replace multiple spaces with a single space
    .replace(/[\u00A0]/g, " ") // Replace non-breaking spaces with regular spaces
    .trim(); // Trim leading and trailing whitespace
};

const formatDate = (date: Date): string => {
  // Convert date to ISO 8601 format
  return date.toISOString(); // Produces a string like '2024-11-02T12:34:56.789Z'
};

export const excelRowToStringArray = (
  cellValues: ExcelJs.CellValue[],
): string[] => {
  return cellValues.map((cellValue) => {
    let visibleValue = "";

    if (typeof cellValue === "string") {
      visibleValue = cellValue;
    } else if (typeof cellValue === "number") {
      visibleValue = cellValue.toString();
    } else if (typeof cellValue === "boolean") {
      visibleValue = cellValue.toString(); // Convert boolean to string ("true" or "false")
    } else if (cellValue instanceof Date) {
      visibleValue = formatDate(cellValue); // Format the date to ISO 8601
    } else if (typeof cellValue === "object" && cellValue !== null) {
      if ("text" in cellValue) {
        visibleValue = cellValue.text;
      } else if ("formula" in cellValue && "result" in cellValue) {
        if (!cellValue.result) {
          return "";
        }
        visibleValue = cellValue.result.toString();
      }
    }

    return cleanValue(visibleValue);
  }); // Filter out empty strings
};

export const isTruthy = (value: string): boolean => {
  return Boolean(value) && value.toLowerCase() === "true";
};
