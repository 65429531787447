import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  DialogTitle,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { Formik } from "formik";
import { ToastOptions, toast } from "react-toastify";
import * as Yup from "yup";
import YupPassword from "yup-password";

YupPassword(Yup);

interface ChangePasswordFormProps {
  loading: boolean;
  changePassword: (oldPassword: string, newPassword: string) => void;
}

const ChangePasswordForm = ({
  changePassword,
  loading,
}: ChangePasswordFormProps) => {
  const handleChangePassword = async (
    oldPassword: string,
    newPassword: string,
  ) => {
    await changePassword(oldPassword, newPassword);
  };

  return (
    <Formik
      initialValues={{
        confirmPassword: "",
        oldPassword: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password")], "Passwords must match")
          .required("Required"),
        oldPassword: Yup.string().required("Old password is required"),
        password: Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters long")
          .max(128, "Password must be at most 128 characters long")
          .matches(
            /[a-z]/,
            "Password must contain at least one lowercase letter",
          )
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase letter",
          )
          .matches(/[0-9]/, "Password must contain at least one number")
          .matches(
            /[!@#$%^&*()_+{}[\]:;"',.<>?\\-]/,
            "Password must contain at least one special character",
          )
          .matches(/^[^\s]*$/, "Password must not contain whitespace"),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting },
      ) => {
        try {
          await handleChangePassword(values.oldPassword, values.password);

          setStatus({ success: true });
          setSubmitting(false);
          resetForm();
          toast(`Password reset!`, {
            type: "success",
          } as ToastOptions);
        } catch (err: any) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          toast("Something went wrong - Please check your password!", {
            type: "error",
          } as ToastOptions);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              paddingBottom: "0px",
              paddingLeft: "40px",
              paddingTop: "0px",
            }}
          >
            {"Change Password"}
          </DialogTitle>
          <Grid
            container
            spacing={2}
            sx={{ margin: "0", maxWidth: "900px", padding: "0px 24px" }}
          >
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(touched.oldPassword && errors.oldPassword)}
              >
                <StyledTextField
                  id="oldPassword"
                  type="password"
                  label="Current password"
                  name="oldPassword"
                  value={values.oldPassword}
                  loading={loading}
                  disabled={loading || isSubmitting}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.oldPassword && errors.oldPassword && (
                  <FormHelperText error>{errors.oldPassword}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
              >
                <StyledTextField
                  id="password"
                  type="password"
                  label="New password"
                  name="password"
                  value={values.password}
                  loading={loading}
                  disabled={loading || isSubmitting}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.password && errors.password && (
                  <FormHelperText error>{errors.password}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword,
                )}
              >
                <StyledTextField
                  id="confirmPassword"
                  type="password"
                  label="Confirm password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  loading={loading}
                  disabled={loading || isSubmitting}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormHelperText error>
                    {errors.confirmPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {errors.submit && (
              <Grid item xs={12}>
                <Box sx={{ justifyContent: "center", margin: "auto", mt: 3 }}>
                  <FormHelperText
                    error
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <Typography align="center">{errors.submit}</Typography>
                  </FormHelperText>
                </Box>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: "flex !important",
                justifyContent: "right !important;",
              }}
            >
              <StyledButton
                data-test-id="update-password-button"
                loading={loading}
                variant="contained"
                type="submit"
              >
                Update
              </StyledButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
