import InsightsIcon from "@mui/icons-material/Insights";
import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import { userHasEditorAccess } from "@utils/projectAccess";
import * as RouteHelper from "@utils/routes";
import { PieChart, HardHat, Package, Users } from "lucide-react";
import { ProjectAccessLevel } from "social-pro-common/entities/project";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { NavItemType } from "types";

export const getPackageNav = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
): NavItemType => {
  const theme = useTheme();

  const children = [];

  children.push({
    icon: <PieChart />,
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.contractorDashboard(project.id, contractorPackage.id)
        : "",
  });

  children.push({
    icon: <HardHat />,
    id: "packages",
    title: "Subcontractors",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.packages(project.id, contractorPackage.id)
        : "",
  });

  children.push({
    icon: <Package />,
    id: "my-package",
    title: "My Package",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.userPackage(project.id, contractorPackage.id)
        : "",
  });
  if (
    project?.projectAccessLevel === ProjectAccessLevel.Admin ||
    project?.projectAccessLevel === ProjectAccessLevel.Manager ||
    project?.projectAccessLevel === ProjectAccessLevel.Write
  ) {
    children.push({
      icon: <Users />,
      id: "project-employees",
      title: "Employees",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.projectEmployees(project.id, contractorPackage.id)
          : "",
    });
  }

  if (userHasEditorAccess(project)) {
    children.push({
      icon: <InsightsIcon />,
      id: "forecast",
      title: "Forecasting",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.forecast(project.id, contractorPackage.id)
          : "",
    });
  }

  return {
    children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "packages",
    title: "Manage",
    type: "group",
  };
};

export const getReducedPackageNav = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
): NavItemType => {
  const theme = useTheme();
  const children = [];

  children.push({
    icon: <PieChart />,
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.contractorDashboard(project.id, contractorPackage.id)
        : "",
  });

  children.push({
    icon: <Package />,
    id: "my-package",
    title: "My Package",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.userPackage(project.id, contractorPackage.id)
        : "",
  });
  if (
    project?.projectAccessLevel === ProjectAccessLevel.Admin ||
    project?.projectAccessLevel === ProjectAccessLevel.Manager ||
    project?.projectAccessLevel === ProjectAccessLevel.Write
  ) {
    children.push({
      icon: <Users />,
      id: "project-employees",
      title: "Employees",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.projectEmployees(project.id, contractorPackage.id)
          : "",
    });
  }

  if (userHasEditorAccess(project)) {
    children.push({
      icon: <InsightsIcon />,
      id: "forecast",
      title: "Forecasting",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.forecast(project.id, contractorPackage.id)
          : "",
    });
  }

  return {
    children: children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "packages",
    title: "Manage",
    type: "group",
  };
};
