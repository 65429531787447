import { TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import {
  calculateLocalContentPackageAnzTotal,
  calculateLocalContentPackageContractTotal,
  calculateLocalContentPackageValueTotal,
} from "social-pro-common/utils/calc";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

interface LidpTableSumRowProps {
  localContents: PackageLocalContentLineItem[];
  hasButtonColumn: boolean;
}

export const LidpTableSumRow = ({
  hasButtonColumn,
  localContents,
}: LidpTableSumRowProps) => {
  const theme = useTheme();

  const val = calculateLocalContentPackageValueTotal(localContents);

  return (
    <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
      <TableCell colSpan={3} align="right">
        <Typography align="right">{"Total Local Content"}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">
          {`${formatDecimalPlaces(val, 2)}`}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">-</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">-</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">-</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">
          {`${formatDecimalPlaces(
            calculateLocalContentPackageContractTotal(localContents),
            4,
          )}%`}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">
          {`${formatDecimalPlaces(
            calculateLocalContentPackageAnzTotal(localContents),
            4,
          )}%`}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">
          {localContents.reduce((acc, lc) => acc + lc.smeCount, 0)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography align="center">
          {localContents.reduce((acc, lc) => acc + lc.supplierCount, 0)}
        </Typography>
      </TableCell>
      {hasButtonColumn ? <TableCell align="center" /> : null}
    </TableRow>
  );
};
