import { useProjectContext } from "@hooks/context/useProjectContext";
import {
  Table,
  TableBody,
  Grid,
  Pagination,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import * as RouteHelper from "@utils/routes";
import { ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { DEFAULT_PAGINATION_LIMIT } from "social-pro-common/entities/contractorEmployee";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import {
  EmployeeHoursToDateLineItem,
  LabourHourLineItem,
  createDefaultLabourHour,
} from "social-pro-common/interfaces/labourHour";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

import { LabourHourTableRow } from "./LabourHourTableRow";
import { LabourHourTableRowSkeleton } from "./LabourHourTableRowSkeleton";

interface LabourHoursTableProps {
  isLoading: boolean;
  isSubmitting: boolean;
  reportId: string;
  userOrganisation?: OrganisationLineItem;
  labourHours: LabourHourLineItem[];
  previousEmployeeLabourHours: EmployeeHoursToDateLineItem[];
  filterName: string;
  contractorPackageId?: string;
  employees: EmployeeLineItem[];
  showStudiedHours?: boolean;
  currentSelectedEmployees: string[];
  employeeCount: number;
  readOnly?: boolean;
  setPage: (page: number) => void;
  upsertLabourHours: (labourHours: LabourHourLineItem[]) => Promise<void>;
}

export const LabourHoursTable = ({
  contractorPackageId,
  currentSelectedEmployees,
  employeeCount,
  employees,
  filterName,
  isLoading,
  isSubmitting,
  labourHours,
  previousEmployeeLabourHours,
  readOnly,
  reportId,
  setPage,
  showStudiedHours,
  upsertLabourHours,
  userOrganisation,
}: LabourHoursTableProps) => {
  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };
  const { contractorPackage, selectedProject } = useProjectContext();

  const TABLE_HEAD: TableHeaderCell[] = [
    {
      align: "left",
      id: "employee-name",
      label: "Name or Code",
      minWidth: "15%",
      width: "15%",
    },
    {
      align: "left",
      id: "employee-social-factor",
      label: "Social Factors",
      minWidth: "15%",
      width: "15%",
    },
    {
      align: "center",
      id: "hours-to-date",
      label: "Hours to date",
      minWidth: "10%",
      width: "10%",
    },
    {
      align: "center",
      id: "hours-studied-this-month",
      label: showStudiedHours ? "Hours studied this month" : "",
      minWidth: "25%",
      width: "25%",
    },
    {
      align: "center",
      id: "hours-this-month",
      label: "Hours worked this month",
      minWidth: "25%",
      width: "25%",
    },
  ];

  const isNotFound =
    employeeCount >= 0 && !employees.length && Boolean(filterName);

  const loading = isLoading && !isSubmitting;
  return (
    <>
      {loading ? (
        <Table>
          <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <LabourHourTableRowSkeleton
                key={index}
                showStudiedHours={!!showStudiedHours}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table data-test-id="labour-hours-submission-table">
          <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
          <TableBody>
            {!loading &&
            selectedProject &&
            contractorPackageId &&
            userOrganisation &&
            employeeCount >= 0 &&
            reportId
              ? employees.map((row) => {
                  const employeeLabourHour = labourHours.find(
                    (lh) =>
                      lh.employeeId === row.id && lh.reportId === reportId,
                  );

                  const previousEmployeeLabourHour =
                    previousEmployeeLabourHours.find((lh) => lh.id === row.id);
                  return (
                    <LabourHourTableRow
                      key={row.id}
                      loading={loading}
                      disabled={isSubmitting}
                      isSubmitting={currentSelectedEmployees.length > 0}
                      project={selectedProject}
                      employee={row}
                      labourHour={
                        employeeLabourHour ||
                        createDefaultLabourHour(
                          selectedProject.id,
                          reportId,
                          contractorPackageId,
                          userOrganisation,
                          row,
                        )
                      }
                      showStudiedHours={showStudiedHours}
                      previousEmployeeLabourHour={previousEmployeeLabourHour}
                      readOnly={readOnly}
                      handleUpdateLabourHour={upsertLabourHours}
                    />
                  );
                })
              : null}

            {isNotFound && (
              <NoResultsFoundRow
                colSpan={TABLE_HEAD.length + 1}
                filterName={filterName}
              />
            )}

            {employeeCount === -1 && !loading ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={TABLE_HEAD.length + 1}
                  sx={{ borderRadius: "12px !important", py: 3 }}
                  className="fullBorderRad"
                >
                  <Typography paragraph align="center" sx={{ margin: "0" }}>
                    No employees on project{" "}
                    {!readOnly ? (
                      <>
                        -{" "}
                        <Link
                          to={RouteHelper.projectEmployees(
                            selectedProject?.id || "",
                            contractorPackage?.id || "",
                          )}
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            textDecorationColor: "#000",
                          }}
                        >
                          Add Employees To Project
                        </Link>
                      </>
                    ) : null}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      )}
      {employeeCount > DEFAULT_PAGINATION_LIMIT ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "0 40px 40px 40px",
          }}
        >
          <Pagination
            count={Math.ceil(employeeCount / DEFAULT_PAGINATION_LIMIT)}
            sx={{ margin: "auto" }}
            onChange={handleChange}
            boundaryCount={2}
          />
        </Grid>
      ) : null}
    </>
  );
};
