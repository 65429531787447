"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateProjectProgress = exports.calculatePackageProgress = exports.calculateProjectProgressLineItem = exports.calculatePackageProgressLineItem = exports.calcANZValueAdd = exports.calcLocalContent = exports.calcRequiredDeemedHours = exports.calcRequiredSpend = exports.calculatedAvailableSpend = exports.calculateTargetValueFromTargetValueRealised = exports.calculateTargetValueRealised = exports.calculateLocalContentPackageLocalValueAddTotal = exports.calculateLocalContentPackageAnzTotal = exports.calculateLocalContentPackageContractTotal = exports.calculateLocalContentPackageValueTotal = void 0;
var number_1 = require("./number");
var calculateLocalContentPackageValueTotal = function (localContents) {
    return localContents.reduce(function (acc, lc) { return acc + lc.value; }, 0);
};
exports.calculateLocalContentPackageValueTotal = calculateLocalContentPackageValueTotal;
var calculateLocalContentPackageContractTotal = function (localContents) {
    return localContents.reduce(function (acc, lc) { return acc + lc.contractContent; }, 0);
};
exports.calculateLocalContentPackageContractTotal = calculateLocalContentPackageContractTotal;
var calculateLocalContentPackageAnzTotal = function (localContents) {
    return localContents.reduce(function (acc, lc) { return acc + lc.anzValueAdd; }, 0);
};
exports.calculateLocalContentPackageAnzTotal = calculateLocalContentPackageAnzTotal;
var calculateLocalContentPackageLocalValueAddTotal = function (localContents) {
    return localContents.reduce(function (acc, lc) { return acc + lc.localValueAdd; }, 0);
};
exports.calculateLocalContentPackageLocalValueAddTotal = calculateLocalContentPackageLocalValueAddTotal;
var calculateTargetValueRealised = function (targetValue, baseMultiplier) {
    return (0, number_1.formatDecimalPlacesNumerical)(baseMultiplier * (targetValue / 100));
};
exports.calculateTargetValueRealised = calculateTargetValueRealised;
var calculateTargetValueFromTargetValueRealised = function (targetValueRealised, baseMultiplier) {
    return (0, number_1.formatDecimalPlacesNumerical)((targetValueRealised / baseMultiplier) * 100);
};
exports.calculateTargetValueFromTargetValueRealised = calculateTargetValueFromTargetValueRealised;
var calculatedAvailableSpend = function (totalValue, margin, variations, offset) {
    return totalValue - margin + variations - offset;
};
exports.calculatedAvailableSpend = calculatedAvailableSpend;
var calcRequiredSpend = function (mpsgHoursRequirement, requiredHours) {
    return (mpsgHoursRequirement / 100) * requiredHours;
};
exports.calcRequiredSpend = calcRequiredSpend;
var calcRequiredDeemedHours = function (availableSpend, deemedLabourRatio, deemedLabourHourlyRate) {
    if (deemedLabourHourlyRate === 0) {
        return 0;
    }
    return (availableSpend * deemedLabourRatio) / 100 / deemedLabourHourlyRate;
};
exports.calcRequiredDeemedHours = calcRequiredDeemedHours;
var calcLocalContent = function (importValueAdd) {
    return 100 - importValueAdd;
};
exports.calcLocalContent = calcLocalContent;
var calcANZValueAdd = function (localImportPc, contractContent) {
    return (localImportPc / 100) * contractContent;
};
exports.calcANZValueAdd = calcANZValueAdd;
var calculatePackageProgressLineItem = function (hourCommitments, spendCommitments) {
    var achievedHours = hourCommitments.length
        ? hourCommitments.reduce(function (acc, c) {
            return acc + c.achievedValue;
        }, 0)
        : 0;
    var targetHours = hourCommitments.length
        ? hourCommitments.reduce(function (acc, c) {
            return acc + c.targetValueRealised;
        }, 0)
        : 0;
    var progressHours = targetHours ? (achievedHours / targetHours) * 100 : 0;
    var achievedSpend = spendCommitments.length
        ? spendCommitments.reduce(function (acc, c) {
            return acc + c.achievedValue;
        }, 0)
        : 0;
    var targetSpend = spendCommitments.length
        ? spendCommitments.reduce(function (acc, c) {
            return acc + c.targetValueRealised;
        }, 0)
        : 0;
    var progressSpend = targetSpend ? (achievedSpend / targetSpend) * 100 : 0;
    return (Math.min(100, progressHours) + Math.min(100, progressSpend)) / 2;
};
exports.calculatePackageProgressLineItem = calculatePackageProgressLineItem;
var calculateProjectProgressLineItem = function (hourCommitments, spendCommitments) {
    var achievedHours = hourCommitments.length
        ? hourCommitments.reduce(function (acc, c) {
            return acc + c.achievedValue;
        }, 0)
        : 0;
    var targetHours = hourCommitments.length
        ? hourCommitments.reduce(function (acc, c) {
            return acc + c.targetValueRealised;
        }, 0)
        : 0;
    var progressHours = targetHours ? (achievedHours / targetHours) * 100 : 0;
    var achievedSpend = spendCommitments.length
        ? spendCommitments.reduce(function (acc, c) {
            return acc + c.achievedValue;
        }, 0)
        : 0;
    var targetSpend = spendCommitments.length
        ? spendCommitments.reduce(function (acc, c) {
            return acc + c.targetValueRealised;
        }, 0)
        : 0;
    var progressSpend = targetSpend ? (achievedSpend / targetSpend) * 100 : 0;
    return (Math.min(100, progressHours) + Math.min(100, progressSpend)) / 2;
};
exports.calculateProjectProgressLineItem = calculateProjectProgressLineItem;
var calculatePackageProgress = function (hourCommitments, spendCommitments) {
    var achievedHours = hourCommitments.length
        ? hourCommitments.reduce(function (acc, c) { return acc + c.achieved_value; }, 0)
        : 0;
    var targetHours = hourCommitments.length
        ? hourCommitments.reduce(function (acc, c) {
            return acc + c.target_value_realised;
        }, 0)
        : 0;
    var progressHours = targetHours ? (achievedHours / targetHours) * 100 : 0;
    var achievedSpend = spendCommitments.length
        ? spendCommitments.reduce(function (acc, c) {
            return acc + c.achieved_value;
        }, 0)
        : 0;
    var targetSpend = spendCommitments.length
        ? spendCommitments.reduce(function (acc, c) {
            return acc + c.target_value_realised;
        }, 0)
        : 0;
    var progressSpend = targetSpend ? (achievedSpend / targetSpend) * 100 : 0;
    return (Math.min(100, progressHours) + Math.min(100, progressSpend)) / 2;
};
exports.calculatePackageProgress = calculatePackageProgress;
var calculateProjectProgress = function (hourCommitments, spendCommitments) {
    var achievedHours = hourCommitments.reduce(function (acc, c) { return acc + c.achieved_value; }, 0);
    var targetHours = hourCommitments.reduce(function (acc, c) {
        return acc + c.target_value_realised;
    }, 0);
    var progressHours = (achievedHours / targetHours) * 100;
    var achievedSpend = spendCommitments.reduce(function (acc, c) { return acc + c.achieved_value; }, 0);
    var targetSpend = spendCommitments.reduce(function (acc, c) {
        return acc + c.target_value_realised;
    }, 0);
    var progressSpend = (achievedSpend / targetSpend) * 100;
    return (Math.min(100, progressHours) + Math.min(100, progressSpend)) / 2;
};
exports.calculateProjectProgress = calculateProjectProgress;
