import { Box, Grid, FormControl } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledDatePicker } from "@stories/atoms/StyledDatePicker/StyledDatePicker";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import * as yup from "yup";

interface TrainingContractFormProps {
  selectedEmployee: EmployeeLineItem;
  setEmployee: (employee: EmployeeLineItem) => void;
  handleClose: () => void;
}

export const TrainingContractForm = ({
  handleClose,
  selectedEmployee,
  setEmployee,
}: TrainingContractFormProps) => {
  const validationSchema = yup.object({
    educationalInstitute: yup
      .string()
      .required("Educational institute is required"),
    qualification: yup.string().required("Qualification name is required"),
    traineeContractEndDate: yup
      .date()
      .required("Contract end date is required"),
    trainingContractNumber: yup
      .string()
      .required("Contract number is required"),
    uniqueStudentId: yup.string().required("Student ID is required"),
  });
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      educationalInstitute: selectedEmployee.educationalInstitute || "",
      qualification: selectedEmployee.qualification || "",
      traineeContractEndDate: dayjs(
        selectedEmployee.traineeContractEndDate || new Date(),
      ),
      trainingContractNumber: selectedEmployee.trainingContractNumber || "",
      uniqueStudentId: selectedEmployee.uniqueStudentId || "",
    },
    onSubmit: (values) => {
      setEmployee({
        ...selectedEmployee,
        educationalInstitute: values.educationalInstitute,
        qualification: values.qualification,
        traineeContractEndDate: values.traineeContractEndDate.toDate(),
        trainingContractNumber: values.trainingContractNumber,
        uniqueStudentId: values.uniqueStudentId,
      });
      handleClose();
    },
    validationSchema: validationSchema,
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <StyledTextField
              fullWidth
              loading={false}
              margin="dense"
              id="uniqueStudentId"
              name="uniqueStudentId"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.uniqueStudentId}
              error={
                formik.touched.uniqueStudentId &&
                Boolean(formik.errors.uniqueStudentId)
              }
              helperText={
                formik.touched.uniqueStudentId
                  ? formik.errors.uniqueStudentId
                  : ""
              }
              label={"Unique Student ID"}
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ paddingTop: "8px !important" }}>
            <StyledTextField
              fullWidth
              loading={false}
              margin="dense"
              id="trainingContractNumber"
              name="trainingContractNumber"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.trainingContractNumber}
              error={
                formik.touched.trainingContractNumber &&
                Boolean(formik.errors.trainingContractNumber)
              }
              helperText={
                formik.touched.trainingContractNumber
                  ? formik.errors.trainingContractNumber
                  : ""
              }
              label={"Trainee Contract Number"}
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ paddingTop: "8px !important" }}>
            <StyledTextField
              fullWidth
              loading={false}
              margin="dense"
              id="educationalInstitute"
              name="educationalInstitute"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.educationalInstitute}
              error={
                formik.touched.educationalInstitute &&
                Boolean(formik.errors.educationalInstitute)
              }
              helperText={
                formik.touched.educationalInstitute
                  ? formik.errors.educationalInstitute
                  : ""
              }
              label={"Educational Institute"}
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ paddingTop: "8px !important" }}>
            <StyledTextField
              fullWidth
              loading={false}
              margin="dense"
              id="qualification"
              name="qualification"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.qualification}
              error={
                formik.touched.qualification &&
                Boolean(formik.errors.qualification)
              }
              helperText={
                formik.touched.qualification ? formik.errors.qualification : ""
              }
              label={"Qualification"}
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ paddingTop: "8px !important" }}>
            <FormControl fullWidth>
              <StyledDatePicker
                label="End Date"
                id="traineeContractEndDate"
                name="traineeContractEndDate"
                disabled={formik.isSubmitting}
                onChange={(value: dayjs.Dayjs) => {
                  if (value) {
                    formik.setFieldValue("traineeContractEndDate", value, true);
                  }
                }}
                value={formik.values.traineeContractEndDate}
                error={
                  formik.touched.traineeContractEndDate &&
                  Boolean(formik.errors.traineeContractEndDate)
                }
                helperText={
                  formik.touched.traineeContractEndDate
                    ? formik.errors.traineeContractEndDate
                    : ""
                }
                openTo="month"
                views={["year", "month"]}
                slotProps={{
                  popper: {
                    popperOptions: {
                      modifiers: [
                        {
                          enabled: true,
                          fn: ({ state }: { state: Partial<any> }) => {
                            state.styles.popper.height = "358px";
                            if (state.placement.includes("top-start")) {
                              state.styles.popper = {
                                ...state.styles.popper,
                                alignItems: "flex-end",
                                display: "flex",
                              };
                            }
                            if (state.placement.includes("bottom")) {
                              state.styles.popper = {
                                ...state.styles.popper,
                                display: "block",
                              };
                            }
                          },
                          name: "applyCustom",
                          phase: "beforeWrite",
                          requires: ["computeStyles"],
                        },
                      ],
                    },
                  },
                  textField: {
                    "data-test-id": "qualification-end-date",
                    helperText: formik.touched.traineeContractEndDate
                      ? formik.errors.traineeContractEndDate
                      : "",
                    margin: "dense",
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex !important",
              justifyContent: "space-between !important",
              paddingTop: "16px !important",
            }}
          >
            <StyledButton
              loading={formik.isSubmitting}
              variant="outlined"
              disabled={formik.isSubmitting}
              onClick={handleClose}
            >
              Cancel
            </StyledButton>
            <StyledButton
              loading={formik.isSubmitting}
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
