import { getApiData } from "@hooks/utils/api";
import { useCallback, useEffect, useState } from "react";
import { decodeLabourHourTargetModel } from "social-pro-common/decoders/labourHour";
import { LabourHourTargetModel } from "social-pro-common/entities/labourHour";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";

export interface TableSortInfo {
  page: number;
  order: "asc" | "desc";
  orderBy: number;
}

export const useLabourHoursForTarget = (
  projectId?: string,
  targetId?: string,
  contractorPackageId?: string,
  sortInfo?: TableSortInfo,
) => {
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [labourHourCount, setLabourHourCount] = useState<number>(0);
  const [labourHours, setLabourHours] = useState<LabourHourForTargetLineItem[]>(
    [],
  );
  const [isLabourHourLoading, setIsLabourHourLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const listLabourHoursForTarget = useCallback(
    async (
      projectId: string,
      targetId: string,
      contractorPackageId: string,
      sortInfo: TableSortInfo,
    ): Promise<void> => {
      try {
        setIsLabourHourLoading(true);
        const labourHourResult = await getApiData(
          "listLabourHoursForTarget",
          "labourHour",
          projectId,
          {
            contractorPackageId,
            page: sortInfo.page.toString(),
            sortColumn: sortInfo.orderBy,
            sortDirection: sortInfo.order,
            targetId,
          },
        );
        const labourHourModel = decodeLabourHourTargetModel(
          labourHourResult.data as LabourHourTargetModel,
        );

        setHasMore(labourHourModel.hasMore);
        setLabourHourCount(labourHourModel.totalLabourHours);
        setLabourHours(labourHourModel.labourHours);
      } catch (error) {
        setError("Could not list labourHours");
      } finally {
        setIsLabourHourLoading(false);
      }
    },
    [getApiData, decodeLabourHourTargetModel],
  );

  const fetchMoreLabourHours = useCallback(
    async (
      projectId: string,
      targetId: string,
      contractorPackageId: string,
      sortInfo: TableSortInfo,
    ) => {
      try {
        setIsLoadingMore(true);
        const labourHourResult = await getApiData(
          "listLabourHoursForTarget",
          "labourHour",
          projectId,
          {
            contractorPackageId,
            page: sortInfo.page.toString(),
            sortColumn: sortInfo.orderBy,
            sortDirection: sortInfo.order,
            targetId,
          },
        );
        const labourHourModel = decodeLabourHourTargetModel(
          labourHourResult.data as LabourHourTargetModel,
        );
        setHasMore(labourHourModel.hasMore);
        setLabourHourCount(labourHourModel.totalLabourHours);
        setLabourHours((prev) => [...prev, ...labourHourModel.labourHours]);
      } catch (error) {
        setError("Could not list labourHours");
      } finally {
        setIsLoadingMore(false);
      }
    },
    [
      getApiData,
      decodeLabourHourTargetModel,
      labourHours,
      projectId,
      targetId,
      contractorPackageId,
      sortInfo,
    ],
  );

  useEffect(() => {
    if (projectId && contractorPackageId && targetId && sortInfo) {
      if (sortInfo.page === 0) {
        listLabourHoursForTarget(
          projectId,
          targetId,
          contractorPackageId,
          sortInfo,
        );
      } else {
        fetchMoreLabourHours(
          projectId,
          targetId,
          contractorPackageId,
          sortInfo,
        );
      }
    } else {
      setIsLabourHourLoading(false);
    }
  }, [
    listLabourHoursForTarget,
    projectId,
    targetId,
    contractorPackageId,
    sortInfo,
  ]);

  return {
    error,
    hasMore,
    isLabourHourLoading,
    isLoadingMore,
    labourHourCount,
    labourHours,
    listLabourHoursForTarget,
  };
};
