import {
  Button,
  Grid,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Avatar from "@stories/ui-component/extended/Avatar";

export const OrganisationTableRowSkeleton = () => {
  return (
    <TableRow key={`sub-contractor-row-${0}`}>
      <TableCell>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Skeleton animation={"wave"} variant={"circular"}>
              <Avatar />
            </Skeleton>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Skeleton animation={"wave"}>
              <Typography align="left" variant="subtitle1">
                Contractor Name
              </Typography>
            </Skeleton>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
          <TextField className="search tableInput" />
        </Skeleton>
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
          <TextField className="search tableInput" />
        </Skeleton>
      </TableCell>
      <TableCell align="center">
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          <Skeleton animation="wave" variant="rounded">
            <Button
              color="primary"
              sx={{
                "&:hover ": {
                  background: "#e3f2fd",
                },
                borderColor: "#0063c6",
                color: "#0063c6",
              }}
              size="large"
             />
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
