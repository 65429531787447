import { useSustainabilitySubData } from "@hooks/crud/dashboard/useSustainabilitySubData";
import { Grid } from "@mui/material";

import { SustainabilityTargetStack } from "../ProjectProgress/SustainabilityTargetStack";

interface LocalContentProgressSubDataProps {
  loading: boolean;
  projectId?: string;
  packageId?: string;
  reportId?: string;
}

export const LocalContentProgressSubData = ({
  loading,
  packageId,
  projectId,
  reportId,
}: LocalContentProgressSubDataProps) => {
  const { isAnalysisLoading, sustainabilitySubData } = useSustainabilitySubData(
    projectId,
    packageId,
    reportId,
  );
  const isLoading = loading || isAnalysisLoading;
  return (
    <Grid container justifyContent={"space-between"}>
      <SustainabilityTargetStack
        loading={isLoading}
        targets={sustainabilitySubData?.fuels || []}
        title={"Fuels, Lubricants and Solvents"}
        dataTestId={"sustainability-fuels"}
        firstColumnTitle={"Category"}
        secondColumnTitle="Qty"
        thirdColumnTitle="%"
        units="%"
        rightMargin
      />
      <SustainabilityTargetStack
        loading={isLoading}
        targets={sustainabilitySubData?.water || []}
        title={"Water Usage"}
        dataTestId={"sustainability-water"}
        firstColumnTitle={"Category"}
        secondColumnTitle="Qty"
        thirdColumnTitle="%"
        units="%"
        leftMargin
        rightMargin
      />
      <SustainabilityTargetStack
        loading={isLoading}
        targets={sustainabilitySubData?.waste || []}
        title={"Waster (Landfil, Reused, Soil)"}
        dataTestId={"sustainability-waste"}
        firstColumnTitle={"Category"}
        secondColumnTitle="Qty"
        thirdColumnTitle="%"
        units="%"
        leftMargin
      />
    </Grid>
  );
};
