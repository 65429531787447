import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Skeleton, TextField } from "@mui/material";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";

interface SearchInputProps {
  loading: boolean;
  inputValue: string;
  setInputValue: (value: string) => void;
  handleSearchChange: () => void;
}

export const SearchInput = ({
  handleSearchChange,
  inputValue,
  loading,
  setInputValue,
}: SearchInputProps) => {
  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded" width="300px" height="40px">
        <TextField
          label="DEFAULT TEXT FIELD CONTENT - LOADING"
          margin="dense"
          fullWidth
        />
      </Skeleton>
    );
  }
  return (
    <StyledTextField
      data-test-id="search-input"
      className="search"
      loading={loading}
      value={inputValue}
      type="text"
      placeholder="Search..."
      name="filterProject"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
      }}
      onBlur={handleSearchChange}
      onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
          handleSearchChange();
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
