"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProjectDetails = exports.decodeProjectDetail = exports.encodeProject = exports.decodeProject = void 0;
var date_1 = require("../../utils/date");
var string_1 = require("../../utils/string");
var contactInfo_1 = require("../contactInfo");
var financial_1 = require("../financial");
var greenMetric_1 = require("../greenMetric");
var packageLocalContent_1 = require("../packageLocalContent");
var projectLabourHourCommitment_1 = require("../projectLabourHourCommitment");
var projectPolicy_1 = require("../projectPolicy");
var projectSocialSpendCommitment_1 = require("../projectSocialSpendCommitment");
var decodeProject = function (project, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var presignedUrl;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, getImageUrl(project.organisation_logo)];
            case 1:
                presignedUrl = _b.sent();
                return [2 /*return*/, {
                        archived: project.archived,
                        collaborators: project.collaborators || [],
                        commitmentsHours: project.commitments_hours.map(function (c) {
                            return (0, projectLabourHourCommitment_1.decodeProjectLabourHourCommitment)(c);
                        }),
                        commitmentsSpend: project.commitments_spend.map(function (c) {
                            return (0, projectSocialSpendCommitment_1.decodeProjectSocialSpendCommitment)(c);
                        }),
                        created: new Date(project.created),
                        estimatedProgress: project.estimated_progress,
                        financial: (0, financial_1.decodeFinancial)(project.financial),
                        firstPackageStartDate: project.first_package_start_date
                            ? project.first_package_start_date
                            : undefined,
                        greenMetrics: project.green_metrics.map(function (g) { return (0, greenMetric_1.decodeGreenMetric)(g); }),
                        id: project.project_id,
                        lastPackageEndDate: project.last_package_end_date
                            ? project.last_package_end_date
                            : undefined,
                        localContents: ((_a = project === null || project === void 0 ? void 0 : project.local_content) === null || _a === void 0 ? void 0 : _a.map(function (lc) { return (0, packageLocalContent_1.decodePackageLocalContent)(lc); })) || [],
                        organisationId: project.organisation_id,
                        organisationLogo: presignedUrl,
                        organisationName: project.organisation_name,
                        policies: project.policies.map(function (p) { return (0, projectPolicy_1.decodeProjectPolicy)(p); }),
                        projectAccessLevel: project.project_access_level,
                        projectAddress: (0, contactInfo_1.decodeContactInfo)(project.project_address),
                        projectCode: project.project_code,
                        projectEndDate: project.project_end_date,
                        projectName: project.project_name,
                        projectOwner: project.project_owner,
                        projectReportMonths: (0, date_1.getReportMonths)((0, date_1.stringToDate)(project.project_start_date), (0, date_1.stringToDate)(project.project_end_date)),
                        projectStartDate: project.project_start_date,
                        reportingDueDate: project.reporting_due_date,
                        representativeEmail: project.representative_email,
                        representativeName: project.representative_name,
                        userPackageId: project.user_package_id,
                    }];
        }
    });
}); };
exports.decodeProject = decodeProject;
var encodeProject = function (project) {
    var _a;
    var safeProject = (0, string_1.makePostgresSafe)(project);
    return {
        archived: safeProject.archived,
        collaborators: safeProject.collaborators || [],
        commitments_hours: safeProject.commitmentsHours.map(function (c) {
            return (0, projectLabourHourCommitment_1.encodeProjectLabourHourCommitment)(c);
        }),
        commitments_spend: safeProject.commitmentsSpend.map(function (c) {
            return (0, projectSocialSpendCommitment_1.encodeProjectSocialSpendCommitment)(c);
        }),
        created: new Date(safeProject.created),
        estimated_progress: safeProject.estimatedProgress,
        financial: (0, financial_1.encodeFinancial)(safeProject.financial),
        financial_id: safeProject.financial.id,
        first_package_start_date: safeProject.firstPackageStartDate,
        green_metrics: safeProject.greenMetrics.map(function (g) { return (0, greenMetric_1.encodeGreenMetric)(g); }),
        last_package_end_date: safeProject.lastPackageEndDate,
        local_content: ((_a = project === null || project === void 0 ? void 0 : project.localContents) === null || _a === void 0 ? void 0 : _a.map(function (lc) { return (0, packageLocalContent_1.encodePackageLocalContent)(lc); })) || [], //TODO: Remove this when local content is added to backend
        organisation_id: safeProject.organisationId,
        organisation_logo: safeProject.organisationLogo,
        organisation_name: safeProject.organisationName,
        policies: safeProject.policies.map(function (p) { return (0, projectPolicy_1.encodeProjectPolicy)(p); }),
        project_access_level: safeProject.projectAccessLevel,
        project_address: (0, contactInfo_1.encodeContactInfo)(safeProject.projectAddress),
        project_code: safeProject.projectCode,
        project_contact_info_id: safeProject.projectAddress.id,
        project_end_date: safeProject.projectEndDate,
        project_id: safeProject.id,
        project_name: safeProject.projectName,
        project_owner: safeProject.projectOwner,
        project_start_date: safeProject.projectStartDate,
        reporting_due_date: safeProject.reportingDueDate,
        representative_email: safeProject.representativeEmail,
        representative_name: safeProject.representativeName,
        user_package_id: safeProject.userPackageId || "",
    };
};
exports.encodeProject = encodeProject;
var decodeProjectDetail = function (project, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var presignedUrl, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(project.organisation_logo.length > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, getImageUrl(project.organisation_logo)];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = "";
                _b.label = 3;
            case 3:
                presignedUrl = _a;
                return [2 /*return*/, {
                        archived: project.archived,
                        collaborators: project.collaborators || [],
                        contractorPackageId: project.contractor_package_id,
                        created: new Date(project.created),
                        estimatedProgress: project.estimated_progress,
                        id: project.project_id,
                        isAccepted: project.is_accepted,
                        nextReportId: project.next_report_id,
                        organisationId: project.organisation_id,
                        organisationLogo: presignedUrl,
                        organisationName: project.organisation_name,
                        packageType: project.package_type,
                        projectAccessLevel: project.project_access_level,
                        projectAddress: (0, contactInfo_1.decodeContactInfo)(project.project_address),
                        projectContactInfoId: project.project_contact_info_id,
                        projectEndDate: project.project_end_date,
                        projectName: project.project_name,
                        projectOwner: project.project_owner,
                        projectStartDate: project.project_start_date,
                        reportingDueDate: project.reporting_due_date,
                        representativeEmail: project.representative_email,
                        representativeName: project.representative_name,
                    }];
        }
    });
}); };
exports.decodeProjectDetail = decodeProjectDetail;
var encodeProjectDetails = function (project) {
    var safeProject = (0, string_1.makePostgresSafe)(project);
    return {
        archived: safeProject.archived,
        collaborators: safeProject.collaborators || [],
        contractor_package_id: safeProject.contractorPackageId,
        created: new Date(safeProject.created),
        estimated_progress: safeProject.estimatedProgress,
        is_accepted: safeProject.isAccepted,
        next_report_id: project.nextReportId,
        organisation_id: safeProject.organisationId,
        organisation_logo: safeProject.organisationLogo,
        organisation_name: safeProject.organisationName,
        package_type: safeProject.packageType,
        project_access_level: project.projectAccessLevel,
        project_address: (0, contactInfo_1.encodeContactInfo)(safeProject.projectAddress),
        project_contact_info_id: safeProject.projectAddress.id,
        project_end_date: safeProject.projectEndDate,
        project_id: safeProject.id,
        project_name: safeProject.projectName,
        project_owner: safeProject.projectOwner,
        project_start_date: safeProject.projectStartDate,
        reporting_due_date: safeProject.reportingDueDate,
        representative_email: safeProject.representativeEmail,
        representative_name: safeProject.representativeName,
    };
};
exports.encodeProjectDetails = encodeProjectDetails;
