import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Typography,
  Card,
  Button,
  CardContent,
  CardHeader,
} from "@mui/material";
import { useState } from "react";
import { SocialBusinessLineItem } from "social-pro-common/interfaces/socialBusiness";

interface DirectoryCardProps {
  socialBusiness: SocialBusinessLineItem;
}
export const DirectoryCard = ({ socialBusiness }: DirectoryCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = socialBusiness.socialBusinessBlurb.length > 150;
  return (
    <Card
      className="direct-card"
      variant="outlined"
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <CardHeader
        avatar={
          <img
            style={{ backgroundColor: "#8FC9F9" }}
            width={80}
            alt={socialBusiness.socialBusinessName}
            src={socialBusiness.socialBusinessLogo}
          />
        }
        title={socialBusiness.socialBusinessName}
      />
      <CardContent>
        <Typography
          variant="caption"
          component="p"
          id="fullDescription"
          mb={2}
          sx={{
            color: "#000",
            fontSize: "14px",
            lineHeight: "24px",
            marginTop: "5px",
          }}
        >
          {truncateText
            ? isExpanded
              ? socialBusiness.socialBusinessBlurb
              : `${socialBusiness.socialBusinessBlurb
                  .substr(0, 150)
                  .replace("<p>", "")}...`
            : socialBusiness.socialBusinessBlurb}

          {truncateText ? (
            <Button
              onClick={toggleDescription}
              className={isExpanded ? "readmore active" : "readmore"}
              endIcon={<ArrowRightIcon sx={{ transition: "0.3s all" }} />}
              sx={{
                background: "#fff !important",
                color: "#000",
                fontSize: "14px",
                fontWeight: "bold",
                lineHeight: "18px",
                padding: "0",
                paddingLeft: "2px",
              }}
            >
              {isExpanded ? "Read Less" : "Read More"}
            </Button>
          ) : null}
        </Typography>

        <span
          style={{
            color: "#000",
            display: "block",
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          Service Locations
        </span>
        <Typography
          variant="caption"
          component="p"
          mb={2}
          sx={{ color: "#000", fontSize: "14px" }}
        >
          {socialBusiness.socialBusinessLocations}
        </Typography>
        <span
          style={{
            color: "#000",
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          Products and Service
        </span>
        <Typography
          variant="caption"
          component="p"
          mb={2}
          sx={{ color: "#000", fontSize: "14px" }}
        >
          {socialBusiness.socialBusinessServices}
        </Typography>
        <span
          style={{
            color: "#000",
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          Commitment Category
        </span>
        <Typography
          variant="caption"
          component="p"
          mb={2}
          sx={{ color: "#000", fontSize: "14px" }}
        >
          {socialBusiness.socialBusinessBeneficiaries}
        </Typography>

        <span
          style={{
            color: "#000",
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          EBA
        </span>
        <Typography
          variant="caption"
          component="p"
          mb={2}
          sx={{ color: "#000", fontSize: "14px" }}
        >
          {`Current EBA: ${
            socialBusiness.socialBusinessEba.length
              ? socialBusiness.socialBusinessEba
              : "N/A"
          }`}
        </Typography>
        <span
          style={{
            color: "#000",
            display: "block",
            fontSize: "14px",
            fontWeight: "bold",
            margin: "0",
          }}
        >
          Website
        </span>
        <Typography
          mb={2}
          sx={{
            color: "#697586",
            fontSize: "14px",
            margin: "0",
          }}
        >
          <a href={socialBusiness.socialBusinessWebsite} target="_blank" rel="noreferrer">
            {socialBusiness.socialBusinessWebsite}
          </a>
        </Typography>
      </CardContent>
    </Card>
  );
};
