"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeOrganisation = exports.decodeOrganisation = void 0;
var contactInfo_1 = require("../../interfaces/contactInfo");
var string_1 = require("../../utils/string");
var contactInfo_2 = require("../contactInfo");
var integration_1 = require("../integration");
var decodeOrganisation = function (organisation, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var presignedUrl, _a;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!(organisation.organisation_logo && organisation.organisation_logo.length > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, getImageUrl(organisation.organisation_logo)];
            case 1:
                _a = _c.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = "";
                _c.label = 3;
            case 3:
                presignedUrl = _a;
                return [2 /*return*/, {
                        abn: organisation.abn,
                        alertNotifications: organisation.alert_notifications,
                        contactInfo: organisation.contact_info
                            ? (0, contactInfo_2.decodeContactInfo)(organisation.contact_info)
                            : (0, contactInfo_1.createDefaultContactInfo)(),
                        created: new Date(organisation.created),
                        id: organisation.organisation_id,
                        integrations: ((_b = organisation === null || organisation === void 0 ? void 0 : organisation.integrations) === null || _b === void 0 ? void 0 : _b.map(function (i) { return (0, integration_1.decodeIntegration)(i); })) || [],
                        isContractor: organisation.is_contractor,
                        isSetup: organisation.is_setup,
                        maxActiveProjects: organisation.max_active_projects,
                        organisationLogo: presignedUrl,
                        organisationLogoSrc: organisation.organisation_logo,
                        organisationName: organisation.organisation_name,
                        planType: organisation.plan_type,
                        representativeEmail: organisation.representative_email,
                        representativeName: organisation.representative_name,
                        stripeId: organisation.stripe_id,
                        tradingName: organisation.trading_name,
                        transient: organisation.transient,
                        users: organisation.users,
                    }];
        }
    });
}); };
exports.decodeOrganisation = decodeOrganisation;
var encodeOrganisation = function (organisation) {
    var _a;
    var safeOrganisation = (0, string_1.makePostgresSafe)(organisation);
    return {
        abn: safeOrganisation.abn,
        alert_notifications: safeOrganisation.alertNotifications,
        contact_info: (0, contactInfo_2.encodeContactInfo)(safeOrganisation.contactInfo),
        contact_info_id: safeOrganisation.contactInfo.id,
        created: safeOrganisation.created,
        integrations: ((_a = organisation === null || organisation === void 0 ? void 0 : organisation.integrations) === null || _a === void 0 ? void 0 : _a.map(function (i) { return (0, integration_1.encodeIntegration)(i); })) || [],
        is_contractor: safeOrganisation.isContractor,
        is_setup: safeOrganisation.isSetup,
        max_active_projects: safeOrganisation.maxActiveProjects,
        organisation_id: safeOrganisation.id,
        organisation_logo: safeOrganisation.organisationLogoSrc,
        organisation_name: safeOrganisation.organisationName,
        plan_type: safeOrganisation.planType,
        representative_email: safeOrganisation.representativeEmail,
        representative_name: safeOrganisation.representativeName,
        stripe_id: safeOrganisation.stripeId,
        trading_name: safeOrganisation.tradingName,
        transient: safeOrganisation.transient,
        users: safeOrganisation.users,
    };
};
exports.encodeOrganisation = encodeOrganisation;
