import { getApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import { decodePolicyAnalyticsModel } from "social-pro-common/decoders/analytics";
import { PolicyAnalyticsModel } from "social-pro-common/entities/analytics";
import { PolicyAnalyticsModelLineItem } from "social-pro-common/interfaces/analytics";

export const usePolicyAnalytics = (
  projectId?: string,
  contractorPackageId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [policyAnalytics, setPolicyAnalytics] =
    useState<PolicyAnalyticsModelLineItem>();
  const [error, setError] = useState<string | null>(null);

  const getPolicyAnalyticsModel = useCallback(
    async (projectId: string, contractorPackageId?: string): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getPolicyAnalytics",
          "analytics",
          projectId,
          { contractorPackageId },
        );
        const res = decodePolicyAnalyticsModel(
          analysisResult.data as PolicyAnalyticsModel,
        );
        setPolicyAnalytics(res);
      } catch (error) {
        catchSentryError(error);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [getApiData, decodePolicyAnalyticsModel, catchSentryError],
  );

  useEffect(() => {
    if (projectId && contractorPackageId) {
      getPolicyAnalyticsModel(
        projectId,
        projectId !== contractorPackageId ? contractorPackageId : undefined,
      );
    }
  }, [projectId, contractorPackageId, getPolicyAnalyticsModel]);

  return {
    error,
    getPolicyAnalyticsModel,
    isAnalysisLoading,
    policyAnalytics,
  };
};