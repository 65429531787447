import { Box, Grid, Typography, Button } from "@mui/material";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";

interface ViewTrainingContractFormProps {
  selectedEmployee: EmployeeLineItem;
  handleClose: () => void;
}

export const ViewTrainingContractForm = ({
  handleClose,
  selectedEmployee,
}: ViewTrainingContractFormProps) => {
  return (
    <Box marginTop="3%">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" component="h6" fontWeight="500">
              Unique Student Id:
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="body2">
              {selectedEmployee.uniqueStudentId}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" component="h6" fontWeight="500">
              Trainee Number:
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="body2">
              {selectedEmployee.trainingContractNumber}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" component="h6" fontWeight="500">
              Trainee Qualification:
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="body2">
              {selectedEmployee.qualification}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" component="h6" fontWeight="500">
              Education Institute:
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="body2">
              {selectedEmployee.educationalInstitute}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" component="h6" fontWeight="500">
              Trainee Contract End Date:
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {selectedEmployee.traineeContractEndDate && (
              <Typography variant="body2">
                {selectedEmployee.traineeContractEndDate.toLocaleDateString()}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex !important",
            justifyContent: "center !important",
            paddingTop: "16px !important",
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
