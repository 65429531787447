import { getApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import * as React from "react";
import { decodeOrganisationSocialSpendSubDataModel } from "social-pro-common/decoders/organisationDashboard";
import { OrganisationSocialSpendSubDataModel } from "social-pro-common/entities/organisationDashboard";
import { OrganisationSocialSpendSubDataModelLineItem } from "social-pro-common/interfaces/organisationDashboard";

export const useOrganisationSocialSpendSubData = () => {
  const [isAnalysisLoading, setIsAnalysisLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [socialSpendSubData, setSocialSpendSubData] =
    React.useState<OrganisationSocialSpendSubDataModelLineItem>();

  const getSocialSpendSubData = React.useCallback(async () => {
    try {
      setIsAnalysisLoading(true);
      const analysisResult = await getApiData(
        "getSocialSpendSubData",
        "organisationDashboard",
        "",
      );
      const socialSpendSubData = decodeOrganisationSocialSpendSubDataModel(
        analysisResult.data as OrganisationSocialSpendSubDataModel,
      );
      setSocialSpendSubData(socialSpendSubData);
    } catch (error) {
      catchSentryError(error);
      setError("Could not fetch chart data");
    } finally {
      setIsAnalysisLoading(false);
    }
  }, [getApiData, decodeOrganisationSocialSpendSubDataModel, catchSentryError]);

  React.useEffect(() => {
    getSocialSpendSubData();
  }, [getSocialSpendSubData]);

  return {
    error,
    isAnalysisLoading,
    socialSpendSubData,
  };
};