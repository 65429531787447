import {
  Table,
  TableBody,
  TableContainer,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import {
  ProjectDetailLineItem,
  ProjectStatus,
} from "social-pro-common/interfaces/project";
import { KeyedObject } from "types";

// project imports
import { ProjectCard } from "./ProjectCard";
import { ProjectCardSkeleton } from "./ProjectCardSkeleton";
import { ProjectTableHeader } from "./ProjectTableHeader";
import box from "../../../assets/images/dashboard/box.png";

interface ProjectTableProps {
  loading: boolean;
  isContractor: boolean;
  userProfile: ProfileLineItem;
  projects: ProjectDetailLineItem[];
  archived: ProjectStatus;
  handleViewProject: (project: ProjectDetailLineItem) => void;
  handleEditProject: (projectId: string) => void;
  handleArchiveProject: (project: ProjectDetailLineItem) => void;
  handleAcceptPackage: (projectId: string, contractorPackageId: string) => void;
  handleOpeEditModal: () => void;
}

export const ProjectTable = ({
  archived,
  handleAcceptPackage,
  handleArchiveProject,
  handleEditProject,
  handleOpeEditModal,
  handleViewProject,
  isContractor,
  loading,
  projects,
  userProfile,
}: ProjectTableProps) => {
  const sortedProjects = projects.sort(
    (a: ProjectDetailLineItem, b: ProjectDetailLineItem) => {
      if (a.created.getTime() < b.created.getTime()) return -1;
      if (a.created.getTime() > b.created.getTime()) return 1;
      return 0;
    },
  );

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          margin: "0 40px",
          padding: "5px 0 !important",
        }}
      >
        {loading ? (
          <TableContainer>
            <Table className="project-table">
              <ProjectTableHeader loading />
              <TableBody>
                {loading
                  ? Array.from({ length: 10 }, (_, index) => (
                      <ProjectCardSkeleton key={index} />
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        {!loading && projects.length > 0 ? (
          <TableContainer>
            <Table className="project-table" data-test-id="project-table">
              <ProjectTableHeader loading={loading} />
              <TableBody>
                {sortedProjects.map((row: KeyedObject, index: number) => {
                  return (
                    <ProjectCard
                      key={`${row.id}-${index}`}
                      userProfile={userProfile}
                      project={row as ProjectDetailLineItem}
                      index={index}
                      handleViewProject={handleViewProject}
                      handleAcceptPackage={handleAcceptPackage}
                      handleEditProject={() => handleEditProject(row.id)}
                      handleArchiveProject={handleArchiveProject}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        {!loading && projects.length === 0 ? (
          <Grid
            data-test-id="empty-project-table"
            item
            xs={12}
            md={12}
            sx={{
              alignItems: "center",
              background: "#E9F0F5",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
              minHeight: "400px",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                justifyContent: "center",
              }}
              id="create-project-box"
            >
              <img src={box} alt="box" />
              {archived === ProjectStatus.Active &&
              isContractor &&
              userProfile.isAdmin ? (
                <>
                  <Typography
                    variant="h4"
                    style={{
                      color: "#87959F",
                      fontSize: "18px",
                      fontWeight: "400",
                    }}
                  >
                    Create a project
                  </Typography>

                  <StyledButton
                    id={"add-first-project-button"}
                    className="blackBtn"
                    loading={loading}
                    variant="contained"
                    onClick={handleOpeEditModal}
                  >
                    Add Project
                  </StyledButton>
                </>
              ) : (
                <Typography
                  variant="h4"
                  style={{
                    color: "#87959F",
                    fontSize: "18px",
                    fontWeight: "400",
                  }}
                >
                  No projects
                </Typography>
              )}
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};
