import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
  Button,
  Stack,
} from "@mui/material";
import { EployeeTabStatus } from "social-pro-common/interfaces/contractorEmployee";

interface ListEmployeesTabsProps {
  isLoading: boolean;
  value: EployeeTabStatus;
  handleChange: (
    event: React.SyntheticEvent,
    newValue?: EployeeTabStatus,
  ) => void;
}

export const ListEmployeesTabs = ({
  handleChange,
  isLoading,
  value,
}: ListEmployeesTabsProps): JSX.Element => {
  //TODO: https://github.com/mui/material-ui/issues/32894 Fix the console warnings

  return (
    <Box>
      {isLoading ? (
        <Stack direction={"row"} spacing={2}>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Active</Button>
          </Skeleton>
          <Skeleton animation="wave" variant={"rounded"}>
            <Button>Active</Button>
          </Skeleton>
        </Stack>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            value={EployeeTabStatus.Active}
            size="small"
            data-test-id="active-employees-btn"
          >
            Active
          </ToggleButton>
          <ToggleButton
            value={EployeeTabStatus.Archived}
            size="small"
            data-test-id="archived-employees-btn"
          >
            Archived
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
