import {
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  Avatar,
  Skeleton,
  Grid,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import React, { useState } from "react";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { SubcontractorNoteLineItem } from "social-pro-common/interfaces/subcontractorNote";

type NotesSectionProps = {
  notes: SubcontractorNoteLineItem[];
  organisation?: OrganisationLineItem;
  loading: boolean;
  onSubmit: (text: string) => void;
};

const PackageNotesSection: React.FC<NotesSectionProps> = ({
  loading,
  notes,
  onSubmit,
  organisation,
}) => {
  const [noteText, setNoteText] = useState("");

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoteText(event.target.value);
  };

  const handleNoteSubmit = () => {
    onSubmit(noteText);
    setNoteText("");
  };

  return (
    <Grid container spacing={2} direction={"row"}>
      <Grid item md={12}>
        {loading ? (
          <Skeleton variant="text" animation="wave" width="100%">
            <TextField
              label="Add a note"
              multiline
              fullWidth
              rows={4}
              variant="outlined"
              value={noteText}
            />
          </Skeleton>
        ) : (
          <TextField
            label="Add a note"
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            value={noteText}
            onChange={handleNoteChange}
          />
        )}
      </Grid>
      <Grid
        item
        md={12}
        sx={{
          alignItems: "flex-end",
          display: "flex !important",
          justifyContent: "end",
        }}
      >
        <StyledButton
          loading={loading}
          variant="contained"
          type="submit"
          onClick={handleNoteSubmit}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </StyledButton>
      </Grid>
      <Grid item md={12}>
        <List>
          {loading ? (
            <>
              <ListItem>
                <ListItemAvatar>
                  <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                    animation="wave"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={<Skeleton variant="text" animation="wave" />}
                  secondary={<Skeleton variant="text" animation="wave" />}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                    animation="wave"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={<Skeleton variant="text" animation="wave" />}
                  secondary={<Skeleton variant="text" animation="wave" />}
                />
              </ListItem>
            </>
          ) : (
            notes.map((note) => (
              <ListItem key={note.id}>
                <ListItemAvatar>
                  <Avatar
                    src={organisation?.organisationLogo}
                    alt={organisation?.organisationName}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={note.comment}
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {new Date(note.created).toLocaleString()}
                    </Typography>
                  }
                />
              </ListItem>
            ))
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default PackageNotesSection;
