import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { useEffect, useState } from "react";
import { ContractorPackageSetupLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  PackageLabourHourCommitmentLineItem,
  labourHourCommitmentTypeToDescription,
  labourHourCommitmentTypeToString,
} from "social-pro-common/interfaces/packageLabourHourCommitment";
import {
  FormikField,
  ProjectLineItem,
} from "social-pro-common/interfaces/project";
import { getBaseMultiplierForHours } from "social-pro-common/interfaces/projectLabourHourCommitment";
import {
  calculateTargetValueFromTargetValueRealised,
  calculateTargetValueRealised,
} from "social-pro-common/utils/calc";
import * as yup from "yup";

import { PackageLabourHourTableRow } from "../PackageLabourHourTableRow/PackageLabourHourTableRow";
import { PackageLabourHourTableRowSkeleton } from "../PackageLabourHourTableRow/PackageLabourHourTableRowSkeleton";

interface PackageSocialRequirementFormHoursProps {
  loading: boolean;
  project: ProjectLineItem;
  contractorPackageSetup: ContractorPackageSetupLineItem;
  commitmentLineItems: PackageLabourHourCommitmentLineItem[];
  isFinalStep: boolean;
  handleNext: (contractorPackage: ContractorPackageSetupLineItem) => void;
  handleBack: () => void;
}

export const PackageSocialRequirementFormHours = ({
  commitmentLineItems,
  contractorPackageSetup,
  handleBack,
  handleNext,
  isFinalStep,
  loading,
  project,
}: PackageSocialRequirementFormHoursProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [fields, setFields] = useState<FormikField[]>([]);

  useEffect(() => {
    const fieldsFromCommitments: FormikField[] = commitmentLineItems.map(
      (c) => {
        return {
          description:
            c.targetDescription ||
            labourHourCommitmentTypeToDescription(c.targetName),
          id: c.id,
          initialValue: c.targetValue,
          label:
            c.targetDescription ||
            labourHourCommitmentTypeToString(c.targetName),
          outcomeMultiplier: getBaseMultiplierForHours(
            contractorPackageSetup.contractorPackage.financial,
            c,
          ),
          targetValue: c.targetValue,
          targetValueRealised: c.targetValueRealised,
          title:
            c.targetDescription ||
            labourHourCommitmentTypeToString(c.targetName),
          type: yup.number().required().min(0),
        } as FormikField;
      },
    ) as FormikField[];

    setFields(fieldsFromCommitments);
  }, [commitmentLineItems]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const updatedCommitments = commitmentLineItems.map((c) => {
      if (c.id) {
        const matchingField = fields.find((f) => f.id === c.id);
        if (!matchingField) {
          throw new Error("Field not found");
        }
        return {
          ...c,
          targetValue: matchingField.targetValue,
          targetValueRealised: matchingField.targetValueRealised,
        } as PackageLabourHourCommitmentLineItem;
      }
    }) as PackageLabourHourCommitmentLineItem[];

    const commitmentsForPackage =
      contractorPackageSetup.contractorPackage.commitmentsHours.reduce(
        (
          acc: PackageLabourHourCommitmentLineItem[],
          c: PackageLabourHourCommitmentLineItem,
        ) => {
          const matchingCommitment = updatedCommitments.find(
            (uc) => uc.id === c.id,
          );
          if (matchingCommitment) {
            return [...acc, matchingCommitment];
          }
          return [...acc, c];
        },
        [] as PackageLabourHourCommitmentLineItem[],
      );
    handleNext({
      ...contractorPackageSetup,
      contractorPackage: {
        ...contractorPackageSetup.contractorPackage,
        commitmentsHours: commitmentsForPackage,
      },
    });
    setIsSubmitting(false);
  };

  return (
    <Box sx={{ padding: "25px 40px 0px !important" }}>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <SubTitle title={`${project.projectName} Labour Hours`} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box>
              <Table data-test-id="package-hours-commitment-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width="30%">
                      Commitment
                    </TableCell>
                    <TableCell align="center" width="30%">
                      Target %
                    </TableCell>
                    <TableCell align="center" width="30%">
                      Target Outcome
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading || fields.length == 0
                    ? Array.from({ length: 6 }, (_, i) => (
                        <PackageLabourHourTableRowSkeleton key={i} />
                      ))
                    : fields.map((field, index) => {
                        return (
                          <PackageLabourHourTableRow
                            key={`social-procurement-table-row-${index}`}
                            loading={loading || isSubmitting}
                            title={field.title}
                            field={field}
                            onChangeTarget={(numberValue: number) => {
                              const realisedValue =
                                calculateTargetValueRealised(
                                  numberValue,
                                  field.outcomeMultiplier,
                                );
                              setFields((prev) => {
                                return prev.map((f) => {
                                  if (f.id === field.id) {
                                    return {
                                      ...f,
                                      targetValue: numberValue,
                                      targetValueRealised: realisedValue,
                                    };
                                  }
                                  return f;
                                });
                              });
                            }}
                            onChangeTargetRealised={(numberValue: number) => {
                              const targetValue =
                                calculateTargetValueFromTargetValueRealised(
                                  numberValue,
                                  field.outcomeMultiplier,
                                );
                              setFields((prev) => {
                                return prev.map((f) => {
                                  if (f.id === field.id) {
                                    return {
                                      ...f,
                                      targetValue: targetValue,
                                      targetValueRealised: numberValue,
                                    };
                                  }
                                  return f;
                                });
                              });
                            }}
                          />
                        );
                      })}
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "end",
              marginTop: "16px",
              padding: "0px 0px 40px 0px",
            }}
          >
            <Stack direction="row" spacing={1}>
              <StyledButton
                loading={loading || isSubmitting}
                disabled={loading || isSubmitting}
                onClick={handleBack}
                variant="outlined"
              >
                Back
              </StyledButton>
              <StyledButton
                loading={loading || isSubmitting}
                disabled={loading || isSubmitting}
                variant="contained"
                type="submit"
              >
                {isFinalStep ? "Submit" : "Next"}
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
