import { listApiData, postApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState, useEffect } from "react";
import {
  decodeReportSubmission,
  encodeReportSubmission,
} from "social-pro-common/decoders/reportSubmission";
import { ReportSubmission } from "social-pro-common/entities/reportSubmission";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";

export const useReportSubmissionSustainabilityForProject = (
  projectId?: string,
  reportId?: string,
) => {
  const [reportSubmissions, setReportSubmissions] = useState<
    ReportSubmissionLineItem[]
  >([]);
  const [
    isReportSubmissionsForProjectLoading,
    setIsReportSubmissionsForProjectLoading,
  ] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listReportSubmissionsForProject = useCallback(
    async (id: string, reportId: string) => {
      try {
        setIsReportSubmissionsForProjectLoading(true);
        const reportResult = await listApiData(
          "listReportSubmissionsForProject",
          "reportSubmissionSustainability",
          id,
          { reportId },
        );
        reportResult.data.reverse();
        setReportSubmissions(
          reportResult.data.map((p) =>
            decodeReportSubmission(p as ReportSubmission),
          ),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not list reports");
      } finally {
        setIsReportSubmissionsForProjectLoading(false);
      }
    },
    [listApiData, decodeReportSubmission],
  );

  const updateReportSubmission = useCallback(
    async (report: ReportSubmissionLineItem) => {
      try {
        setIsReportSubmissionsForProjectLoading(true);
        const encodedReport = encodeReportSubmission(report);
        await postApiData(
          "updateReportSubmission",
          "reportSubmissionSustainability",
          encodedReport,
        );
        setReportSubmissions(
          reportSubmissions.filter((p) => p.id !== report.id),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not update report");
      } finally {
        setIsReportSubmissionsForProjectLoading(false);
      }
    },
    [postApiData, encodeReportSubmission, reportSubmissions],
  );

  const deleteReportSubmission = useCallback(
    async (report: ReportSubmissionLineItem) => {
      try {
        setIsReportSubmissionsForProjectLoading(true);
        const encodedReport = encodeReportSubmission(report);
        await postApiData(
          "deleteReportSubmission",
          "reportSubmissionSustainability",
          encodedReport,
        );
        setReportSubmissions(
          reportSubmissions.filter((p) => p.id !== report.id),
        );
      } catch (error) {
        catchSentryError(error);
        setError("Could not delete report");
      } finally {
        setIsReportSubmissionsForProjectLoading(false);
      }
    },
    [postApiData, encodeReportSubmission, reportSubmissions],
  );

  useEffect(() => {
    if (projectId && reportId) {
      listReportSubmissionsForProject(projectId, reportId);
    }
  }, [projectId, reportId, listReportSubmissionsForProject]);

  return {
    deleteReportSubmission,
    error,
    isReportSubmissionsForProjectLoading,
    reportSubmissions,
    updateReportSubmission,
  };
};