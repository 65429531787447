import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid, Skeleton, Typography } from "@mui/material";
import { PackageAnalyticsPolicy } from "@stories/molecules/PackageAnalyticsPolicy/PackageAnalyticsPolicy";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

interface PackageDetailsPolicyProps {
  isLoading: boolean;
  contractorPackage?: ContractorPackageLineItem;
}

export const PackageDetailsPolicy = ({
  contractorPackage,
  isLoading,
}: PackageDetailsPolicyProps) => {
  const { isProjectLoading, selectedProject } = useProjectContext();

  const loading = isLoading || isProjectLoading;
  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table"
        sx={{ margin: "0 0 20px", padding: "0 !important", width: "100%" }}
      >
        <Grid item md={12}>
          {loading ? (
            <Skeleton animation="wave">
              <Typography variant="h2">{"Policies"}</Typography>
            </Skeleton>
          ) : (
            <Typography variant="h2">{"Policies"}</Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table"
        sx={{
          margin: "0",
          padding: "0 !important",
          paddingLeft: "0px !important",
          width: "100%",
        }}
      >
        <PackageAnalyticsPolicy
          isLoading={loading}
          project={selectedProject}
          contractorPackage={contractorPackage}
        />
      </Grid>
    </>
  );
};
