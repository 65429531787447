import * as ExcelJs from "exceljs";
import {
  EmployeeSocialFactor,
  EmploymentLevel,
  EmploymentType,
  Gender,
  OccupationType,
} from "social-pro-common/entities/contractorEmployee";
import {
  createDefaultEmployee,
  EmployeeLineItem,
  Occupation,
  stringImportToGender,
  stringImportToOccupationType,
} from "social-pro-common/interfaces/contractorEmployee";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { validateABN } from "social-pro-common/utils/abn";
import { calculateAge, isValidDate } from "social-pro-common/utils/date";
import { toTitleCase } from "social-pro-common/utils/string";

interface XlsxEmployeeResult {
  employees: EmployeeLineItem[];
  errorMessage?: string;
}

//Worksite Id	Worksite	Damstra ID	Employee	Company	Company ABN	Mobilisation Name	Mobilisation Date	Job Title	Response Time	Crew Title	Department Name	Gender	Postal Code	Terminal	Total Time Spent	Question Begin Date	Punch Time	Birth Date	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses	Responses
const parseDamastraTWMS = (
  values: ExcelJs.CellValue[],
  rowIndex: number,
  userOrganisation: OrganisationLineItem,
  reject: (reason?: any) => void,
): EmployeeLineItem | null => {
  console.log(values);
  let employeeFirstName = values[1] as string | number | undefined;
  if (employeeFirstName && typeof employeeFirstName !== "string") {
    employeeFirstName = employeeFirstName.toString().trim();
  }
  let employeeLastName = values[2] as string | number | undefined;
  if (employeeLastName && typeof employeeLastName !== "string") {
    employeeLastName = employeeLastName.toString().trim();
  }
  if (
    employeeFirstName &&
    (employeeFirstName as string).length > 0 &&
    employeeFirstName !== "Insert rows to enter more data as required"
  ) {
    const newEmployee = createDefaultEmployee(userOrganisation);
    newEmployee.postCode = userOrganisation.contactInfo.postCode;
    newEmployee.employerAbn = userOrganisation.abn;

    newEmployee.employeeName = `${employeeLastName}, ${employeeFirstName}`;
    newEmployee.employeeIdentifier = values[3]
      ? (values[3] as string)
      : undefined;

    const primaryEmployer = values[4] as string;
    if (!primaryEmployer) {
      reject(`Error reading primary employer on line ${rowIndex}`);
    }
    newEmployee.primaryEmployer = toTitleCase(primaryEmployer);

    const employerAbn = values[5] as string;
    if (!employerAbn) {
      reject(
        `Error reading primary employer abn on line ${rowIndex} - Please add this after Company column`,
      );
    }
    newEmployee.employerAbn = employerAbn;
    newEmployee.employmentType =
      newEmployee.primaryEmployer === userOrganisation.organisationName
        ? EmploymentType.Direct
        : EmploymentType.Indirect;

    const dob = values[7] as string;
    if (!dob || dob.length > 10 || dob.length < 8) {
      reject(`Error reading dob on line ${rowIndex}`);
    }
    const dateParts = dob.split("/");
    if (dateParts.length !== 3) {
      reject(`Error reading dob on line ${rowIndex}`);
    }
    // month is 0-based, that's why we need dataParts[1] - 1
    const formattedDate = new Date(
      +dateParts[2],
      +dateParts[1] - 1,
      +dateParts[0],
    );
    if (dob && isValidDate(new Date(formattedDate))) {
      newEmployee.dob = new Date(formattedDate);
    }

    const parsedGender = stringImportToGender(values[8] as string);
    if (!parsedGender) {
      reject(`Error reading gender on line ${rowIndex}`);
    } else {
      newEmployee.gender = parsedGender;
    }

    const occupation = values[9] as string;
    if (!occupation) {
      reject(`Error reading occupation on line ${rowIndex}`);
    }

    newEmployee.occupation = occupation as Occupation;

    const occupationType = values[10] as string;
    if (!occupationType) {
      reject(
        `Error reading occupation type on line ${rowIndex} - Please add this after Job Title column`,
      );
    }

    const occupationTypeValue = stringImportToOccupationType(occupationType);
    if (!occupationTypeValue) {
      reject(`Error reading occupation type on line ${rowIndex}`);
    }
    newEmployee.occupationType = occupationTypeValue as OccupationType;

    if (values[15] && values[15] === "Yes") {
      newEmployee.employmentLevel = EmploymentLevel.Apprentice;
    } else if (values[16] && values[16] === "Yes") {
      newEmployee.employmentLevel = EmploymentLevel.Trainee;
    } else if (values[17] && values[17] === "Yes") {
      newEmployee.employmentLevel = EmploymentLevel.Trainee;
    } else if (values[19] && values[19] === "Yes") {
      newEmployee.employmentLevel = EmploymentLevel.Cadet;
    }

    if (values[11] && values[11] === "Yes") {
      newEmployee.socialFactors.push(
        EmployeeSocialFactor.AboriginalOrTorresStraitIslander,
      );
    }

    if (values[12] && values[12] === "Yes") {
      newEmployee.socialFactors.push(EmployeeSocialFactor.PersonWithDisability);
    }

    if (values[14] && values[14] === "Yes") {
      newEmployee.socialFactors.push(EmployeeSocialFactor.SingleParent);
    }

    if (values[21] && values[21] === "Yes") {
      newEmployee.socialFactors.push(EmployeeSocialFactor.LongTermUnemployed);
    }

    if (values[25] && values[25] === "Yes") {
      newEmployee.socialFactors.push(EmployeeSocialFactor.Veteran);
    }

    if (values[28] && values[28] === "Yes") {
      newEmployee.socialFactors.push(EmployeeSocialFactor.AsylumSeekerRefugee);
    }
    if (values[31] && values[31] === "Yes") {
      newEmployee.socialFactors.push(
        EmployeeSocialFactor.CulturallyDiverseMigrant,
      );
    }
    if (
      newEmployee.gender === Gender.Female &&
      newEmployee.dob &&
      calculateAge(newEmployee.dob) >= 45
    ) {
      newEmployee.socialFactors.push(EmployeeSocialFactor.WomenOver45);
    }
    return newEmployee;
  }
  return null;
};

//First Name	Last Name	ID	Company	Induction Date	Date of Birth	Gender	Job Title	Do you identify as an Aboriginal or Torres Strait Islander person?	Do you consider yourself to be person living with a disability? (mental, physical and/or intellectual disability)	Are you a carer of someone living with a disability?	Are you a single parent?	Have you graduated from University within the past 2 years?	Are you a Victorian Registered Apprentice?	Are you a Victorian Registered Trainee?	Are you undertaking a School Based Traineeship?	Have you completed Secondary College?	Are you currently undertaking an Undergraduate Degree?	Do you consider yourself to be a person experiencing long-term unemployment? (Equal to or greater than 6 months)	Are you a young person aged between 15-24?	Do you identify as a person who has had contact with criminal justice system?	Are you registered with a Jobs Victoria Mentor service (formerly Jobs Victoria Employment Network (JVEN))?	Are you a Military Veteran, or immediate family of a Military Veteran of the Australian Defence Forces?	Are you new to the construction industry? (12 months or less)	Are you an Australian Citizen or Permanent Resident?	Do you identify as a Refugee or Asylum Seeker?	Do you identify as a new Migrant (less than 2 years in Australia)?	Do you identify as a long-term unemployed or underemployed Migrant? (Working not in the field which you are qualified)	Are you Culturally and Linguistically Diverse?	Do you require a visa to work in Australia? If yes, you will be required to supply a copy of passport/visa (VEVO)
const parseDamastraEPP = (
  values: ExcelJs.CellValue[],
  rowIndex: number,
  userOrganisation: OrganisationLineItem,
  reject: (reason?: any) => void,
): EmployeeLineItem | null => {
  console.log(values);
  let employeeName = values[4] as string | number | undefined;
  if (employeeName && typeof employeeName !== "string") {
    employeeName = employeeName.toString().trim();
  }
  if (
    employeeName &&
    (employeeName as string).length > 0 &&
    employeeName !== "Insert rows to enter more data as required"
  ) {
    const newEmployee = createDefaultEmployee(userOrganisation) || "";
    if (!newEmployee) {
      reject("Error creating employee");
    }
    newEmployee.employeeName = `${employeeName}`;
    newEmployee.employeeIdentifier = values[3]
      ? (values[3] as string)
      : undefined;

    const parsedGender = stringImportToGender((values[14] as string) || "");
    if (!parsedGender) {
      reject(`Error reading gender on line ${rowIndex}`);
    } else {
      newEmployee.gender = parsedGender;
    }

    const dob = values[20] as Date;
    if (dob && isValidDate(new Date(dob))) {
      newEmployee.dob = new Date(dob);
    }

    const postCode = values[15] as string;
    if (postCode) {
      newEmployee.postCode = postCode;
    }

    const primaryEmployer = values[5] as string;
    if (!primaryEmployer) {
      reject(`Error reading primary employer on line ${rowIndex}`);
    }
    newEmployee.primaryEmployer = toTitleCase(primaryEmployer);

    newEmployee.employmentType =
      newEmployee.primaryEmployer === userOrganisation.organisationName
        ? EmploymentType.Direct
        : EmploymentType.Indirect;

    const employerAbn = values[6] as string;
    if (!employerAbn || !validateABN(employerAbn.toString())) {
      reject(`Error reading employer abn on line ${rowIndex}`);
    }
    newEmployee.employerAbn = employerAbn;

    const occupation = values[9] as string;
    if (!occupation) {
      reject(`Error reading occupation on line ${rowIndex}`);
    }
    newEmployee.occupation = occupation as Occupation;

    const occupationType = values[10] as string;
    if (!occupationType) {
      reject(
        `Error reading occupation type on line ${rowIndex} - Please add this after Job Title column`,
      );
    }

    const occupationTypeValue = stringImportToOccupationType(occupationType);
    if (!occupationTypeValue) {
      reject(`Error reading occupation type on line ${rowIndex}`);
    }
    newEmployee.occupationType = occupationTypeValue as OccupationType;

    if (values[25] && values[25] === "Yes") {
      newEmployee.employmentLevel = EmploymentLevel.Apprentice;
    } else if (values[24] && values[24] === "Yes") {
      newEmployee.employmentLevel = EmploymentLevel.Trainee;
    } else if (values[40] && values[40] === "Yes") {
      newEmployee.employmentLevel = EmploymentLevel.Trainee;
    } else if (values[22] && values[22] === "Yes") {
      newEmployee.employmentLevel = EmploymentLevel.Cadet;
    }

    if (values[36] && values[36] === "Yes") {
      newEmployee.socialFactors.push(
        EmployeeSocialFactor.AboriginalOrTorresStraitIslander,
      );
    }

    if (values[35] && values[35] === "Yes") {
      newEmployee.socialFactors.push(EmployeeSocialFactor.PersonWithDisability);
    }

    if (values[26] && values[26] === "Yes") {
      newEmployee.socialFactors.push(EmployeeSocialFactor.SingleParent);
    }

    if (values[23] && values[23] === "Yes") {
      newEmployee.socialFactors.push(EmployeeSocialFactor.LongTermUnemployed);
    }

    if (values[29] && values[29] === "Yes") {
      newEmployee.socialFactors.push(EmployeeSocialFactor.Veteran);
    }

    if (values[30] && values[30] === "Yes") {
      newEmployee.socialFactors.push(EmployeeSocialFactor.AsylumSeekerRefugee);
    }
    if (values[35] && values[35] === "Yes") {
      newEmployee.socialFactors.push(
        EmployeeSocialFactor.CulturallyDiverseMigrant,
      );
    }
    if (
      newEmployee.gender === Gender.Female &&
      newEmployee.dob &&
      calculateAge(newEmployee.dob) >= 45
    ) {
      newEmployee.socialFactors.push(EmployeeSocialFactor.WomenOver45);
    }
    return newEmployee;
  }
  return null;
};

export const readXlsxFileToEmployeeForDamstra = async (
  userOrganisation: OrganisationLineItem,
  file: File,
): Promise<XlsxEmployeeResult> => {
  const DATA_START_ROW = 3;
  let errorMessage: string;
  return new Promise((resolve, reject) => {
    const wb = new ExcelJs.Workbook();
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const data = new Uint8Array(reader.result as ArrayBuffer);
        wb.xlsx.load(data).then((workbook: ExcelJs.Workbook) => {
          const employees = [] as EmployeeLineItem[];
          let count = 0;

          const idMap = new Map();

          workbook.eachSheet((sheet: ExcelJs.Worksheet) => {
            if (count == 0) {
              sheet.eachRow((row: ExcelJs.Row, rowIndex: number) => {
                if (rowIndex >= DATA_START_ROW) {
                  const columnCount = row.cellCount;
                  let newEmployee: EmployeeLineItem | null = null;
                  if (columnCount === 32) {
                    newEmployee = parseDamastraTWMS(
                      row.values as ExcelJs.CellValue[],
                      rowIndex,
                      userOrganisation,
                      reject,
                    );
                  } else if (columnCount === 42) {
                    newEmployee = parseDamastraEPP(
                      row.values as ExcelJs.CellValue[],
                      rowIndex,
                      userOrganisation,
                      reject,
                    );
                  } else {
                    reject("Invalid Damstra file format");
                  }

                  if (!newEmployee) {
                    reject(`Could not parse employee on row - ${rowIndex}`);
                  } else if (!idMap.has(newEmployee.employeeName)) {
                    idMap.set(newEmployee.employeeName, rowIndex);
                    employees.push(newEmployee);
                  }
                }
              });
            }
            count += 1;
          });
          resolve({ employees, errorMessage });
        });
      } else {
        reject("Error reading file");
      }
    };
    reader.readAsArrayBuffer(file);
  });
};
