import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import ColourPicker from "@stories/molecules/ColourPicker/ColourPicker";
import { catchSentryError } from "@utils/sentry";
import * as React from "react";
import { ToastOptions, toast } from "react-toastify";
import { ForecastUnitType } from "social-pro-common/entities/forecastData";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  ForecastDataLineItem,
  ForecastModelLineItem,
  formatForecastDataTypeToString,
} from "social-pro-common/interfaces/forecastData";

import ForecastInputTable from "./ForecastInputTable/ForecastInputTable";

interface ForecastModalProps {
  open: boolean;
  loading: boolean;
  contractorPackage: ContractorPackageLineItem;
  existingForecast: ForecastModelLineItem;
  updateForecast: (forecast: ForecastModelLineItem) => void;
  handleClose: () => void;
}

export default function ForecastModal({
  contractorPackage,
  existingForecast,
  handleClose,
  loading,
  open,
  updateForecast,
}: ForecastModalProps) {
  const [selectedForecast, setSelectedForecast] =
    React.useState<ForecastModelLineItem>(existingForecast);

  const totalDataValue = existingForecast.data.reduce((acc, data) => {
    const newValue = selectedForecast.data.find((d) => d.id === data.id);
    if (newValue) {
      return acc + (newValue.value || 0);
    }
    return acc + (data.value || 0);
  }, 0);

  const onSubmit = async () => {
    try {
      if (selectedForecast) {
        await updateForecast({ ...selectedForecast });
        handleClose();
      }
    } catch (error) {
      catchSentryError(error);
      toast("Could not save forecast data!", {
        type: "error",
      } as ToastOptions);
    }
  };

  const handleDataChange = (forecastData: ForecastDataLineItem) => {
    const newForecastData = selectedForecast.data.map((d) => {
      if (d.id === forecastData.id) {
        return forecastData;
      }
      return d;
    });
    setSelectedForecast({ ...selectedForecast, data: newForecastData });
  };

  const isHoursTarget = existingForecast.units === ForecastUnitType.Hour;
  const matchingCommitment = isHoursTarget
    ? contractorPackage?.commitmentsHours.find(
        (c) =>
          c.projectLabourHourCommitmentId ===
          existingForecast.projectLabourHourCommitmentId,
      )
    : contractorPackage?.commitmentsSpend.find(
        (c) =>
          c.projectSocialSpendCommitmentId ===
          existingForecast.projectSocialSpendCommitmentId,
      );

  if (!matchingCommitment) {
    return null;
  }

  const tooManyHours = totalDataValue > matchingCommitment.targetValueRealised;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      component="div"
      sx={{
        backgroundColor: "white",
        margin: "auto",
        marginBottom: "5%",
        marginTop: "5%",
        width: "30%",
      }}
      className="bgBlueOverlay"
    >
      <Grid
        container
        spacing={3}
        sx={{
          backgroundColor: "white",
          borderRadius: "12px",
          padding: "20px",
        }}
      >
        <Grid item xs={12} sm={12}>
          <Typography variant="h2">{"Edit Forecast Details"}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              background: "#000 !important",
              borderRadius: "50%",
              color: "#fff",
              marginRight: "10px",
              padding: "5px",
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12}>
          <StyledTextField
            loading={loading}
            disabled={loading}
            id={`forecast-model-name-input`}
            name="forecastDataValue"
            label="Forecast Model Name"
            margin="dense"
            fullWidth
            onChange={(e) => {
              setSelectedForecast({
                ...selectedForecast,
                modelName: e.target.value,
              });
            }}
            value={selectedForecast.modelName}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Autocomplete
            fullWidth
            id="unit-type"
            options={Object.values(ForecastUnitType)}
            getOptionLabel={(option) =>
              formatForecastDataTypeToString(option) || ""
            }
            disabled={loading}
            value={selectedForecast.units}
            onChange={(
              event: React.SyntheticEvent<Element, Event>,
              value: ForecastUnitType | null,
            ) => {
              if (value) {
                setSelectedForecast({
                  ...selectedForecast,
                  units: value,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="unitType"
                label="Unit Type"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <ColourPicker
            hex={selectedForecast.colour}
            setHex={(hex: string) => {
              setSelectedForecast({
                ...selectedForecast,
                colour: hex,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ padding: "10px" }}>
          <ForecastInputTable
            loading={loading}
            forecast={selectedForecast}
            handleChange={handleDataChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} alignItems={"center"} textAlign={"center"}>
          <Typography
            variant="h4"
            sx={{
              color: tooManyHours ? "red" : undefined,
            }}
          >
            {`${!isHoursTarget ? "$" : ""}${totalDataValue} / ${
              !isHoursTarget ? "$" : ""
            }${matchingCommitment.targetValueRealised}${
              isHoursTarget ? " Hours" : ""
            }`}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} />
        <Grid item xs={6} sm={6}>
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <StyledButton
              loading={loading}
              onClick={() => {
                handleClose();
              }}
              variant="outlined"
            >
              Cancel
            </StyledButton>
            <StyledButton
              loading={loading}
              disabled={tooManyHours}
              variant="contained"
              onClick={onSubmit}
            >
              Save
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
    </Modal>
  );
}
