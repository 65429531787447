import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { TutorialCard } from "@stories/molecules/TutorialCard/TutorialCard";

interface TutorialSectionProps {
  loading: boolean;
}

export const TutorialSection = ({
  loading,
}: TutorialSectionProps): JSX.Element => {
  const onChange = (url: string) => {
    console.log(url);
  };

  const tutorials = [
    {
      description: "How to create a project in SocialPro",
      title: "How to create a project",
      url: "https://d3g8bb645c6csx.cloudfront.net/create-a-project.mp4",
    },
    {
      description: "How to add and manage employees in SocialPro",
      title: "Add and manage employees",
      url: "https://d3g8bb645c6csx.cloudfront.net/add-and-manage-employees.mp4",
    },
    {
      description: "How to add and manage subcontractors in SocialPro",
      title: "Add and manage subcontractors",
      url: "https://d3g8bb645c6csx.cloudfront.net/add-and-manage-subcontractors.mp4",
    },
  ];
  return (
    <>
      <Grid item md={12}>
        {loading ? (
          <Stack direction="column">
            <Skeleton animation="wave">
              <Typography
                variant="h2"
                component="div"
                fontWeight="bold"
                fontSize="28px"
              >
                Video Tutorials
              </Typography>
            </Skeleton>

            <Skeleton animation="wave">
              <Typography sx={{ mt: 1.5 }} variant="h4" fontWeight="normal">
                Quick and informative video tutorials covering features and
                products
              </Typography>
            </Skeleton>
          </Stack>
        ) : (
          <Stack direction="column">
            <Typography
              variant="h2"
              component="div"
              fontWeight="bold"
              fontSize="28px"
            >
              Video Tutorials
            </Typography>
            <Typography sx={{ mt: 1.5 }} variant="h4" fontWeight="normal">
              Quick and informative video tutorials covering features and
              products
            </Typography>
          </Stack>
        )}
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          marginTop: "20px",
        }}
      >
        {tutorials.map((tutorial, index) => {
          return (
            <Grid md={4} key={index}>
              <TutorialCard
                key={index}
                title={tutorial.title}
                description={tutorial.description}
                url={tutorial.url}
                loading={false}
                handleChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
