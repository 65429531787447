import { Card, CardContent, CardHeader, Chip, Typography } from "@mui/material";
import { useState } from "react";
import {
  createDefaultIntegrationInfoLineItem,
  IntegrationInfoLineItem,
  IntegrationType,
} from "social-pro-common/interfaces/integration";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";

import { DamstraImportModal } from "./DamstraImportModal/DamstraImportModal";

interface DamstraImportProps {
  loading: boolean;
  integration?: IntegrationInfoLineItem;
  userOrganisation?: OrganisationLineItem;
  handleIntegrationChange: (integration: IntegrationInfoLineItem) => void;
}

export const DamstraImport = ({
  handleIntegrationChange,
  integration,
  loading,
  userOrganisation,
}: DamstraImportProps) => {
  console.log("Integration", integration);
  const [open, setOpen] = useState(false);
  return (
    <>
      <Card
        className="direct-card"
        variant="outlined"
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
        onClick={() => setOpen(true)}
      >
        <CardHeader
          avatar={
            <img
              style={{ backgroundColor: "#8FC9F9" }}
              width={80}
              alt={"Damstra"}
              src={"damstra.png"}
            />
          }
          title={"Damstra"}
        />
        <CardContent>
          <Typography
            variant="caption"
            component="p"
            id="fullDescription"
            mb={2}
            sx={{
              color: "#000",
              fontSize: "14px",
              lineHeight: "24px",
              marginTop: "5px",
            }}
          >
            Import Damstra Employee Excel files.
          </Typography>
          <Chip
            label={integration?.enabled ? "Enabled" : "Not enabled"}
            color={integration?.enabled ? "success" : "warning"}
          />
        </CardContent>
      </Card>
      {open && userOrganisation ? (
        <DamstraImportModal
          loading={loading}
          open={open}
          toggle={integration?.enabled || false}
          handleClose={() => setOpen(false)}
          onSubmit={async (enabled: boolean): Promise<void> => {
            if (integration) {
              await handleIntegrationChange({
                ...integration,
                enabled: enabled,
              });
            } else {
              const newIntegration = createDefaultIntegrationInfoLineItem(
                userOrganisation.id,
                IntegrationType.DamstraImport,
              );
              await handleIntegrationChange({
                ...newIntegration,
                enabled,
              });
            }
            setOpen(false);
          }}
        />
      ) : null}
    </>
  );
};
