import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSustainabilityAnalytics } from "@hooks/crud/analytics/useSustainabilityAnalytics";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { MonthSelect } from "@stories/atoms/MonthSelect/MonthSelect";
import { ContractorAnalyticsEnvSustainability } from "@stories/molecules/ContractorAnalyticsEnvSustainability/ContractorAnalyticsEnviroSustainability";
import { SustainabilityTypeTabs } from "@stories/molecules/SustainabilityTypeTabs/SustainabilityTypeTabs";
import { useEffect, useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { dateToString, stringToDate } from "social-pro-common/utils/date";

interface PackageDetailsEnviroSustainabilityProps {
  loading: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
}
export const PackageDetailsEnviroSustainability = ({
  contractorPackage,
  loading,
  project,
}: PackageDetailsEnviroSustainabilityProps) => {
  const [reportId, setReportDate] = useState<string>(dateToString(new Date()));

  const [greenMetric, setGreenMetric] = useState<GreenMetricLineItem>();
  const { isPrimaryPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { isAnalysisLoading, sustainabilityAnalytics } =
    useSustainabilityAnalytics(reportId, project?.id, contractorPackage?.id);

  useEffect(() => {
    if (project) {
      setGreenMetric(project.greenMetrics.find((gm) => gm));
    }
  }, [project]);

  const isLoading = loading || isProjectLoading || isAnalysisLoading;
  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table"
        sx={{
          alignItems: "center", // Vertically align items
          display: "flex",
          margin: "0 0 15px",
          padding: "0 !important",
          width: "100%",
        }}
      >
        <Grid item md={6}>
          {loading ? (
            <Skeleton animation="wave">
              <Typography variant="h2">
                {"Package Environmental Sustainability"}
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="h2">
              {"Package Environmental Sustainability"}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            alignItems: "center", // Ensure alignment for this item
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box className="newStyleField monthSelect">
            <MonthSelect
              data-test-id="month-select"
              loading={loading}
              project={selectedProject}
              contractorPackage={contractorPackage}
              isPrimaryPackage={isPrimaryPackage}
              setDate={(date: Date) => {
                setReportDate(dateToString(date));
              }}
              date={stringToDate(reportId)}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <SustainabilityTypeTabs
          isLoading={isLoading}
          value={greenMetric}
          greenMetrics={project?.greenMetrics || []}
          setGreenMetric={setGreenMetric}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className="analytic-table"
        sx={{ margin: "0", width: "100%" }}
      >
        <ContractorAnalyticsEnvSustainability
          loading={isLoading}
          greenMetric={greenMetric}
          targets={
            greenMetric && sustainabilityAnalytics?.targets.length
              ? sustainabilityAnalytics?.targets.filter(
                  (t) => t.greenMetricId === greenMetric?.id,
                )
              : []
          }
        />
      </Grid>
    </>
  );
};
