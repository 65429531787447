import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { EmployeeTableSubRow } from "@stories/molecules/EmployeeTable/EmployeeTableSubRow";
import * as React from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";

interface EmployeeListModalProps {
  open: boolean;
  existingEmployee: EmployeeLineItem;
  handleClose: () => void;
}

export default function EmployeeListModal({
  existingEmployee,
  handleClose,
  open,
}: EmployeeListModalProps) {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className="employpop bgBlueOverlay"
    >
      <DialogTitle sx={{ margin: "0" }}>{"Employee Details"}</DialogTitle>{" "}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          "&:hover": {
            backgroundColor: "#143E7C",
          },
          background: "black",
          color: "white",
          padding: "5px",
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <EmployeeTableSubRow employee={existingEmployee} />
      </DialogContent>
    </Dialog>
  );
}
