import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  TableCell,
  TableRow,
  IconButton,
  Stack,
  Skeleton,
  Button,
} from "@mui/material";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";

export const ProjectCardSkeleton = () => {
  return (
    <TableRow style={{ cursor: "pointer", padding: "10px 0 0" }}>
      <TableCell>
        <Skeleton animation="wave" variant="circular">
          <span className={"archivedIcon"} />
        </Skeleton>
      </TableCell>
      <TableCell>{<Skeleton animation="wave" />}</TableCell>
      <TableCell>{<Skeleton animation="wave" />}</TableCell>
      <TableCell>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
          <CircularProgressWithLabel value={100} color={"success"} />
        </Skeleton>
      </TableCell>
      <TableCell align="center">
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          <Stack direction="row" spacing={1}>
            <Skeleton animation="wave" variant="rounded">
              <Button
                color="primary"
                sx={{
                  "&:hover ": {
                    background: "#e3f2fd",
                  },
                  borderColor: "#0063c6",
                  color: "#0063c6",
                }}
                size="large"
               />
            </Skeleton>
            <Skeleton animation="wave" variant="circular">
              <IconButton
                color="primary"
                sx={{
                  "&:hover ": {
                    background: "#e3f2fd",
                  },
                  borderColor: "#0063c6",
                  color: "#0063c6",
                }}
                size="large"
              >
                <EditIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Skeleton>
            <Skeleton animation="wave" variant="circular">
              <IconButton color="error" size="large">
                <DeleteIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Skeleton>
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
