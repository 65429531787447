import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

import { ContractorOneTimePackageTableRow } from "./ContractorOneTimePackageTableRow";
import { ContractorOneTimePackageTableRowSkeleton } from "./ContractorOneTimePackageTableRowSkeleton";

interface ContractorOneTimePackageTableProps {
  loading: boolean;
  userProfile?: ProfileLineItem;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  contractorPackages: ContractorPackageLineItem[];
  isPrimaryPackage: boolean;
  searchValue: string;
  handleOpen: () => void;
  handleEditPackage: (contractorPackageId: string) => void;
  handleDeletePackage: (contractorPackage: ContractorPackageLineItem) => void;
  resendInvite: (contractorPackageId: string) => void;
}

export const ContractorOneTimePackageTable = ({
  contractorPackage,
  contractorPackages,
  handleDeletePackage,
  handleEditPackage,
  isPrimaryPackage,
  loading,
  project,
  resendInvite,
  searchValue,
  userProfile,
}: ContractorOneTimePackageTableProps) => {
  let filteredPackages = contractorPackages.filter((p) =>
    p.contractorName.toLowerCase().includes(searchValue.toLowerCase()),
  );

  filteredPackages = filteredPackages.sort((a, b) => {
    if (a.packageNumber > b.packageNumber) {
      return 1;
    }
    return -1;
  });

  const tableHeader = (
    <TableHead>
      <TableRow sx={{ marginTop: "0px !important" }}>
        <TableHeader
          loading={loading}
          title={"No."}
          sx={{ width: "5%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Subcontractor"}
          sx={{ width: "40%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Description"}
          sx={{ width: "20%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Target Date"}
          sx={{ width: "15%" }}
          align="left"
        />
        <TableHeader
          loading={loading}
          title={"Status"}
          sx={{ width: "15%" }}
          align="center"
        />
        <TableHeader
          align="center"
          loading={loading}
          title={"Action"}
          sx={{ width: "10%" }}
        />
      </TableRow>
    </TableHead>
  );
  return (
    <TableContainer>
      {loading ? (
        <Table stickyHeader aria-label="sticky table">
          {tableHeader}
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <ContractorOneTimePackageTableRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table
          data-test-id="contractor-package-table"
          stickyHeader
          aria-label="sticky table"
        >
          {tableHeader}
          <TableBody>
            {project &&
              contractorPackage &&
              filteredPackages &&
              filteredPackages.map(
                (row: ContractorPackageLineItem, index: number) => (
                  <ContractorOneTimePackageTableRow
                    key={`contractor-package-row-${index}`}
                    project={project}
                    userProfile={userProfile}
                    contractorPackage={contractorPackage}
                    subPackage={row}
                    handleDeletePackage={handleDeletePackage}
                    handleEditPackage={handleEditPackage}
                    resendInvite={resendInvite}
                  />
                ),
              )}
            {filteredPackages.length === 0 ? (
              <NoRows colSpan={isPrimaryPackage ? 10 : 8} title={"Suppliers"} />
            ) : null}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
