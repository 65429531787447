import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Skeleton, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { SearchInput } from "@stories/molecules/SearchInput/SearchInput";
import { SubContractorTabs } from "@stories/molecules/SubContractorTabs/SubContractorTabs";
import ContractorPackageModal from "@stories/organisms/ContractorPackageModal/ContractorPackageModal";
import OneTimeContractorPackageModal from "@stories/organisms/OneTimeContractorPackageModal/OneTimeContractorPackageModal";
import { ContractorOneTimePackageTable } from "@stories/organisms/Tables/ContractorOneTimePackageTable";
import { ContractorPackageTable } from "@stories/organisms/Tables/ContractorPackageTable";
import { userHasManagerAccess, userIsAdmin } from "@utils/projectAccess";
import { IS_PROD_ENV } from "init";
import { useState } from "react";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

export const ContractorPackages = () => {
  const [step, setStep] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setStep(newValue);
  };

  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const {
    contractorPackages,
    createContractorPackageSetUp,
    deleteContractorPackage,
    getContractorPackageSetup,
    isContractorPackageLoading,
    resendInvite,
    updateContractorOneTimePackage,
    updateContractorPackage,
  } = useContractorPackage(selectedProject?.id);

  const [selectedPackageId, setSelectedPackageId] = useState<string>();

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openOneTimeModal, setOpenOneTimeModal] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string>("");
  const handleSearchChange = () => {
    setSearchValue(inputValue.length > 0 ? inputValue : "");
  };

  const handleClose = () => {
    setOpenEditModal(false);
    setSelectedPackageId(undefined);
  };
  const handleOpen = () => {
    setSelectedPackageId(undefined);
    setOpenEditModal(true);
  };

  const handleCloseOneTime = () => {
    setOpenOneTimeModal(false);
    setSelectedPackageId(undefined);
  };
  const handleOpenOneTime = () => {
    setSelectedPackageId(undefined);
    setOpenOneTimeModal(true);
  };

  const handleEditPackage = (contractorPackageId: string) => {
    setSelectedPackageId(contractorPackageId);

    if (step) {
      setOpenOneTimeModal(true);
    } else {
      setOpenEditModal(true);
    }
  };

  const handleDeletePackage = (
    contractorPackage: ContractorPackageLineItem,
  ) => {
    deleteContractorPackage(contractorPackage);
  };

  contractorPackages.sort((a, b) => {
    if (a.contractorId !== b.contractorId) {
      return a.contractorId === userOrganisation?.id ? -1 : 1;
    }
    return a.created.getTime() - b.created.getTime();
  });

  const filteredPackages = contractorPackages.filter((contractorPackage) =>
    step === 0
      ? contractorPackage.packageType !== PackageType.OneTime
      : contractorPackage.packageType === PackageType.OneTime,
  );

  const loading =
    isContractorPackageLoading || isAuthProfileLoading || isProjectLoading;
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Project Subcontractors"
          subTitle={"Manage Subcontractors"}
        />
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 40px 10px",
          }}
        >
          {!IS_PROD_ENV ? (
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                padding: "0 !important",
                textAlign: "right",
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                alignContent={"start"}
                justifyContent={"start"}
              >
                <SubContractorTabs
                  isLoading={loading}
                  value={step}
                  handleChange={handleChange}
                />
              </Stack>
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            md={IS_PROD_ENV ? 12 : 6}
            sx={{
              padding: "0 !important",
              textAlign: "left",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignContent={"end"}
              justifyContent={"end"}
            >
              <SearchInput
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleSearchChange={handleSearchChange}
              />

              {loading ? (
                <>
                  <Skeleton animation="wave" variant="rounded">
                    <StyledButton
                      className="blackBtn"
                      loading={loading}
                      variant="contained"
                      disabled={loading}
                      startIcon={<AddCircleIcon />}
                    >
                      Add Subcontractor
                    </StyledButton>
                  </Skeleton>
                </>
              ) : userIsAdmin(userProfile) ||
                userHasManagerAccess(selectedProject) ? (
                <>
                  {step ? (
                    <StyledButton
                      className="blackBtn"
                      data-test-id="add-one-time-button"
                      variant="contained"
                      loading={loading}
                      disabled={loading}
                      onClick={handleOpenOneTime}
                      startIcon={<AddCircleIcon />}
                    >
                      Add One Time Supplier
                    </StyledButton>
                  ) : (
                    <StyledButton
                      data-test-id="add-contractor-button"
                      className="blackBtn"
                      variant="contained"
                      loading={loading}
                      disabled={loading}
                      onClick={handleOpen}
                      startIcon={<AddCircleIcon />}
                    >
                      Add Subcontractor
                    </StyledButton>
                  )}
                </>
              ) : null}
            </Stack>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: "0 40px",
            overflow: "hidden",
            padding: "5px 0 !important",
          }}
          className="contract-table"
        >
          {step ? (
            <ContractorOneTimePackageTable
              loading={loading}
              project={selectedProject}
              isPrimaryPackage={isPrimaryPackage}
              searchValue={searchValue}
              userProfile={userProfile}
              contractorPackages={filteredPackages}
              handleOpen={handleOpen}
              resendInvite={resendInvite}
              handleEditPackage={handleEditPackage}
              handleDeletePackage={handleDeletePackage}
            />
          ) : (
            <ContractorPackageTable
              loading={loading}
              project={selectedProject}
              contractorPackage={contractorPackage}
              isPrimaryPackage={isPrimaryPackage}
              searchValue={searchValue}
              userProfile={userProfile}
              contractorPackages={filteredPackages}
              handleOpen={handleOpen}
              resendInvite={resendInvite}
              handleEditPackage={handleEditPackage}
              handleDeletePackage={handleDeletePackage}
            />
          )}
        </Grid>
        {selectedProject && openEditModal ? (
          <ContractorPackageModal
            open={openEditModal}
            loading={loading}
            handleClose={handleClose}
            project={selectedProject}
            createContractorPackage={createContractorPackageSetUp}
            updateContractorPackage={updateContractorPackage}
            selectedPackageId={selectedPackageId}
            packageCount={filteredPackages.length}
          />
        ) : null}
        {selectedProject && openOneTimeModal ? (
          <OneTimeContractorPackageModal
            open={openOneTimeModal}
            loading={loading}
            handleClose={handleCloseOneTime}
            getContractorOneTimePackage={getContractorPackageSetup}
            createContractorOneTimePackage={createContractorPackageSetUp}
            updateContractorOneTimePackage={updateContractorOneTimePackage}
            project={selectedProject}
            selectedPackageId={selectedPackageId}
            packageCount={filteredPackages.length}
          />
        ) : null}
      </Grid>
    </>
  );
};
