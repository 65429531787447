import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { LongtermUnemployedForm } from "@stories/molecules/LongtermUnemployedForm/LongtermUnemployedForm";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { SupportingDocumentLineItem } from "social-pro-common/interfaces/supportingDocument";

interface LongtermUnemployedModalProps {
  open: boolean;
  employee: EmployeeLineItem;
  supportingDocument?: SupportingDocumentLineItem;
  setOpen: (value: boolean) => void;
  setEmployee: (employee: EmployeeLineItem) => void;
}

export default function LongtermUnemployedModal({
  employee,
  open,
  setEmployee,
  setOpen,
  supportingDocument,
}: LongtermUnemployedModalProps) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className={"bgBlueOverlay"}
    >
      <DialogTitle sx={{ fontSize: "24px", padding: "20px 40px 10px" }}>
        {"Add Longterm Unemployment Details"}
      </DialogTitle>
      <DialogContent sx={{ padding: "20px 40px 40px" }}>
        <LongtermUnemployedForm
          employee={employee}
          setEmployee={setEmployee}
          handleClose={handleClose}
          supportingDocument={supportingDocument}
        />
      </DialogContent>
    </Dialog>
  );
}
