import {
  AuthenticateChallenge,
  useAuthContext,
} from "@hooks/context/useAuthContext";
import {
  Box,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { ToastOptions, toast } from "react-toastify";
import * as Yup from "yup";

// ===============================|| JWT LOGIN ||=============================== //

interface LoginFormProps {
  showResetPassword: boolean;
  setEmailAddress: (email: string) => void;
  setPassword: (password: string) => void;
  setShowResetPassword: (showResetPassword: boolean) => void;
}

const LoginForm = ({
  setEmailAddress,
  setPassword,
  setShowResetPassword,
}: LoginFormProps) => {
  const { signIn } = useAuthContext();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          // .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const emailAddress = values.email.toLowerCase();
          const challengeName = await signIn(
            emailAddress,
            values.password.trim(),
          );

          if (challengeName === AuthenticateChallenge.NEW_PASSWORD_REQUIRED) {
            setEmailAddress(emailAddress);
            setPassword(values.password.trim());
            setShowResetPassword(true);
          } else if (challengeName === AuthenticateChallenge.AUTH_ERROR) {
            setStatus({ success: false });
            setErrors({ submit: "Invalid email or password" });
          } else {
            setErrors({
              submit:
                "An error occured. Please contact support or try again later.",
            });
          }
        } catch (err: any) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          toast("Something went wrong!", { type: "error" } as ToastOptions);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
                sx={{ margin: "auto" }}
              >
                <TextField
                  id="email"
                  type="email"
                  label="Email"
                  disabled={isSubmitting}
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="info@socialproapp.com"
                />
                {touched.email && errors.email && (
                  <FormHelperText error>{errors.email}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
                sx={{ margin: "auto" }}
              >
                <TextField
                  id="password"
                  type="password"
                  label="Password"
                  disabled={isSubmitting}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="*******"
                />
                {touched.password && errors.password && (
                  <FormHelperText error>{errors.password}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            {errors.submit && (
              <Grid item xs={12}>
                <Box sx={{ justifyContent: "center", margin: "auto" }}>
                  <FormHelperText
                    error
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <Typography data-test-id="login-error" align="center">
                      {errors.submit}
                    </Typography>
                  </FormHelperText>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ fontFamily: "Roboto", textAlign: "center" }}>
                <Button
                  id="submit_button"
                  disabled={isSubmitting}
                  variant={"contained"}
                  fullWidth
                  type="submit"
                  size="large"
                  sx={{
                    background: "#054F81",
                    margin: "0",
                    minWidth: "200px",
                    transition: "0.3s all",
                    width: "auto",
                  }}
                >
                  Sign In
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <a href="/forgot-password">Forgot password?</a>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
