import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";
import LongtermUnemployedModal from "@stories/organisms/LongtermUnemployedModal/LongtermUnemployedModal";
import React from "react";
import { EmployeeSocialFactor } from "social-pro-common/entities/contractorEmployee";
import {
  EmployeeLineItem,
  LongtermUnemployedCategory,
  employeeLongtermUnemployedCategoryToString,
} from "social-pro-common/interfaces/contractorEmployee";

interface EmployeeSupportingDocsFormRowSelectProps {
  employee: EmployeeLineItem;
  setEmployee: (employee: EmployeeLineItem) => void;
}

export const EmployeeSupportingDocsFormRowSelectUnemployment = ({
  employee,
  setEmployee,
}: EmployeeSupportingDocsFormRowSelectProps) => {
  const theme = useTheme();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const supportingDocument = employee.supportingDocuments.find(
    (d) => d.supportingDocumentType === EmployeeSocialFactor.LongTermUnemployed,
  );
  return (
    <>
      <TableRow>
        <TableCell align="left" colSpan={1}>
          <Typography>{"Long-term Unemployment Contract Details"}</Typography>
          <Stack>
            <Typography variant="caption">
              Program Provider:{" "}
              {supportingDocument?.documentDescription ? (
                supportingDocument?.documentDescription
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
            <Typography variant="caption">
              Category:{" "}
              {supportingDocument?.documentCategory ? (
                employeeLongtermUnemployedCategoryToString(
                  supportingDocument?.documentCategory as LongtermUnemployedCategory,
                )
              ) : (
                <Typography variant="caption" sx={{ color: "red" }}>
                  required
                </Typography>
              )}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip TransitionComponent={Zoom} placement="top" title="Edit">
              <IconButton
                color="error"
                sx={{
                  "&:hover ": {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.light,
                  },
                  background: theme.palette.primary.light,
                  borderColor: "black",
                  color: theme.palette.primary.dark,
                }}
                size="large"
                onClick={() => setModalIsOpen(true)}
              >
                <UploadTwoToneIcon
                  sx={{
                    fontSize: "1.1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
      <LongtermUnemployedModal
        open={modalIsOpen}
        supportingDocument={supportingDocument}
        employee={employee}
        setOpen={setModalIsOpen}
        setEmployee={setEmployee}
      />
    </>
  );
};
