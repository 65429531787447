import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useDashboardV2 } from "@hooks/crud/dashboard/useDashboardV2";
import { Divider, Grid } from "@mui/material";
import { useState } from "react";
import {
  dateToString,
  getNumberOfDaysUntilNextReport,
} from "social-pro-common/utils/date";

import { DashboardSideBar } from "./DashboardSidebar";
import { LabourHourProgress } from "./LabourHourProgress/LabourHourProgress";
import { LocalContentProgress } from "./LocalContentProgress/LocalContentProgress";
import { ProjectProgressV2 } from "./ProjectProgress/ProjectProgressV2";
import { SocialSpendProgress } from "./SocialSpendProgress/SocialSpendProgress";
import "react-circular-progressbar/dist/styles.css";
import { SustainabilityProgress } from "./SustainabilityProgress/SustainabilityProgress";
import { WidgetContainer } from "./WidgetContainer/WidgetContainer";

export const DashboardV2 = () => {
  const [reportId] = useState<string>(dateToString(new Date()));

  const { isAuthLoading } = useAuthContext();
  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { dashboardData, isAnalysisLoading } = useDashboardV2(
    selectedProject?.id,
    contractorPackage?.id,
    reportId,
  );

  const loading = isAuthLoading || isProjectLoading || isAnalysisLoading;

  const numberOfDaysForPackageSubmission =
    getNumberOfDaysUntilNextReport(selectedProject);

  return (
    <>
      <Grid container sx={{ minHeight: "100vh" }}>
        <Grid item md={9}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px 30px !important",
            }}
          >
            <ProjectProgressV2
              loading={loading}
              project={selectedProject}
              projectProgress={dashboardData?.projectProgress}
            />
            {/* <RegionMap
              projectId={selectedProject?.id}
              contractorPackageId={contractorPackage?.id}
              reportType="hours"
              state={selectedProject?.projectAddress?.state}
            /> */}
            {/* <RegionMap
              projectId={selectedProject?.id}
              contractorPackageId={contractorPackage?.id}
              reportType="spend"
              state={selectedProject?.projectAddress?.state}
            /> */}
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ marginTop: "16px", width: "100%" }}
            />
            <WidgetContainer
              loading={loading}
              widgets={dashboardData?.widgets}
            />
            {loading || selectedProject?.commitmentsHours.length ? (
              <LabourHourProgress
                loading={loading}
                project={selectedProject}
                isPrimaryPackage={isPrimaryPackage}
                contractorPackage={contractorPackage}
                labourHourProgress={dashboardData?.labourHourProgress}
                reportId={reportId}
              />
            ) : null}
            {loading || selectedProject?.commitmentsSpend.length ? (
              <SocialSpendProgress
                loading={loading}
                isPrimaryPackage={isPrimaryPackage}
                project={selectedProject}
                contractorPackage={contractorPackage}
                socialSpendProgress={dashboardData?.spendProgress}
                reportId={reportId}
              />
            ) : null}
            {loading || selectedProject?.localContents.length ? (
              <LocalContentProgress
                loading={loading}
                projectId={selectedProject?.id}
                packageId={contractorPackage?.id}
                localContentProgress={dashboardData?.localContentProgress}
                reportId={reportId}
              />
            ) : null}
            {loading || selectedProject?.greenMetrics.length ? (
              <SustainabilityProgress
                loading={loading}
                projectId={selectedProject?.id}
                packageId={contractorPackage?.id}
                sustainabilityProgress={dashboardData?.sustainabilityProgress}
                reportId={reportId}
              />
            ) : null}
          </Grid>
        </Grid>
        <DashboardSideBar
          loading={loading}
          subcontractorActivity={dashboardData?.subcontractorActivity}
          numberOfDaysForPackageSubmission={numberOfDaysForPackageSubmission}
        />
      </Grid>
    </>
  );
};
