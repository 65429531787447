import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface EmployeeNameExistsDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
export const EmployeeNameExistsDialog = ({
  isOpen,
  setIsOpen,
}: EmployeeNameExistsDialogProps) => {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: 435, width: "80%" } }}
      maxWidth="xs"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="bgBlueOverlay"
    >
      <DialogTitle>{"Employee already exists"}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2}>
            <Typography>
              {`An employee with this name or code exists. Please rename the employee or use a different code.`}
            </Typography>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
