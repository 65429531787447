import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Chip,
  InputAdornment,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { getEmployeeFactors } from "@utils/employeSocialFactors";
import { wordsToThreeLetterAcronym } from "@utils/string";
import { isNumber } from "lodash";
import { FocusEvent } from "react";
import {
  EmployeeLineItem,
  isInvalidMPSG,
} from "social-pro-common/interfaces/contractorEmployee";
import {
  EmployeeHoursToDateLineItem,
  LabourHourLineItem,
} from "social-pro-common/interfaces/labourHour";
import {
  labourHourCommitmentTypeToAbbreviatedString,
  labourHourCommitmentTypeToString,
} from "social-pro-common/interfaces/packageLabourHourCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { formatDecimalPlacesNumerical } from "social-pro-common/utils/number";

interface LabourHourTableRowProps {
  loading: boolean;
  disabled: boolean;
  isSubmitting: boolean;
  project: ProjectLineItem;
  employee: EmployeeLineItem;
  labourHour: LabourHourLineItem;
  previousEmployeeLabourHour?: EmployeeHoursToDateLineItem;
  showStudiedHours?: boolean;
  readOnly?: boolean;
  handleUpdateLabourHour: (labourHour: LabourHourLineItem[]) => Promise<void>;
}

export const LabourHourTableRow = ({
  disabled,
  employee,
  handleUpdateLabourHour,
  isSubmitting,
  labourHour,
  loading,
  previousEmployeeLabourHour,
  project,
  readOnly,
  showStudiedHours,
}: LabourHourTableRowProps) => {
  const hoursToDate =
    (previousEmployeeLabourHour?.hoursWorked || 0) +
    (previousEmployeeLabourHour?.hoursStudied || 0);
  const socialFactors = getEmployeeFactors(employee, project);

  const onChangeHoursWorked = async (e: FocusEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value.replace(",", ""));
    if (
      labourHour.amountOfHoursWorked !== value &&
      isNumber(value) &&
      isFinite(value)
    ) {
      handleUpdateLabourHour([
        {
          ...labourHour,
          amountOfHoursWorked: value,
          socialFactors: socialFactors.map((sf) => sf.id),
          supportingDocuments: employee.supportingDocuments,
        },
      ]);
    }
  };

  const onChangeHoursStudied = async (e: FocusEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value.replace(",", ""));
    if (
      labourHour.amountOfHoursStudied !== value &&
      isNumber(value) &&
      isFinite(value)
    ) {
      handleUpdateLabourHour([
        {
          ...labourHour,
          amountOfHoursStudied: value,
          socialFactors: socialFactors.map((sf) => sf.id),
          supportingDocuments: employee.supportingDocuments,
        },
      ]);
    }
  };

  const invalidMpsgEmployee = showStudiedHours && isInvalidMPSG(employee);

  const isDisabled = isSubmitting || disabled;
  return (
    <TableRow key={employee.id}>
      <TableCell align="left" scope="row">
        {`${employee.employeeName}${
          employee.employeeIdentifier ? ` (${employee.employeeIdentifier})` : ""
        }`}
      </TableCell>
      <TableCell align="left">
        {socialFactors.map((sf, i) => {
          return (
            <Tooltip
              TransitionComponent={Zoom}
              key={`tool-tip-employee-social-factor-${i}`}
              title={
                sf.targetDescription ||
                labourHourCommitmentTypeToString(sf.targetName)
              }
            >
              <Chip
                size="small"
                label={
                  sf.targetDescription
                    ? wordsToThreeLetterAcronym(sf.targetDescription)
                    : labourHourCommitmentTypeToAbbreviatedString(sf.targetName)
                }
                onMouseDown={(event) => event.stopPropagation()}
                style={{
                  backgroundColor: sf.colour,
                  color: "#fff",
                }}
              />
            </Tooltip>
          );
        })}
      </TableCell>
      <TableCell align="center" className="center">
        <Typography align="center">
          {formatDecimalPlacesNumerical(hoursToDate)}
        </Typography>
      </TableCell>
      {showStudiedHours ? (
        <TableCell align="center" className="transpField center">
          <StyledTextField
            loading={false}
            disabled={isDisabled || invalidMpsgEmployee || readOnly}
            className="search tableInput"
            id={`${employee.id}-labour-hour-row`}
            name="labourHoursStudied"
            margin="dense"
            fullWidth
            onBlur={onChangeHoursStudied}
            value={labourHour.amountOfHoursStudied}
            sx={{ width: "75%" }}
            InputProps={{
              endAdornment: invalidMpsgEmployee ? (
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Training contract details not set for this employee."
                >
                  <InputAdornment
                    position="end"
                    sx={{
                      color:
                        isDisabled || invalidMpsgEmployee
                          ? "rgba(0, 0, 0, 0.26)"
                          : "red",
                    }}
                  >
                    <ErrorOutlineOutlinedIcon
                      color="error"
                      sx={{ color: "red" }}
                    />
                  </InputAdornment>
                </Tooltip>
              ) : (
                <InputAdornment
                  position="end"
                  sx={{
                    color:
                      isDisabled || invalidMpsgEmployee
                        ? "rgba(0, 0, 0, 0.26)"
                        : "red",
                  }}
                >
                  hours
                </InputAdornment>
              ),
              inputComponent: NumericFormatCustom as any,
            }}
          />
        </TableCell>
      ) : (
        <TableCell />
      )}
      <TableCell align="center" className="transpField center">
        <StyledTextField
          loading={loading}
          className="search tableInput"
          disabled={isDisabled || invalidMpsgEmployee || readOnly}
          id={`${employee.id}-labour-hour-row`}
          name="labourHoursWorked"
          margin="dense"
          fullWidth
          sx={{ width: "75%" }}
          onBlur={onChangeHoursWorked}
          value={labourHour.amountOfHoursWorked}
          InputProps={{
            endAdornment: invalidMpsgEmployee ? (
              <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                title="Training contract details not set for this employee."
              >
                <InputAdornment
                  position="end"
                  sx={{
                    color:
                      isDisabled || invalidMpsgEmployee
                        ? "rgba(0, 0, 0, 0.26)"
                        : "red",
                  }}
                >
                  <ErrorOutlineOutlinedIcon
                    color="error"
                    sx={{ color: "red" }}
                  />
                </InputAdornment>
              </Tooltip>
            ) : (
              <InputAdornment
                position="end"
                sx={{
                  color:
                    isDisabled || invalidMpsgEmployee
                      ? "rgba(0, 0, 0, 0.26)"
                      : "red",
                }}
              >
                hours
              </InputAdornment>
            ),
            inputComponent: NumericFormatCustom as any,
          }}
        />
      </TableCell>
    </TableRow>
  );
};
