import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { useState } from "react";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

interface LidpTableRowProps {
  loading: boolean;
  reportId?: string;
  localContent: PackageLocalContentLineItem;
  showExpected: boolean;
  readOnly?: boolean;
  handleEdit: () => void;
  onDelete: () => void;
}

export const LidpTableRow = ({
  handleEdit,
  loading,
  localContent,
  onDelete,
  readOnly,
  reportId,
  showExpected,
}: LidpTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell align="left">{localContent.itemDescription}</TableCell>
        <TableCell align="left">{localContent.manufacturer}</TableCell>
        <TableCell align="left">{localContent.supplier}</TableCell>
        <TableCell align="left">
          <Typography align="center">
            {formatDecimalPlaces(localContent.value)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography align="center">
            {formatDecimalPlaces(localContent.steelMass)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography align="center">
            {formatDecimalPlaces(localContent.localValueAdd)}%
          </Typography>
        </TableCell>
        <TableCell align="center" sx={{ backgroundColor: "#f4f4f3" }}>
          <Typography align="center">
            {formatDecimalPlaces(localContent.importValueAdd)}%
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography align="center">
            {formatDecimalPlaces(localContent.contractContent, 4)}%
          </Typography>
        </TableCell>
        <TableCell align="center" sx={{ backgroundColor: "#f4f4f3" }}>
          <Typography align="center">
            {formatDecimalPlaces(localContent.anzValueAdd, 4)}%
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography align="center">{localContent.smeCount}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography align="center">{localContent.supplierCount}</Typography>
        </TableCell>
        <TableCell align="right" sx={{ textAlign: "right !important" }}>
          {!showExpected && !readOnly ? (
            <Box display="flex" gap="10px" justifyContent={"right"}>
              <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
                <IconButton
                  color="primary"
                  size="large"
                  disabled={loading}
                  onClick={() => handleEdit()}
                >
                  <EditIcon
                    sx={{
                      fontSize: "1.1rem",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                placement="top"
                title="Delete"
                TransitionComponent={Zoom}
              >
                <IconButton
                  color="error"
                  size="large"
                  disabled={
                    loading ||
                    (!!localContent.reportId &&
                      localContent.reportId !== reportId)
                  }
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  <DeleteIcon
                    sx={{
                      fontSize: "1.1rem",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
        </TableCell>
      </TableRow>
      {!readOnly ? (
        <ConfirmationDialog
          message={"Are you sure you want to delete this Local Content item?"}
          open={openConfirmDialog}
          title={"Delete Local Content"}
          intent={"error"}
          buttonText={"Delete"}
          onCancel={() => setOpenConfirmDialog(false)}
          onConfirm={() => onDelete()}
        />
      ) : null}
    </>
  );
};
